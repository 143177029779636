
import {useLazyQuery,useQuery,useMutation} from '@apollo/client'
import {Allclientes,ClientesByFilter,GetClieContactbyId,GetClieContactDetbyId,GetDataContact_mail_ById,GetClientContactbyIdContact,GetDataContactClient_ById,AllclientesActivo} from "../model/query/clientes/clientes"
import {CreateClient,UpdateClient,BloqueoClient,createContactClient,createContactTypeClient,DeleteContactTypeClient,createUpdateClientContactSesion,ResetPasswordClient,contactBloqContacto,deleteContactClient} from "../model/mutation/clientes/Setclient"


export const useAllClientes = () =>{
    const result = useQuery(Allclientes);
    return result;
}

export const useAllClientesActivo = () =>{
    const result = useQuery(AllclientesActivo);
    return result;
}

export const useClientesByFilter = () =>{
    const result = useLazyQuery(ClientesByFilter);
    return result;
}

export const useCreateclient = () =>{
   const result = useMutation(CreateClient,{refetchQueries:[{query:Allclientes},{query:AllclientesActivo}]});
    return result;
}

export const useEditclient = () =>{
   const result = useMutation(UpdateClient,{refetchQueries:[{query:Allclientes},{query:AllclientesActivo}]});
    return result;
}

export const useBlocclient = () =>{
   const result = useMutation(BloqueoClient,{refetchQueries:[{query:Allclientes},{query:AllclientesActivo}]});
    return result;
}

export const useBlocContact = () =>{
     const result = useMutation(contactBloqContacto);
    return result;
}



export const useClieContactbyId = () =>{
    const result = useLazyQuery(GetClieContactbyId);
    return result;
}

export const useClieContactbyIdSesion = (props) =>{
   
    const result = useQuery(GetDataContactClient_ById,{variables: {id_Sesion:props}});
    return result;
}


export const useClientContactbyIdContact = () =>{
    const result = useLazyQuery(GetClientContactbyIdContact);
    return result;
}


export const useContactDetbyId = () =>{
    const result = useLazyQuery(GetClieContactDetbyId);
    return result;
}

export const useContactmailById = () =>{
    const result = useLazyQuery(GetDataContact_mail_ById);
    return result;
} 

export const useDeleteContactClient = () =>{
    const result = useMutation(deleteContactClient);
    return result;
}


export const useCreateUpdateContactSesion = () =>{
    const result = useMutation(createUpdateClientContactSesion);
    return result;
}

export const useCreateContactTypeClient = () =>{
    const result = useMutation(createContactTypeClient);
    return result;
}

export const useCreateContactClient = () =>{
    const result = useMutation(createContactClient);
    return result;
}

export const useDeleteContactTypeClient = () =>{
    const result = useMutation(DeleteContactTypeClient);
    return result;
}

export const useResetpassClient = () =>{
   const result = useMutation(ResetPasswordClient,{});
   return result;
}





export default useAllClientes


