import gql from "graphql-tag";

export const Loginuser = gql`
query login($User:String!,$pass:String!){
   GetLogin(Users:$User,Pass:$pass){
    success
    message
    ids
    token
    IniPage {
      sesionId
      link
      paginaInicio
      menukey
    }
    Menu {
      sesionId
      menukey
      icon
      title
    }
    SubMenu {
      sesionId
      menukey
      dkey
      title
      link
    }
    
  } 
}`;

export const Validatelogin = gql`
query Validatelogin($Token:String!){ 
   GetLogin_validate(token:$Token)
    {
      success
      message
      modal
      token
    }
}`; 

export const TokenValidate = gql`
query TokenValidate($Token:String!){ 
GetTokenValidate(token:$Token){
    success
    token
    

   }

}`; 