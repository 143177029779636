import React  from 'react'
import { Button, Form, Input, Space, Row, Col ,Select } from 'antd';
import '../../Assets/Css/page.css';
import {useAllCargo,useAllEstCivil,useAllNacionalidad,useAllSexo} from '../../hooks/custom-general';
const { Option } = Select;


const SearchPostEmpleado = ({HandleDataEmp,SetdataEmp,handleviewnewpostulant}) => {
    const [form5] = Form.useForm();
   
    
    const{cargoAlldata} = useAllCargo();
    const{civilAlldata} = useAllEstCivil();
    const{NacAlldata} = useAllNacionalidad();
    const{SexoAlldata} = useAllSexo();
 
   
    const onAdd = values => {
       // console.log(values)
       
        return HandleDataEmp(values); 
          
    }

    const  handleClear = () => {
             form5.resetFields();
              return SetdataEmp([])
    }

    const  handlenew  = () => {
      return handleviewnewpostulant();
    }
   
    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;

    };

    


    return (
        
             <Form
                form={form5}
                name="NuevaPostulacion"
                className="ant-advanced-search-form"
                initialValues={{
                    preserve:false,
                    resetFields:true,
                }}
                onFinish={onAdd}
            >
                
               
                <Row gutter={24}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6 }}>
                <Form.Item
                    name="Cargo"
                    initialValue={[]}
                     rules={[
                                    { required: false,message: 'Seleccione Cargo'},
                                
                                ]}
                >
                   <Select placeholder="Ingrese Cargo" mode="multiple" showArrow maxTagCount= 'responsive' 
                        showSearch
                        filterOption={(input, option) =>
                         option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                   >
                   {cargoAlldata.map((data) => {
                        return (
                            <Option key={data.cargo_id} value={data.cargo_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6 }}>
                <Form.Item
                    name="Rut"
                    initialValue=""
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Ingrese Rut"
                    />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6 }}>
                <Form.Item
                    name="Nombre"
                    initialValue=""
                    hasFeedback
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Ingrese Nombre"
                    />
                </Form.Item>
                </Col>
               
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6 }}>
                <Form.Item 
                    name="Nacionalidad"
                     initialValue={[]}
                    
                >
                    <Select placeholder="Ingrese Nacionalidad" mode="multiple" showArrow maxTagCount= 'responsive' >
                   {NacAlldata.map((data) => {
                        return (
                            <Option key={data.nacionalidad_id} value={data.nacionalidad_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                    </Select>
                </Form.Item>
                </Col>

                </Row>
                
                <Row gutter={24}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6 }}>
                <Form.Item
                    name="Estado_civil"
                    initialValue={[]}
                >
                    <Select placeholder="Ingrese Estado civil" mode="multiple" showArrow   maxTagCount= 'responsive' >
                     {civilAlldata.map((data) => {
                        return (
                            <Option key={data.estado_civil_id} value={data.estado_civil_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                   
                    </Select>
                </Form.Item>
                </Col>
               
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6 }}>
                <Form.Item
                    name="Sexo"
                    initialValue={[]}
                >
                   <Select placeholder="Ingrese Sexo" mode="multiple" maxTagCount= 'responsive' showArrow >
                    {SexoAlldata.map((data) => {
                        return (
                            <Option key={data.sexo_id} value={data.sexo_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })} 
                    </Select>
                </Form.Item>
                </Col>
                
                <Col xs={{ span: 12, offset: 0 }} lg={{ span: 4 }}>
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={false} >
                        Buscar
                    </Button>
                     <Button key="back" onClick={handleClear}>
                        Limpiar
                    </Button>
                    </Space>

                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6 }}>
                <Form.Item>
                    <Button key="nuevo" type="primary" onClick={handlenew} >
                        Nuevo Postulante
                    </Button>
                </Form.Item>
                </Col>
                </Row>
            </Form>
        
    )
}

export default SearchPostEmpleado
