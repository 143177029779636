import React, {useEffect}  from 'react'
import { Modal, Button, Form, Input, Space, Row, Col ,DatePicker,Select,Typography} from 'antd';
import '../../Assets/Css/page.css';
import moment from 'moment'
import {useAllNiveles} from '../../hooks/custom-general';
const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;

const ModalEditEducacion = ({viewEditEdu, Loading, handleSavePostEdu, handleCancelEducacion, EditDataEdu}) => {
    const [formEditEducacion] = Form.useForm();
     const{NivelesAlldata} = useAllNiveles();
    const dateFormat = 'DD/MM/YYYY'
   
    useEffect(() => {
        
        if (ModalEditEducacion) {
           formEditEducacion.setFieldsValue({
                NivelCursado: EditDataEdu?.ultimo_nivel_cursado,
                InstEducativa: EditDataEdu?.Inst_Educativa,
                Titulo: EditDataEdu?.Titulo,
                NotaEgreso: EditDataEdu?.Nota_egreso,
                FechaEgreso:  moment(EditDataEdu?.fecha_egreso,'DD/MM/YYYY'),
                Comentarios: EditDataEdu?.descripcion,
              
              
            });
        }
       
    }, [EditDataEdu, formEditEducacion]);
    
    const onAdd = async values => {
        return handleSavePostEdu(values);   
    }

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;

    };

    return (
        <Modal
            visible={viewEditEdu}
            forceRender
            title="Editar Educación"
            onCancel={handleCancelEducacion}
            footer={[
                
               
            ]}
        >
             <Form
                form={formEditEducacion}
                name="NuevoPostulante"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                onFinish={onAdd}
            >
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Title level={5}>{"Seleccione"}</Title>
                </Col>
                </Row>
              
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="NivelCursado"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Ultimo Nivel Cursado',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Ultimo Nivel Cursado" >
                  {NivelesAlldata.map((data) => {
                        return (
                            <Option key={data.nivel_id} value={data.nivel_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}  
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="InstEducativa"
                    tooltip="Ingrese Descripción Cargo"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Institución Educativa'  
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Institución Educativa"
                    />
                </Form.Item>
                </Col>

                </Row>
                
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Titulo"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Titulo',
                        },
                    ]}
                    hasFeedback
                >
                   <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Titulo Educativo"
                    />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="NotaEgreso"
                    tooltip="NotaEgreso"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Nota Egreso'   
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="number"
                        placeholder="Nota Egreso"
                    />
                </Form.Item>
                </Col>

                </Row>

                 <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="FechaEgreso"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Fecha Egreso',
                        },
                    ]}
                    hasFeedback
                >
                    <DatePicker  style={{ width: '100%' }} format={dateFormat}  placeholder="Fecha Egreso"/>
                </Form.Item>
                </Col>
                

                </Row>
                
                
                
                
                <Form.Item
                    name="Comentarios" 
                >
                 <TextArea rows={4} placeholder="Comentarios"  style={{ width: '100%' }} />
                </Form.Item>
               
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                       Editar
                    </Button>
                    <Button key="back" onClick={handleCancelEducacion}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ModalEditEducacion
