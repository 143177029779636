import { useState, useEffect} from 'react'
import {useLazyQuery,useMutation} from '@apollo/client'
import {Menuall} from "../model/query/modulos/Menuall"
import {permisos} from "../model/query/modulos/permisos"
import {InsertModPermisos} from "../model/mutation/modulos/SetModPermisos"
//import {Loginuser} from "../model/query/login/Loginuser"


export const usePermisosUser = () =>{
    const result = useLazyQuery(permisos);
    return result;
}

export const useMenuAll = () => {
    const [getMenuAlldata] = useLazyQuery(Menuall);
    const [menuAlldata,SetmenuAlldata] = useState([])
    const[error,SetError] = useState(null)
    const[isLoading,SetIsLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  userData =  await getMenuAlldata()
                if(userData){
                    SetmenuAlldata(userData.data.GetMenuTransfer)
                } 
            }
            catch(error){
                SetError(error.message)
            }
            SetIsLoading(false)

        }
        getDataAll()

    },[getMenuAlldata])

     return{
         menuAlldata,
         error,
         isLoading
     }
}

export const useInsertModPermisos = () =>{
   const result = useMutation(InsertModPermisos,{});
    return result;
}



export default usePermisosUser


