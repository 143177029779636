import {useQuery,useMutation} from '@apollo/client'
import {MsgAlertByIdSesion,MsgAlertClientByIdSesion} from "../model/query/MenAlert/AlertaMensaje"
import {UpdateMsgAlertByIdSesion} from "../model/mutation/MenAlert/AlertaMensaje"


//Querys
export const useMensajeAlert = (props) =>{
 
    const result = useQuery(MsgAlertByIdSesion,{variables: {id_Sesion:props,modulo:"postulacion"},
        fetchPolicy: 'network-only',
        pollInterval: 15000,
        onCompleted: () => console.log('called')});
    return result;
}

export const useUpdateMensajeAlert = (props) =>{
   
    const result = useMutation(UpdateMsgAlertByIdSesion,{refetchQueries:[{query:MsgAlertByIdSesion,variables: {id_Sesion:props,modulo:"postulacion"}}]});
    return result;
}

export const useMensajeClientAlert = (props) =>{
    
    const result = useQuery(MsgAlertClientByIdSesion,{variables: {id_Sesion:props,modulo:"postulacioncliente"},
        fetchPolicy: 'network-only',
        pollInterval: 15000,
        onCompleted: () => console.log('called')});
    return result;
}

export const useUpdateMensajeClientAlert = (props) =>{
   
    const result = useMutation(UpdateMsgAlertByIdSesion,{refetchQueries:[{query:MsgAlertClientByIdSesion,variables: {id_Sesion:props,modulo:"postulacioncliente"}}]});
    return result;
}

export const useMensajeAuAlert = (props) =>{
 
    const result = useQuery(MsgAlertByIdSesion,{variables: {id_Sesion:props,modulo:"Ausencias"},
        fetchPolicy: 'network-only',
        pollInterval: 15000,
        onCompleted: () => console.log('called')});
    return result;
}

export const useUpdateMensajeAuAlert = (props) =>{
   
    const result = useMutation(UpdateMsgAlertByIdSesion,{refetchQueries:[{query:MsgAlertByIdSesion,variables: {id_Sesion:props,modulo:"Ausencias"}}]});
    return result;
}

export const useMensajeVacAlert = (props) =>{
 
    const result = useQuery(MsgAlertByIdSesion,{variables: {id_Sesion:props,modulo:"Vacaciones"},
        fetchPolicy: 'network-only',
        pollInterval: 15000,
        onCompleted: () => console.log('called')});
    return result;
}

export const useUpdateMensajeVacAlert = (props) =>{
   
    const result = useMutation(UpdateMsgAlertByIdSesion,{refetchQueries:[{query:MsgAlertByIdSesion,variables: {id_Sesion:props,modulo:"Vacaciones"}}]});
    return result;
}

export default useMensajeAlert


