import React,{useState} from 'react'
import { Modal, Button, Form,  Space, Row, Col,Select,DatePicker } from 'antd';
import '../../../Assets/Css/page.css';
import 'moment/locale/es-us';
import locale from 'antd/es/date-picker/locale/es_ES';
import { useContratadoByIdCliente } from '../../../hooks/custom-contratos'
const { Option } = Select;
const { RangePicker } = DatePicker;




const ModalRelogDel = ({viewDel, Loading, handleDelReloj, handleCancelRelojDel,clientes}) => {
    const [selEmpleados] = useContratadoByIdCliente();
    const [form5] = Form.useForm();
     const dateFormat = 'DD/MM/YYYY'
    const [empleados,setEmpleados] = useState([]);
     
    const onAdd = async values => {
       
        handleDelReloj(values)
        form5.resetFields();
          
    }

    const onClieChange = async values => {
        const emplesel = await  selEmpleados({variables:{
            Id_cliente:values,
        }})
       
 
        if(emplesel.loading===false){
            setEmpleados(emplesel.data.GetContratadoByIdCliente)
        }
         
    }

    return (
        <>
        <Modal
            visible={viewDel}
            title={"Borrar ingresos Reloj"}
            onCancel={handleCancelRelojDel}
            footer={[]} 
        >

        
        <Form
                form={form5}
                name="BorrarReloj"
                initialValues={{
                    preserve:false,
                    resetFields:true,
                }}
                onFinish={onAdd}
            >
                
                
                <Row gutter={24}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Cliente"
                     rules={[
                                { required: true,message: 'Seleccione Cliente'},
                            ]}
                >
               <Select placeholder="Selección Cliente"  showArrow maxTagCount= 'responsive'  onChange={onClieChange} showSearch
                        filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                  {clientes.map(data => {
                        return (
                            <Option key={data.Id_cliente} value={data.Id_cliente}>
                                {data.Nombre}
                            </Option>
                        );
                    })}  
                </Select>
                </Form.Item>
                </Col>
               
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12}}>
                <Form.Item
                    name="Id_contrato"
                     rules={[
                                { required: true,message: 'Seleccione Empleado'},
                            ]}
                >
                <Select placeholder="Selección Empleado"  showArrow maxTagCount= 'responsive'  showSearch
                        filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                 {empleados.map((data) => {
                        return (
                            <Option key={data.Id_contrato} value={data.Id_contrato}>
                                {data.Nombre}
                            </Option>
                        );
                    })} 
                </Select>
                </Form.Item>
                </Col>
                </Row>
                 
                <Row gutter={24}>
               
               
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 14 }}>
                <Form.Item
                    name="FechaReloj"
                     rules={[
                                { required: true,message: 'Seleccione fecha de turno'},
                            ]}
                >
                    <RangePicker locale={locale} format={dateFormat} />
                </Form.Item>
                </Col>
                </Row>
 

                <Row gutter={24}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6 }}>
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Eliminar
                    </Button>
                    <Button key="back" onClick={handleCancelRelojDel}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
                </Col>
                </Row>
            </Form>
     
        </Modal>
       </> 
    )
}

export default ModalRelogDel
