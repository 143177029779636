import gql from "graphql-tag";

export const EditUsers = gql`
mutation Edituser($id_sesion:ID!,$Nombre:String!, $Apellidop:String!,$Apellidom:String!,$Email:String!){
    updateUser(id_sesion:$id_sesion,
        Nombre: $Nombre,
        Apellidop: $Apellidop,
        Apellidom : $Apellidom,  
        Email : $Email, 
        
    )
    {
        success
        message 
        id_usuario
        id_Sesion
        Users
        Nombre
        Apellidop
        Apellidom
        Email
        Fecha_creacion 
        Estado 
    } 
    
}`;

export const CreateUsers = gql`
mutation CreateUsers($Users:String!,$Pass:String!,$Nombre:String!, $Apellidop:String!, $Apellidom:String! ,$Email:String!){
    createUser(Users:$Users,
        Pass:$Pass,
        Nombre: $Nombre,
        Apellidop: $Apellidop,
        Apellidom : $Apellidom,  
        Email : $Email, 
       
      
    )
   {
        success
        message 
        id_usuario
        id_Sesion
        Users
        Nombre
        Apellidop
        Apellidom
        Email
        Fecha_creacion 
        Estado
        NombreCompleto
        
    } 
    
}`;

export const BloqueoUsers = gql`
mutation DeleteUsers($id_usuario:ID!,$Estado:String!){
    deleteUser(id_usuario:$id_usuario
               Estado:$Estado,)
    {
        success
        message 
    } 
    
}`;

export const Updatepass = gql`
mutation Updatepassw($id_sesion:ID!,$oldPass:String!,$NewPass:String!){
    updatePassword(id_sesion:$id_sesion
                ,oldPass:$oldPass
                ,NewPass:$NewPass
    )
    {
        success
        message
        
    } 
    
}`;

export const Resetpass = gql`
mutation Resetpassw($id_sesion:ID!){
     resetPassword(id_sesion:$id_sesion)
    {
        success
        message
        
    } 
    
}`;

export const updateUserSend = gql`
mutation updateUserSend($sesionId:ID!,$send_name:[String]!){
    updateUserSend(send_name: $send_name,
                   sesionId:$sesionId)
    {
        success
        message
    }
  
}`;

