import React,{useEffect,useState}  from 'react'
import { Modal, Button, Form, Input,InputNumber, Space, Row, Col,Select} from 'antd';
import '../../Assets/Css/page.css';

import {useAllBoolean} from '../../hooks/custom-general';
const { TextArea } = Input;
const { Option, OptGroup } = Select;

const ModalPostEditBenef = ({ EditBeneficio,EditDataPost, Loading, handleBeneficiosSave, handleCancelbef}) => {
    const{booleanAlldata} = useAllBoolean();
    const [formBeneficio] = Form.useForm();
     const [disabledL,setDisabledL] = useState(false);
    const [disabledB,setDisabledB] = useState(false);
    
    useEffect(() => {
        if (EditBeneficio) {
            
            formBeneficio.setFieldsValue({
                
                
                Colacion:EditDataPost.Monto_colacion,
                Movilizacion:EditDataPost.Monto_movilizacion,
                SueldoLiquido: EditDataPost?.Sueldo_liquido,
                SueldoBase: EditDataPost?.Sueldo_base,
                Bono: EditDataPost?.Bono,
                HoraExtra:EditDataPost.Hora_extra,
                DescripcionBeneficios:EditDataPost.Obs_beneficios,
            
            });

            if(EditDataPost?.Sueldo_liquido>0){
                setDisabledL(false)
                setDisabledB(true)
            }

            if(EditDataPost?.Sueldo_base>0){
                setDisabledB(false)
                setDisabledL(true)
            }

            
        }
    },[EditBeneficio, EditDataPost, formBeneficio]);


    const onBeneficios = values => {
        return handleBeneficiosSave(values);     
    }

   
    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;

    };

    const onChangeL = (values) => {
       
        if(values>0){
           setDisabledB(true)
           formBeneficio.setFieldsValue({
                SueldoBase: 0 
            });
        }
        if(values===0){
           setDisabledB(false)
        }

        if(values===null){
           setDisabledB(false)
        }
    };

    const onChangeB = (values) => {
       
        if(values>0){
           setDisabledL(true)
           formBeneficio.setFieldsValue({
                SueldoLiquido: 0,
            });
        }
        if(values===0){
           setDisabledL(false)
        }

        if(values===null){
           setDisabledL(false)
        }
    };

    return (
        <>
        <Modal
            visible={EditBeneficio}
            title="Editar Remuneración"
             //style={{ top: 20 }}
            onCancel={handleCancelbef}
            footer={[]}
        >

        
            <Form
                layout={"vertical"}
                form={formBeneficio}
                name="Solicitud"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                
                onFinish={onBeneficios}
            >
                
                
                <Row gutter={16}>

                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                 initialValue={0}
                    label="Sueldo Liquido"
                    name="SueldoLiquido"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese El Sueldo Liquido',
                        },
                 
                    ]}
                    hasFeedback
                >
               
                <InputNumber 
                   
                    style={{ width: '100%' }}
                    min={0}
                    max={10000000}
                    prefix={<Icon type='DollarOutlined' className="site-form-item-icon" />}
                    placeholder="Sueldo Liquido"
                    formatter={value => ` ${value}`.replace(/\./,',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    parser={x => parseFloat(`${x}`.replace(/,/, '#').replace(/\./g, '').replace(/#/,'.')) } 
                    onChange={onChangeL} 
                    disabled={disabledL}
                   
                />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                 initialValue={0}
                    label="Sueldo Base"
                    name="SueldoBase"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese El Sueldo Base',
                        },
                    ]}
                    hasFeedback
                >
                <InputNumber 
                   
                    style={{ width: '100%' }}
                    prefix={<Icon type='DollarOutlined' className="site-form-item-icon" />}
                    placeholder="Sueldo Base"
                    formatter={value => ` ${value}`.replace(/\./,',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    parser={x => parseFloat(`${x}`.replace(/,/, '#').replace(/\./g, '').replace(/#/,'.')) } 
                    onChange={onChangeB}  
                    disabled={disabledB}
                />
                </Form.Item>
                </Col>
                </Row>
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                 initialValue={0}
                    label="Bono"
                    name="Bono"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese El Bono',
                        },
                    ]}
                    hasFeedback
                >
                <InputNumber 
                 
                    style={{ width: '100%' }}
                    prefix={<Icon type='DollarOutlined' className="site-form-item-icon" />}
                    placeholder="Bono"
                    formatter={value => ` ${value}`.replace(/\./,',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    parser={x => parseFloat(`${x}`.replace(/,/, '#').replace(/\./g, '').replace(/#/,'.')) }  
                   
                />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                 <Form.Item 
                    label="Hora Extra"
                    name="HoraExtra"
                   
                    rules={[
                        {
                            required: true,
                            message: 'Por favor Ingrese Respuesta'  
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                <Select placeholder="Hora extra" >
                <OptGroup label="Hora extra">
                   {booleanAlldata.map((data) => {
                        return (
                            <Option key={data.estid} value={data.estid}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </OptGroup>    
                </Select>
                </Form.Item>
                </Col>
                </Row>
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                     initialValue={0}
                    label="Colacion"
                    name="Colacion"
                   
                >
                 <InputNumber 
                   
                    style={{ width: '100%' }}
                    prefix={<Icon type='DollarOutlined' className="site-form-item-icon" />}
                    placeholder="Monto Colación"
                    formatter={value => ` ${value}`.replace(/\./,',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    parser={x => parseFloat(`${x}`.replace(/,/, '#').replace(/\./g, '').replace(/#/,'.')) }  
                   
                />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                     initialValue={0}
                    label="Movilización"
                    name="Movilizacion"
                    
                >
               <InputNumber 
                    
                    style={{ width: '100%' }}
                    prefix={<Icon type='DollarOutlined' className="site-form-item-icon" />}
                    placeholder="Monto Movilización"
                    formatter={value => ` ${value}`.replace(/\./,',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    parser={x => parseFloat(`${x}`.replace(/,/, '#').replace(/\./g, '').replace(/#/,'.')) }  
                   
                />
                </Form.Item>
                </Col>
               
                </Row>
                
                <Form.Item
                    name="DescripcionBeneficios" 
                >
                 <TextArea rows={4} placeholder="Observaciones"  style={{ width: '100%' }} />
                </Form.Item>
               

                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Guardar
                    </Button>
                    <Button key="back" onClick={handleCancelbef}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>    
        </Modal>
        </> 
    )
}

export default ModalPostEditBenef 
