import React,{useState,useEffect,useRef,forwardRef} from 'react'
import moment from 'moment'
import '../Assets/Css/page.css';
import { Modal,Card, Button,message} from 'antd';
import { Excel } from "antd-table-saveas-excel";
import {useReactToPrint} from "react-to-print";
import { PrinterOutlined} from '@ant-design/icons';


import IcoButton from '../Components/IconButtonGroup';
import Buscar from '../Components/Loading';
import ModalClave from '../Components/ModalClave';
import {useUpdatepass} from '../hooks/custom-users';
import { isNonEmptyArray } from '@apollo/client/utilities';


import TableReportHextra from '../Components/contratos/reporthextra/TableReportHextra';
import Modalfiltrohextra from '../Components/contratos/reporthextra/Modalfiltrohextra';
import HeadReportHextra from '../Components/contratos/reporthextra/HeadReportHextra';
import HeadReportHextraPrint from '../Components/contratos/reporthextra/HeadReportHextraPrint';
import { useReportHoraExtra } from '../hooks/custom-contratos';
import { useAllClientesActivo } from '../hooks/custom-Clientes'
import{useVerifyToken,useVerify} from "../hooks/useToken"  

const ReportHextra = () => {
     const {data:Tokensverify, loading:TokensverifyLoading}= useVerify();
     const{Tokens,TokensLoading} = useVerifyToken();  
   const{data:clieData,loading:clieloading}= useAllClientesActivo();
   const[ReportHoraExtra] = useReportHoraExtra(); 
   const componentRef = useRef(null);
   const [Visible, setVisible] = useState(false);
   const [VisiblePrint, setVisiblePrint] = useState(false);
   const [Loading, setLoading] = useState(false);
   const [clientes, setClientes] = useState();
   const [SetUpd] = useUpdatepass();

   const [Datahextra, SetDatahextra] = useState(false); 
   const [Datapersona, SetDatapersona] = useState([]); 
   const [loadingpage, setloadingpage] = useState(true);
   const [viewNew,setViewNew] = useState(false);
   const [creadoPor, setCreadoPor] = useState('');
  
   
   useEffect(() => {
        if(TokensverifyLoading===false){
            
            if(Tokensverify.GetLogin_validate.success===false){
                Modal.error({
                    title: Tokensverify.GetLogin_validate.message,
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");
                    }
                })
            }
           
        }

        if(TokensLoading===false){
            if(Tokens){
                 const userF=Tokens.token.split('||')
                 setCreadoPor(userF[2])
            }
        } 
        
        if(clieloading===false){
            const dataclient = clieData.GetClientAllActivo
            setClientes(dataclient)
            setloadingpage(false)   
        }
       
    }, [Tokens, TokensLoading, Tokensverify, TokensverifyLoading, clieData, clieloading])

    const paramstable = {
        bordered: true,
        pagination:false,
        size: 'small',
        tableLayout: 'auto',
         scroll: { x: 'max-content', y: '38vh'},
    }

    const paramstablePrint = {
        bordered: true,
        pagination:false,
        size: 'small',
        tableLayout: 'auto',
         scroll: { x: 'max-content'},
    }

   
   const [submenu] = useState([
        {
            key: '1',
            icon: 'KeyOutlined',
            title: 'Nueva Clave',

        }, {
            key: '2',
            icon: 'PoweroffOutlined',
            title: 'Salir',

        }
    ])

    const onGroupClick = (array) => {
       
        switch (array.key) {
            case '1':
                //console.log(array.key);
                setVisible(true);
               break
            default:
                Modal.confirm({
                    title: 'Estas Seguro de Cerrar la aplicación ..??',
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");

                        
                }
                })
        }
    }

    const handleOk = async (values) => {

       
        const EditPass = await  SetUpd({variables:{
            id_sesion:creadoPor,
            oldPass: values.claveactual,
            NewPass: values.NuevaClave,
          
        }})
      //console.log(EditPass)
       // setLoading(true);
        const success = EditPass.data.updatePassword.success
        const message = EditPass.data.updatePassword.message
        if(success===true){   
           const  modal = Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
                setVisible(false);
                modal.destroy();
            }, 3000);

        }else{
            Modal.warning({
                 content: message,
            });
            setLoading(false);
              
           
        }  

    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleSaveFiltro = async (values) => {
        setViewNew(false);
         const report = await  ReportHoraExtra({variables:{
            fecha_ini: moment(values.FechaFiltro[0]).format('YYYY-MM-DD'),
            fecha_fin: moment(values.FechaFiltro[1]).format('YYYY-MM-DD'),
            Id_contrato:values.Id_contrato
        }})

        if(report.loading===false){
            SetDatahextra(report.data.GetReportHoraExtra.ReportData)
            SetDatapersona({
                "Nombre":report.data.GetReportHoraExtra.Nombre,
                "Codigo":report.data.GetReportHoraExtra.Codigo,
                "Rut":report.data.GetReportHoraExtra.Rut,
                "Sucursal":report.data.GetReportHoraExtra.Sucursal,
                "Empresa":report.data.GetReportHoraExtra.Empresa,
                "Desde":report.data.GetReportHoraExtra.Desde,
                "hasta":report.data.GetReportHoraExtra.hasta,
            })
        }

        
               
    };

    const Prints = useReactToPrint( {
       
        content: () => componentRef.current,
    });
    
    const handleFiltro=()=>{
       setViewNew(true);
    }

    const handlePrint=()=>{
        setVisiblePrint(true);
        setTimeout(() => {
                Prints();
        }, 10);

         setTimeout(() => {
               setVisiblePrint(false);;
        }, 10);
       
        
    }

    const handleCancelFiltro= () => {
         setViewNew(false);
    };
    
    const handleExcel = () => {
        if(isNonEmptyArray(Datahextra)){
            const Columsdata = [
                
                {
                    title: 'Fecha',
                    dataIndex: 'Fecha',
                    key: 'Fecha',
               
                },
                 {
                    title: 'Rut',
                    dataIndex: 'Rut',
                    key: 'Rut',
                },
                 {
                    title: 'Nombre',
                    dataIndex: 'Nombre',
                    key: 'Nombre',
                },
                 {
                    title: 'sucursal',
                    dataIndex: 'sucursal',
                    key: 'sucursal',
                },
                 {
                    title: 'empresa',
                    dataIndex: 'empresa',
                    key: 'empresa',
                },
                 {
                    title: 'id_reloj',
                    dataIndex: 'id_reloj',
                    key: 'id_reloj',
                },
                {
                    title: 'Jornada',
                    dataIndex: 'turno',
                    key: 'turno',
                },
                {
                title:  'Entrada' ,
                    children: [
                        {
                            title: 'Entrada',
                            dataIndex: 'hora_entrada',
                            key: 'hora_entrada',
                        },
                        {
                            title: 'Extra',
                            dataIndex: 'tiempo_ad_entrada',
                            key: 'tiempo_ad_entrada',
                        },
                    ]
                },
                {
                title:  'Salida' ,
                    children: [   
                    {
                        title: 'Salida',
                        dataIndex: 'hora_salida',
                        key: 'hora_salida',
                    },
                    {
                        title: 'Extra',
                        dataIndex: 'tiempo_ad_salida',
                        key: 'tiempo_ad_salida',    
                    },
                    ]
                },
                {
                title:  'Tiempo Extra' ,
                    children: [ 
                        {
                            title: 'Normal',
                            dataIndex: 'pagohextra_normal',
                            key: 'pagohextra_normal',   
                        },
                        {
                            title: 'Festivo',
                            dataIndex: 'pagohextra_feriado',
                            key: 'pagohextra_feriado',    
                        },
                    ]},
                {
                title:  'Total Tiempo Extra' ,
                    children: [    
                        {
                            title: 'Total',
                            dataIndex: 'hextra_total',
                            key: 'hextra_total',    
                        },
                        {
                            title: 'Total2',
                            dataIndex: 'hextra_total_num',
                            key: 'hextra_total_num',    
                        },
                    ]
                },
                {
                title:  'Tiempo Noche' ,
                    children: [    
                        {
                            title: 'Total',
                            dataIndex: 'horas_total_noche',
                            key: 'horas_total_noche',    
                        },
                        {
                            title: 'Total2',
                            dataIndex: 'horas_total_noche_num',
                            key: 'horas_total_noche_num',    
                        },
                    ]
                },
   
   
            ];
        
            const excel = new Excel();
            excel
                .addSheet("test")
                .addColumns(Columsdata)
                .addDataSource(Datahextra, {
                    str2Percent: true
                })
                .saveAs("Excel.xlsx");
        }else{
              message.warning('Seleccione información primero...');
        }
    }
    
    if(loadingpage){
        return (
        <div className="site-card-border-less-wrapper">
           
          <Buscar/>
         
        </div>
        )
    }

    const Print = forwardRef((props, ref) => {
    return  (
            <div ref={componentRef}>
                <HeadReportHextraPrint  Datapersona={Datapersona} handlePrint={handlePrint} handleFiltro={handleFiltro}/>
                <TableReportHextra   Datahextra={Datahextra}  paramstable={paramstablePrint} />
            </div>   
        )
    });



    return (
        
        <div className="site-card-border-less-wrapper"  >
            
          <Card  size="small" style={{ textAlign: 'Left', }}  title='Reporte Horas Extras'    extra={<IcoButton icon='SettingOutlined' onChildClick={(array) => onGroupClick(array)} submenu={submenu}  />}   >
              <HeadReportHextra  Datapersona={Datapersona} handlePrint={handlePrint} handleFiltro={handleFiltro} handleExcel={handleExcel}/>
                <TableReportHextra   Datahextra={Datahextra} paramstable={paramstable}  />
            </Card>
            <ModalClave Visible={Visible} Loading={Loading} handleOk={handleOk} handleCancel={handleCancel }/>
            <Modalfiltrohextra viewNew ={viewNew}  Loading={Loading}  handleSaveFiltro ={handleSaveFiltro} handleCancelFiltro={handleCancelFiltro}  clientes={clientes}  />
            <Modal 
             visible={VisiblePrint}
             footer={[<Button  key="8" icon={<PrinterOutlined />} onClick={Prints}> Imprimir</Button>]}>
                <Print />
            </Modal>
            
        </div>  
    );
}

export default ReportHextra