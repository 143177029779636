import React from 'react'
import { Modal, Button, Form,  Space, Row, Col,Upload  ,message } from 'antd';
import '../../../Assets/Css/page.css';



const ModalRelojControlAdj = ({viewAdj, Loading, handleSaveAdj, handleCancelAdj}) => {
  
    const [form18] = Form.useForm();
    const formData = new FormData();
 
     
    const onAdd = async values => {
        form18.resetFields();
       
        const resp =  await fetch(process.env.REACT_APP_LINK_UPRELOJ, {
            method: 'POST',
            body:formData ,
        })
      
        const Arrfilename = await resp.json();
        return handleSaveAdj(values,Arrfilename); 
      
       
          
    }

    const normFile = (e) => {
      
        formData.append('files',e.file);
        return e && e.fileList;
    };

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;

    };

    const props = {
      beforeUpload: file => {
        
        const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (!isXLSX) {
             message.error(`${file.name} No es un Archivo .xlsx`);
             return Upload.LIST_IGNORE
        }else{
              return false;
       }

        
        
        
      },
    };

  
   

    return (
        <>
        <Modal
            visible={viewAdj}
            title={"Subir Archivo Reloj Control"}
            onCancel={handleCancelAdj}
            footer={[]}
        >

        
        <Form
                form={form18}
                name="AdjuntoReloj"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                onFinish={onAdd}
            >
                
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24 }}>
               <Form.Item >
                    <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle
                    rules={[
                        {
                            required: true,
                            message: 'Por favor Seleccione Adjunto'  
                        }  
                    ]}>
                    <Upload.Dragger name="file" {...props}>
                        <p className="ant-upload-drag-icon">
                        <Icon type='InboxOutlined' className="site-form-item-icon" />
                        
                        </p>
                        <p className="ant-upload-text">Haga clic o arrastre el archivo a esta área para cargarlo</p>
                       
                    </Upload.Dragger>
                    
                    </Form.Item>
                </Form.Item>
                </Col>
               
                </Row>
                
               


                <Form.Item name="botones">
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Guardar
                    </Button>
                    <Button key="back" onClick={handleCancelAdj}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>
     
        </Modal>
       </> 
    )
}

export default ModalRelojControlAdj
