import React, { useState, useEffect }   from 'react'
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import {useMenus}  from '../hooks/useMenus';
import Loading from './Loading';
import '../Assets/Css/page.css';
import { Menu,Layout } from 'antd';
import Headers from './Header';

const { Sider} = Layout;
const { SubMenu } = Menu;



const MenuLeftNivel3dynamic = () => {  
    const [openKeys, setOpenKeys] = useState([])

    const {menudata,isLoading} = useMenus()
    let location = useLocation();
   

    useEffect(() => {
     
       if (Array.isArray(menudata.SubMenu) && menudata.SubMenu.length) {
            const resultado = menudata.SubMenu.find(item => item.link === location.pathname);
            if(resultado){
               
                setOpenKeys([resultado.menukey]) 
            }   
            
         
        }
         
    },[location.pathname, menudata.SubMenu]);

    const onOpenChange = keys => {
           
            const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
            if (menudata.Menu.indexOf(latestOpenKey) === -1) {
                setOpenKeys(keys);
            } else {
                setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
            } 
        
    };
   
    
    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;

    };
    
   
    if(isLoading){
        return (<><Loading/></>)
    }
   //selectedKeys={location.pathname}
    return (
        <>
            <Sider
               theme="light"
                breakpoint="xl"
                collapsedWidth="0"
               
            > 
           
            <Headers/>
            <Menu theme="light" mode="inline"  openKeys={openKeys} onOpenChange={onOpenChange}  >
            { 
                menudata.Menu.map((item) =>( 
                    <SubMenu key={item.menukey} icon={<Icon type={item.icon} />} title={item.title} >
                        
                       

                        {  
                            menudata.SubMenu.filter(subitem => subitem.menukey === item.menukey && subitem.lengthkey === "3").map(filtered => (
                                <Menu.Item key={filtered.link} >{filtered.title}<Link to={filtered.link} /></Menu.Item>
                            ))
                            
                        }

                        {
                            menudata.Menu2.filter(subitem => subitem.menukey === item.menukey).map(filtered2 =>
                                <SubMenu key={filtered2.submenukey}  icon={<Icon type={filtered2.icon} />} title={filtered2.title} >
                                    
                                    {
                                        menudata.SubMenu.filter(subitem => subitem.submenukey === filtered2.submenukey).map(filtered => (
                                            <Menu.Item key={filtered.link} >{filtered.title}<Link to={filtered.link} /></Menu.Item>
                                        ))
                                    }
                                </SubMenu>
                            )
                        }
                       
                    </SubMenu>
                )) 
            }
                
            </Menu>
            </Sider> 
        </>
    )
}

export default MenuLeftNivel3dynamic
