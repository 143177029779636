import gql from "graphql-tag";

export const InsertModPermisos = gql`

mutation InsertModPermisos($sesionId:ID!,$menuId:[ID]!){
 
  arrinsertPermisos(
    sesionId: $sesionId,
    menuId:$menuId
  ){
    success
    message 
  } 
}`;