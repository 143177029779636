import React ,{useState,useEffect} from 'react'
import { Transfer } from 'antd';




const CardModule = ({targetkey,dataMenu,setDatasave}) => {
  const  [targetKeys,setTargetKeys]= useState(targetkey);
// console.log(targetkey)

 useEffect(() => {
           setTargetKeys(targetkey)
           return setDatasave(targetkey);
    }, [setDatasave, targetkey])

   
    const [selectedKeys, setSelectedKeys] = useState([]);
    const onChange = (nextTargetKeys, direction, moveKeys) => {
     // console.log('targetKeys:', nextTargetKeys);
     // console.log('direction:', direction);
     // console.log('moveKeys:', moveKeys);
      setTargetKeys(nextTargetKeys);
      setDatasave(nextTargetKeys);
    };
    
    const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    //console.log('sourceSelectedKeys:', sourceSelectedKeys);
    //console.log('targetSelectedKeys:', targetSelectedKeys);
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const onScroll = (direction, e) => {
   // console.log('direction:', direction);
   // console.log('target:', e.target);
  };
    
    return (
               <Transfer
               listStyle={{
                width: '100%',
                height: '55vh',
                }}
                dataSource={dataMenu}
                titles={['Seleccione', 'Seleccionados']}
                targetKeys={targetKeys}
                selectedKeys={selectedKeys}
                onChange={onChange}
                onSelectChange={onSelectChange}
                onScroll={onScroll}
                render={item => item.title}
                />
          
       
    )
}

export default CardModule