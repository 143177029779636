import gql from "graphql-tag";

export const Allusers = gql`
query{
  GetAllUsers {
    id_usuario
    id_Sesion
    Users
    Nombre
    Apellidop
    Apellidom
    Email
    Fecha_creacion
    Estado
    NombreCompleto
    NombreApe
  
  }
}`;


export const  GetUsers_AlertNameByIdSesion = gql`
query  GetUsers_AlertNameByIdSesion($id_Sesion:ID!)
{
 GetUsers_AlertNameByIdSesion(id_Sesion:$id_Sesion)
  {
    descripcion
  }
  
}`;
