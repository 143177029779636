import gql from "graphql-tag";

export const Menuuser = gql`
query menu($User:String!){
   GetMenu(Users:$User){
     success
    message
    Menu {
      sesionId
      menukey
      icon
      title
      orden
    } 
    Menu2 {
      sesionId
      menukey
      submenukey
      icon
      title
      orden
    }
    SubMenu {
      sesionId
      menukey
      submenukey
      dkey
      lengthkey
      title
      link
      orden
    } 
    
  } 
}`;
