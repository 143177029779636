import React,{useState,useEffect} from 'react'

import '../Assets/Css/page.css';
import { Modal,Card, Button} from 'antd';
import IcoButton from '../Components/IconButtonGroup';
import IconButtonListClient from '../Components/PostulacionCliente/IconButtonListClient';
import Buscar from '../Components/Loading';
import ModalClave from '../Components/ModalClave';

import {useUpdatepass} from '../hooks/custom-users';
import TablePostulacion from '../Components/PostulacionCliente/TablePostulacion';
import ModalPostNew from '../Components/PostulacionCliente/ModalPostNew';
import ModalPostView from '../Components/PostulacionCliente/ModalPostView';
import ModalClieObsEstadoPost from '../Components/PostulacionCliente/ModalClieObsEstadoPost';
import ModalPostAdj from '../Components/PostulacionCliente/ModalPostAdj';
import ModalPostulantes from '../Components/PostulacionCliente/ModalPostulantes';
import ModalObsEstadoPostDet from '../Components/PostulacionCliente/ModalObsEstadoPostDet';
import ModalPostAdjDet from '../Components/PostulacionCliente/ModalPostAdjDet';
import ModalPostulanteView from '../Components/PostulacionCliente/ModalPostulanteView';

import {useClieContactbyIdSesion} from '../hooks/custom-Clientes';

import {useCreateClientPostClienteCargo,useUpdateClientPostCargo,
        useUpdateClientPostPersona,useUpdateClientPostBeneficios,useUpdateClientPostOtros,useInsertClientObsPost,
        useInsertClientAdjPost,useClientPostulacionObs,useClientPostulacionAdj,useClientPostulacionObsDet,useClientPostulacionAdjDet, 
        useInsertClientPostDet, useClientPostulacionSelect,useInsertClientObsPostDet,useInsertClientAdjPostDet,
        useClientPostulanteDelete,useAllPostulaciones} from '../hooks/custom-PostulacionCliente';

import{useVerifyToken,useVerify} from "../hooks/useToken"    


const RequerimientosCliente = () => {
    const {data:Tokensverify, loading:TokensverifyLoading}= useVerify();
    const{Tokens,TokensLoading} = useVerifyToken();
    const [SesionID, setSesionID] = useState("0");
    const {data:postata,loading:postLoading} = useAllPostulaciones(SesionID); 
    const {data:clieData,loading:ClieLoading}= useClieContactbyIdSesion(SesionID); 
    
    
    const [SetCreatePostClienteCargo] = useCreateClientPostClienteCargo(SesionID);
    const [SetUpdatePostCargo] = useUpdateClientPostCargo(SesionID);
    const [SetUpdatePostPersona] = useUpdateClientPostPersona(SesionID);
    const [SetUpdatePostBeneficios] = useUpdateClientPostBeneficios(SesionID);
    const [SetUpdatePostOtros] = useUpdateClientPostOtros(SesionID);
    
    const [SetEditObsPost] = useInsertClientObsPost(SesionID);
    const [SetEditObsPostDet] = useInsertClientObsPostDet();
    const [SetInsertAdjPost] = useInsertClientAdjPost();
    const [SetInsertAdjPostDet] = useInsertClientAdjPostDet();
    const [DataObs] = useClientPostulacionObs();
    const [DataAdj] = useClientPostulacionAdj();
    const [DataPostDet] = useInsertClientPostDet();
    const [DataPostDetSel] = useClientPostulacionSelect();
    const [DataObsDet] = useClientPostulacionObsDet();
    const [DataAdjDet] = useClientPostulacionAdjDet();
    const [PostDelete] =  useClientPostulanteDelete();
    const [Visible, setVisible] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [SetUpd] = useUpdatepass();
    const [Clientedata,setClientedata] = useState([]);
    const [NewCliente, setNewCliente] = useState(false);
    const [ViewCliente, setViewCliente] = useState(false);
   
    const [EditDataPost, setEditDataPost] = useState([]);
    const [EditEst, setEditEst] = useState(false);
    const [EditEstDet, setEditEstDet] = useState(false);
    const [EditAdj, setEditAdj] = useState(false);
    const [EditAdjDet, setEditAdjDet] = useState(false);
    const [dataEditAdj, setDataEditAdj] = useState([]);
    const [dataEditAdjDet, setDataEditAdjDet] = useState([]);
    const [dataEditEst, setDataEditEst] = useState([]);
    const [dataEditEstDet, setDataEditEstDet] = useState([]);
    const [dataClieObs, setDataClieObs] = useState([]);
    const [dataObsDet, setDataObsDet] = useState([]);
    const [dataAdj, setDataAdj] = useState([]);
    const [dataAdjDet, setDataAdjDet] = useState([]);
    const [postVisible, setPostVisible] = useState(false);
    const [dataSelClie, setDataSelClie] = useState([]);
    const [dataDetSelect, setDataDetSelect] = useState([]);
    const [DataEmp,SetdataEmp] = useState([]); 
    const [dataCardEmple,setDataCardEmple] = useState([]); 
    const [DataSelEmple,setDataSelEmple] = useState([]); 
    const [VerCardEmple,setVerCardEmple] = useState(false);
    const [datapostulacion,setDatapostulacion]=useState();
    const [datapostulacionName,setDatapostulacionName]=useState(false);
    const [dataCliecontact,setDataCliecontact]=useState([]);
    const [creadoPor, setCreadoPor] = useState('');
    const [nameClient, setNameClient] = useState();
    const [dataSelpostulant, setDataSelpostulant] = useState();
  
    
   

    useEffect(() => {
         if(TokensverifyLoading===false){
            
            if(Tokensverify.GetLogin_validate.success===false){
                Modal.error({
                    title: Tokensverify.GetLogin_validate.message,
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");
                    }
                })
            }
           
        }
        
        if(TokensLoading===false){
            if(Tokens){
                 const userF=Tokens.token.split('||')
                 setSesionID(userF[2])
                 setCreadoPor(userF[2])
            }
          
        }  
        
        if(postLoading===false){
            const datauser = postata.GetClientPostById
            setClientedata(datauser)
        }

        if(ClieLoading===false){
            const dataclient = clieData.GetDataContactClient_ById[0]
            setDataCliecontact(dataclient)
            setNameClient(dataclient.Nombre_Cliente)
        }
       
    }, [ClieLoading, Tokens, TokensLoading, Tokensverify, TokensverifyLoading, clieData, postLoading, postata])
   
     
    const datapostulant = async(id_postulacion_cab) => {
        const DataPostDetSelect = await DataPostDetSel({variables:{
            id_postulacion_cab:id_postulacion_cab
        }})
        setDataDetSelect(DataPostDetSelect.data.GetClientPostSel)
        
    }

    const [submenu] = useState([
        {
            key: '1',
            icon: 'KeyOutlined',
            title: 'Nueva Clave',

        }, {
            key: '2',
            icon: 'PoweroffOutlined',
            title: 'Salir',

        }
    ])

    const onGroupClick = (array) => {
       
        switch (array.key) {
            case '1':
                //console.log(array.key);
                setVisible(true);
               break
            default:
                Modal.confirm({
                    title: 'Estas Seguro de Cerrar la aplicación ..??',
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");

                        
                }
                })
        }
    }

    const onAdduser=()=>{
        setNewCliente(true);
    }

    const onEditPost = (record) => {
        setDatapostulacion(record.id_postulacion_cab)
        setViewCliente(true);
        setEditDataPost(record);
    }

    const onEditEst = async (record) => {
       
            const ObsData = await DataObs({variables:{
                id_postulacion_cab:record.id_postulacion_cab
            }})

            setDataClieObs(ObsData.data.GetClientObs)
            setDataEditEst(record);
        
        setEditEst(true);
    }

    const onEditPostAdj = async  (record) => {
       
        const AdjData = await DataAdj({variables:{
            id_postulacion_cab:record.id_postulacion_cab
        }})
        setDataAdj(AdjData.data.GetClientAdj)
        setDataEditAdj(record);
        setEditAdj(true);
    }

    const onEditEstDet = async (record) => {

       // console.log(record);
       
        const ObsDataDet = await  DataObsDet({variables:{
            id_postulacion_det:record.id_postulacion_det
        }})
       
        setDataObsDet(ObsDataDet.data.GetClientObsDet)
        setDataEditEstDet(record);
        setEditEstDet(true);
    }

     const onEditPostAdjDet= async  (record) => {
        
        const AdjDataDet = await DataAdjDet({variables:{
            id_postulacion_det:record.id_postulacion_det
        }})
        setDataAdjDet(AdjDataDet.data.GetClientAdjDet)
        setDataEditAdjDet(record);
        setEditAdjDet(true);
      
    }

    const onHandlePostCardDet = async (record) => {
       
        setDataCardEmple(record)
        setDataSelEmple(record);
        setVerCardEmple(true);
    }

    const onEditPostEmp = async  (record) => {
        setDataSelpostulant(record)
        setDataSelClie(record)
        const DataPostDetSelect = await DataPostDetSel({variables:{
            id_postulacion_cab:record.id_postulacion_cab
        }})
        setDataDetSelect(DataPostDetSelect.data.GetClientPostSel)
        setPostVisible(true);
    }

    const handleOk = async (values) => {
        const EditPass = await  SetUpd({variables:{
            id_sesion:creadoPor,
            oldPass: values.claveactual,
            NewPass: values.NuevaClave,
          
        }})
      //console.log(EditPass)
       // setLoading(true);
        const success = EditPass.data.updatePassword.success
        const message = EditPass.data.updatePassword.message
        if(success===true){   
           const  modal = Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
                setVisible(false);
                modal.destroy();
            }, 3000);

        }else{
            Modal.warning({
                 content: message,
            });
            setLoading(false);
              
           
        }  

    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleCreateCargoSave = async (values) => {
         //console.log(values)
        setLoading(true);

        const CreateResult = await  SetCreatePostClienteCargo({variables:{
            Id_cliente: dataCliecontact.Id_cliente,
            Cliente: dataCliecontact.Nombre_Cliente,
            Solicitante: dataCliecontact.Id_contacto,
            creado_por: creadoPor,
            Cargo: values.Cargo,
            Nro_vacantes: values.vacantes,
            Horario_empleo: values.HorarioLaboral,
            Tipo_contrato: values.TipoContrato,
            Meses_plazo_fijo: values.MesesPfijo,
            Experiencia: values.Experiencia,
            DescripcionCargo: values.DescripcionCargo
        }})
       
     
        const success = CreateResult.data.createClientPostClienteCargo.success
        const message = CreateResult.data.createClientPostClienteCargo.message
        setDatapostulacion(CreateResult.data.createClientPostClienteCargo.id_postulacion_cab)
        setDatapostulacionName('')
        
        if(success){   
        Modal.success({
                content: message,
        });

        setTimeout(() => {
            setLoading(false);
        }, 1500);

        }else{
            Modal.warning({
                content: message,
            }); 
            setLoading(false);

        }
        
    };

    const handleCargoSave = async (values) => {
         //console.log(values)
        setLoading(true);

        const UpdateResult = await  SetUpdatePostCargo({variables:{
            id_postulacion_cab:datapostulacion,
            Cargo: values.Cargo,
            Nro_vacantes: values.vacantes,
            Horario_empleo: values.HorarioLaboral,
            Tipo_contrato: values.TipoContrato,
            Meses_plazo_fijo: values.MesesPfijo,
            Experiencia: values.Experiencia,
            DescripcionCargo: values.DescripcionCargo
        }})


        const success = UpdateResult.data.UpdateClientPostCargo.success
        const message = UpdateResult.data.UpdateClientPostCargo.message
        setEditDataPost(UpdateResult.data.UpdateClientPostCargo);
        

        if(success){   
        Modal.success({
                content: message,
        });

        setTimeout(() => {
            setLoading(false);
           
        }, 1500);

        }else{
            Modal.warning({
                content: message,
            }); 
            setLoading(false);

        }
        

    };

    const handlePersonaSave = async (values) => {

        setLoading(true);
        if(datapostulacion){ 
            const UpdateResult = await  SetUpdatePostPersona({variables:{
                id_postulacion_cab: datapostulacion,
                 Nivel_cursado: values.NivelCursado,
                Genero: values.Genero,
                Rest_nacionalidad: values.RestNacionalidad,
                Rango_edad: values.RangoEdad,
                Rest_comuna: values.RestComuna,
                Persona_discapacidad: values.Discapacidad,
                Obs_personas: values.DescripcionPersona,
            }})
        
        
            const success = UpdateResult.data.UpdateClientPostPersona.success
            const message = UpdateResult.data.UpdateClientPostPersona.message
            setEditDataPost(UpdateResult.data.UpdateClientPostPersona);
           
            
            if(success){   
                Modal.success({
                    content: message,
                });

                setTimeout(() => {
                    setLoading(false);
                    //setNewCliente(false);
                    //modal.destroy();
                }, 1500);

            }else{
                Modal.warning({
                    content: message,
                }); 
                setLoading(false);

            }
        }else{
            Modal.warning({
                content: "Debe agregar Cargo Primero...",
            }); 
            setLoading(false);

        }  

    };

    const handleOtrosSave = async (values) => {
        
        setLoading(true);
        if(datapostulacion){
       
            const UpdateResult = await  SetUpdatePostOtros({variables:{
                id_postulacion_cab: datapostulacion,
                Ropa: values.Ropa,
                EPP: values.EPP,
                Examenes:values.Examenes,
                Renovacion: values.Renovacion,
                Contrato_planta: values.ContratoPlanta,
                Licencia_conducir: values.ClaseLicConducir,
                Obs_otros: values.DescripcionOtros,
            }})
        
        
            const success = UpdateResult.data.UpdateClientPostOtros.success
            const message = UpdateResult.data.UpdateClientPostOtros.message
            setEditDataPost(UpdateResult.data.UpdateClientPostOtros);
          
            
            if(success){   
                Modal.success({
                    content: message,
                });

                setTimeout(() => {
                    setLoading(false);
                    //setNewCliente(false);
                    //modal.destroy();
                }, 1500);

            }else{
                Modal.warning({
                    content: message,
                }); 
                setLoading(false);

            }
        }else{
            Modal.warning({
                content: "Debe agregar Cargo Primero...",
            }); 
            setLoading(false);

        } 
    };

    const handleBeneficiosSave = async (values) => {
          
        setLoading(true);
        if(datapostulacion){
            const UpdateResult = await  SetUpdatePostBeneficios({variables:{
                id_postulacion_cab: datapostulacion,
                Sueldo_liquido: values.SueldoLiquido,
                Sueldo_base: values.SueldoBase,
                Bono: values.Bono,
                Hora_extra: values.HoraExtra,
                Monto_colacion: values.Colacion,
                Monto_movilizacion: values.Movilizacion,
                Obs_beneficios: values.DescripcionBeneficios,
            }})
        
        
            const success = UpdateResult.data.UpdateClientPostBeneficios.success
            const message = UpdateResult.data.UpdateClientPostBeneficios.message
            setEditDataPost(UpdateResult.data.UpdateClientPostBeneficios);
          
        
            
            if(success){   
                Modal.success({
                    content: message,
                });

                setTimeout(() => {
                    setLoading(false);
                   
                }, 1500);

            }else{
                Modal.warning({
                    content: message,
                }); 
                setLoading(false);

            }
        }else{
            Modal.warning({
                content: "Debe agregar Cargo Primero...",
            }); 
            setLoading(false);

        } 

    };
    
    const handleCancelUse = () => {
        setDatapostulacion()
        setDatapostulacionName('Debe Agregar Cargo Primero...')
        setNewCliente(false);
    };

    const handleSelPost = async (values) => {
       
        const Result = await  DataPostDet({variables:{
            keyunique: values,
            id_postulacion_cab: dataSelClie.id_postulacion_cab,
            creado_por: creadoPor, 
        }})
       
       
        const success = Result.data.createClientPostDet.success
        const message = Result.data.createClientPostDet.message
        if(success){   
            const  modal = Modal.success({
                content: message,
            });

            const DataPostDetSelect = await DataPostDetSel({variables:{
                id_postulacion_cab:dataSelClie.id_postulacion_cab
            }})
            setDataDetSelect(DataPostDetSelect.data.GetClientPostSel)
            
            setTimeout(() => {
               // setLoading(false);
               // setEditPost(false);
                modal.destroy();
            }, 3000);

        }else{
            Modal.warning({
                content: message,
            });
           // setLoading(false);
        }  
    };

    const handleSaveEst = async (values) => {
         
        
        setLoading(true);
        const EditObs = await  SetEditObsPost({variables:{
            id_postulacion_cab: dataEditEst.id_postulacion_cab,
            Estado_id:values.Estado,
            Observaciones: values.Observaciones,
            creado_por: creadoPor,
      
        }})
       
         
            const success = EditObs.data.EditClientObsPost.success
            const message = EditObs.data.EditClientObsPost.message
            if(success){ 
                const ObsData = await DataObs({variables:{
                    id_postulacion_cab:dataEditEst.id_postulacion_cab
                }})
            
                setDataClieObs(ObsData.data.GetClientObs)
                
                
                const  modal = Modal.success({
                   content: message,
                });

                setTimeout(() => {
                  setLoading(false);
                 // setEditEst(false);
                  modal.destroy();
                }, 1500);

            }else{
              Modal.warning({
                   content: message,
              });
              setLoading(false);  
            }  
    };

    const handleSaveEstDet = async (values) => {
        
        setLoading(true);
        const EditObsDet = await  SetEditObsPostDet({variables:{
            id_postulacion_det: dataEditEstDet.id_postulacion_det,
            Estado_id:values.EstadoPostDet,
            Observaciones: values.ObservacionesDet,
            creado_por: creadoPor,
      
        }})
       
         
        const success = EditObsDet.data.EditClientObsPostDet.success
        const message = EditObsDet.data.EditClientObsPostDet.message
        if(success){   
            
            const ObsDataDet = await  DataObsDet({variables:{
                id_postulacion_det:dataEditEstDet.id_postulacion_det
            }})
        
            setDataObsDet(ObsDataDet.data.GetClientObsDet)
            datapostulant(dataSelpostulant.id_postulacion_cab)
            
            const  modal = Modal.success({
                content: message,
            });

            setTimeout(() => {
                setLoading(false);
                modal.destroy();
            }, 1500);

        }else{
            Modal.warning({
                content: message,
            });
            setLoading(false);  
        }  
    };

    const handleSaveAdj = async (values,Arrfilename) => {
     
        setLoading(true);
        const valor = Arrfilename.map((item) => item.filename);

        const EditAdj = await SetInsertAdjPost({variables:{
            id_postulacion_cab: dataEditAdj.id_postulacion_cab,
            Nombre:values.Nombre,
            Descripcion: values.Descripcion,
            adjunto:  valor,
            creado_por: creadoPor,
      
        }})
       
         
            const success = EditAdj.data.EditClientObsAdj.success
            const message = EditAdj.data.EditClientObsAdj.message
            if(success){   
                
                const AdjData = await DataAdj({variables:{
                    id_postulacion_cab: dataEditAdj.id_postulacion_cab
                }})
                setDataAdj(AdjData.data.GetClientAdj)
                
                
                

                setTimeout(() => {
                    Modal.success({
                    content: message,
                    });
                    setLoading(false);
                  
                }, 3000);

            }
            else{
                Modal.warning({
                    content: message,
                });
                setLoading(false);
            }   
    };

    const handleSaveAdjDet = async (values,Arrfilename) => {
     //console.log(values,Arrfilename)
        setLoading(true);
        const valor = Arrfilename.map((item) => item.filename);

        const EditAdjDet = await SetInsertAdjPostDet({variables:{
            id_postulacion_det: dataEditAdjDet.id_postulacion_det,
            Nombre:values.Nombre,
            Descripcion: values.Descripcion,
            adjunto:  valor,
            creado_por: creadoPor,
      
        }})
       
         
            const success = EditAdjDet.data.EditClientObsAdjDet.success
            const message = EditAdjDet.data.EditClientObsAdjDet.message
            if(success){   
                

                const AdjDataDet = await DataAdjDet({variables:{
                    id_postulacion_det:dataEditAdjDet.id_postulacion_det
                }})
                setDataAdjDet(AdjDataDet.data.GetClientAdjDet)

                setTimeout(() => {
                    Modal.success({
                        content: message,
                    });
                    setLoading(false);
                  
                }, 3000);

            }
            else{
                Modal.warning({
                    content: message,
                });
                setLoading(false);
            }  
    };

    const handleCancelView = () => {
        setViewCliente(false);
    };

    const handleCancelPostEmp = () => {
        setPostVisible(false);
    };

    const handleCancelEst = (value) => {
        setEditEst(false);
    };

    const handleCancelEstDet = () => {
        setEditEstDet(false);
    };

    const handleCancelAdj = () => {
        setEditAdj(false);
    };

     const handleCancelAdjDet = () => {
        setEditAdjDet(false);
    };

    const handleCancelCardEmple = () => {
        setVerCardEmple(false);
    };

    const onDeletePost=  (record)=> {
      
        Modal.confirm({
           title: 'Estas Seguro de eliminar el postulante  ' +record.Nombre+ ' ..??',
            
           onOk:async()=>{
            
            const DelResult = await  PostDelete({variables:{
                Id_postulacion_det:record.id_postulacion_det,
            }})
        
           
            const success = DelResult.data.DeleteClientPostDet.success
            const message = DelResult.data.DeleteClientPostDet.message
           // console.log(success)
            if(success){   
                const DataPostDetSelect = await DataPostDetSel({variables:{
                    id_postulacion_cab:record.id_postulacion_cab
                }})
                setDataDetSelect(DataPostDetSelect.data.GetPostSel)
                datapostulant(record.id_postulacion_cab)
                

                const  modal = Modal.success({
                    content: message,
                });

                setTimeout(() => {
                    modal.destroy();
                }, 1500);

            }else{
                Modal.warning({
                    content: message,
                });
               
                
            
            } 
        }  
       })
       
    }

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;

    };

    if(postLoading){
        return (
        <div className="site-card-border-less-wrapper">
           
          <Buscar/>
         
        </div>
       )
    }
    return (
        <div className="site-card-border-less-wrapper">
        
            <Card size="small" style={{ textAlign: 'Left', }}  title={ <b>{nameClient}</b>   }   className='card_height'  extra={[<IconButtonListClient key ="1" icon='BellOutlined'  SesionID={SesionID}  />,<IcoButton key ="2" icon='SettingOutlined' onChildClick={(array) => onGroupClick(array)} submenu={submenu}  />]}   >
                <Card size="small" bordered={false} style={{  display: 'flex' }}>
                    <Button onClick={onAdduser} icon={<Icon type={'FileDoneOutlined'} />} >Nuevo Requerimiento</Button>
                </Card> 
                <TablePostulacion data={Clientedata}  EditPost={onEditPost} EditEst={onEditEst} EditPostAdj={onEditPostAdj} EditPostEmp={onEditPostEmp}  />
            </Card>
            <ModalClave Visible={Visible} Loading={Loading} handleOk={handleOk} handleCancel={handleCancel }/>
            <ModalPostNew NewCliente={NewCliente} datapostulacionName={datapostulacionName} handleCreateCargoSave={handleCreateCargoSave} Loading={Loading}  handlePersonaSave={handlePersonaSave} handleBeneficiosSave={handleBeneficiosSave} handleOtrosSave={handleOtrosSave}  handleCancelUse={handleCancelUse} />
            
            <ModalPostView ViewCliente={ViewCliente} EditDataPost={EditDataPost} Loading={Loading}  handleCargoSave={handleCargoSave} handlePersonaSave={handlePersonaSave} handleBeneficiosSave={handleBeneficiosSave} handleOtrosSave={handleOtrosSave}  handleCancelView={handleCancelView}/>
            <ModalClieObsEstadoPost EditEst={EditEst} Loading={Loading} handleSaveEst={handleSaveEst} handleCancelEst={handleCancelEst} dataEditEst={dataEditEst} dataClieObs = {dataClieObs} />
            <ModalPostAdj EditAdj={EditAdj} Loading={Loading} handleSaveAdj={handleSaveAdj} handleCancelAdj={handleCancelAdj} dataEditAdj={dataEditAdj} dataAdj ={dataAdj} />
            <ModalPostulantes  PostVisible={postVisible}  handleCancelPostEmp={handleCancelPostEmp} SetdataEmp ={SetdataEmp} DataEmp={DataEmp} handleSelPost={handleSelPost}  dataDetSelect={dataDetSelect}  HandleEditEstDet={onEditEstDet} HandleEditPostAdjDet={onEditPostAdjDet} handleDeletepost={onDeletePost} HandlePostCardDet={onHandlePostCardDet} creadoPor={creadoPor}/>
            <ModalObsEstadoPostDet EditEstDet={EditEstDet} Loading={Loading} handleSaveEstDet={handleSaveEstDet} handleCancelEstDet={handleCancelEstDet} dataEditEstDet={dataEditEstDet} dataObsDet = {dataObsDet} />
            <ModalPostAdjDet EditAdjDet={EditAdjDet} Loading={Loading} handleSaveAdjDet={handleSaveAdjDet} handleCancelAdjDet={handleCancelAdjDet} dataEditAdjDet={dataEditAdjDet} dataAdjDet ={dataAdjDet} />
            <ModalPostulanteView  viewdataPost ={VerCardEmple} handleEditPost ={DataSelEmple} handleCancelPostView ={handleCancelCardEmple}  DataPostulant={dataCardEmple}/>
        </div>

    );
}

export default RequerimientosCliente