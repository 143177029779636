import gql from "graphql-tag";

export const PostulacionAdjDet = gql`
query postulacionAdjDet($id_postulacion_det:ID!){
  GetAdjDet(id_postulacion_det:$id_postulacion_det){
    title
    Descripcion
    adjunto
    
  }
}`;
