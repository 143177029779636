import React  from 'react'
import { Modal, Form,Row,Col,Input ,Space,Button,Select,DatePicker,Typography,Avatar} from 'antd';
import '../../Assets/Css/page.css';
import {useAllNiveles} from '../../hooks/custom-general';
const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;

const ModalEduAdd = ({viewEduAdd, DataPostulant, Loading, handleCancelEduAdd,handleAddPostEduc}) => {
    const{NivelesAlldata} = useAllNiveles();
    const [formEducacionAdd] = Form.useForm();
    const dateFormat = 'DD/MM/YYYY'

    const onAddEducacion = async values => {
       //// console.log(values)
        formEducacionAdd.resetFields();
        return handleAddPostEduc(values)    
    }

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;
    };

    return (
        <>
        <Modal
            visible={viewEduAdd}
            title="Agregar Educación"
            style={{ top: 20 }}
            width={'100vh'}
            onCancel={ handleCancelEduAdd}
            footer={[]}
        >

        <Form
                form={formEducacionAdd}
                name="AgregarPostulante"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                onFinish={onAddEducacion}
            >
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 21 }}>
                <Title level={5}> <Space><Avatar size={32} icon={<Icon type='UserOutlined' className="site-form-item-icon" />} />{DataPostulant.Nombre}</Space></Title>
                </Col>
               
                </Row>
              
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="NivelCursado"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Ultimo Nivel Cursado',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Ultimo Nivel Cursado" >
                  {NivelesAlldata.map((data) => {
                        return (
                            <Option key={data.nivel_id} value={data.nivel_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}  
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="InstEducativa"
                    tooltip="Ingrese Institución Educativa"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Institución Educativa'  
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Institución Educativa"
                    />
                </Form.Item>
                </Col>

                </Row>
                
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Titulo"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese Titulo',
                        },
                    ]}
                    hasFeedback
                >
                   <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Titulo Educativo"
                    />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="NotaEgreso"
                    tooltip="NotaEgreso"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese Nota Egreso'   
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="number"
                        placeholder="Nota Egreso"
                    />
                </Form.Item>
                </Col>

                </Row>

                 <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="FechaEgreso"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese Fecha Egreso',
                        },
                    ]}
                    hasFeedback
                >
                    <DatePicker format={dateFormat} style={{ width: '100%' }}  placeholder="Fecha Egreso"/>
                </Form.Item>
                </Col>
                

                </Row>
                
                
                
                
                <Form.Item
                    name="descripcion" 
                >
                 <TextArea rows={4} placeholder="Comentarios"  style={{ width: '100%' }} />
                </Form.Item>
               
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Agregar
                    </Button>
                    <Button key="back" onClick={handleCancelEduAdd}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>
            
        </Modal>
       </> 
    )
   
}

export default ModalEduAdd
