import React ,{useEffect,useState} from 'react'
import {Form,Select,Button,Space,  Modal} from 'antd';
import {useAllusers} from '../../hooks/custom-users';
import CardModule from '../../Components/Modulos/CardModule';
import usePermisosUser from '../../hooks/custom-modulos';
import {useMenuAll}  from '../../hooks/custom-modulos';
import {useInsertModPermisos} from '../../hooks/custom-modulos';
const { Option } = Select;




const SelectSaveModule = () => {
    const {menuAlldata} = useMenuAll();
    const {data,loading} = useAllusers();
    const [getPermisos] = usePermisosUser();
    const [SetPermisos] = useInsertModPermisos();
    const [targetkey,setTargetkey] = useState();
    const [Userdata,setUserdata] = useState([]);
    const [dataMenu,setDataMenu] = useState();
     const [datasave,setDatasave] = useState();
     const [Loading, setLoading] = useState(false);

 
    useEffect(() => {
        if(loading===false){
            const datauser = data.GetAllUsers
            setUserdata(datauser)
        }
        
    }, [data,loading])

    const onChange = async  values =>{
        const  userData = await getPermisos({ variables:{
                sesionId:values, 
            }}
        )
        
        const datafilter = userData.data.GetUserGrand;
        setDataMenu(menuAlldata)
        const initialTargetKeys =datafilter.filter(item => +item.key > 0).map(item => item.key);
        setTargetkey(initialTargetKeys)
        
    }

    const onAdd = async  values => {
        //console.log(datasave,values.User)
         setLoading(true);
        if (Array.isArray(datasave) && datasave.length) {    
            
            
            const IsertResult = await SetPermisos({variables:{
                sesionId:values.User,
                menuId:datasave,
               
            
            }})
            //console.log(IsertResult)

            const success = IsertResult.data.arrinsertPermisos.success
            const message = IsertResult.data.arrinsertPermisos.message
            //console.log(success)
            if(success){   
                setLoading(false);    
                const  modal = Modal.success({
                        content: message,
                    });

                    setTimeout(() => {
                        
                        modal.destroy();
                    }, 3000);

            }else{
                Modal.warning({
                    content: message,
                });
                setLoading(false);
               
                
            
            }  
        }
        else{
               Modal.warning({
                    content: 'Debe seleccionar los modulos antes de Guardar',
                });
                setLoading(false);

        }      
      
    }
    
  //console.log(initialTargetKeys,datafilter)
    
    
    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Form name="Guardar" layout="inline" onFinish={onAdd}>
        
            <Form.Item name="User" label="Usuario">
                <Select placeholder="Seleccione Usuario" onChange={onChange}>
                    {Userdata.map((data) => {
                        return (
                            <Option key={data.id_Sesion} value={data.id_Sesion}>
                                {data.NombreApe}
                            </Option>
                        );
                    })}
                </Select>
            </Form.Item>
           
          
            <Form.Item name="submit">
                <Button htmlType="submit" type="primary" loading={Loading}>Guardar</Button>
            </Form.Item>
       
        </Form>
        
        <CardModule targetkey={targetkey} dataMenu={dataMenu} setDatasave={setDatasave} />
        </Space>
       
    )
}

export default SelectSaveModule
