import gql from "graphql-tag";

export const  GetRegistroRelojControl = gql`

query GetRegistroRelojControl($Id_cliente:ID!,$Id_contrato:ID,$mes:[String],$anio:String!){

  GetRegistroRelojControl(
      Id_cliente: $Id_cliente,
      Id_contrato: $Id_contrato,
      mes: $mes, 
      anio: $anio
    )
  {
    Id_registro
    Nro
    rut
    nombre
    sucursal
    empresa
    id_reloj
    fecha_registro
    format_fecha_registro
    reg_anio
    reg_mes
    tipo_registro
    anio_carga
    mes_carga
    fecha_creacion
    Creado_por
  }

    
}`;

export const GetReportHoraExtra = gql`
query GetReportHoraExtra($fecha_ini:String!,$fecha_fin:String!,$Id_contrato:ID!){
  GetReportHoraExtra(fecha_ini:$fecha_ini,
                     fecha_fin:$fecha_fin,
                     Id_contrato:$Id_contrato) {
    Nombre
    Codigo
    Rut
    Sucursal
    Empresa
    Desde
    hasta
    ReportData {
      id_registro
      Fecha
      Rut
      Nombre
      sucursal
      empresa
      id_reloj
      turno
      fechaent_EsDomingo
      fechasal_EsDomingo
      fechaent_EsFeriado
      fechasal_EsFeriado
      fecha_registrofecha
      hora_entrada
      hora_salida
      tiempo_ad_entrada
      tiempo_ad_salida
      pagohextra_normal
      pagohextra_feriado
      hextra_total
      hextra_total_num
      horas_total_noche
      horas_total_noche_num
    }
  } 
}`;

export const GetlistaTurnos = gql`
query GetEmpleTurnos_ByFilter($fecha_desde:String,$fecha_hasta:String,$Id_contrato:ID,$Id_turno:[String],$Id_cliente:String!){

  GetEmpleTurnos_ByFilter
      (
        Id_contrato: $Id_contrato
        Id_turno: $Id_turno
        fecha_desde: $fecha_desde
        fecha_hasta: $fecha_hasta
        Id_cliente: $Id_cliente
      ) 
  {
    Id_contTurno
    Id_empleado
    Id_cliente
    Rut
    Nombre
    Id_turno
    turno
    fecha_desde
    fecha_hasta
    aniomes_desde
    aniomes_hasta
    id_activo
     activo
    fecha_creacion
    Creado_por
    Fecha_modificacion
    Modificado_por
  
  } 
}`;

export const GetEmpleLicencias_ByFilter = gql`
query GetEmpleLicencias_ByFilter($fecha_desde:String,$fecha_hasta:String,$Id_contrato:ID,$Id_tipo_ausencia:[String],$Id_cliente:String!){

  GetEmpleLicencias_ByFilter(
      Id_cliente: $Id_cliente
      Id_contrato: $Id_contrato
      Id_tipo_ausencia: $Id_tipo_ausencia
      fecha_desde: $fecha_desde
      fecha_hasta: $fecha_hasta
   )
   {
    Id_contractlic
    Id_contrato
    Id_empleado
    Id_cliente
    Nombre
    Rut
    aprobada
    nro_dias
    EsAprobada
    Id_tipoausencia
    tipoausencia
    fecha_desde
    fecha_hasta
    fecha_aprobacion
    fecha_retorno
    Fecha_creacion
    Creado_por

   }
}`;

export const GetEmpleVacaciones_ByFilter = gql`
query GetEmpleVacaciones_ByFilter($fecha_desde:String,$fecha_hasta:String,$Id_contrato:ID,$Id_cliente:String!){

 GetEmpleVacaciones_ByFilter(
      Id_cliente: $Id_cliente
      Id_contrato: $Id_contrato
      fecha_desde: $fecha_desde
      fecha_hasta: $fecha_hasta
   )
   {
    Id_contractvac
    Id_contrato
    Id_empleado
    Id_cliente
    Nombre
    Rut
    aprobada
    nro_dias
    EsAprobada
    fecha_desde
    fecha_hasta
    fecha_aprobacion
    fecha_retorno
    Fecha_creacion
    Creado_por

   }
}`;

export const  GetVacacionesObs = gql`
query GetVacacionesObs($Id_contractvac:ID!){
    GetVacacionesObs(
      Id_contractvac:$Id_contractvac
    )
    {
      title
      Estado
      Observaciones
    }
}`;

export const  GetAusenciasObs = gql`
query GetAusenciasObs($Id_contractlic:ID!){
    GetAusenciasObs(
      Id_contractlic:$Id_contractlic
    )
    {
      title
      Estado
      Observaciones
    }
}`;

export const GetAusenciasAdj = gql`
query GetAusenciasAdj($Id_contractlic:ID!){
  GetAusenciasAdj(
    Id_contractlic: $Id_contractlic
  )
  {
    title
    Descripcion
    adjunto

  }
}`; 

export const GetVacacionesAdj = gql`
query GetVacacionesAdj($Id_contractvac:ID!){
  GetVacacionesAdj(
    Id_contractvac: $Id_contractvac
  )
  {
    title
    Descripcion
    adjunto

  }
}`; 

export const AllContratadoAct = gql`
query{
  GetAllContratadoAct
  {
    Id_contrato
    Id_empleado
    Rut
    Nombre
    Id_cliente
    id_cargo
    cargo
    Tipo_contrato
    tcontrato_name
    fecha_ini_contrato
    fecha_fin_contrato
    id_isapre
    isapre
    id_afp
    afp
    Activo
    Fecha_creacion
    Fecha_modificacion
    Creado_por
    Creado_por_name
    Modificado_por
    Modificado_por_name

  
  } 
}`;

export const GetContratadoByIdCliente = gql`
query GetContratadoByIdCliente($Id_cliente:String!){

  GetContratadoByIdCliente(Id_cliente:$Id_cliente)
  {
    Id_contrato
    Id_empleado
    Rut
    Nombre
    Id_cliente
    id_cargo
    cargo
    Tipo_contrato
    tcontrato_name
    fecha_ini_contrato
    fecha_fin_contrato
    id_isapre
    isapre
    id_afp
    afp
    Activo
    Fecha_creacion
    Fecha_modificacion
    Creado_por
    Creado_por_name
    Modificado_por
    Modificado_por_name

  
  } 
}`;

export const GetCalEmpleTurnos_ByFilter = gql`
query GetCalEmpleTurnos_ByFilter($fecha:String!,$Id_contrato:ID,$Id_turno:[String],$Id_cliente:String!){
    GetCalEmpleTurnos_ByFilter
    (Id_contrato: $Id_contrato
    Id_turno: $Id_turno
    fecha: $fecha
    Id_cliente:$Id_cliente)
  {
    fecha
    Nombre
    turno
  
  } 
}`;

export const GetContratados_ByFilter = gql`
query GetContratados_ByFilter($Id_cliente:String!,$Id_contrato:[ID],$Tipo_contrato:[String],$id_isapre:[String],$id_afp:[String]){
  GetContratados_ByFilter(
        Id_cliente: $Id_cliente
        Id_contrato: $Id_contrato
        Tipo_contrato:$Tipo_contrato
        id_isapre: $id_isapre
        id_afp: $id_afp)
  {
    Id_contrato
    Id_empleado
    Rut
    Nombre
    Id_cliente
    id_cargo
    cargo
    cliente
    Tipo_contrato
    tcontrato_name
    fecha_ini_contrato
    fecha_fin_contrato
    id_isapre
    isapre
    id_afp
    afp
    Activo
    Fecha_creacion
    Fecha_modificacion
    Creado_por
    Creado_por_name
    Modificado_por
    Modificado_por_name
  } 
}`;

export const GetReportHoraExtraResumen = gql`
query GetReportHoraExtraResumen($fecha_ini:String!,$fecha_fin:String!,$Id_contrato:[ID]!){
 GetReportHoraExtraResumen(
    Id_contrato: $Id_contrato
    fecha_ini:$fecha_ini,
    fecha_fin:$fecha_fin
)
  {
    
     success
    message
    Sucursal
    Empresa
    Desde
    hasta
    MontoBaseBono
    ReportData {
      id_registro
      Id_contrato
      Fecha
      Rut
      Nombre
      sucursal
      empresa
      id_reloj
      turno
      fechaent_EsDomingo
      fechasal_EsDomingo
      fechaent_EsFeriado
      fechasal_EsFeriado
      fecha_registrofecha
      hora_entrada
      hora_salida
      tiempo_ad_entrada
      tiempo_ad_salida
      pagohextra_normal
      pagohextra_feriado
       pagohextra_normal_num
      pagohextra_feriado_num
      hextra_total
      horas_total_noche
      hextra_total_num
      horas_total_noche_num
      bono
      fechaLic 
      fechaVac 
      fechaAus 
      diasLic
      diasVac
      DiasAus
      DiasTrab
      razonLic
    }
   
  }
  
}`;

export const GetContratadoBancById = gql`
  query GetContratadoBancById($Id_contrato:ID!){
    GetContratadoBancById(
        Id_contrato: $Id_contrato
      )
    {
      Id_contractbanc
      Id_contrato
      Id_empleado
      Nombre
      Id_entfin
      NombreEntidad
      nrocuenta
      creado_por
      Creado_por_name
      Fecha_creacion
    
    }
  
}`;


export const  GetVacacionesPendById = gql`
query GetVacacionesPendById($Id_contrato:ID!){
  GetVacacionesPendById(
    Id_contrato: $Id_contrato
  )
  {
    Id_contractvacp
    Id_contrato
    Id_empleado
    rut
    diasDeVacacionesPorAno
    diasacumulados
    diasprogresivos
    diasTotales
    diasacumTomados
    diasprogreTomados
    diasprogrevendido
    diastotTomados
    diastotRestantes
    
  }
}`;