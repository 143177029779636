import React,{useState,useEffect}  from 'react'
import { Modal, Form,Row,Col,Input ,Space,Button,Select,DatePicker} from 'antd';
//import {  validate } from 'rut.js'
import '../../Assets/Css/page.css';
import moment from 'moment'
import {useAllEstCivil,useAllNacionalidad,useAllSexo,useAllRegiones,useComunasById} from '../../hooks/custom-general';
const { Option } = Select;



const ModalEditPost = ({viewEditPost, Loading, handleSavePost, handleCancelPostulant, EditDataPost}) => {
    const [formEditPost] = Form.useForm();
   
    const{civilAlldata} = useAllEstCivil();
    const{NacAlldata} = useAllNacionalidad();
    const{SexoAlldata} = useAllSexo();
     const{RegionAlldata} = useAllRegiones();
    const[SelComuna] = useComunasById();
    const [Comunas,setComunas] = useState([]);
    const dateFormat = 'DD/MM/YYYY'
   
    useEffect(() => {
        
       
        if (ModalEditPost) {
            
            if(EditDataPost){
                async function getviewComuna(){
                const comunassel = await  SelComuna({variables:{
                    region_id:EditDataPost?.region_id,
                    }})
            
                
                    if(comunassel.loading===false){
                        setComunas(comunassel.data.GralComunasById)
                    
                    }

                }
                    getviewComuna()
            }

            formEditPost.setFieldsValue({
                Rut: EditDataPost?.Rut,
                FechaNacimiento: moment(EditDataPost?.Fecha_nacimiento,'YYYY/MM/DD'),
                NombrePostulante:  EditDataPost?.Nombre,
                EstCivilModal: EditDataPost?.Id_estado_civil,
                Sexo: EditDataPost?.Id_sexo,
                Nacionalidad: EditDataPost?.Id_Nacionalidad,
                Region:  EditDataPost?.region_id,
                Comuna:  EditDataPost?.id_comuna,
                Direccion: EditDataPost?.Direccion,
              
            });
        }
       
    }, [EditDataPost, SelComuna, formEditPost]);

    const onCityChange = async values => {
      console.log(values);
        const comunassel = await  SelComuna({variables:{
            region_id:values,
        }})
 
     
        if(comunassel.loading===false){
            setComunas(comunassel.data.GralComunasById)
           
        }
         
    }
    
    const onAdd = async values => {
        return handleSavePost(values);   
    }

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;

    };

    return (
        <Modal
            visible={viewEditPost}
            forceRender
            style={{ top: 20 }}
            title="Editar Postulación"
            onCancel={handleCancelPostulant}
            footer={[
                
               
            ]}
        >
             <Form
                form={formEditPost}
                name="NuevoPostulante"
                onFinish={onAdd}
            >
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="Rut"
                   /* tooltip="Ingrese Rut sin puntos solo Guion"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Rut'  
                        }  
                        ,{
                            pattern: /[0-9kK-]+$/,
                            message: 'Rut solo numeros y Guion y K ',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                            if (validate(value)) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Rut mal ingresado..!'));
                            },
                        }),
                    ]}
                    hasFeedback  */
                    
                >
                    <Input 
                        maxLength={15}
                        prefix={<Icon type='UserOutlined'  className="site-form-item-icon" />}
                        type="text"
                        disabled
                        placeholder="Ingrese Rut sin puntos solo Guión"
                        
                    />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="FechaNacimiento"
                    rules={[
                        { required: true,message: 'Seleccione Fecha Nacimiento'},
                                
                    ]}
                    hasFeedback
                >
                  <DatePicker  style={{ width: '100%' }}  format={dateFormat} placeholder="Fecha Nacimiento"/>
                </Form.Item>
              
                </Col>
                </Row>
                <Form.Item
                    name="NombrePostulante"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Nombre Cliente',
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Nombre Cliente"
                    />
                </Form.Item>
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
               <Form.Item
                    name="EstCivilModal"
                    rules={[
                        { required: true,message: 'Seleccione Estado Civil'},
                                
                    ]}
                    hasFeedback
                >
                   <Select placeholder="Estado Civil" >
                    {civilAlldata.map((data) => {
                        return (
                            <Option key={data.estado_civil_id} value={data.estado_civil_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
                </Col>
                
                </Row>
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Sexo"
                    rules={[
                        { required: true,message: 'Seleccione Genero'},
                                
                    ]}
                    hasFeedback
                >
                   <Select placeholder="Genero" >
                    {SexoAlldata.map((data) => {
                        return (
                            <Option key={data.sexo_id} value={data.sexo_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Nacionalidad"
                    rules={[
                        { required: true,message: 'Seleccione Nacionalidad'},
                                
                    ]}
                    hasFeedback
                >
                   <Select placeholder="Nacionalidad" >
                    {NacAlldata.map((data) => {
                        return (
                            <Option key={data.nacionalidad_id} value={data.nacionalidad_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
              
                </Col>
                </Row>
          
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
               <Form.Item
                    name="Region"
                    rules={[
                        { required: true,message: 'Seleccione Region'},
                                
                    ]}
                    hasFeedback
                >
                   <Select placeholder="Region" onChange={onCityChange}>
                    {RegionAlldata.map((data) => {
                        return (
                            <Option key={data.region_id} value={data.region_id}>
                                {data.region_nombre}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Comuna"
                    rules={[
                        { required: true,message: 'Seleccione Comuna'},
                                
                    ]}
                    hasFeedback
                >
                <Select placeholder="Comunas" >
                    {Comunas.map((data) => {
                        return (
                            <Option key={data.comuna_id} value={data.comuna_id}>
                                {data.comuna_nombre}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
              
                </Col>
                </Row>
                <Form.Item
                    name="Direccion"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Direccion',
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Direccion"
                    />
                </Form.Item>
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading} >
                        Editar
                    </Button>
                    <Button key="back" onClick={handleCancelPostulant}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
               
               
            </Form>
        </Modal>
    )
}

export default ModalEditPost
