import React, { useState, useRef} from 'react'
import { Table, Input, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined, LockOutlined, EditOutlined, UserSwitchOutlined} from '@ant-design/icons';

const TableUsers = ({ data,  Deleteuser, Resetclave, Edituser}) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
   
    const paramstable = {
       bordered: true,
        pagination:false,
        size: 'small',
        tableLayout: 'auto',
        scroll: { x: 'max-content', y: '50vh'},
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button onClick={() => {handleReset(clearFilters); confirm({closeDropdown: false });}} size="small" style={{ width: 90 }}>
                        Borrar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput, 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
       
    };


    const [Columsdata] = useState([
        {
            title: 'Id',
            dataIndex: 'id_usuario',
            key: 'id_usuario',
            hidden: true,
            
            

        },
        {
            title: 'Usuario',
            dataIndex: 'Users',
            key: ' Users',
            fixed:false,
            ...getColumnSearchProps('Users'),

        },
        {
            title: 'Nombre',
            dataIndex: 'NombreCompleto',
            key: 'NombreCompleto',
            fixed:false,
            ...getColumnSearchProps('NombreCompleto'),

        },
        {
            title: 'Email',
            dataIndex: 'Email',
            key: 'Email',
            fixed:false,
            ...getColumnSearchProps('Email'),

        },
        {
            title: 'Creación',
            dataIndex: 'Fecha_creacion',
            key: 'Fecha_creacion',
            fixed:false,
            ...getColumnSearchProps('Fecha_creacion'),

        },
        {
            title: 'Estado',
            dataIndex: 'Estado',
            key: 'Estado',
            fixed:false,
            ...getColumnSearchProps('Estado'),

        },
        {
            title: 'Acción',
            dataIndex: 'accion',
            key: 'accion',
            fixed:false,
            render: (text, record) => {
               
                return (
                    <>
                        <Space size="middle">
                            <LockOutlined onClick={() => { onResetclave(record) }} />
                            <EditOutlined onClick={() => { onEdituser(record) }} />
                            <UserSwitchOutlined onClick={() => { onDeleteuser(record) }} />

                        </Space>
                    </>

                )
            }
        }
    ].filter(item => !item.hidden));
   

    const onDeleteuser = (record) => {
        return Deleteuser(record);
    }

    const onResetclave = (record) => {
        return Resetclave(record);
    }

    const onEdituser = (record) => {
        return Edituser(record);
    }
   

    return (
          
        <Table columns={Columsdata} dataSource={data} {...paramstable} rowKey="id_usuario"/> 
    )
}

export default TableUsers
