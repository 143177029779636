import gql from "graphql-tag";

export const GralEstado = gql`

query{
     GetallEst {
      estado_id
      codigo_gral	
      descripcion
      activo   
    }  
   
}`

export const GralEstadoPost = gql`

query{
    GetallEstPost
    {
      estado_postulacion_id
			codigo_gral
			descripcion
			activo
    }  
}`

export const GralSexo = gql`

query{
   GetallSexo
    {
     sexo_id
      descripcion
      activo
    }
    
}`

export const GralNacionalidad = gql`

query{
   GetallNacionalidad
    {
      nacionalidad_id
      descripcion
      activo
    }
    
}`

export const GralCargo = gql`

query{
   GetallCargo
    {
      cargo_id
      descripcion
      grupo
      activo
    }
    
}`

export const GralGiro = gql`
query{
    GetallGiro {
      giro_id
      descripcion
      activo
  }
}`


export const GralEstCivil = gql`

query{
   GetallEstCivil
    {
      estado_civil_id
      descripcion
      activo
    }
    
}`

export const GralIdioma = gql`

query{
  GetallIdioma
      {
        idioma_id
        descripcion
        activo
      }
    
}`

export const GralTipoContacto = gql`

query{
  GetallTipoContacto
      {
        tipo_contacto_id
        descripcion
        Icon
        activo
      }
    
}`

export const GralComunasById = gql`

query GetGralComunasById($region_id:ID){
  GralComunasById(region_id:$region_id)
      {
        comuna_id
        comuna_nombre
        provincia_id
        region_id
      }
}`

export const GralRegiones = gql`

query{
  Gralregiones
      {
        region_id
        region_nombre
        region_ordinal
      }
}`

export const GralTipoEmpleo = gql`

query{
  GralTipoEmpleo
      {
        templeo_id
        descripcion
        activo
      }
    
}`

export const GralNiveles = gql`
query{
  GetallNiveles
      {
        nivel_id
        descripcion
       activo
      }
}`
;

export const GetallBoolean = gql`
query{
  GetallBoolean
    {
      estid
      codigo
      descripcion
    }
}`
;

export const GetallRangoEdad = gql`
query{
  GetallRangoEdad
    {
      redadid
      codigo
      descripcion
    }
}`
;

export const GetallhorarioLab = gql`
query{
  GetallhorarioLab
  {
    hlabid
		codigo
		descripcion
  }
}`
;

export const GetallExperencia = gql`
query{
  GetallExperencia
    {
      expid
      codigo
      descripcion
    }
}`
;

export const GetallExamenes = gql`
query{
  GetallExamenes
    {
      examenid
      codigo
      descripcion
    }
}`
;

export const GetallLicencia = gql`
query{
  GetallLicencia
    {
      licid
      codigo
      descripcion
    }
}`
;

export const GetallTContrato = gql`
query{
  GetallTContrato
    {
      tcontid
      codigo
      descripcion
    }
}`
;

export const GetallSendAlert = gql`
query{
 GetallSendAlert
  {
    send_id
    descripcion
    activo
  }
  
}`;

export const GetallMeses = gql`
query{
GetallMeses {
    mes_id
    mes
    descripcion
  }
    
}`;

export const GetallTurnos = gql`
query{
 GetallTurnos
  {

    Id_turno
    turno
    ingreso
    salida
    minTrababajo
    minColacion
    minvalhextra
    activo
  
  } 
}`;

export const GetallIsapres = gql`
query{
 GetallIsapres
  {
    isapre_id
    descripcion
    activo
  }
  
}`;

export const GetallAfp = gql`
query{
 GetallAfp
  {
    afp_id
    descripcion
    activo
  }
  
}`;

export const GetallBancos = gql`
query{
GetallBancos {
    banco_id
    descripcion
    activo
  }
}`;

export const GetallTipoAusencia = gql`
query{
GetallTipoAusencia {
    tipoa_id
    descripcion
    activo
  }
}`;

export const GetallAprobacion = gql`
query{
GetallAprobacion {
    aprobada
    descripcion
    activo
  }
}`;

export const GetallTDoctoAusencia = gql`
query{
  GetallTDoctoAusencia
  {
    tdoctoid
    codigo
    descripcion
    activo
  }
}`;

export const GetallTDoctoVacaciones = gql`
query{
  GetallTDoctoVacaciones
  {
    vtdoctoid
    codigo
    descripcion
    activo
  }
}`;

