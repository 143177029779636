import React  from 'react'
import { Modal, Button, Form, Input, Space } from 'antd';
import '../Assets/Css/page.css';

const ModalClave = ({Visible, Loading, handleOk, handleCancel}) => {
    const [form] = Form.useForm();
    const onFinish = async values => {
        
        setTimeout(() => {
            form.resetFields();
        }, 3000);
        return handleOk(values); 
       
    }

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;

    };

    return (
        <Modal
            visible={Visible}
            title="Cambiar clave"
            onCancel={handleCancel}
            footer={[
                
               
            ]}
        >
            <Form
                form={form}
                name="normal_login"
                className="login-form"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="claveactual"
                    rules={[
                        {
                            required: true,
                            message: 'Introduzca su Clave Actual.',
                        },
                    ]}
                >
                    <Input.Password
                        prefix={<Icon type='LockOutlined' className="site-form-item-icon" />}
                        type="password"
                        placeholder="Clave Actual"
                    />
                </Form.Item>
                <Form.Item
                    name="NuevaClave"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese su nueva Clave!',
                        },
                        { 
                            min: 8, 
                            message: 'El password mínimo de 8 caracteres.' 
                        },
                       
                    ]}
                    hasFeedback
                >
                    <Input.Password
                        prefix={<Icon type='LockOutlined'  className="site-form-item-icon" />}
                        type="password"
                        placeholder="Nueva Clave"
                    />
                </Form.Item>

                <Form.Item
                    name="ReNuevaClave"
                    dependencies={['NuevaClave']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Por favor Confirme su clave !',
                        },
                        { 
                            min: 8, 
                            message: 'El password mínimo de 8 caracteres.' 
                        },
                        
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('NuevaClave') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('¡Las dos claves que ingresó no coinciden!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        prefix={<Icon type='LockOutlined' className="site-form-item-icon" />}
                        type="password"
                        placeholder="Confirmar Clave"
                    />
                </Form.Item>

                


                <Form.Item>
                    <Space>
                    <Button key="back" onClick={handleCancel}>
                        Cancelar
                    </Button>
                    <Button htmlType="submit" type="primary" loading={Loading} >
                        Cambiar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ModalClave
