
import {useQuery,useLazyQuery,useMutation} from '@apollo/client'
import {ClientPostulacionesById,GetClientPostSel,GetClientPostByClient} from "../model/query/postulacionCliente/AllPostulaciones"
import {ClientesByLikes} from "../model/query/postulacionCliente/ClientesByLikes"
import {PostulacionClientObs} from "../model/query/postulacionCliente/PostulacionObs"
import {PostulacionClientAdj} from "../model/query/postulacionCliente/PostulacionAdj"
import {PostulacionClientObsDet} from "../model/query/postulacionCliente/PostulacionObsDet"
import {PostulacionClientAdjDet} from "../model/query/postulacionCliente/PostulacionAdjDet"
import {PostulacionClientSelect} from "../model/query/postulacionCliente/PostulacionSelect"
import {createClientPostClienteCargo,UpdateClientPostCliente,UpdateClientPostCargo,UpdateClientPostPersona,
        UpdateClientPostBeneficios,UpdateClientPostOtros,InsertClientObsPost,InsertClientAdjPost,
        InsertClientPostDet,InsertClientObsPostDet,InsertClientAdjPostDet,DeleteClientPostDet} from "../model/mutation/postulacioncliente/Setpostulacion"



//Querys
export const useAllPostulaciones = (props) =>{
  
    const result = useQuery(ClientPostulacionesById,{variables: {id_Sesion:props},
        //fetchPolicy: 'network-only',
        pollInterval: 15000,
        onCompleted: () => console.log('called')});
    return result;
}

export const useClientPostulacionesById = () =>{
    const result = useLazyQuery(ClientPostulacionesById);
    return result;
}

export const useClientPostulacionSel = () =>{
    const result = useLazyQuery(GetClientPostSel);
    return result;
}

export const useClientPostulacionByClient = () =>{
    const result = useLazyQuery(GetClientPostByClient);
    return result;
}


export const useClientesByLike = () =>{
    const result = useLazyQuery(ClientesByLikes);
    return result;
}

export const useClientPostulacionObs = () =>{
    const result = useLazyQuery(PostulacionClientObs,{});
    return result;
}

export const useClientPostulacionAdj = () =>{
    const result = useLazyQuery(PostulacionClientAdj,{});
    return result;
}

export const useClientPostulacionObsDet = () =>{
    const result = useLazyQuery(PostulacionClientObsDet,{});
    return result;
}

export const useClientPostulacionAdjDet = () =>{
    const result = useLazyQuery(PostulacionClientAdjDet,{});
    return result;
}

export const useClientPostulacionSelect = () =>{
    const result = useLazyQuery(PostulacionClientSelect,{});
    return result;
}


//mutation
export const useCreateClientPostClienteCargo = (props) =>{
    
    const result = useMutation(createClientPostClienteCargo,{refetchQueries:[{query:ClientPostulacionesById,variables: {id_Sesion:props}, }]});
    return result;
}

export const useUpdateClientPostCliente = (props) =>{
    
    const result = useMutation(UpdateClientPostCliente,{refetchQueries:[{query:ClientPostulacionesById,variables: {id_Sesion:props}, }]});
    return result;
}

export const useUpdateClientPostCargo = (props) =>{
   
    const result = useMutation(UpdateClientPostCargo,{refetchQueries:[{query:ClientPostulacionesById,variables: {id_Sesion:props}, }]});
    return result;
}

export const useUpdateClientPostPersona = (props) =>{
    
   const result = useMutation(UpdateClientPostPersona,{refetchQueries:[{query:ClientPostulacionesById,variables: {id_Sesion:props}, }]});
    return result;
}

export const useUpdateClientPostBeneficios = (props) =>{
   
   const result = useMutation(UpdateClientPostBeneficios,{refetchQueries:[{query:ClientPostulacionesById,variables: {id_Sesion:props}, }]});
    return result;
}

export const useUpdateClientPostOtros = (props) =>{
   
   const result = useMutation(UpdateClientPostOtros,{refetchQueries:[{query:ClientPostulacionesById,variables: {id_Sesion:props},
        //fetchPolicy: 'network-only',
        pollInterval: 15000,
        onCompleted: () => console.log('called')}]});
    return result;
}

export const useInsertClientObsPost = (props) =>{
   
   const result = useMutation(InsertClientObsPost,{refetchQueries:[{query:ClientPostulacionesById,variables: {id_Sesion:props},
        //fetchPolicy: 'network-only',
        pollInterval: 15000,
        onCompleted: () => console.log('called')}]});
   useClientPostulacionesById();
    return result;
}

export const useInsertClientObsPostDet = () =>{
   const result = useMutation(InsertClientObsPostDet);
    return result;
}

export const useInsertClientAdjPost = () =>{
   const result = useMutation(InsertClientAdjPost,{});
    return result;
}

export const useInsertClientAdjPostDet = () =>{
   const result = useMutation(InsertClientAdjPostDet,{});
    return result;
}

export const useInsertClientPostDet = () =>{
   const result = useMutation(InsertClientPostDet,{});
    return result;
}




export const useClientPostulanteDelete = () =>{
    const result = useMutation(DeleteClientPostDet,{});
    return result;
}

export default useClientPostulacionesById


