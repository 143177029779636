import { PageHeader, Card ,Typography, Descriptions,Button,Tooltip,Avatar } from 'antd';
import '../../Assets/Css/page.css'
const CardContactos = ({dataContacto,handleViewTContact,handleViewLContact,dataContactoDet,handleDelType,handleDelContact}) => {
   // console.log(dataContactoDet)
const { Paragraph } = Typography;

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;

    };

    
    const onhandledel = (values) => {
       
       return handleDelType(values)
    };

    const Onopencontact = (values) => {
      
       return handleViewTContact(values)
    };

    const Ondeletecontact = (values) => {
        
       return handleDelContact(values)
    };


    const Onsesioncontact = (values) => {
     
       return handleViewLContact(values)
    };

    return (
    
    <div key ={"D10000"}  className="site-page-header-ghost-wrapper">
    {dataContacto.map((items,index) =>( 
        
    <> <Card key={index.toString()} size="small" bordered={true} >

        <PageHeader 
            key={"PH1"+index}
            className="site-page-header-responsive"
            ghost={false}
            title={items.Nombre}
            avatar={{ src: <Avatar style={{ backgroundColor: "#1890ff" }} icon={<Icon type="UserOutlined" key="handlehead" />} />} }
             extra={

                [ <Tooltip mouseLeaveDelay={0} title="Crear Contactos" color={'blue'}  ><Button key ={"b1"+index} onClick={() => { Onopencontact(items) }}  icon={<Icon type='ContactsOutlined' />}/></Tooltip>
             
               , <Tooltip mouseLeaveDelay={0} title="Crear Sesion Usuario" color={'blue'} ><Button  key ={"b2"+index} onClick={() => { Onsesioncontact(items) }}  icon={<Icon type='ExportOutlined' />}/></Tooltip>
            ,
             <Tooltip mouseLeaveDelay={0} title="Eliminar Usuario" color={'blue'}  ><Button key ={"b3"+index} onClick={() => { Ondeletecontact(items) }}  icon={<Icon type='UserDeleteOutlined' />}/></Tooltip>
             ]}
           
        >
            <div key={index}>
             <Paragraph key ={"P1"+index} type="secondary">
                 {items.DescripcionCargo}
            </Paragraph>
            </div>
            
           
           <Descriptions size="small" column={2} >
            {  dataContactoDet.filter(subitem => subitem.Id_contacto === items.Id_contacto).map(filtered => (
                <><Descriptions.Item   label={<Icon type={filtered.Icon} />}>{filtered.descripcion}</Descriptions.Item>
                    <Descriptions.Item  contentStyle={{ position: 'relative', left: '70%', }}>
                        <Tooltip title="Eliminar" color={'blue'}>
                            <Button key ={"b4"+index} size="small" icon={<Icon type="RestOutlined" key="handleDel" />}  onClick={() => { onhandledel(filtered) } } />
                        </Tooltip>
                    </Descriptions.Item>
                </>   
                
            )) }
            </Descriptions>
           
            
        </PageHeader>
       </Card><br /></>
    )) }
  </div>
    
   
    )
}

export default CardContactos
