import React,{useEffect}  from 'react'
import { Modal, Button, Form, Input, Space, Row, Col,Select} from 'antd';
import '../../Assets/Css/page.css';

import {useAllNiveles,
useAllRangoEdad,useAllBoolean,useAllSexo} from '../../hooks/custom-general';
const { TextArea } = Input;
const { Option, OptGroup } = Select;

const ModalPostEditPers = ({ EditPersona,EditDataPost, Loading, handlePersonaSave, handleCancelPers}) => {

    const{NivelesAlldata} = useAllNiveles();
    const{SexoAlldata} = useAllSexo();
    const{rangoEdadAlldata} = useAllRangoEdad();
    const{booleanAlldata} = useAllBoolean();

    const [formPersona] = Form.useForm();
    
    useEffect(() => {
        
        if(EditPersona){
            let genero='';
            
            if(EditDataPost.Genero!=null){
                genero = EditDataPost.Genero.split(',')
                formPersona.setFieldsValue({
                    NivelCursado:EditDataPost.Nivel_cursado,
                    Genero:genero,
                    RestNacionalidad:EditDataPost.Rest_nacionalidad,
                    RangoEdad:EditDataPost.Rango_edad,
                    RestComuna:EditDataPost.Rest_comuna,
                    Discapacidad:EditDataPost.Persona_discapacidad,
                    DescripcionPersona:EditDataPost.Obs_personas,
                
                });
            }
            else{
                
                formPersona.setFieldsValue({
                    NivelCursado:EditDataPost.Nivel_cursado,
                    RestNacionalidad:EditDataPost.Rest_nacionalidad,
                    RangoEdad:EditDataPost.Rango_edad,
                    RestComuna:EditDataPost.Rest_comuna,
                    Discapacidad:EditDataPost.Persona_discapacidad,
                    DescripcionPersona:EditDataPost.Obs_personas,
                
                });
            }
            
            
        }
       
        
       
    }, [EditDataPost, EditPersona, formPersona]);
    
    const onPersona = values => {
           
            return handlePersonaSave(values); 
          
    }

    return (
        <>
        <Modal
            visible={EditPersona}
            title="Editar Personas"
            // style={{ top: 20 }}
            onCancel={handleCancelPers}
            footer={[]}
        >

       
                <Form
                layout={"vertical"}
                form={formPersona}
                name="NuevoPostulante"
                onFinish={onPersona}
            >
                
                
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    label="Educación"
                    name="NivelCursado"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Ultimo Nivel Cursado',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Ultimo Nivel Cursado" >
                <OptGroup label="Nivel Cursado">
                  {NivelesAlldata.map((data) => {
                        return (
                            <Option key={data.nivel_id} value={data.nivel_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}  
                </OptGroup>    
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    label="Genero"
                    name="Genero"
                    rules={[
                        { required: true,message: 'Seleccione Genero'},
                                
                    ]}
                    hasFeedback
                >
                <Select placeholder="Genero" mode="multiple" maxTagCount= 'responsive' showArrow >
                <OptGroup label="Genero">
                    {SexoAlldata.map((data) => {
                        return (
                            <Option key={data.sexo_id} value={data.descripcion}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </OptGroup>    
                </Select>
                </Form.Item>
                </Col>
                </Row>
               
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    label="Restricción Nacionalidad"
                    name="RestNacionalidad"
                    rules={[
                        { required: true,message: 'Seleccione Nacionalidad'},
                                
                    ]}
                    hasFeedback
                >
                <Select placeholder="Restricción Nacionalidad" >
                <OptGroup label="Restricción Nacionalidad">
                    {booleanAlldata.map((data) => {
                    return (
                        <Option key={data.estid} value={data.estid}>
                            {data.descripcion}
                        </Option>
                    );
                })}
                </OptGroup>
                </Select>
                </Form.Item>
                </Col>
                 <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    label="Restricción Comuna"
                    name="RestComuna"
                    rules={[
                        { required: true,message: 'Seleccione Comuna'},
                                
                    ]}
                    hasFeedback
                >
                <Select placeholder="Restricción Comunas" >
                <OptGroup label="Restricción Comunas">
                    {booleanAlldata.map((data) => {
                        return (
                            <Option key={data.estid} value={data.estid}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </OptGroup>    
                </Select>
                </Form.Item>
              
                </Col>
                </Row>
          
                <Row gutter={16}>
               <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                     label="Rango Edad"
                    name="RangoEdad"
                    rules={[
                        { required: true,message: 'Seleccione Rango de Edad'},
                                
                    ]}
                    hasFeedback
                >
                   <Select placeholder="Rango de Edad" >
                   <OptGroup label="Rango de Edad">
                    {rangoEdadAlldata.map((data) => {
                        return (
                            <Option key={data.redadid} value={data.redadid}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                    </OptGroup>
                    </Select>
                </Form.Item>
                </Col>
               <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    label="Discapacidad"
                    name="Discapacidad"
                    rules={[
                        { required: true,message: 'Seleccione Estado'},
                                
                    ]}
                    hasFeedback
                >
                <Select placeholder="Persona con Discapacidad" >
                    <OptGroup label="Persona con Discapacidad">
                    {booleanAlldata.map((data) => {
                        return (
                            <Option key={data.estid} value={data.estid}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                    </OptGroup>
                </Select>
                </Form.Item>
                </Col>
                </Row>
               
                <Form.Item
                    name="DescripcionPersona" 
                >
                 <TextArea rows={4} placeholder="Observaciones"  style={{ width: '100%' }} />
                </Form.Item>
               
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading} >
                        Guardar
                    </Button>
                    <Button key="back" onClick={handleCancelPers}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
               
               
               
            </Form>
           
           
        </Modal>
         </> 
    )
}

export default ModalPostEditPers 
