import React, { useState, useRef} from 'react'
import { Table, Input, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined} from '@ant-design/icons';

const TableRelojControl = ({DataReloj}) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
   
   
    const searchInput = useRef(null);
    
    
  
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button onClick={() => {handleReset(clearFilters); confirm({closeDropdown: false });}} size="small" style={{ width: 90 }}>
                        Borrar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput, 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
       
    };
    
    
    const paramstable = {
       bordered: true,
        pagination:false,
        size: 'small',
        tableLayout: 'auto',
        scroll: { x: 'max-content', y: '40vh'},
    }

    const [Columsdata] = useState([
        {
            title: 'Id_registro',
            dataIndex: 'Id_registro',
            key: 'Id_registro',
            hidden: true,  
            

        },
        {
            title: 'Nro_codigo',
            dataIndex: 'Nro',
            key: 'Nro',
            fixed:false,
            ...getColumnSearchProps('Nro'),
  

        },
        {
            title: 'Rut',
            dataIndex: 'rut',
            key: 'rut',
            fixed:false,
            ...getColumnSearchProps('rut'),

        },
        {
            title: 'Nombre',
            dataIndex: 'nombre',
            key: 'nombre',
            fixed:false,
            ...getColumnSearchProps('nombre'),

        },
        {
            title: 'Sucursal',
            dataIndex: 'sucursal',
            key: 'sucursal',
            fixed:false, 
            ...getColumnSearchProps('sucursal'),

        },
        {
            title: 'Empresa',
            dataIndex: 'empresa',
            key: 'empresa',
            fixed:false, 
            ...getColumnSearchProps('empresa'),

        },
        {
            title: 'Cod_reloj',
            dataIndex: 'id_reloj',
            key: 'id_reloj',
            fixed:false, 
            ...getColumnSearchProps('id_reloj'),

        },
        {
            title: 'Fecha',
            dataIndex: 'format_fecha_registro',
            key: 'format_fecha_registro',
            fixed:false, 
            ...getColumnSearchProps('format_fecha_registro'),

        },
        {
            title: 'Tipo',
            dataIndex: 'tipo_registro',
            key: 'tipo_registro',
            fixed:false, 
            ...getColumnSearchProps('tipo_registro'),
            
        },
        
        
        
        
       
    ].filter(item => !item.hidden));
   
   
    
    return (
        <div>
            <Table  columns={Columsdata} dataSource={DataReloj} {...paramstable} rowKey="Id_registro"/> 
        </div>
    )
}

export default TableRelojControl
