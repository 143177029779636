import React,{useState} from 'react'
import 'moment/locale/es-us';
import locale from 'antd/es/date-picker/locale/es_ES';
import { Button, Form,  Space, Row, Col ,DatePicker,Select  } from 'antd';
import '../../../Assets/Css/page.css';


import { useContratadoByIdCliente } from '../../../hooks/custom-contratos'
const { Option } = Select;
const { RangePicker } = DatePicker;


const SearchEmpleVacaciones = ({HandleDataVacaciones,SetdataVacaciones,clientes}) => {
    const [selEmpleados] = useContratadoByIdCliente();
    const [form5] = Form.useForm();
    const dateFormat = 'DD/MM/YYYY'
    const [empleados,setEmpleados] = useState([]);

    

   
    const onAdd = values => {
        return HandleDataVacaciones(values); 
    }

    const  handleClear = () => {
             form5.resetFields();
              return SetdataVacaciones([])
    }



    const onClieChange = async values => {
        const emplesel = await  selEmpleados({variables:{
            Id_cliente:values,
        }})
       
 
        if(emplesel.loading===false){
            setEmpleados(emplesel.data.GetContratadoByIdCliente)
        }
         
    }
   
    return (
        
             <Form
                form={form5}
                name="NuevaVacaciones"
                className="ant-advanced-search-form"
                initialValues={{
                    preserve:false,
                    resetFields:true,
                }}
                onFinish={onAdd}
            >
                
               
                <Row gutter={24}>
                
                
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 4 }}>
                <Form.Item
                    name="Cliente"
                     rules={[
                                { required: true,message: 'Seleccione Cliente'},
                            ]}
                >
               <Select placeholder="Selección Cliente"  showArrow maxTagCount= 'responsive'  onChange={onClieChange} showSearch
                        filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                  {clientes.map(data => {
                        return (
                            <Option key={data.Id_cliente} value={data.Id_cliente}>
                                {data.Nombre}
                            </Option>
                        );
                    })}  
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 4}}>
                <Form.Item
                    name="Id_contrato"
                     rules={[
                                { required: false,message: 'Seleccione Empleado'},
                            ]}
                >
                <Select placeholder="Selección Empleado"  showArrow maxTagCount= 'responsive'  showSearch
                        filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                 {empleados.map((data) => {
                        return (
                            <Option key={data.Id_contrato} value={data.Id_contrato}>
                                {data.Nombre}
                            </Option>
                        );
                    })} 
                </Select>
                </Form.Item>
                </Col>
               
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6 }}>
                <Form.Item
                    name="FechaVacaciones"
                     rules={[
                                { required: false,message: 'Seleccione fecha de Vacaciones'},
                            ]}
                >
                    <RangePicker locale={locale} format={dateFormat} />
                </Form.Item>
                </Col>

                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 5 }}>
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={false} >
                        Buscar
                    </Button>
                    <Button key="back" onClick={handleClear}>
                        Limpiar
                    </Button>
                    {/*<Button onClick={ViewCal}>
                       Calendario
                    </Button>*/}
                    </Space>

                </Form.Item>
                </Col>
                </Row>
            </Form>
        
    )
}

export default SearchEmpleVacaciones
