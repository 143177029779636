import React,{useState}  from 'react'
import { Modal,Form,Row,Col,Input ,Space,Button,Select,DatePicker,Typography,Avatar,Checkbox} from 'antd';
import '../../Assets/Css/page.css';
import {useAllTipoEmpleo,useAllCargo} from '../../hooks/custom-general';
const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;

const ModalLabAdd = ({ viewLabAdd, DataPostulant, Loading,handleCancelLabAdd,handleAddPostLab}) => {
  
   
   
    const{cargoAlldata} = useAllCargo();
    const{TipoEmpAlldata} = useAllTipoEmpleo();
   

 
    const [formLaboralAdd] = Form.useForm();
   
   
    const [disabled, setDisabled] = useState(false);
    const [required, setRequired] = useState(true);

    const dateFormat = 'DD/MM/YYYY'

    const onAddLaboral = async values => {
       //// console.log(values)
        formLaboralAdd.resetFields();
        return handleAddPostLab(values)    
    }

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;
    };

    const onChange = (e) => {
        setDisabled(e.target.checked);
        //console.log("checked:",e.target.checked)
        if(e.target.checked===false){
           setRequired(true)
        }
        if(e.target.checked===true){
           setRequired(false)
        }
       
        

    };

    return (
        <>
        <Modal
            visible={viewLabAdd}
            title="Nueva Experiencia Laboral "
            style={{ top: 20 }}
            width={'100vh'}
            onCancel={handleCancelLabAdd}
            footer={[]}
        >

       <Form
                form={formLaboralAdd}
                name="AgregarNuevoPostulante"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                
                onFinish={onAddLaboral}
            >
                
               <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 21 }}>
                <Title level={5}> <Space><Avatar size={32} icon={<Icon type='UserOutlined' className="site-form-item-icon" />} />{DataPostulant.Nombre}</Space></Title>
                </Col>
               
                </Row>
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Cargo"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Cargo',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Cargo" showArrow maxTagCount= 'responsive' 
                        showSearch
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                  {cargoAlldata.map((data) => {
                        return (
                            <Option key={data.cargo_id} value={data.cargo_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}  
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="TipoEmpleo"
                    tooltip="Ingrese Tipo Empleo"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Tipo Empleo'  
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                   <Select placeholder="Tipo Empleo" >
                  {TipoEmpAlldata.map((data) => {
                        return (
                            <Option key={data.templeo_id} value={data.templeo_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}  
                </Select>
                </Form.Item>
                </Col>

                </Row>
                
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="NombreEmpresa"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Nombre Empresa',
                        },
                    ]}
                    hasFeedback
                >
                   <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Nombre Empresa"
                    />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="DireccionEmp"
                    tooltip="Direccion Empresa"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese Direccion Empresa'   
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Direccion Empresa"
                    />
                </Form.Item>
                </Col>

                </Row>

                 <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="FechaInicio"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Fecha Comienzo',
                        },
                    ]}
                    hasFeedback
                >
                    <DatePicker format={dateFormat} style={{ width: '100%' }}  placeholder="Fecha Comienzo"/>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="FechaTermino"
                    rules={[
                        {
                            required:required,
                            message: 'Por favor ingrese Fecha Termino',
                        },
                    ]}
                    hasFeedback
                >
                    <DatePicker disabled={disabled} format={dateFormat} style={{ width: '100%' }}  placeholder="Fecha Termino"/>
                </Form.Item>
                </Col>

                </Row>
                
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
               <Form.Item 
                    name="CargoActual"
                    tooltip="Empleo Actual"
                     valuePropName="checked"
                >
                <Checkbox  onChange={onChange}>
                Este es tu Cargo Actual 
                </Checkbox>
                </Form.Item>
                
                </Col>

                </Row>
                
                
                <Form.Item
                    name="DescripcionCargo" 
                >
                 <TextArea rows={4} placeholder="Descripcion Cargo"  style={{ width: '100%' }} />
                </Form.Item>
               
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Agregar
                    </Button>
                    <Button key="back" onClick={handleCancelLabAdd}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>
            
        </Modal>
       </> 
    )
   
}

export default ModalLabAdd
