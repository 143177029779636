import React  from 'react'
import { Button, PageHeader ,Row,Col} from 'antd';

import { FilterOutlined ,PrinterOutlined,FileExcelOutlined} from '@ant-design/icons';
import '../../../Assets/Css/page.css';



const HeadReportHextra = ({Datapersona,handlePrint,handleFiltro,handleExcel}) => {
 
    return (
        
    <div className="site-page-header-ghost-wrapper2">
    <PageHeader
      ghost={false}
      
      title={Datapersona.Nombre}
      subTitle=" Informe de Horas Extras"
      extra={[
        <Button  key="2" icon={<FileExcelOutlined />} onClick={handleExcel}> A Excel</Button>,
        <Button  key="4" icon={<PrinterOutlined />} onClick={handlePrint}> Imprimir</Button>,
        <Button   key="3" icon={<FilterOutlined />} onClick={handleFiltro}>Filtros</Button>,
      ]}
    >
    <Row gutter={8}>
      <Col  xs={{ span: 24, offset: 0 }} lg={{ span: 4 }}><b>Codigo: </b>{Datapersona.Codigo}</Col>
      <Col  xs={{ span: 24, offset: 0 }} lg={{ span: 4 }}><b>Rut: </b>{Datapersona.Rut}</Col>
      <Col  xs={{ span: 24, offset: 0 }} lg={{ span: 4 }}><b>Sucursal: </b>{Datapersona.Sucursal}</Col>
      <Col  xs={{ span: 24, offset: 0 }} lg={{ span: 4 }}><b>Empresa: </b>{Datapersona.Empresa}</Col>
      
    </Row>
    <Row gutter={16}>
      <Col  xs={{ span: 24, offset: 0 }} lg={{ span: 4 }}><b>Desde: </b>{Datapersona.Desde}</Col>
      <Col  xs={{ span: 24, offset: 0 }} lg={{ span: 4 }}><b>Hasta: </b>{Datapersona.hasta}</Col>
      
    </Row>
    </PageHeader>
  </div>
        
    )
}

export default HeadReportHextra
