import gql from "graphql-tag";

export const ClientesByLikes = gql`
query ClientesByLikes($descripcion:String!){
  GetClientbyLike(descripcion:$descripcion) {
    Id_cliente
    Rut
    Nombre
  }
}`;
