import { useState, useEffect} from 'react'
import {useLazyQuery} from '@apollo/client'
import {Menuuser} from "../model/query/usuarios/Menuuser"


export const useMenus = () => {
    const [getMenudata] = useLazyQuery(Menuuser);
   // const[result,SetResult] = useState([])
   const [menudata,Setmenudata] = useState([])
    const[error,SetError] = useState(null)
    const[isLoading,SetIsLoading] = useState(true)
    
    useEffect(()=>{
        async function getData(){
            //SetIsLoading(true)
            try{
                    const tokenString = localStorage.getItem('token');
                    const userToken = JSON.parse(tokenString); 
                    const userF=userToken.split('||')
                    const  userData =  await getMenudata({ variables:{
                        User:userF[1],
                        
                    }}
                    )
                    if(userData){
                        const Success = userData.data.GetMenu.success
                        if(Success){
                            Setmenudata(userData.data.GetMenu)
                        }  
                    } 
            }
            catch(error){
               
                SetError(error.message)
            }
            SetIsLoading(false)

        }
        getData()

    },[getMenudata])

     return{
         menudata,
         error,
         isLoading
     }
}



export default useMenus