import gql from "graphql-tag";

export const PostulacionClientAdjDet = gql`
query postulacionClientAdjDet($id_postulacion_det:ID!){
  GetClientAdjDet(id_postulacion_det:$id_postulacion_det){
    title
    Descripcion
    adjunto
    
  }
}`;
