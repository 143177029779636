import React, { useState, useRef} from 'react'
import { Table, Input, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined} from '@ant-design/icons';

const TableSelempleado = ({DataEmp,handleSelPost}) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [selRowKeys,  setSelRowKeys] = useState([]);
   
    const searchInput = useRef(null);
    
    const handlesave = ()=>{
        
        return handleSelPost(selRowKeys)
    }
  
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button onClick={() => {handleReset(clearFilters); confirm({closeDropdown: false });}} size="small" style={{ width: 90 }}>
                        Borrar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput, 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
       
    };
    

    const paramstable = {
       bordered: true,
        pagination:false,
        size: 'small',
        tableLayout: 'auto',
        scroll: { x: 'max-content', y: '30vh'},
    }

    const [Columsdata] = useState([
       
        {
            title: 'Id',
            dataIndex: 'Id_empleado',
            key: 'Id_empleado',
            hidden: true,  

        },
        {
            title: 'Nombre',
            dataIndex: 'Nombre',
            key: 'Nombre',
            fixed:false,
            ...getColumnSearchProps('Nombre'),

        },
        {
            title: 'Rut',
            dataIndex: 'Rut',
            key: 'Rut',
            fixed:false,
            ...getColumnSearchProps('Rut'),

        },
        {
            title: 'Sexo',
            dataIndex: 'Sexo',
            key: 'Sexo',
            fixed:false,
            ...getColumnSearchProps('Sexo'),

        },
        {
            title: 'Nacionalidad',
            dataIndex: 'Nacionalidad',
            key: 'Nacionalidad',
            fixed:false,
            ...getColumnSearchProps('Nacionalidad'),

        },
         {
            title: 'Est.civil',
            dataIndex: 'Estado_civil',
            key: 'Estado_civil',
            fixed:false, 
            ...getColumnSearchProps('Estado_civil'),

        },
        {
            title: 'Comuna',
            dataIndex: 'comuna_nombre',
            key: 'comuna_nombre',
           fixed:false,
            ...getColumnSearchProps('comuna_nombre'),
            
        },
        {
            title: 'Edad',
            dataIndex: 'edad',
            key: 'edad',
            fixed:false,
            ...getColumnSearchProps('comuna_nombre'),
            

        },
        
        
       
    ].filter(item => !item.hidden));
   

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            
           // setSelLine(selectedRows)
            setSelRowKeys(selectedRowKeys)
        },
        /*onSelect: (record, selected, selectedRows) => {
            console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            console.log(selected, selectedRows, changeRows);
        },*/
    };

    return (
        <div>
            <div style={{ marginBottom: 8 ,marginTop: 8}}>
                <Button type="primary" onClick={handlesave} disabled={!selRowKeys.length}>Guardar </Button>
                <span style={{ marginLeft: 8 }}>
                    {selRowKeys.length ? `${selRowKeys.length} items Seleccionados` : ''}
                </span>
            </div>  
            <Table rowSelection={{ ...rowSelection }} columns={Columsdata} dataSource={DataEmp} {...paramstable} rowKey="Id_empleado"/> 
        </div>
    )
}

export default TableSelempleado
