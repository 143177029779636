import gql from "graphql-tag";

export const PostulacionObsDet = gql`
query postulacionObsDet($id_postulacion_det:ID!){
  GetObsDet(id_postulacion_det:$id_postulacion_det){
   title
   Estado
   Observaciones 
    
  }
}`;
