import React, { useState, useRef} from 'react'
import { Table, Input, Button, Space,Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined,EditOutlined,UsergroupAddOutlined ,UserSwitchOutlined} from '@ant-design/icons';

const TableClientes = ({ data,  Deletecliente,  Editcliente,NuevoContacto}) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
   
    const paramstable = {
       bordered: true,
        pagination:false,
        size: 'small',
        tableLayout: 'auto',
        scroll: { x: 'max-content', y: '50vh'},
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button onClick={() => {handleReset(clearFilters); confirm({closeDropdown: false });}} size="small" style={{ width: 90 }}>
                        Borrar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput, 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
       
    };


    const [Columsdata] = useState([
        {
            title: 'Id',
            dataIndex: 'Id_cliente',
            key: 'Id_cliente',
            hidden: true,
            

        },
        {
            title: 'Rut',
            dataIndex: 'Rut',
            key: ' Rut',
            fixed:false,
            ...getColumnSearchProps('Rut'),

        },
        {
            title: 'Nombre',
            dataIndex: 'Nombre',
            key: 'Nombre',
            fixed:false,
            ...getColumnSearchProps('Nombre'),

        },
        {
            title: 'Direccion',
            dataIndex: 'Direccion_principal',
            key: 'Direccion_principal',
            fixed:false,
            ...getColumnSearchProps('Direccion_principal'),

        },
        {
            title: 'Giro',
            dataIndex: 'giro',
            key: 'giro',
            fixed:false,
            ...getColumnSearchProps('giro'),

        },
       
        {
            title: 'Estado',
            dataIndex: 'estadou',
            key: 'estadou',
            fixed:false,
            ...getColumnSearchProps('estadou'),

        },
        {
            title: 'Acción',
            dataIndex: 'accion',
            key: 'accion',
            fixed:false,
            render: (text, record) => {
               
                return (
                    <>
                        <Space size="middle">
                             <Tooltip title="Contactos" color={'blue'} key={'blue'}><UsergroupAddOutlined onClick={() => { onNewContact(record) }} /></Tooltip>
                             <Tooltip title="Editar" color={'blue'} key={'blue'}><EditOutlined onClick={() => { onEditcliente(record) }} /></Tooltip>
                             <Tooltip title="Estado" color={'blue'} key={'blue'}><UserSwitchOutlined  onClick={() => { onDeletecliente(record) }} /></Tooltip>

                        </Space>
                    </>

                )
            }
        }
    ].filter(item => !item.hidden));
   

    const onDeletecliente = (record) => {
        return Deletecliente(record);
    }

   const onNewContact = (record) => {
        return NuevoContacto(record);
    }

    const onEditcliente = (record) => {
        return Editcliente(record);
    }
   

    return (
          
        <Table columns={Columsdata} dataSource={data} {...paramstable} rowKey="Id_cliente"/> 
    )
}

export default TableClientes
