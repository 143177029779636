import gql from "graphql-tag";

export const PostulacionClientObs = gql`
query postulacionClientObs($id_postulacion_cab:ID!){
  GetClientObs(id_postulacion_cab:$id_postulacion_cab){
   title
   Estado
   Observaciones 
    
  }
}`;
