import React,{useState,useEffect} from 'react'

import '../Assets/Css/page.css';
import { Modal,Card, Button} from 'antd';
import IcoButton from '../Components/IconButtonGroup';
import IcoButtonList from '../Components/Postulaciones/IconButtonList';
import Buscar from '../Components/Loading';
import ModalClave from '../Components/ModalClave';
import ModalPostNew from '../Components/Postulaciones/ModalPostNew';
import {useUpdatepass} from '../hooks/custom-users';
import TablePostulacion from '../Components/Postulaciones/TablePostulacion';
//import ModalEditPost from '../Components/Postulaciones/ModalEditPost';
import ModalPostView from '../Components/Postulaciones/ModalPostView';
import ModalObsEstadoPost from '../Components/Postulaciones/ModalObsEstadoPost';
import ModalPostAdj from '../Components/Postulaciones/ModalPostAdj';
import ModalPostulantes from '../Components/Postulaciones/ModalPostulantes';
import ModalObsEstadoPostDet from '../Components/Postulaciones/ModalObsEstadoPostDet';
import ModalPostAdjDet from '../Components/Postulaciones/ModalPostAdjDet';
import ModalPostulanteView from '../Components/Postulaciones/ModalPostulanteView';

import {useAllPostulaciones,useCreatePostCliente,useUpdatePostCliente,useUpdatePostCargo,
        useUpdatePostPersona,useUpdatePostBeneficios,useUpdatePostOtros,useInsertObsPost,
        useInsertAdjPost,usePostulacionObs,usePostulacionAdj,usePostulacionObsDet,usePostulacionAdjDet, 
        useInsertPostDet, usePostulacionSelect,useInsertObsPostDet,useInsertAdjPostDet,
        usePostulanteDelete} from '../hooks/custom-Postulaciones';

import{useVerifyToken,useVerify} from "../hooks/useToken"          



const Requerimientos = () => {
    const {data:Tokensverify, loading:TokensverifyLoading}= useVerify();
    const{Tokens,TokensLoading} = useVerifyToken();
    const [SesionID, setSesionID] = useState("0");
    const {data,loading} = useAllPostulaciones(); 
    
    const [SetCreatePostCliente] = useCreatePostCliente();
     const [SetUpdatePostCliente] = useUpdatePostCliente();
    const [SetUpdatePostCargo] = useUpdatePostCargo();
    const [SetUpdatePostPersona] = useUpdatePostPersona();
    const [SetUpdatePostBeneficios] = useUpdatePostBeneficios();
    const [SetUpdatePostOtros] = useUpdatePostOtros();
    
    const [SetEditObsPost] = useInsertObsPost();
     const [SetEditObsPostDet] = useInsertObsPostDet();
    const [SetInsertAdjPost] = useInsertAdjPost();
    const [SetInsertAdjPostDet] = useInsertAdjPostDet();
    const [DataObs] = usePostulacionObs();
    const [DataAdj] = usePostulacionAdj();
   
    const [DataPostDet] = useInsertPostDet();
    const [DataPostDetSel] = usePostulacionSelect();
    const [DataObsDet] = usePostulacionObsDet();
    const [DataAdjDet] = usePostulacionAdjDet();
    const [PostDelete] =  usePostulanteDelete();
    const [Visible, setVisible] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [SetUpd] = useUpdatepass();
    const [Clientedata,setClientedata] = useState([]);
    const [NewCliente, setNewCliente] = useState(false);
    const [ViewCliente, setViewCliente] = useState(false);
    //const [EditPost, setEditPost] = useState(false);
    const [EditDataPost, setEditDataPost] = useState([]);
    const [EditEst, setEditEst] = useState(false);
    const [EditEstDet, setEditEstDet] = useState(false);
    const [EditAdj, setEditAdj] = useState(false);
    const [EditAdjDet, setEditAdjDet] = useState(false);
    const [dataEditAdj, setDataEditAdj] = useState([]);
    const [dataEditAdjDet, setDataEditAdjDet] = useState([]);
    const [dataEditEst, setDataEditEst] = useState([]);
    const [dataEditEstDet, setDataEditEstDet] = useState([]);
    const [dataObs, setDataObs] = useState([]);
    const [dataObsDet, setDataObsDet] = useState([]);
    const [dataAdj, setDataAdj] = useState([]);
    const [dataAdjDet, setDataAdjDet] = useState([]);
    const [postVisible, setPostVisible] = useState(false);
    const [dataSelClie, setDataSelClie] = useState([]);
    const [dataDetSelect, setDataDetSelect] = useState([]);
    const[DataEmp,SetdataEmp] = useState([]); 
    const[dataCardEmple,setDataCardEmple] = useState([]); 
    const[DataSelEmple,setDataSelEmple] = useState([]); 
    const[VerCardEmple,setVerCardEmple] = useState(false);
    const[datapostulacion,setDatapostulacion]=useState();
    const[datapostulacionName,setDatapostulacionName]=useState(false);
    const [dataSelpostulant, setDataSelpostulant] = useState();

  
    const [creadoPor, setCreadoPor] = useState('');

    

    useEffect(() => {
  
         if(TokensverifyLoading===false){
            
            if(Tokensverify.GetLogin_validate.success===false){
                Modal.error({
                    title: Tokensverify.GetLogin_validate.message,
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");
                    }
                })
            }
           
        }

        if(TokensLoading===false){
            if(Tokens){
                 const userF=Tokens.token.split('||')
                 setSesionID(userF[2])
                 setCreadoPor(userF[2])
            }
          
        }   

      
        if(loading===false){
            const datauser = data.GetAllPosts
            setClientedata(datauser)
        }

         
        
    }, [Tokens, TokensLoading, Tokensverify, TokensverifyLoading, data, loading])


    const datapostulant = async(id_postulacion_cab) => {
        const DataPostDetSelect = await DataPostDetSel({variables:{
            id_postulacion_cab:id_postulacion_cab
        }})
        setDataDetSelect(DataPostDetSelect.data.GetPostSel)
       
        
    }
   
   const [submenu] = useState([
        {
            key: '1',
            icon: 'KeyOutlined',
            title: 'Nueva Clave',

        }, {
            key: '2',
            icon: 'PoweroffOutlined',
            title: 'Salir',

        }
    ])

    const onGroupClick = (array) => {
       
        switch (array.key) {
            case '1':
                //console.log(array.key);
                setVisible(true);
               break
            default:
                Modal.confirm({
                    title: 'Estas Seguro de Cerrar la aplicación ..??',
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");

                        
                }
                })
        }
    }

    const onAdduser=()=>{
        setNewCliente(true);
    }

    const onEditPost = (record) => {
        setDatapostulacion(record.id_postulacion_cab)
        setViewCliente(true);
        setEditDataPost(record);
      
    }

    const onEditEst = async (record) => {

        //console.log(record);
        setEditEst(true);
        const ObsData = await DataObs({variables:{
            id_postulacion_cab:record.id_postulacion_cab
        }})
      //    console.log(ObsData );
        
        setDataObs(ObsData.data.GetObs)
        setDataEditEst(record);
    }

    const onEditPostAdj= async  (record) => {
        setEditAdj(true);
        const AdjData = await DataAdj({variables:{
            id_postulacion_cab:record.id_postulacion_cab
        }})
        setDataAdj(AdjData.data.GetAdj)
        setDataEditAdj(record);
      
    }

    const onEditEstDet = async (record) => {

       // console.log(record);
        setEditEstDet(true);
        const ObsDataDet = await  DataObsDet({variables:{
            id_postulacion_det:record.id_postulacion_det
        }})
       
        setDataObsDet(ObsDataDet.data.GetObsDet)
        setDataEditEstDet(record);
    }

     const onEditPostAdjDet= async  (record) => {
        setEditAdjDet(true);
        const AdjDataDet = await DataAdjDet({variables:{
            id_postulacion_det:record.id_postulacion_det
        }})
        setDataAdjDet(AdjDataDet.data.GetAdjDet)
        setDataEditAdjDet(record);
      
    }

    const onHandlePostCardDet = async (record) => {

       
        setVerCardEmple(true);
       
        setDataCardEmple(record)
        setDataSelEmple(record);
    }

    const onEditPostEmp = async  (record) => {
       
        setDataSelClie(record)
        setDataSelpostulant(record)
        const DataPostDetSelect = await DataPostDetSel({variables:{
            id_postulacion_cab:record.id_postulacion_cab
        }})
        setDataDetSelect(DataPostDetSelect.data.GetPostSel)
        setPostVisible(true);
    }

    const handleOk = async (values) => {

        
        const EditPass = await  SetUpd({variables:{
            id_sesion:creadoPor,
            oldPass: values.claveactual,
            NewPass: values.NuevaClave,
          
        }})
      //console.log(EditPass)
       // setLoading(true);
        const success = EditPass.data.updatePassword.success
        const message = EditPass.data.updatePassword.message
        if(success===true){   
           const  modal = Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
                setVisible(false);
                modal.destroy();
            }, 3000);

        }else{
            Modal.warning({
                 content: message,
            });
            setLoading(false);
              
           
        }  

    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleSave = async (values) => {
       
        //console.log(values)
        setLoading(true);
        const CreateResult = await  SetCreatePostCliente({variables:{
            Id_cliente: values.Codigo,
            Solicitante: values.Solicitante,
            creado_por: creadoPor
         }})
       
     
         const success = CreateResult.data.createPostCliente.success
         const message = CreateResult.data.createPostCliente.message
         setDatapostulacion(CreateResult.data.createPostCliente.id_postulacion_cab)
         setDatapostulacionName(CreateResult.data.createPostCliente.Cliente)
        
         if(success){   
            Modal.success({
                  content: message,
            });

            setTimeout(() => {
                setLoading(false);
                //setNewCliente(false);
                //modal.destroy();
            }, 1500);

         }else{
             Modal.warning({
                  content: message,
             }); 
             setLoading(false);

         }
   
    };

    const handleUpdateClient = async (values) => {
       
        //console.log(values)
        setLoading(true);
        const CreateResult = await  SetUpdatePostCliente({variables:{
            id_postulacion_cab:datapostulacion,
            Solicitante: values.Solicitante,
           
         }})
       
     
         const success = CreateResult.data.UpdatePostCliente.success
         const message = CreateResult.data.UpdatePostCliente.message
         setEditDataPost(CreateResult.data.UpdatePostCliente);
         
         if(success){   
            Modal.success({
                  content: message,
            });

            setTimeout(() => {
                setLoading(false);
                //setNewCliente(false);
                //modal.destroy();
            }, 1500);

         }else{
             Modal.warning({
                  content: message,
             }); 
             setLoading(false);

         }
   
    };

    const handleCargoSave = async (values) => {
         //console.log(values)
        setLoading(true);
        if(datapostulacion){
            const UpdateResult = await  SetUpdatePostCargo({variables:{
                id_postulacion_cab:datapostulacion,
                Cargo: values.Cargo,
                Nro_vacantes: values.vacantes,
                Horario_empleo: values.HorarioLaboral,
                Tipo_contrato: values.TipoContrato,
                Meses_plazo_fijo: values.MesesPfijo,
                Experiencia: values.Experiencia,
                DescripcionCargo: values.DescripcionCargo
            }})
       
     
            const success = UpdateResult.data.UpdatePostCargo.success
            const message = UpdateResult.data.UpdatePostCargo.message
            setEditDataPost(UpdateResult.data.UpdatePostCargo);


            if(success){   
                Modal.success({
                        content: message,
                });

                setTimeout(() => {
                    setLoading(false);
                    //setNewCliente(false);
                    //modal.destroy();
                }, 1500);

            }else{
                Modal.warning({
                    content: message,
                }); 
                setLoading(false);

            }
        }else{
            Modal.warning({
                content: "Debe agregar Cliente Primero...",
            }); 
            setLoading(false);

        } 

    };

    const handlePersonaSave = async (values) => {

        setLoading(true);
        if(datapostulacion){  
            const UpdateResult = await  SetUpdatePostPersona({variables:{
                id_postulacion_cab: datapostulacion,
                Nivel_cursado: values.NivelCursado,
                Genero: values.Genero,
                Rest_nacionalidad: values.RestNacionalidad,
                Rango_edad: values.RangoEdad,
                Rest_comuna: values.RestComuna,
                Persona_discapacidad: values.Discapacidad,
                Obs_personas: values.DescripcionPersona,
            }})
       
     
            const success = UpdateResult.data.UpdatePostPersona.success
            const message = UpdateResult.data.UpdatePostPersona.message
            setEditDataPost(UpdateResult.data.UpdatePostPersona);


            if(success){   
            Modal.success({
                    content: message,
            });

            setTimeout(() => {
                setLoading(false);
                //setNewCliente(false);
                //modal.destroy();
            }, 1500);

            }else{
                Modal.warning({
                    content: message,
                }); 
                setLoading(false);

            }
        }else{
            Modal.warning({
                content: "Debe agregar Cliente Primero...",
            }); 
            setLoading(false);

        }  

    };

    const handleOtrosSave = async (values) => {
        
        setLoading(true);
        if(datapostulacion){  
            const UpdateResult = await  SetUpdatePostOtros({variables:{
                id_postulacion_cab: datapostulacion,
                Ropa: values.Ropa,
                EPP: values.EPP,
                Examenes:values.Examenes,
                Renovacion: values.Renovacion,
                Contrato_planta: values.ContratoPlanta,
                Licencia_conducir: values.ClaseLicConducir,
                Obs_otros: values.DescripcionOtros,
            }})
        
        
            const success = UpdateResult.data.UpdatePostOtros.success
            const message = UpdateResult.data.UpdatePostOtros.message
            setEditDataPost(UpdateResult.data.UpdatePostOtros);


            if(success){   
            Modal.success({
                    content: message,
            });

            setTimeout(() => {
                setLoading(false);
                //setNewCliente(false);
                //modal.destroy();
            }, 1500);

            }else{
                Modal.warning({
                    content: message,
                }); 
                setLoading(false);

            }
        }else{
            Modal.warning({
                content: "Debe agregar Cliente Primero...",
            }); 
            setLoading(false);

        }     
    };

    const handleBeneficiosSave = async (values) => {
          
        setLoading(true);
        if(datapostulacion){  
            const UpdateResult = await  SetUpdatePostBeneficios({variables:{
                id_postulacion_cab: datapostulacion,
                Sueldo_liquido: values.SueldoLiquido,
                Sueldo_base: values.SueldoBase,
                Bono: values.Bono,
                Hora_extra: values.HoraExtra,
                Monto_colacion: values.Colacion,
                Monto_movilizacion: values.Movilizacion,
                Obs_beneficios: values.DescripcionBeneficios,
            }})
       
     
            const success = UpdateResult.data.UpdatePostBeneficios.success
            const message = UpdateResult.data.UpdatePostBeneficios.message
            setEditDataPost(UpdateResult.data.UpdatePostBeneficios);



            if(success){   
            Modal.success({
                    content: message,
            });

            setTimeout(() => {
                setLoading(false);
                //setNewCliente(false);
                //modal.destroy();
            }, 1500);

            }else{
                Modal.warning({
                    content: message,
                }); 
                setLoading(false);

            }
        }else{
            Modal.warning({
                content: "Debe agregar Cliente Primero...",
            }); 
            setLoading(false);

        }     

    };
    
    const handleCancelUse = () => { 
        
        setDatapostulacion()
        setDatapostulacionName('Debe Agregar Cliente Primero...')
        setNewCliente(false);
        
    };

    const handleSelPost = async (values) => {
       
      //  console.log(dataSelClie)
      //  console.log(values)
        //setLoading(true);

        const Result = await  DataPostDet({variables:{
            Id_empleado: values,
            id_postulacion_cab: dataSelClie.id_postulacion_cab,
            creado_por: creadoPor, 
        }})
       
       
        const success = Result.data.createPostDet.success
        const message = Result.data.createPostDet.message
        if(success){   
            const  modal = Modal.success({
                content: message,
            });

            const DataPostDetSelect = await DataPostDetSel({variables:{
                id_postulacion_cab:dataSelClie.id_postulacion_cab
            }})
            setDataDetSelect(DataPostDetSelect.data.GetPostSel)
            
            setTimeout(() => {
               // setLoading(false);
               // setEditPost(false);
                modal.destroy();
            }, 3000);

        }else{
            Modal.warning({
                content: message,
            });
           // setLoading(false);
        }  
    };


    const handleRefreshsel = async (values) => {
         const DataPostDetSelect = await DataPostDetSel({variables:{
                id_postulacion_cab:values
            }})
            setDataDetSelect(DataPostDetSelect.data.GetPostSel)
    }

    const handleSaveEst = async (values) => {
         
        //console.log(values)
        setLoading(true);
        const EditObs = await  SetEditObsPost({variables:{
            id_postulacion_cab: dataEditEst.id_postulacion_cab,
            Estado_id:values.Estado,
            Observaciones: values.Observaciones,
            creado_por: creadoPor,
      
        }})
       
         
            const success = EditObs.data.EditObsPost.success
            const message = EditObs.data.EditObsPost.message
            if(success){ 
                const ObsData = await DataObs({variables:{
                    id_postulacion_cab:dataEditEst.id_postulacion_cab
                }})
            
                setDataObs(ObsData.data.GetObs)
              
                
                const  modal = Modal.success({
                   content: message,
                });

                setTimeout(() => {
                  setLoading(false);
                 // setEditEst(false);
                  modal.destroy();
                }, 1500);

            }else{
              Modal.warning({
                   content: message,
              });
              setLoading(false);  
            }  
    };

    const handleSaveEstDet = async (values) => {
         
       
        setLoading(true);
        const EditObsDet = await  SetEditObsPostDet({variables:{
            id_postulacion_det: dataEditEstDet.id_postulacion_det,
            Estado_id:values.EstadoPostDet,
            Observaciones: values.ObservacionesDet,
            creado_por: creadoPor,
      
        }})
       
         
            const success = EditObsDet.data.EditObsPostDet.success
            const message = EditObsDet.data.EditObsPostDet.message
            if(success){   

           
                const ObsDataDet = await  DataObsDet({variables:{
                    id_postulacion_det:dataEditEstDet.id_postulacion_det
                }})
            
                setDataObsDet(ObsDataDet.data.GetObsDet)
                datapostulant(dataSelpostulant.id_postulacion_cab);
                

              
                
                const  modal = Modal.success({
                   content: message,
                });

                setTimeout(() => {
                  setLoading(false);
                  //setEditEstDet(false);
                  modal.destroy();
                }, 1500);

               

            }else{
              Modal.warning({
                   content: message,
              });
              setLoading(false);  
            }  
    };

    const handleSaveAdj = async (values,Arrfilename) => {
     
        setLoading(true);
        const valor = Arrfilename.map((item) => item.filename);

        const EditAdj = await SetInsertAdjPost({variables:{
            id_postulacion_cab: dataEditAdj.id_postulacion_cab,
            Nombre:values.Nombre,
            Descripcion: values.Descripcion,
            adjunto:  valor,
            creado_por: creadoPor,
      
        }})
       
         
            const success = EditAdj.data.EditObsAdj.success
            const message = EditAdj.data.EditObsAdj.message
            if(success){   
                
                const AdjData = await DataAdj({variables:{
                    id_postulacion_cab: dataEditAdj.id_postulacion_cab
                }})
                setDataAdj(AdjData.data.GetAdj)
                
                setTimeout(() => {
                    Modal.success({
                        content: message,
                    });
                    setLoading(false);
                   
                  
                }, 3000);

            }
            else{
                Modal.warning({
                    content: message,
                });
                setLoading(false);
            }   
    };

    const handleSaveAdjDet = async (values,Arrfilename) => {
     //console.log(values,Arrfilename)
        setLoading(true);
        const valor = Arrfilename.map((item) => item.filename);

        const EditAdjDet = await SetInsertAdjPostDet({variables:{
            id_postulacion_det: dataEditAdjDet.id_postulacion_det,
            Nombre:values.Nombre,
            Descripcion: values.Descripcion,
            adjunto:  valor,
            creado_por: creadoPor,
      
        }})
       
         
            const success = EditAdjDet.data.EditObsAdjDet.success
            const message = EditAdjDet.data.EditObsAdjDet.message
            if(success){   
                

                const AdjDataDet = await DataAdjDet({variables:{
                    id_postulacion_det:dataEditAdjDet.id_postulacion_det
                }})
                setDataAdjDet(AdjDataDet.data.GetAdjDet)

                
                setTimeout(() => {
                    const  modal = Modal.success({
                         content: message,
                    });
                
                    setLoading(false);
                    //setEditEst(false);
                    modal.destroy();
                }, 3000);

            }
            else{
                Modal.warning({
                    content: message,
                });
                setLoading(false);
            }  
    };

    const handleCancelView = () => {
        setViewCliente(false);
    };

    const handleCancelPostEmp = () => {
        setPostVisible(false);
    };

    const handleCancelEst = (value) => {
        setEditEst(false);
    };

    const handleCancelEstDet = () => {
        setEditEstDet(false);
    };

    const handleCancelAdj = () => {
        setEditAdj(false);
    };

     const handleCancelAdjDet = () => {
        setEditAdjDet(false);
    };

    const handleCancelCardEmple = () => {
        setVerCardEmple(false);
    };

    const onDeletePost=  (record)=> {
        console.log(record.id_postulacion_det)
        
       

        Modal.confirm({
           title: 'Estas Seguro de eliminar el postulante  ' +record.Nombre+ ' ..??',
            
           onOk:async()=>{
            
            const DelResult = await  PostDelete({variables:{
                Id_postulacion_det:record.id_postulacion_det,
            }})
        
           
            const success = DelResult.data.DeletePostDet.success
            const message = DelResult.data.DeletePostDet.message
           // console.log(success)
            if(success){   
            const  modal = Modal.success({
                    content: message,
                });

                const DataPostDetSelect = await DataPostDetSel({variables:{
                    id_postulacion_cab:record.id_postulacion_cab
                }})
                setDataDetSelect(DataPostDetSelect.data.GetPostSel)

                setTimeout(() => {
                    modal.destroy();
                }, 1500);

            }else{
                Modal.warning({
                    content: message,
                });
               
                
            
            } 
        }  
       })
       
    }

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;

    };

    if(loading){
        return (
        <div className="site-card-border-less-wrapper">
           
          <Buscar/>
         
        </div>
        )
    }

    
    return (
        <div className="site-card-border-less-wrapper">
        
            <Card size="small" style={{ textAlign: 'Left' }}  title={<b>Requerimientos</b>}  className='card_height'  extra={[ <IcoButtonList key="1" icon='BellOutlined' SesionID={SesionID}    />,<IcoButton key="2" icon='SettingOutlined' onChildClick={(array) => onGroupClick(array)} submenu={submenu}  />]}   >
                <Card size="small" bordered={false} style={{  display: 'flex' }}>
                    <Button onClick={onAdduser} icon={<Icon type={'FileDoneOutlined'} />} >Nuevo Requerimiento</Button>
                </Card>
                <TablePostulacion data={Clientedata}  EditPost={onEditPost} EditEst={onEditEst} EditPostAdj={onEditPostAdj} EditPostEmp={onEditPostEmp}  />
            </Card>
            <ModalClave Visible={Visible} Loading={Loading} handleOk={handleOk} handleCancel={handleCancel }/>
            <ModalPostNew NewCliente={NewCliente} datapostulacionName={datapostulacionName} Loading={Loading} handleSave={handleSave} handleCargoSave={handleCargoSave} handlePersonaSave={handlePersonaSave} handleBeneficiosSave={handleBeneficiosSave} handleOtrosSave={handleOtrosSave}  handleCancelUse={handleCancelUse} />
            
            <ModalPostView ViewCliente={ViewCliente} EditDataPost={EditDataPost} Loading={Loading} handleUpdateClient={handleUpdateClient} handleCargoSave={handleCargoSave} handlePersonaSave={handlePersonaSave} handleBeneficiosSave={handleBeneficiosSave} handleOtrosSave={handleOtrosSave}  handleCancelView={handleCancelView}/>
            <ModalObsEstadoPost EditEst={EditEst} Loading={Loading} handleSaveEst={handleSaveEst} handleCancelEst={handleCancelEst} dataEditEst={dataEditEst} dataObs = {dataObs} />
            <ModalPostAdj EditAdj={EditAdj} Loading={Loading} handleSaveAdj={handleSaveAdj} handleCancelAdj={handleCancelAdj} dataEditAdj={dataEditAdj} dataAdj ={dataAdj} />
            <ModalPostulantes  PostVisible={postVisible}  handleRefreshsel={handleRefreshsel} setDataDetSelect={setDataDetSelect} dataSelClie={dataSelClie} handleCancelPostEmp={handleCancelPostEmp} SetdataEmp ={SetdataEmp} DataEmp={DataEmp} handleSelPost={handleSelPost}  dataDetSelect={dataDetSelect}  HandleEditEstDet={onEditEstDet} HandleEditPostAdjDet={onEditPostAdjDet} handleDeletepost={onDeletePost} HandlePostCardDet={onHandlePostCardDet}  creadoPor={creadoPor}/>
            <ModalObsEstadoPostDet EditEstDet={EditEstDet} Loading={Loading} handleSaveEstDet={handleSaveEstDet} handleCancelEstDet={handleCancelEstDet} dataEditEstDet={dataEditEstDet} dataObsDet = {dataObsDet} />
            <ModalPostAdjDet EditAdjDet={EditAdjDet} Loading={Loading} handleSaveAdjDet={handleSaveAdjDet} handleCancelAdjDet={handleCancelAdjDet} dataEditAdjDet={dataEditAdjDet} dataAdjDet ={dataAdjDet} />
            <ModalPostulanteView  viewdataPost ={VerCardEmple} handleEditPost ={DataSelEmple} handleCancelPostView ={handleCancelCardEmple}  DataPostulant={dataCardEmple}/>
        </div>

    );
}

export default Requerimientos