
import {useLazyQuery,useMutation,useQuery} from '@apollo/client'
import {GetRegistroRelojControl, GetReportHoraExtra,GetlistaTurnos,
        AllContratadoAct,GetContratadoByIdCliente,GetCalEmpleTurnos_ByFilter,
        GetContratados_ByFilter,GetEmpleLicencias_ByFilter,GetEmpleVacaciones_ByFilter,
        GetAusenciasObs,GetVacacionesObs,GetAusenciasAdj,GetVacacionesAdj,
        GetReportHoraExtraResumen, GetContratadoBancById,GetVacacionesPendById} from "../model/query/contratos/contratos"

import {UploadRelojControl,Nuevo_TurnoEmpleado,Nuevo_EmpleadoLaboral,
        Nuevo_EmpleadoContrato,Nuevo_EmpleadoBanco,
        Elimina_RelojControl,Elimina_TurnoEmpleado,Nueva_LicenciaEmpleado,
        Elimina_LicenciaEmpleado,Nueva_VacacionesEmpleado,Elimina_VacacionesEmpleado,
        Aprobar_VacacionesEmpleado,Rechazar_VacacionesEmpleado,Aprobar_LicenciaEmpleado,
        Rechazar_LicenciaEmpleado,Estado_LicenciaEmpleado,Crear_AusenciasAdj,
        Crear_VacacionesAdj,Estado_VacacionesEmpleado,Nuevo_EmpleadoVacaciones
         } from "../model/mutation/contratos/contratos"


/////////////////////////Querys//////////////////////////////////
export const useRelojControlByFilter = () =>{
    const result = useLazyQuery(GetRegistroRelojControl);
    return result;
}

export const useReportHoraExtra = () =>{
    const result = useLazyQuery(GetReportHoraExtra);
    return result;
}

export const useReportHoraExtraResumen = () =>{
    const result = useLazyQuery(GetReportHoraExtraResumen);
    return result;
}

export const useListaTurnos = () =>{
    const result = useLazyQuery(GetlistaTurnos);
    return result;
}

export const useListaLicencias = () =>{
    const result = useLazyQuery(GetEmpleLicencias_ByFilter);
    return result;
}

export const useListaVacaciones = () =>{
    const result = useLazyQuery(GetEmpleVacaciones_ByFilter);
    return result;
}

export const useAllContratadoAct = () =>{
    const result = useQuery(AllContratadoAct);
    return result;
}

export const useContratadoByIdCliente = () =>{
    const result = useLazyQuery(GetContratadoByIdCliente);
    return result;
}

export const useCalEmpleTurnos_ByFilter = () =>{
    const result = useLazyQuery(GetCalEmpleTurnos_ByFilter);
    return result;
}

export const useContratados_ByFilter = () =>{
    const result = useLazyQuery(GetContratados_ByFilter);
    return result;
}

export const useContratadoBancById = () =>{
    const result = useLazyQuery(GetContratadoBancById);
    return result;
}

export const useObsAusencia = () =>{
    const result = useLazyQuery(GetAusenciasObs);
    return result;
}

export const useObsVacaciones = () =>{
    const result = useLazyQuery(GetVacacionesObs);
    return result;
}

export const useVacacionesPendById = () =>{
    const result = useLazyQuery(GetVacacionesPendById);
    return result;
}

export const useAdjVacaciones = () =>{
    const result = useLazyQuery(GetVacacionesAdj);
    return result;
}

export const useAdjAusencia = () =>{
    const result = useLazyQuery(GetAusenciasAdj);
    return result;
}

/////////////////////useMutation ////////////////////////
/////////////////////Create ////////////////////////
export const useUploadRelojControl = () =>{
    const result = useMutation(UploadRelojControl);
    return result;
}

export const useNuevoTurnoEmpleado = () =>{
    const result = useMutation(Nuevo_TurnoEmpleado);
    return result;
}

export const useNuevaLicenciaEmpleado = () =>{
    const result = useMutation(Nueva_LicenciaEmpleado);
    return result;
}

export const useNuevaVacacionesEmpleado = () =>{
    const result = useMutation(Nueva_VacacionesEmpleado);
    return result;
}

export const useNuevo_EmpleadoLaboral = () =>{
    const result = useMutation(Nuevo_EmpleadoLaboral);
    return result;
}

export const useNuevo_EmpleadoContrato = () =>{
    const result = useMutation(Nuevo_EmpleadoContrato);
    return result;
}

export const useNuevo_EmpleadoBanco = () =>{
    const result = useMutation(Nuevo_EmpleadoBanco);
    return result;
}

export const useNuevo_EmpleadoVacaciones = () =>{
    const result = useMutation(Nuevo_EmpleadoVacaciones);
    return result;
}
/////////////////////Delete ////////////////////////

export const useDeleteRelojControl = () =>{
    const result = useMutation(Elimina_RelojControl);
    return result;
}

export const useEliminaTurnoEmpleado = () =>{
 //console.log(props[0].Id_cliente)
    const result = useMutation(Elimina_TurnoEmpleado);
    return result;
}

export const useEliminaLicenciaEmpleado = () =>{
 //console.log(props[0].Id_cliente)
    const result = useMutation(Elimina_LicenciaEmpleado);
    return result;
}

export const useEliminaVacacionesEmpleado = () =>{
 //console.log(props[0].Id_cliente)
    const result = useMutation(Elimina_VacacionesEmpleado);
    return result;
}

/////////////////////Update ////////////////////////

export const useAprobarVacacionesEmpleado = () =>{
    const result = useMutation(Aprobar_VacacionesEmpleado);
    return result;
}

export const useRechazarVacacionesEmpleado = () =>{
    const result = useMutation(Rechazar_VacacionesEmpleado);
    return result;
}

export const useAprobarLicenciaEmpleado = () =>{
    const result = useMutation(Aprobar_LicenciaEmpleado);
    return result;
}

export const useRechazarLicenciaEmpleado = () =>{
    const result = useMutation(Rechazar_LicenciaEmpleado);
    return result;
}

export const useEstadoVacacionesEmpleado = () =>{
    const result = useMutation(Estado_VacacionesEmpleado);
    return result;
}

export const useEstadoLicenciaEmpleado = () =>{
    const result = useMutation(Estado_LicenciaEmpleado);
    return result;
}

export const useCrearAusenciasAdj = () =>{
    const result = useMutation(Crear_AusenciasAdj);
    return result;
}

export const useCrearVacacionesAdj = () =>{
    const result = useMutation(Crear_VacacionesAdj);
    return result;
}

export default useRelojControlByFilter
