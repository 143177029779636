import React, {useEffect}  from 'react'
import { Modal, Button, Form, Input, Space, Row, Col ,DatePicker,Typography} from 'antd';
import '../../Assets/Css/page.css';
import moment from 'moment'
const { Title } = Typography;

const ModalEditCert = ({ viewEditCert, Loading, handleSavePostCert, handleCancelCert, EditDataCert}) => {
    const [formEditCert] = Form.useForm();
    const dateFormat = 'DD/MM/YYYY'
   
    useEffect(() => {
        
        if (ModalEditCert) {
            formEditCert.setFieldsValue({
                EmpresaEmisora: EditDataCert?.Empresa_emisora,
                NombreCertificado: EditDataCert?.nombre_cert_licencia,
                FechaCaducidad:  moment(EditDataCert?.fecha_de_caducidad,'YYYY/MM/DD'),
                IdCredLicencial: EditDataCert?.id_credencial_licencia,
                URLCredencial: EditDataCert?.URL_credencial,
              
            });
        }
       
    }, [EditDataCert, formEditCert]);
    
    const onAdd = async values => {
        return handleSavePostCert(values);   
    }

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;

    };

    return (
        <Modal
            visible={viewEditCert}
            forceRender
            title="Editar Certificado"
            onCancel={handleCancelCert}
            footer={[
                
               
            ]}
        >
             <Form
                form={formEditCert}
                name="NuevoPostulante"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                onFinish={onAdd}
            >
                
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Title level={5}>{"Seleccione"}</Title>
                </Col>
                </Row>
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="EmpresaEmisora"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Empresa Emisora',
                        },
                    ]}
                    hasFeedback
                >
                   <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Empresa Emisora"
                    />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="NombreCertificado"
                    tooltip="Direccion Empresa"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese Nombre Certificado licencia'   
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Nombre Certificado"
                    />
                </Form.Item>
                </Col>

                </Row>
                
                
                 <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="FechaCaducidad"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Fecha Caducidad',
                        },
                    ]}
                    hasFeedback
                >
                    <DatePicker format={dateFormat} style={{ width: '100%' }}  placeholder="Fecha Caducidad"/>
                </Form.Item>
                </Col>
                 <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="IdCredLicencial"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese id Licencia/certificado',
                        },
                    ]}
                    hasFeedback
                >
                   <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="id Licencia/certificado"
                    />
                </Form.Item>
                </Col>

                </Row>
                
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="URLCredencial"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese URL Credencial',
                        },
                    ]}
                    hasFeedback
                >
                   <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="URL Credencial"
                    />
                </Form.Item>
                </Col>
                

                </Row>
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                       Editar
                    </Button>
                    <Button key="back" onClick={handleCancelCert}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ModalEditCert
