import gql from "graphql-tag";

//////////////////INSERT//////////////////////

export const createClientPostClienteCargo = gql`

mutation createClientPostClienteCargo($Id_cliente:ID!,$Cliente:String!,$Solicitante:ID!,$creado_por:String!,
                                 $Cargo: ID!,$Nro_vacantes: ID!,$Horario_empleo: ID!, $Tipo_contrato: ID!, 
                                $Meses_plazo_fijo: ID, $Experiencia: ID!,$DescripcionCargo: String){
 

    createClientPostClienteCargo(
      Id_cliente: $Id_cliente,
      Cliente: $Cliente,
      Solicitante: $Solicitante,
      creado_por:$creado_por,
      Cargo: $Cargo,
      Nro_vacantes: $Nro_vacantes,
      Horario_empleo: $Horario_empleo,
      Tipo_contrato: $Tipo_contrato,
      Meses_plazo_fijo: $Meses_plazo_fijo,
      Experiencia: $Experiencia,
      DescripcionCargo: $DescripcionCargo,
      
    )
      {
      success
      message
      id_postulacion_cab
      }
    

}`;

export const InsertClientObsPost = gql`

mutation InsertClientObsPost($id_postulacion_cab:ID!,$Estado_id:String!,
                        $Observaciones:String,$creado_por:String!,
                    ){
 
      EditClientObsPost(id_postulacion_cab: $id_postulacion_cab,
      Estado_id:$Estado_id,
      Observaciones: $Observaciones,
      creado_por:$creado_por) 
      {
        success
        message
      }
}`;

export const InsertClientObsPostDet = gql`

mutation InsertClientObsPostDet($id_postulacion_det:ID!,$Estado_id:String!,
                        $Observaciones:String,$creado_por:String!,
                    ){
 
      EditClientObsPostDet(id_postulacion_det: $id_postulacion_det,
      Estado_id:$Estado_id,
      Observaciones: $Observaciones,
      creado_por:$creado_por) 
      {
        success
        message
      }
}`;

export const InsertClientAdjPost = gql`

mutation InsertClientAdjPost($id_postulacion_cab:ID!,$Nombre:String!,
                        $Descripcion:String,$adjunto:[String]!,$creado_por:String!,
                    ){
 
      EditClientObsAdj(id_postulacion_cab: $id_postulacion_cab,
      Nombre: $Nombre
      Descripcion: $Descripcion
      adjunto :$adjunto
      creado_por: $creado_por) 
      {
        success
        message
      }
}`;

export const InsertClientAdjPostDet = gql`

mutation InsertClientAdjPostDet($id_postulacion_det:ID!,$Nombre:String!,
                        $Descripcion:String,$adjunto:[String]!,$creado_por:String!,
                    ){
 
      EditClientObsAdjDet(id_postulacion_det: $id_postulacion_det,
      Nombre: $Nombre
      Descripcion: $Descripcion
      adjunto :$adjunto
      creado_por: $creado_por) 
      {
        success
        message
      }
}`;

export const InsertClientPostDet = gql`
mutation InsertClientPostDet($keyunique:[String]!,$id_postulacion_cab:ID!,
                         $creado_por:ID!,
                    ){
 
  createClientPostDet(keyunique:$keyunique,
                id_postulacion_cab:$id_postulacion_cab,
                creado_por:$creado_por)
  {
    success
    message
  } 
}`;


//////////////////UPDATE//////////////////////
export const UpdateClientPostCliente = gql`
mutation UpdateClientPostCliente($id_postulacion_cab:ID!,$Solicitante:ID!){
 
        UpdateClientPostCliente(id_postulacion_cab: $id_postulacion_cab ,Solicitante: $Solicitante) 
        {
          success
          message
          id_postulacion_cab
          Id_cliente
          Cliente
          Solicitante
          NombreSolicitante
          Cargo
          DCargo
          Nro_vacante
          Nro_vacantes
          Horario_empleo
          DHorario_empleo
          Tipo_contrato
          DTipo_contrato
          Meses_plazo_fijo
          Sueldo_liquido
          Sueldo_base
          Bono
          DescripcionCargo
          Nivel_cursado
          DNivel_cursado
          Experiencia
          DExperiencia
          Genero
          Rest_nacionalidad
          Drest_nacionalidad
          Rango_edad
          DRango_edad
          Rest_comuna
          Drest_comuna
          Persona_discapacidad
          Dpersona_discapacitada
          Licencia_conducir
          Obs_personas
          Monto_colacion
          Monto_movilizacion
          Ropa
          dropa
          EPP
          dEPP
          Hora_extra
          Dhextra
          Obs_beneficios
          Examenes
          Renovacion
          Drenovacion
          Contrato_planta
          Dcontrato_planta
          Obs_otros
          Estado_id
          Estado
          Fecha_creacion
          Creado_por

        }
}`;

export const UpdateClientPostCargo = gql`
mutation UpdateClientPostCargo($id_postulacion_cab: ID!,$Cargo: ID!,$Nro_vacantes: ID!,$Horario_empleo: ID!,
                          $Tipo_contrato: ID!, $Meses_plazo_fijo: ID, $Experiencia: ID!,$DescripcionCargo: String){
 
        UpdateClientPostCargo(
                          id_postulacion_cab: $id_postulacion_cab,
                          Cargo: $Cargo,
                          Nro_vacantes: $Nro_vacantes,
                          Horario_empleo: $Horario_empleo,
                          Tipo_contrato: $Tipo_contrato,
                          Meses_plazo_fijo: $Meses_plazo_fijo,
                          Experiencia: $Experiencia,
                          DescripcionCargo: $DescripcionCargo) 
        {
          success
          message
          id_postulacion_cab
          Id_cliente
          Cliente
          Solicitante
          NombreSolicitante
          Cargo
          DCargo
          Nro_vacante
          Nro_vacantes
          Horario_empleo
          DHorario_empleo
          Tipo_contrato
          DTipo_contrato
          Meses_plazo_fijo
          Sueldo_liquido
          Sueldo_base
          Bono
          DescripcionCargo
          Nivel_cursado
          DNivel_cursado
          Experiencia
          DExperiencia
          Genero
          Rest_nacionalidad
          Drest_nacionalidad
          Rango_edad
          DRango_edad
          Rest_comuna
          Drest_comuna
          Persona_discapacidad
          Dpersona_discapacitada
          Licencia_conducir
          Obs_personas
          Monto_colacion
          Monto_movilizacion
          Ropa
          dropa
          EPP
          dEPP
          Hora_extra
          Dhextra
          Obs_beneficios
          Examenes
          Renovacion
          Drenovacion
          Contrato_planta
          Dcontrato_planta
          Obs_otros
          Estado_id
          Estado
          Fecha_creacion
          Creado_por
        }
}`;

export const UpdateClientPostPersona = gql`
mutation UpdateClientPostPersona($id_postulacion_cab: ID!,$Nivel_cursado: ID!,
                           $Genero: [String]!,$Rest_nacionalidad: ID!,$Rango_edad:ID!,
                            $Rest_comuna: ID!,$Persona_discapacidad: ID!,
                            $Obs_personas: String){
 
        UpdateClientPostPersona(
            id_postulacion_cab: $id_postulacion_cab,
            Nivel_cursado: $Nivel_cursado,
            Genero: $Genero,
            Rest_nacionalidad: $Rest_nacionalidad,
            Rango_edad: $Rango_edad,
            Rest_comuna: $Rest_comuna,
            Persona_discapacidad: $Persona_discapacidad,
            Obs_personas: $Obs_personas,
        ) 
        {
          success
          message
          id_postulacion_cab
          Id_cliente
          Cliente
          Solicitante
          NombreSolicitante
          Cargo
          DCargo
          Nro_vacante
          Nro_vacantes
          Horario_empleo
          DHorario_empleo
          Tipo_contrato
          DTipo_contrato
          Meses_plazo_fijo
          Sueldo_liquido
          Sueldo_base
          Bono
          DescripcionCargo
          Nivel_cursado
          DNivel_cursado
          Experiencia
          DExperiencia
          Genero
          Rest_nacionalidad
          Drest_nacionalidad
          Rango_edad
          DRango_edad
          Rest_comuna
          Drest_comuna
          Persona_discapacidad
          Dpersona_discapacitada
          Licencia_conducir
          Obs_personas
          Monto_colacion
          Monto_movilizacion
          Ropa
          dropa
          EPP
          dEPP
          Hora_extra
          Dhextra
          Obs_beneficios
          Examenes
          Renovacion
          Drenovacion
          Contrato_planta
          Dcontrato_planta
          Obs_otros
          Estado_id
          Estado
          Fecha_creacion
          Creado_por
        }
}`;

export const UpdateClientPostBeneficios = gql`
mutation UpdateClientPostBeneficios($id_postulacion_cab: ID!,$Sueldo_liquido: ID,$Sueldo_base:ID,
                                    $Bono: ID, $Monto_colacion: ID!, $Monto_movilizacion: ID!,
                                    $Hora_extra: ID!,$Obs_beneficios: String
                                   ){
 
        UpdateClientPostBeneficios(
            id_postulacion_cab: $id_postulacion_cab,
            Sueldo_liquido: $Sueldo_liquido,
            Sueldo_base: $Sueldo_base,
            Bono: $Bono,
            Monto_colacion: $Monto_colacion,
            Monto_movilizacion: $Monto_movilizacion,
            Hora_extra: $Hora_extra,
            Obs_beneficios:$Obs_beneficios,
        ) 
        {
          success
          message
          id_postulacion_cab
          Id_cliente
          Cliente
          Solicitante
          NombreSolicitante
          Cargo
          DCargo
          Nro_vacante
          Nro_vacantes
          Horario_empleo
          DHorario_empleo
          Tipo_contrato
          DTipo_contrato
          Meses_plazo_fijo
          Sueldo_liquido
          Sueldo_base
          Bono
          DescripcionCargo
          Nivel_cursado
          DNivel_cursado
          Experiencia
          DExperiencia
          Genero
          Rest_nacionalidad
          Drest_nacionalidad
          Rango_edad
          DRango_edad
          Rest_comuna
          Drest_comuna
          Persona_discapacidad
          Dpersona_discapacitada
          Licencia_conducir
          Obs_personas
          Monto_colacion
          Monto_movilizacion
          Ropa
          dropa
          EPP
          dEPP
          Hora_extra
          Dhextra
          Obs_beneficios
          Examenes
          Renovacion
          Drenovacion
          Contrato_planta
          Dcontrato_planta
          Obs_otros
          Estado_id
          Estado
          Fecha_creacion
          Creado_por
        }
}`;

export const UpdateClientPostOtros = gql`
mutation UpdateClientPostOtros($id_postulacion_cab:ID!, $Ropa: ID!,$EPP: ID!,$Examenes: [String],$Renovacion: ID!,
                        $Contrato_planta: ID!,$Licencia_conducir:[String],$Obs_otros:String
                          ){
 
        UpdateClientPostOtros(
            id_postulacion_cab: $id_postulacion_cab,
            Ropa: $Ropa,
            EPP: $EPP,
            Examenes: $Examenes,
            Renovacion: $Renovacion,
            Contrato_planta: $Contrato_planta,
            Licencia_conducir: $Licencia_conducir,
            Obs_otros: $Obs_otros,
        ) 
        {
          success
          message
          id_postulacion_cab
          Id_cliente
          Cliente
          Solicitante
          NombreSolicitante
          Cargo
          DCargo
          Nro_vacante
          Nro_vacantes
          Horario_empleo
          DHorario_empleo
          Tipo_contrato
          DTipo_contrato
          Meses_plazo_fijo
          Sueldo_liquido
          Sueldo_base
          Bono
          DescripcionCargo
          Nivel_cursado
          DNivel_cursado
          Experiencia
          DExperiencia
          Genero
          Rest_nacionalidad
          Drest_nacionalidad
          Rango_edad
          DRango_edad
          Rest_comuna
          Drest_comuna
          Persona_discapacidad
          Dpersona_discapacitada
          Licencia_conducir
          Obs_personas
          Monto_colacion
          Monto_movilizacion
          Ropa
          dropa
          EPP
          dEPP
          Hora_extra
          Dhextra
          Obs_beneficios
          Examenes
          Renovacion
          Drenovacion
          Contrato_planta
          Dcontrato_planta
          Obs_otros
          Estado_id
          Estado
          Fecha_creacion
          Creado_por
        }
}`;
//////////////////DELETE//////////////////////

export const DeleteClientPostDet = gql`
mutation DeleteClientPostDet($Id_postulacion_det:ID! ){

  DeleteClientPostDet(Id_postulacion_det: $Id_postulacion_det)
  {
    success
    message
  }
}`;




