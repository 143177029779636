import gql from "graphql-tag";

export const PostulacionClientSelect = gql`
query postulacionClientSelect($id_postulacion_cab:ID!){
  GetClientPostSel(id_postulacion_cab:$id_postulacion_cab){
    id_postulacion_det
    id_postulacion_cab
    Id_empleado
    Rut
    Nombre
    Sexo
    Nacionalidad
    Estado_civil
    edad
    Direccion
    comuna_nombre
    Activo
    cargo
    experiencia
    Id_estado_postulacion
    Estado_postulacion 
    
  }
}`;
