import React,{useState, useEffect}  from 'react'
import { Modal, Button, Form, Input, Space, Row, Col ,Select} from 'antd';
import '../../Assets/Css/page.css';

import {useAllHorarioLab,useAllCargo,useAllTContrato,useAllExperencia} from '../../hooks/custom-general';

const { TextArea } = Input;
const { Option, OptGroup } = Select;


const ModalPostEditCargo = ({ EditCargo,EditDataPost, Loading, handleCargoSave, handleCancelCargo}) => {
   
    const{cargoAlldata} = useAllCargo();
    const{horarioLabAlldata} = useAllHorarioLab();
    const{tcontratoAlldata} = useAllTContrato();
     const{experenciaAlldata} = useAllExperencia();

    const [formcargo] = Form.useForm();
    const [disabled2,setDisabled2] = useState(true);
    
    useEffect(() => {
         
        if (EditCargo) {
            formcargo.setFieldsValue({
                Cargo: EditDataPost?.Cargo,
                vacantes: EditDataPost?.Nro_vacante,
                HorarioLaboral: EditDataPost?.Horario_empleo,
                TipoContrato: EditDataPost?.Tipo_contrato,
                MesesPfijo: EditDataPost?.Meses_plazo_fijo,
                Experiencia:EditDataPost.Experiencia,
                DescripcionCargo: EditDataPost?.DescripcionCargo,
            
            });

            if(EditDataPost?.Tipo_contrato==='1'){
                setDisabled2(true)
            }
            if(EditDataPost?.Tipo_contrato==='2'){
                setDisabled2(false)
            }
        }
        
       
    }, [EditCargo, EditDataPost, Loading, formcargo]);

    
    const onCargo = values => {
            formcargo.resetFields();
            return handleCargoSave(values); 
          
    }

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;

    };

    const onChanget2 = (values) => {
       
        if(values==='1'){
            
           setDisabled2(true)
          
        }
        if(values==='2'){
           
           setDisabled2(false)
           
        }

       

        
    };


    return (
        <>
        <Modal
            visible={EditCargo}
            forceRender
            title="Editar Cargo"
            // style={{ top: 20 }}
            onCancel={handleCancelCargo}
            footer={[]}
        >

            <Form
                form={formcargo}
                layout={"vertical"}
                name="Cargo"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                
                onFinish={onCargo}
            >
                
               
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    label="Cargo"
                    name="Cargo"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Cargo',
                        },
                    ]}
                    hasFeedback
                >
                
                <Select placeholder="Cargo" showSearch
                        filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                
                  {cargoAlldata.map((data) => {
                        return (
                            <Option key={data.cargo_id} value={data.cargo_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })} 
                
                </Select>
                
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    label="Vacantes"
                    name="vacantes"
                   
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Nro Vacantes'   
                        }   
                    ]}
                    hasFeedback  
                    
                >
                    <Input
                        prefix={<Icon type='UserAddOutlined' className="site-form-item-icon" />}
                        type="number"
                        placeholder="Nro Vacantes"
                    />
                </Form.Item>
                </Col>
                </Row>
                
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    label="Horario Laboral"
                    name="HorarioLaboral"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Horario Laboral',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Horario Empleo" >
                <OptGroup label="Horario Laboral">
                   {horarioLabAlldata.map((data) => {
                        return (
                            <Option key={data.hlabid} value={data.hlabid}>
                                {data.descripcion}
                            </Option>
                        );
                    })} 
                </OptGroup> 
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                 <Form.Item 
                    label="Tipo Contrato"
                    name="TipoContrato"
                    
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Tipo Contrato'  
                        }   
                    ]}
                    hasFeedback  
                    
                >
                <Select placeholder="Tipo Contrato" onChange={onChanget2} >
                <OptGroup label="Tipo Contrato">
                  {tcontratoAlldata.map((data) => {
                        return (
                            <Option key={data.tcontid} value={data.tcontid}>
                                {data.descripcion}
                            </Option>
                        );
                    })} 
                </OptGroup>     
                </Select>
                </Form.Item>
                </Col>
                </Row>

                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="MesesPfijo"
                    label="Meses Plazo Fijo"
                   
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese Meses Plazo Fijo'   
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                    <Input
                        prefix={<Icon type='CalendarOutlined' className="site-form-item-icon" />}
                        type="number"
                        disabled={disabled2}
                        placeholder="Meses Plazo Fijo"
                    />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                     label="Experiencia Laboral"
                    name="Experiencia"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor Seleccione Experiencia',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Experiencia" >
                <OptGroup label="Experiencia">
                  {experenciaAlldata.map((data) => {
                        return (
                            <Option key={data.expid} value={data.expid}>
                                {data.descripcion}
                            </Option>
                        );
                    })} 
                </OptGroup>     
                </Select>
                </Form.Item>
                </Col>
                </Row>
                
                
                
                
                <Form.Item
                    
                    name="DescripcionCargo" 
                >
                 <TextArea rows={4} placeholder="Otros asociados al cargo"  style={{ width: '100%' }} />
                </Form.Item>
               
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Guardar
                    </Button>
                    <Button key="back" onClick={handleCancelCargo}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>
                
           
           
        </Modal>
       
        </> 
    )
}

export default ModalPostEditCargo
