import { Modal, Card,List,  Divider  } from 'antd';
import '../../../Assets/Css/page.css';
import InfiniteScroll from 'react-infinite-scroll-component';

const ModalMessages = ({ ViewMessage, Loading,  handleCancelMe,messages}) => {

    return (
        <>
        <Modal
            visible={ViewMessage}
            title="Advertencia"
            onCancel={handleCancelMe}
            footer={[]}
        >

        <Card
            style={{ width: '100%' }}
            bordered={false} 
        >
       <div
        id="scrollableDiv"
        style={{
            height: 200,
            overflow: 'auto',
            padding: '0 16px',
            border: '1px solid rgba(140, 140, 140, 0.35)',
      }}
    >
      <InfiniteScroll
       // pageStart={0}
        dataLength={messages.length}
        
        //hasMore={data1.length < 50}
       // loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
        scrollableTarget="scrollableDiv"
      >
        
        <List
        itemLayout="horizontal"
        dataSource={messages}
        renderItem={item => (
            <List.Item>
            <List.Item.Meta
            title="Advertencia"
            description={item.Mensaje}
            />
        </List.Item>
               )}
        />
        </InfiniteScroll>
    </div>
      </Card>
            
        </Modal>
       </> 
    )
}

export default ModalMessages
