import React,{useState,useEffect}  from 'react'

import { Modal, Card,Form,Row,Col,Input ,Space,Button,Select,DatePicker,Typography,Checkbox } from 'antd';

import {  validate } from 'rut.js'
import '../../Assets/Css/page.css';
import {useAllTipoContacto,useAllTipoEmpleo,useAllCargo,useAllNiveles,useAllEstCivil,useAllNacionalidad,useAllSexo,useAllRegiones,useComunasById,useAllIdioma} from '../../hooks/custom-general';
const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;


const ModalPostulantes = ({ Editview, newpostulantname,DataPostulant, Loading, handleCancelPostulant,handleNewPostulant,handleNewPostEduc,handleNewPostCert,handleNewPostLab,handleNewPostIdioma,handleNewPostContacto}) => {
  
    const [activeTabKey1, setActiveTabKey1] = useState('Postulante');
    const{idiomaAlldata} = useAllIdioma();
    const{cargoAlldata} = useAllCargo();
    const{NivelesAlldata} = useAllNiveles();
    const{civilAlldata} = useAllEstCivil();
    const{NacAlldata} = useAllNacionalidad();
    const{SexoAlldata} = useAllSexo();
    const{RegionAlldata} = useAllRegiones();
    const[SelComuna] = useComunasById();
    const {data,loading} = useAllTipoContacto();
    const{TipoEmpAlldata} = useAllTipoEmpleo();
    const [formPostulante] = Form.useForm();
    const [formEducacion] = Form.useForm();
    const [formLaboralCreate] = Form.useForm();
    const [formCert] = Form.useForm();
    const [formIdioma] = Form.useForm();
    const [formContacto] = Form.useForm();
    const [Comunas,setComunas] = useState([]);
    const [Tipodata,setTipodata] = useState([]);
   
    const [disabled, setDisabled] = useState(false);
    const [required, setRequired] = useState(true);

    const [namenewpost,setNamenewpost] = useState('Agregar un Postulante Primero...');
    const dateFormat = 'DD/MM/YYYY'
    

    useEffect(() => {
       
        if(loading===false){    
            const datatipo = data.GetallTipoContacto
            setTipodata(datatipo)
        } 

        
        if(newpostulantname!==false){
            setActiveTabKey1('Educacion')
            setNamenewpost(newpostulantname)
        }
        
        
    }, [data, loading, namenewpost, newpostulantname]);
   
   const onCityChange = async values => {

        const comunassel = await  SelComuna({variables:{
            region_id:values,
        }})
 
     
      
        if(comunassel.loading===false){
            setComunas(comunassel.data.GralComunasById)
           
        }
         
    }
   
    const onAddPostulante = async values => {
       // console.log(values)
       
      //  formPostulante.resetFields();
        return handleNewPostulant(values)    
    }

    const onAddEducacion = async values => {
       // console.log(values)
      //  formEducacion.resetFields();
        //setActiveTabKey1('Laboral')
        return handleNewPostEduc(values)    
    }

    const onAddLaboral = async values => {
      //  console.log(values)
     //   formLaboralCreate.resetFields();
        // setActiveTabKey1('Certificacion')
        return handleNewPostLab(values)    
    }

    const onAddCert = async values => {
       // console.log(values)
      //  formCert.resetFields();
         //setActiveTabKey1('Idiomas')
        return handleNewPostCert(values)    
    }

    const onAddIdioma = async values => {
       // console.log(values)
        //formIdioma.resetFields();
         //setActiveTabKey1('Contactos')
        return handleNewPostIdioma(values)    
    }

    const onAddContacto = async values => {
       // console.log(values)
        //formContacto.resetFields();
         //setActiveTabKey1('Postulante')
        return handleNewPostContacto(values)    
    }

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;
    };

    const onChange = (e) => {
        setDisabled(e.target.checked);
        //console.log("checked:",e.target.checked)
        if(e.target.checked===false){
           setRequired(true)
        }
        if(e.target.checked===true){
           setRequired(false)
        }
       
        

    };


    const tabList = [
    
    {
        key: 'Postulante',
        tab: 'Postulante',
    },
    {
        key: 'Educacion',
        tab: 'Educación',
    },
    {
        key: 'Laboral',
        tab: 'Laboral',
    },
    {
        key: 'Certificacion',
        tab: 'Certificación',
    },
    {
        key: 'Idiomas',
        tab: 'Idiomas',
    },
    {
        key: 'Contactos',
        tab: 'Contactos',
    },
    ];
    
    const contentList = {
        
    Postulante:<Form
                form={formPostulante}
                name="NuevoPostulante"
                onFinish={onAddPostulante}
            >
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="Rut"
                    tooltip="Ingrese Rut sin puntos solo Guion"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Rut'  
                        }  
                        ,{
                            pattern: /[0-9kK-]+$/,
                            message: 'Rut solo numeros y Guion y K ',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                            if (validate(value)) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Rut mal ingresado..!'));
                            },
                        }),
                    ]}
                    hasFeedback  
                    
                >
                    <Input 
                        maxLength={15}
                        prefix={<Icon type='UserOutlined'  className="site-form-item-icon" />}
                        type="text"
                        placeholder="Ingrese Rut sin puntos solo Guión"
                        
                    />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="FechaNacimiento"
                    rules={[
                        { required: true,message: 'Seleccione Fecha Nacimiento'},
                                
                    ]}
                    hasFeedback
                >
                  <DatePicker format={dateFormat} style={{ width: '100%' }}  placeholder="Fecha Nacimiento"/>
                 
                </Form.Item>
              
                </Col>
                </Row>
                <Form.Item
                    name="NombrePostulante"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Nombre Cliente',
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Nombre"
                    />
                </Form.Item>
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
               <Form.Item
                    name="EstCivilModal"
                    rules={[
                        { required: true,message: 'Seleccione Estado Civil'},
                                
                    ]}
                    hasFeedback
                >
                   <Select placeholder="Estado Civil" >
                    {civilAlldata.map((data) => {
                        return (
                            <Option key={data.estado_civil_id} value={data.estado_civil_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
                </Col>
                
                </Row>
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Sexo"
                    rules={[
                        { required: true,message: 'Seleccione Genero'},
                                
                    ]}
                    hasFeedback
                >
                   <Select placeholder="Genero" >
                    {SexoAlldata.map((data) => {
                        return (
                            <Option key={data.sexo_id} value={data.sexo_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Nacionalidad"
                    rules={[
                        { required: true,message: 'Seleccione Nacionalidad'},
                                
                    ]}
                    hasFeedback
                >
                   <Select placeholder="Nacionalidad" >
                    {NacAlldata.map((data) => {
                        return (
                            <Option key={data.nacionalidad_id} value={data.nacionalidad_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
              
                </Col>
                </Row>
          
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
               <Form.Item
                    name="Region"
                    rules={[
                        { required: true,message: 'Seleccione Region'},
                                
                    ]}
                    hasFeedback
                >
                   <Select placeholder="Region" onChange={onCityChange} showSearch
                        filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                    {RegionAlldata.map((data) => {
                        return (
                            <Option key={data.region_id} value={data.region_id}>
                                {data.region_nombre}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Comuna"
                    rules={[
                        { required: true,message: 'Seleccione Comuna'},
                                
                    ]}
                    hasFeedback
                >
                <Select placeholder="Comunas" showSearch
                        filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                    {Comunas.map((data) => {
                        return (
                            <Option key={data.comuna_id} value={data.comuna_id}>
                                {data.comuna_nombre}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
              
                </Col>
                </Row>
                <Form.Item
                    name="Direccion"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese Direccion',
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Direccion"
                    />
                </Form.Item>
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading} >
                        Agregar
                    </Button>
                    <Button key="back" onClick={handleCancelPostulant}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
               
               
            </Form>,
    Educacion: <Form
                form={formEducacion}
                name="NuevoPostulante"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                onFinish={onAddEducacion}
            >
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Title level={5}>{namenewpost}</Title>
                </Col>
                </Row>
              
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="NivelCursado"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Ultimo Nivel Cursado',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Ultimo Nivel Cursado" >
                  {NivelesAlldata.map((data) => {
                        return (
                            <Option key={data.nivel_id} value={data.nivel_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}  
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="InstEducativa"
                    tooltip="Ingrese Institución Educativa"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Institución Educativa'  
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Institución Educativa"
                    />
                </Form.Item>
                </Col>

                </Row>
                
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Titulo"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese Titulo',
                        },
                    ]}
                    hasFeedback
                >
                   <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Titulo Educativo"
                    />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="NotaEgreso"
                    tooltip="NotaEgreso"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese Nota Egreso'   
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="number"
                        placeholder="Nota Egreso"
                    />
                </Form.Item>
                </Col>

                </Row>

                 <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="FechaEgreso"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese Fecha Egreso',
                        },
                    ]}
                    hasFeedback
                >
                    <DatePicker format={dateFormat} style={{ width: '100%' }}  placeholder="Fecha Egreso"/>
                </Form.Item>
                </Col>
                

                </Row>
                
                
                
                
                <Form.Item
                    name="descripcion" 
                >
                 <TextArea rows={4} placeholder="Comentarios"  style={{ width: '100%' }} />
                </Form.Item>
               
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Agregar
                    </Button>
                    <Button key="back" onClick={handleCancelPostulant}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>,
    Laboral: <Form
                form={formLaboralCreate}
                name="NuevoPostulante"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                
                onFinish={onAddLaboral}
            >
                
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Title level={5}>{namenewpost}</Title>
                </Col>
                </Row>
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Cargo"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Cargo',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Cargo"  showArrow maxTagCount= 'responsive' 
                        showSearch
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                  {cargoAlldata.map((data) => {
                        return (
                            <Option key={data.cargo_id} value={data.cargo_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}  
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="TipoEmpleo"
                    tooltip="Ingrese Tipo Empleo"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Tipo Empleo'  
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                   <Select placeholder="Tipo Empleo" >
                  {TipoEmpAlldata.map((data) => {
                        return (
                            <Option key={data.templeo_id} value={data.templeo_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}  
                </Select>
                </Form.Item>
                </Col>

                </Row>
                
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="NombreEmpresa"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Nombre Empresa',
                        },
                    ]}
                    hasFeedback
                >
                   <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Nombre Empresa"
                    />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="DireccionEmp"
                    tooltip="Direccion Empresa"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese Direccion Empresa'   
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Direccion Empresa"
                    />
                </Form.Item>
                </Col>

                </Row>

                 <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="FechaInicio"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Fecha Comienzo',
                        },
                    ]}
                    hasFeedback
                >
                    <DatePicker format={dateFormat} style={{ width: '100%' }}  placeholder="Fecha Comienzo"/>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="FechaTermino"
                    rules={[
                        {
                            required:required,
                            message: 'Por favor ingrese Fecha Termino',
                        },
                    ]}
                    hasFeedback
                >
                    <DatePicker disabled={disabled} format={dateFormat} style={{ width: '100%' }}  placeholder="Fecha Termino"/>
                </Form.Item>
                </Col>

                </Row>
                
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
               <Form.Item 
                    name="CargoActual"
                    tooltip="Empleo Actual"
                     valuePropName="checked"
                >
                <Checkbox  onChange={onChange}>
                Este es tu Cargo Actual 
                </Checkbox>
                </Form.Item>
                
                </Col>

                </Row>
                
                
                <Form.Item
                    name="DescripcionCargo" 
                >
                 <TextArea rows={4} placeholder="Descripcion Cargo"  style={{ width: '100%' }} />
                </Form.Item>
               
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Agregar
                    </Button>
                    <Button key="back" onClick={handleCancelPostulant}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>,    
    Certificacion: <Form
                form={formCert}
                name="NuevoPostulante"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                onFinish={onAddCert}
            >
                
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Title level={5}>{namenewpost}</Title>
                </Col>
                </Row>
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="EmpresaEmisora"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Empresa Emisora',
                        },
                    ]}
                    hasFeedback
                >
                   <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Empresa Emisora"
                    />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="NombreCertificado"
                    tooltip="Direccion Empresa"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese Nombre Certificado licencia'   
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Nombre Certificado"
                    />
                </Form.Item>
                </Col>

                </Row>
                
                
                 <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="FechaCaducidad"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Fecha Caducidad',
                        },
                    ]}
                    hasFeedback
                >
                    <DatePicker format={dateFormat} style={{ width: '100%' }}  placeholder="Fecha Caducidad"/>
                </Form.Item>
                </Col>
                 <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="IdCredLicencia"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese id Licencia/certificado',
                        },
                    ]}
                    hasFeedback
                >
                   <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="id Licencia/certificado"
                    />
                </Form.Item>
                </Col>

                </Row>
                
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="URLCredencial"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese URL Credencial',
                        },
                    ]}
                    hasFeedback
                >
                   <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="URL Credencial"
                    />
                </Form.Item>
                </Col>
                

                </Row>
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                       Agregar
                    </Button>
                    <Button key="back" onClick={handleCancelPostulant}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>,     
    Idiomas: <Form
                 form={formIdioma}
                name="NuevoPostulante"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                onFinish={onAddIdioma}
            >
                
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Title level={5}>{namenewpost}</Title>
                </Col>
                </Row>
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Idiomas"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor Seleccione Idioma(s)',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Ingrese Idioma(s)" mode="multiple" maxTagCount= 'responsive' showArrow >
                    {idiomaAlldata.map((data) => {
                        return (
                            <Option key={data.idioma_id} value={data.idioma_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })} 
                    </Select>
                </Form.Item>
                </Col>
               

                </Row>

               
                
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Agregar
                    </Button>
                    <Button key="back" onClick={handleCancelPostulant}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>,          
    Contactos:<Form
                form={formContacto}
                name="TipoContacto"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                onFinish={onAddContacto}
            >
                
               
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Title level={5}>{namenewpost}</Title>
                </Col>
                </Row>

                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="TipoContacto2"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor Seleccione Tipo Contacto',
                        },
                    ]}
                    hasFeedback
                >
                    <Select placeholder="Tipo Contacto" >
                    {Tipodata.map((data) => {
                        return (
                            <Option key={data.tipo_contacto_id} value={data.tipo_contacto_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="Descripcion2"
                    tooltip="Ingrese Descripción"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Descripción'  
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Descripción"
                    />
                </Form.Item>
                </Col>

                </Row>
                
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Agregar
                    </Button>
                    <Button key="back" onClick={handleCancelPostulant}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>,    
    };
    
    const onTab1Change = key => {
        setActiveTabKey1(key);
    };

    return (
        <>
        <Modal
            visible={Editview}
            forceRender
            title={'Nuevo Postulante'}
            style={{ top: 20 }}
            width={'120vh'}
            onCancel={handleCancelPostulant}
            footer={[]}
        >

        <Card
            style={{ width: '100%' }}
           
            bordered={false} 
            tabList={tabList}
            activeTabKey={activeTabKey1}
            onTabChange={key => {
            onTab1Change(key);
        }}
      >
        {contentList[activeTabKey1]}
      </Card>
            
        </Modal>
       </> 
    )
   
}

export default ModalPostulantes
