import React,{useEffect} from 'react'
import  '../Assets/Css/page.css';
import { Modal,Layout} from 'antd';

import Footer from '../Components/Footer';

import{useVerifyToken,useVerify} from "../hooks/useToken" 
 


const Inipage = () => {
    const {data:Tokensverify, loading:TokensverifyLoading}= useVerify();
    const{Tokens,TokensLoading} = useVerifyToken();
    

    useEffect(() => {
         if(TokensverifyLoading===false){
            
            if(Tokensverify.GetLogin_validate.success===false){
                Modal.error({
                    title: Tokensverify.GetLogin_validate.message,
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");
                    }
                })
            }
           
        }

       
    }, [Tokens, TokensLoading, Tokensverify, TokensverifyLoading])
   
     
   


    const { Content } = Layout;
    return (
           <Layout className='homepage'>
          
           <Content>

           </Content>
            <Footer />
               
        </Layout>
    )
}

export default Inipage
