import React,{useState,useEffect} from 'react'
import moment from 'moment'
import {  format } from 'rut.js'
import '../Assets/Css/page.css';
import { Modal,Card,Space,Button} from 'antd';
import { UsergroupAddOutlined } from '@ant-design/icons';
import IcoButton from '../Components/IconButtonGroup';
import Buscar from '../Components/Loading';
import ModalClave from '../Components/ModalClave';
import {useUpdatepass} from '../hooks/custom-users';

import SearchPostEmpleado from '../Components/Postulantes/SearchPostPostulantes';
import TablePostulantes from '../Components/Postulantes/TablePostulantes';
import ModalPostulante from '../Components/Postulantes/ModalPostulante';
import ModalPostulanteAdj from '../Components/Postulantes/ModalPostulanteAdj';
import ModalPostulanteView from '../Components/Postulantes/ModalPostulanteView';

import{ useEmpleadosByFilter,useCreatePostulante,useCreatePostulanteEdu,useCreatePostulanteLab,
        useCreatePostulanteCert,useCreatePostulanteCont,useCreatePostulanteIdioma,useUpdatePostulante,
        useEmpleadoById,useEmpleAdjuntos,useCreatePostulanteAdj } from '../hooks/custom-Empleados';
import{useVerifyToken,useVerify} from "../hooks/useToken"  


const Postulantes = () => {
    const {data:Tokensverify, loading:TokensverifyLoading}= useVerify();
    const{Tokens,TokensLoading} = useVerifyToken(); 
   const {loading} = useState(); 
   const [Visible, setVisible] = useState(false);
   const [Loading, setLoading] = useState(false);
   const [SetUpd] = useUpdatepass();
   const[Setempleados] = useEmpleadosByFilter(); 
   const [SetCreatePostulante] = useCreatePostulante();
   const [SetCreatePostulanteEdu] = useCreatePostulanteEdu();
   const [SetCreatePostulanteLab] = useCreatePostulanteLab();
   const [SetCreatePostulanteCert] = useCreatePostulanteCert();
   const [SetCreatePostulanteCont] = useCreatePostulanteCont();
   const [SetCreatePostulanteIdioma] = useCreatePostulanteIdioma();
   const [SetCreatePostulanteAdj] = useCreatePostulanteAdj();
   const [SetUpdatePostulante] = useUpdatePostulante();
   const [SelPostulanteById] = useEmpleadoById();
   const [SelDataAdj] = useEmpleAdjuntos();
   
  
   const [DataEmp, SetdataEmp] = useState(false); 
   const [dataEmpFilter, SetdataEmpFilter] = useState(false);   
   const [Editview,SetEditview] = useState(false);
   const [viewAdj,setViewAdj] = useState(false);
   const [dataAdj, setDataAdj] = useState([]);
   const [DataPostulant,setDataPostulant ] = useState([{Nombre:"Guarde postulante Primero",id:0}]);
   
   const [viewdataPost,setviewdataPost] = useState(false);
   const [creadoPor, setCreadoPor] = useState('');
   const [newpostulant, setNewpostulant] = useState(0);
   const [newpostulantname, setNewpostulantname] = useState(false);
   
  
   useEffect(() => {
        if(TokensverifyLoading===false){
            
            if(Tokensverify.GetLogin_validate.success===false){
                Modal.error({
                    title: Tokensverify.GetLogin_validate.message,
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");
                    }
                })
            }
           
        }

       if(TokensLoading===false){
            if(Tokens){
                 const userF=Tokens.token.split('||')
                 setCreadoPor(userF[2])
            }
        } 
       
    }, [Tokens, TokensLoading, Tokensverify, TokensverifyLoading])
   
   const [submenu] = useState([
        {
            key: '1',
            icon: 'KeyOutlined',
            title: 'Nueva Clave',

        }, {
            key: '2',
            icon: 'PoweroffOutlined',
            title: 'Salir',

        }
    ])

    const onGroupClick = (array) => {
       
        switch (array.key) {
            case '1':
                //console.log(array.key);
                setVisible(true);
               break
            default:
                Modal.confirm({
                    title: 'Estas Seguro de Cerrar la aplicación ..??',
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");

                        
                }
                })
        }
    }

    const handleOk = async (values) => {

       
        const EditPass = await  SetUpd({variables:{
            id_sesion:creadoPor,
            oldPass: values.claveactual,
            NewPass: values.NuevaClave,
          
        }})
      //console.log(EditPass)
       // setLoading(true);
        const success = EditPass.data.updatePassword.success
        const message = EditPass.data.updatePassword.message
        if(success===true){   
           const  modal = Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
                setVisible(false);
                modal.destroy();
            }, 3000);

        }else{
            Modal.warning({
                 content: message,
            });
            setLoading(false);
              
           
        }  

    };

    const handleCancel = () => {
        setVisible(false);
    };

    const HandleDataEmp = async (values) => {
        SetdataEmpFilter(values)
        let Rut='';
        if(values.Rut!==''){
             Rut=format(values.Rut);
        }
        const empleado = await  Setempleados({variables:{
            Rut: Rut,
            Nombre:values.Nombre,
            Id_sexo: values.Sexo,
            Id_nacionalidad: values.Nacionalidad,
            Id_estado_civil: values.Estado_civil,
            Id_cargo: values.Cargo,
            edad: "",
            experiencia: "",
            id_idioma: []
          
        }})
 
     
      
        if(empleado.loading===false){
            SetdataEmp(empleado.data.GetEmpleadosByFilter)
           
        }
       

    };
    
    const handleCancelPostulant = async (values) => {
        setNewpostulant(0)
        setNewpostulantname('Agregar un Postulante Primero...');
        SetEditview(false);
    };

    const handleNewPostulant = async (values) => {
            // console.log(values)
        const CreateResult = await  SetCreatePostulante({variables:{
            Rut: format(values.Rut),
            Nombre:values.NombrePostulante,
            Id_Sexo: values.Sexo,
            Id_Nacionalidad: values.Nacionalidad,
            Id_estado_civil: values.EstCivilModal,
            Fecha_nacimiento: moment(values.FechaNacimiento).format('YYYY/MM/DD'),
            Direccion: values.Direccion ,
            id_comuna: values.Comuna,
            Creado_por: creadoPor,

        
        }})
       
        setLoading(true);
        const success = CreateResult.data.createPostulante.success
        const message = CreateResult.data.createPostulante.message
        setNewpostulant(CreateResult.data.createPostulante.Id_empleado)
        if(success){   
        setNewpostulantname(values.NombrePostulante);
            Modal.success({
                 content: message,
            });
           
            setTimeout(() => {
                setLoading(false);
               
           }, 3000);

        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }

    };

    const handleNewPostEduc = async (values) => {
             
        const CreateResult = await  SetCreatePostulanteEdu({variables:{
            Id_empleado: newpostulant,
            ultimo_nivel_cursado:values.NivelCursado, 
            Inst_Educativa: values.InstEducativa,
            Titulo: values.Titulo,
            fecha_egreso: moment(values.FechaEgreso).format('YYYY/MM/DD'),
            Nota_egreso: values.NotaEgreso,
            descripcion: values.descripcion,
            Creado_por: creadoPor,

        
        }})
       
        setLoading(true);
        const success = CreateResult.data.createPostulanteEdu.success
        const message = CreateResult.data.createPostulanteEdu.message
        if(success){   
            Modal.success({
                 content: message,
            });

            setTimeout(() => {
               setLoading(false);
               
           }, 1000);

        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }


    };

    const handleNewPostLab = async (values) => {
           
        let CargoActual = 'false'    
        if(values.CargoActual!==undefined){
            CargoActual =values.CargoActual.toString()
        }
        const CreateResult = await  SetCreatePostulanteLab({variables:{
            Id_empleado: newpostulant,
            id_cargo: values.Cargo,
            tipo_empleo: values.TipoEmpleo,
            Nombre_empresa: values.NombreEmpresa,
            Ubicacion: values.DireccionEmp,
            fecha_inicio: moment(values.FechaInicio).format('YYYY/MM/DD'),
            fecha_finalizacion: moment(values.FechaTermino).format('YYYY/MM/DD'),
            cargo_actual:CargoActual,
            descripcion_cargo: values.DescripcionCargo,
            Creado_por: creadoPor,

        
        }})
       
        setLoading(true);
        const success = CreateResult.data.createPostulanteLab.success
        const message = CreateResult.data.createPostulanteLab.message
        if(success){   
            Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
               
           }, 1000);

        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }

    };

    const handleNewPostCert = async (values) => {
            // console.log(values)
        const CreateResult = await  SetCreatePostulanteCert({variables:{
            Id_empleado: newpostulant,
            Empresa_emisora: values.EmpresaEmisora,
            nombre_cert_licencia: values.NombreCertificado,
            fecha_de_caducidad: moment(values.FechaCaducidad).format('YYYY/MM/DD'),
            id_credencial_licencia: values.IdCredLicencia,
            URL_credencial: values.URLCredencial,
            Creado_por: creadoPor,

        
        }})
       
        setLoading(true);
        const success = CreateResult.data.createPostulanteCert.success
        const message = CreateResult.data.createPostulanteCert.message
        if(success){   
            Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
               
           }, 1000);

        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }

    };

    const handleNewPostIdioma = async (values) => {
            // console.log(values)
        const CreateResult = await  SetCreatePostulanteIdioma({variables:{
            Id_empleado: newpostulant,
            id_idiomas:values.Idiomas,
            Creado_por: creadoPor,

        
        }})
       
        setLoading(true);
        const success = CreateResult.data.createPostulanteIdioma.success
        const message = CreateResult.data.createPostulanteIdioma.message
        if(success){   
            Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
               
           }, 1000);

        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }
    };
    
    const handleNewPostContacto = async (values) => {
       // console.log(values)
        const CreateResult = await  SetCreatePostulanteCont({variables:{
            Id_empleado: newpostulant,
            Id_tipocontacto: values.TipoContacto2,
            descripcion: values.Descripcion2,
            Creado_por: creadoPor,

        
        }})
       
        setLoading(true);
        const success = CreateResult.data.createPostulanteCont.success
        const message = CreateResult.data.createPostulanteCont.message
        if(success){   
            Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
               
           }, 1000);

        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }

    };
    
    const onAddPost=()=>{
       SetEditview(true);
    }

    const onViewAdj=async(values)=>{
       setDataPostulant(values);
     
        const SelAdjData = await SelDataAdj({variables:{
            Id_empleado:values.Id_empleado
        }})
        setDataAdj(SelAdjData.data.GetempleadoAdjById)
       
       setViewAdj(true);
    }

    const handleCancelAdj = () => {
         setViewAdj(false);
    };
    
    const handleSaveAdj = async (values,Arrfilename) => {
     
        setLoading(true);
        const valor = Arrfilename.map((item) => item.filename);

            const InsertAdj = await SetCreatePostulanteAdj({variables:{
                Id_empleado: DataPostulant.Id_empleado,
                Nombre:values.Nombre,
                Descripcion: values.Descripcion,
                adjunto:  valor,
                creado_por: creadoPor,
        
            }})
       
         
            const success = InsertAdj.data.CreatePostulanteAdj.success
            const message = InsertAdj.data.CreatePostulanteAdj.message
            if(success){   
               onViewAdj(DataPostulant)

                

                setTimeout(() => {
                    Modal.success({
                        content: message,
                    });
                    setLoading(false);
                  
                   
                }, 3000);

            }
            else{
                Modal.warning({
                    content: message,
                });
                setLoading(false);
            }   
    };

    const onViewPost= async(values)=>{
       setDataPostulant(values); 
       setviewdataPost(true);
    }
    
    const handleCancelPostView = () => {
        setviewdataPost(false);
    };

    const handleEditPost = async(values,EditDataPost) => {
            // console.log(EditDataPost)
          const EditResult = await  SetUpdatePostulante({variables:{
            Id_empleado:EditDataPost.Id_empleado,
            Rut: format(values.Rut, { dots: false }),
            Nombre:values.NombrePostulante,
            Id_Sexo: values.Sexo,
            Id_Nacionalidad: values.Nacionalidad,
            Id_estado_civil: values.EstCivilModal,
            Fecha_nacimiento: moment(values.FechaNacimiento).format('YYYY/MM/DD'),
            Direccion: values.Direccion ,
            id_comuna: values.Comuna,
            Modificado_por: creadoPor,

        
        }})
       
        setLoading(true);
        const success = EditResult.data.updatePostulante.success
        const message = EditResult.data.updatePostulante.message
       
        if(success){ 
            
            HandleDataEmp(dataEmpFilter) 
            SelPostulanteByIds(EditDataPost) 
            Modal.success({
                 content: message,
            });
            
            setTimeout(() => {
                setLoading(false);
            }, 3000);

        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }
    };

    const SelPostulanteByIds = async(values) => {

        const selmpleado = await  SelPostulanteById({variables:{
            id_empleado: values.Id_empleado,
        }})
        
        
        if(selmpleado.loading===false){
            setDataPostulant(selmpleado.data.Getempleados)

                    
        } 
    }

   


    if(loading){
        return (
        <div className="site-card-border-less-wrapper">
           
          <Buscar/>
         
        </div>
        )
    }
    return (
        <div className="site-card-border-less-wrapper">
        
            <Card size="small" style={{ textAlign: 'Left', }}  title='Maestro Postulantes'    extra={<IcoButton icon='SettingOutlined' onChildClick={(array) => onGroupClick(array)} submenu={submenu}  />}   >
                <Card size="small" bordered={false} style={{  display: 'flex' }}>
                    <Button onClick={onAddPost} icon={<UsergroupAddOutlined />}>Nuevo Postulante</Button>
                </Card>
                
                   <SearchPostEmpleado HandleDataEmp={HandleDataEmp}  SetdataEmp={SetdataEmp}/>
                   <Space>&nbsp;</Space>
                   <TablePostulantes DataEmp={DataEmp} onViewAdj ={onViewAdj} onViewPost ={onViewPost}  />
                
            </Card>
            <ModalClave Visible={Visible} Loading={Loading} handleOk={handleOk} handleCancel={handleCancel }/>
            <ModalPostulante Editview={Editview} newpostulantname={newpostulantname} DataPostulant={DataPostulant}  Loading={Loading}  handleCancelPostulant={handleCancelPostulant}  handleNewPostulant={handleNewPostulant}  handleNewPostEduc={handleNewPostEduc}  handleNewPostCert={handleNewPostCert}  handleNewPostLab={handleNewPostLab}  handleNewPostIdioma={handleNewPostIdioma} handleNewPostContacto={handleNewPostContacto}   />
            <ModalPostulanteAdj viewAdj ={viewAdj} DataPostulant={DataPostulant} Loading={Loading} handleSaveAdj={handleSaveAdj} handleCancelAdj={handleCancelAdj}  dataAdj ={dataAdj} />
            <ModalPostulanteView viewdataPost ={viewdataPost}  handleEditPost={handleEditPost} handleCancelPostView={handleCancelPostView} DataPostulant ={DataPostulant} creadoPor={creadoPor} />
   
        </div>

    );
}

export default Postulantes