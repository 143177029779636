import React, {useEffect}  from 'react'
import { Modal, Button, Form, Input, Space, Row, Col,Select } from 'antd';
import { useAllSendAlert } from '../../hooks/custom-general'
import '../../Assets/Css/page.css';
const { Option, OptGroup } = Select;


const ModalEditUser = ({ EditUser, Loading, handleSaveEdit, handleCancelEdit, dataEditUser,nameAlert}) => {
    const {sendAlertalldata} = useAllSendAlert();
    const [form3] = Form.useForm();
   
    useEffect(() => {
       //  console.log(dataEditUser)
        if (EditUser) {
          
            
           
            if(nameAlert!==false){
                const namesSend = nameAlert.trim().split(',');
                form3.setFieldsValue({
                
                    Usuario: dataEditUser?.Users,
                    NombreUsuario: dataEditUser?.Nombre,
                    ApellidoPaterno: dataEditUser?.Apellidop,
                    ApellidoMaterno: dataEditUser?.Apellidom,
                    Emailuser: dataEditUser?.Email,
                    SendAlert:namesSend,
                });
            }
            else{
                form3.setFieldsValue({
                
                    Usuario: dataEditUser?.Users,
                    NombreUsuario: dataEditUser?.Nombre,
                    ApellidoPaterno: dataEditUser?.Apellidop,
                    ApellidoMaterno: dataEditUser?.Apellidom,
                    Emailuser: dataEditUser?.Email,
                    
                });
            }
        }
       
    }, [form3, dataEditUser, EditUser, nameAlert]);
    
    const onAdd = async (values) => {
         return handleSaveEdit(values); 
         
    }

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;

    };

    return (
        <Modal
            visible={EditUser}
            title="Editar Usuario"
            onCancel={handleCancelEdit}
            footer={[
                
               
            ]}
        >
            <Form
                form={form3}
                layout="vertical"
                name="EditUsuario"
                onFinish={onAdd}
            >
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Usuario"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Usuario',
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Usuario"
                        
                    />
                </Form.Item>
                </Col>
                 <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                 <Form.Item
                    name="NombreUsuario"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Nombre Usuario',
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Nombre Usuario"
                        
                    />
                </Form.Item>
                </Col>
                </Row>
                
               
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="ApellidoPaterno"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Apellido Paterno',
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Apellido Paterno"
                        
                    />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="ApellidoMaterno"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Apellido Materno',
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Apellido Materno"
                        
                    />
                </Form.Item>
                </Col>
                </Row>
                <Form.Item
                    name="Emailuser"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Email !',
                        },
                        
                    ]}
                    hasFeedback
                >
                    <Input
                        prefix={<Icon type='MailOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="E-mail"
                        
                    />
                </Form.Item>
                
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 16 }}>
                <Form.Item
                    name="SendAlert"
                    label="Mensajes"
                    tooltip="Recepción de alertas por correo, Un ejemplo es cuando se crea una nueva Postulación..!  "
                    
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese Opción',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Alertas" mode="multiple" maxTagCount= 'responsive' showArrow>
                <OptGroup label="Alertas">
                  {sendAlertalldata.map((data) => {
                        return (
                            <Option key={data.send_id} value={data.descripcion}>
                                {data.descripcion}
                            </Option>
                        );
                    })} 
                </OptGroup>     
                </Select>
                </Form.Item>
                </Col>
               
                </Row>
                


                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading} >
                        Guardar
                    </Button>
                        <Button key="back" onClick={handleCancelEdit}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ModalEditUser
