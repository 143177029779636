import React, { useState} from 'react'
import { Table } from 'antd';

const TableReportHextra = ({Datahextra,Datapersona,Handleprint,HandleFiltro,paramstable}) => {

    
    

    const [Columsdata] = useState([
     
       {
            title: 'id_registro',
            dataIndex: 'id_registro',
            key: 'id_registro',
            hidden:true,
        },
        {
            title: 'Fecha',
            dataIndex: 'Fecha',
            key: 'Fecha',
        },
        {
            title: 'Jornada',
            dataIndex: 'turno',
            key: 'turno',
        },
        {
        title:  'Entrada' ,
            children: [
                {
                    title: 'Entrada',
                    dataIndex: 'hora_entrada',
                    key: 'hora_entrada',
                },
                {
                    title: 'Extra',
                    dataIndex: 'tiempo_ad_entrada',
                    key: 'tiempo_ad_entrada',
                },
            ]
        },
        {
        title:  'Salida' ,
            children: [   
            {
                title: 'Salida',
                dataIndex: 'hora_salida',
                key: 'hora_salida',
            },
            {
                title: 'Extra',
                dataIndex: 'tiempo_ad_salida',
                key: 'tiempo_ad_salida',    
            },
            ]
        },
         {
        title:  'Tiempo Extra' ,
            children: [ 
                {
                    title: 'Normal',
                    dataIndex: 'pagohextra_normal',
                    key: 'pagohextra_normal',   
                },
                {
                    title: 'Festivo',
                    dataIndex: 'pagohextra_feriado',
                    key: 'pagohextra_feriado',    
                },
            ]
        },
        {
        title:  'Total Tiempo Extra' ,
            children: [    
                {
                    title: 'Total',
                    dataIndex: 'hextra_total',
                    key: 'hextra_total',
                     width:'13vh',    
                },
                {
                    title: 'Total2',
                    dataIndex: 'hextra_total_num',
                    key: 'hextra_total_num',  
                     width:'13vh',  
                },
            ]
        },
        {
        title:  'Tiempo Noche' ,
            children: [    
                {
                    title: 'Total',
                    dataIndex: 'horas_total_noche',
                    key: 'horas_total_noche', 
                     width:'13vh',   
                },
                {
                    title: 'Total2',
                    dataIndex: 'horas_total_noche_num',
                    key: 'horas_total_noche_num',  
                     width:'13vh',  
                },
            ]
        },
   
        
        
        
       
    ].filter(item => !item.hidden));
   
   
    
    

    return (
       
            <Table  columns={Columsdata} dataSource={Datahextra} {...paramstable} rowKey="id_registro"/> 
       
    )
}

export default TableReportHextra
