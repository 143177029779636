import gql from "graphql-tag";

export const PostulacionObs = gql`
query postulacionObs($id_postulacion_cab:ID!){
  GetObs(id_postulacion_cab:$id_postulacion_cab){
   title
   Estado
   Observaciones 
    
  }
}`;
