import React,{useState,useEffect} from 'react'
import moment from 'moment'
import '../Assets/Css/page.css';
import { Modal,Card,Space,Button, Row,Col} from 'antd';
import IcoButton from '../Components/IconButtonGroup';
import Buscar from '../Components/Loading';
import ModalClave from '../Components/ModalClave';
import {useUpdatepass} from '../hooks/custom-users';

import IcoButtonList from '../Components/contratos/creacionLicencias/IconButtonList';
import TableLicencia from '../Components/contratos/creacionLicencias/TableLiencia';
import SearchEmpleLicencia from '../Components/contratos/creacionLicencias/SearchEmpleLicencia';
import ModalLicencia from '../Components/contratos/creacionLicencias/ModalLicencia';
import ModalMessages  from '../Components/contratos/creacionLicencias/ModalMessages';
import ModalObsRechazo  from '../Components/contratos/ModalObsRechazo';
import ModalObsLicencia  from '../Components/contratos/creacionLicencias/ModalObsLicencia';
import ModalLicenciaAdj  from '../Components/contratos/creacionLicencias/ModalLicenciaAdj';
import { useListaLicencias,useNuevaLicenciaEmpleado,useEliminaLicenciaEmpleado,
         useAprobarLicenciaEmpleado,useRechazarLicenciaEmpleado,useObsAusencia,
         useEstadoLicenciaEmpleado,useAdjAusencia,useCrearAusenciasAdj } from '../hooks/custom-contratos';

import { useAllTipoAusencia,useAllAprobacion,useAllTDoctoAusencia } from '../hooks/custom-general'
import { useAllClientesActivo } from '../hooks/custom-Clientes'
import{useVerifyToken,useVerify} from "../hooks/useToken"   

const CreacionAusencias = () => {
    
    const { confirm } = Modal;
    const {data:Tokensverify, loading:TokensverifyLoading}= useVerify();
    const {Tokens,TokensLoading} = useVerifyToken();
    const {allTipoAusenciadata,TipoAusenciaLoading}= useAllTipoAusencia();
    const {allAprobaciondata,AprobacionLoading} = useAllAprobacion();
    const {allTDoctoAusenciadata,TDoctoAusenciaLoading} = useAllTDoctoAusencia();
    const {data:clieData,loading:clieloading}= useAllClientesActivo();
    const [NuevaLicenciaEmpleado] = useNuevaLicenciaEmpleado();
    const [DataObsAusencia] = useObsAusencia();
    const [DataAdjAusencia] = useAdjAusencia();
    const [Crear_AusenciasAdj] = useCrearAusenciasAdj();
    const [EliminaLicenciaEmpleado] = useEliminaLicenciaEmpleado();
    const [AprobarLicenciaEmpleado] = useAprobarLicenciaEmpleado();
    const [RechazarLicenciaEmpleado] = useRechazarLicenciaEmpleado();
    const [EstadoLicenciaEmpleado] = useEstadoLicenciaEmpleado();
    
    
    const [clientes, setClientes] = useState();
    const [Ausencia, setAusencia] = useState();
    const [loadingpage, setloadingpage] = useState(true);
    const [Visible, setVisible] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [SetUpd] = useUpdatepass();

    const[ListaLicencia] =  useListaLicencias(); 
    
    
    const [SelFiltros, setSelFiltros] = useState();
    const [messages, setMessages] = useState(false);
    const [DataLicencia, SetdataLicencia] = useState(false); 
    const [viewNew,setViewNew] = useState(false);
    const [ViewMessage,setViewMessage] = useState(false);
    const [selRowKeys,  setSelRowKeys] = useState([]);
    const [MRechazoView, setMRechazoView] = useState(false);
    const [Estdata,setEstdata] = useState([]);
    const [viewLicencia,setViewLicencia] = useState(false);
    const [dataSel,setDataSel] = useState([]);
    const [dataObs,setDataObs] = useState([]);

    const [viewAdj,setViewAdj] = useState(false);
    const [dataAdj,setDataAdj] = useState([]);
    const [dataSelAdj,setDataSelAdj] = useState([]);
    const [TipoDocto,SetTipoDocto] = useState([]);

     
   
   
   const [creadoPor, setCreadoPor] = useState('');
   
   useEffect(() => {
  
         if(TokensverifyLoading===false){
            
            if(Tokensverify.GetLogin_validate.success===false){
                Modal.error({
                    title: Tokensverify.GetLogin_validate.message,
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");
                    }
                })
            }
           
        }

        if(AprobacionLoading===false){    
           const dataest = allAprobaciondata
             setEstdata(dataest)
        } 

        if(TDoctoAusenciaLoading===false){    
           const dataDoctoAusencia = allTDoctoAusenciadata
             SetTipoDocto(dataDoctoAusencia)
           
        } 

        if(TokensLoading===false){
            if(Tokens){
                 const userF=Tokens.token.split('||')
                 setCreadoPor(userF[2])
            }
        } 
         
        if(TipoAusenciaLoading===false){
        
        setAusencia(allTipoAusenciadata)

        }
        if(clieloading===false){
            const dataclient = clieData.GetClientAllActivo
             
            setClientes(dataclient)
             
        }
        if(clieloading===false && TipoAusenciaLoading===false){
            setloadingpage(false)
        }

        

       
    }, [Tokens, TokensLoading, Tokensverify, TokensverifyLoading, clieData, clieloading, TipoAusenciaLoading, allTipoAusenciadata, AprobacionLoading, allAprobaciondata.GetallAprobacion, allAprobaciondata, allTDoctoAusenciadata, TDoctoAusenciaLoading])
   
   const [submenu] = useState([
        {
            key: '1',
            icon: 'KeyOutlined',
            title: 'Nueva Clave',

        }, {
            key: '2',
            icon: 'PoweroffOutlined',
            title: 'Salir',

        }
    ])

    const onGroupClick = (array) => {
       
        switch (array.key) {
            case '1':
                //console.log(array.key);
                setVisible(true);
               break
            default:
                Modal.confirm({
                    title: 'Estas Seguro de Cerrar la aplicación ..??',
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");

                        
                }
                })
        }
    }

    const handleOk = async (values) => {

      
       
        const EditPass = await  SetUpd({variables:{
            id_sesion:creadoPor,
            oldPass: values.claveactual,
            NewPass: values.NuevaClave,
          
        }})
      //console.log(EditPass)
       // setLoading(true);
        const success = EditPass.data.updatePassword.success
        const message = EditPass.data.updatePassword.message
        if(success===true){   
           const  modal = Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
                setVisible(false);
                modal.destroy();
            }, 3000);

        }else{
            Modal.warning({
                 content: message,
            });
            setLoading(false);
              
           
        }  

    };

    const handleCancel = () => {
        setVisible(false);
    };

   const HandleDataLicencia = async (values) => {
      
        let fecha_desde="";
        let fecha_hasta ="";
        if(values.FechaLicencia){
           fecha_desde= moment(values.FechaLicencia[0]).format('YYYY/MM/DD')
           fecha_hasta =moment(values.FechaLicencia[1]).format('YYYY/MM/DD')
        } 
        
        const Licencia = await  ListaLicencia({variables:{
            Id_contrato: values.Id_contrato,
            Id_tipo_ausencia: values.Licencia,
            fecha_desde: fecha_desde,
            fecha_hasta:  fecha_hasta,
            Id_cliente:values.Cliente,
        }})

        const filterArray=[{Id_contrato: values.Id_contrato,Id_tipo_ausencia: values.Licencia,fecha_desde: fecha_desde,fecha_hasta:  fecha_hasta,Id_cliente:values.Cliente}]
        setSelFiltros(filterArray)
 
        if(Licencia.loading===false){
           SetdataLicencia(Licencia.data.GetEmpleLicencias_ByFilter)
        }
       
    };
    
    const onAddPost=()=>{
       setViewNew(true);
    }

    const handleCancelLicencia = () => {
         setViewNew(false);
    };

    const handleCancelMe = () => {
         setViewMessage(false);
    };
    
    const handleSaveLicencia = async (values) => {
        setLoading(true);
      
        const EditLicencia = await NuevaLicenciaEmpleado({variables:{
            Id_contrato: values.Id_contrato,
            Id_tipoausencia: values.Id_tipoausencia,
            EsAprobada: values.EsAprobada,
            fecha_desde: moment(values.FechaLicencia[0]).format('YYYY/MM/DD'),
            fecha_hasta: moment(values.FechaLicencia[1]).format('YYYY/MM/DD'),
            creado_por: creadoPor,
      
        }})
       
         
            const success = EditLicencia.data.Nueva_LicenciaEmpleado.success
            const message = EditLicencia.data.Nueva_LicenciaEmpleado.message
            const estado = EditLicencia.data.Nueva_LicenciaEmpleado.isdata
            setMessages(EditLicencia.data.Nueva_LicenciaEmpleado.DataError)
        
            if(success){
                if (estado) {
                    setViewMessage(true);
                    setLoading(false);  
                }
                else{   
                    setTimeout(() => {
                        Modal.success({
                            content: message,
                        });
                        setLoading(false);
                    }, 2000);
                }

            }
            else{
                Modal.warning({
                    content: message,
                });
                setLoading(false);
            }  
    };

    const onDelLicencia = async () => {
       
        if(selRowKeys){

            confirm({
                title: 'Eliminar Licencia',
                icon:<Icon type={'ExclamationCircleOutlined'}/> ,
                content: 'Seguro de eliminar estos elementos.....?',
                async onOk() {

                    const DelLicencia = await EliminaLicenciaEmpleado({variables:{
                        Id_contractlic: selRowKeys,
                        Creado_por: creadoPor,
                
                    }})
                
                    
                    const success = DelLicencia.data.Elimina_LicenciaEmpleado.success
                    const message = DelLicencia.data.Elimina_LicenciaEmpleado.message
                    if(success){   

                        const Licencia = await  ListaLicencia({variables:{
                            Id_contrato: SelFiltros[0].Id_contrato,
                            Id_tipo_ausencia: SelFiltros[0].Id_tipo_ausencia,
                            fecha_desde: SelFiltros[0].fecha_desde,
                            fecha_hasta: SelFiltros[0].fecha_hasta,
                            Id_cliente:SelFiltros[0].Id_cliente,
                        }})
                        if(Licencia.loading===false){
                            SetdataLicencia(Licencia.data.GetEmpleLicencias_ByFilter)
                        }

                        const modal= Modal.success({
                                content: message,
                            });
                        setTimeout(() => {
                            modal.destroy();
                        }, 3000);

                    }
                    else{
                        const modal=  Modal.warning({
                            content: message,
                        });
                       setTimeout(() => {
                             modal.destroy();
                        }, 3000);
                    }  
                
                },
                /*onCancel() {
                console.log(selRowKeys);
                },*/
            });
        }
       
    };

    const onAprobLicencia = async () => {
       
        if(selRowKeys){

            confirm({
                title: 'Aprobar Ausencias',
                icon:<Icon type={'ExclamationCircleOutlined'}/> ,
                content: 'Seguro quiere Aprobar estas Solicitudes.....?',
                async onOk() {

                    const AprobLicencia = await AprobarLicenciaEmpleado({variables:{
                        Id_contractlic: selRowKeys,
                        Creado_por: creadoPor,
                
                    }})
                
                    
                    const success = AprobLicencia.data.Aprobar_LicenciaEmpleado.success
                    const message = AprobLicencia.data.Aprobar_LicenciaEmpleado.message
                    if(success){   

                        const Licencia = await  ListaLicencia({variables:{
                            Id_contrato: SelFiltros[0].Id_contrato,
                            Id_tipo_ausencia: SelFiltros[0].Id_tipo_ausencia,
                            fecha_desde: SelFiltros[0].fecha_desde,
                            fecha_hasta: SelFiltros[0].fecha_hasta,
                            Id_cliente:SelFiltros[0].Id_cliente,
                        }})
                        if(Licencia.loading===false){
                            SetdataLicencia(Licencia.data.GetEmpleLicencias_ByFilter)
                        }

                        const modal= Modal.success({
                                content: message,
                            });
                        setTimeout(() => {
                            modal.destroy();
                        }, 3000);

                    }
                    else{
                        const modal=  Modal.warning({
                            content: message,
                        });
                       setTimeout(() => {
                             modal.destroy();
                        }, 3000);
                    }  
                
                },
                /*onCancel() {
                console.log(selRowKeys);
                },*/
            });
        }
       
    };

    const onRechLicencia = () => {
        if(selRowKeys){
           setMRechazoView(true);
        }
       
    };

    const handleCancelObs = () => {
        setMRechazoView(false);
    };

    const onVerObs = async (record) => {

        //console.log(record);
        setViewLicencia(true);
        const ObsData = await DataObsAusencia({variables:{
            Id_contractlic:record.Id_contractlic
        }})
       
        setDataObs(ObsData.data.GetAusenciasObs)
        setDataSel(record);
    }

     const handleCancelObsVer = () => {
        setViewLicencia(false);
    };

    const handleSaveObs = async (values) => {
        if(selRowKeys){
           

                const RechLicencia = await RechazarLicenciaEmpleado({variables:{
                    Id_contractlic: selRowKeys,
                    Observaciones:values.Observaciones,
                    Creado_por: creadoPor,
            
                }})
            
                
                const success = RechLicencia.data.Rechazar_LicenciaEmpleado.success
                const message = RechLicencia.data.Rechazar_LicenciaEmpleado.message
                if(success){   

                    const Licencia = await  ListaLicencia({variables:{
                        Id_contrato: SelFiltros[0].Id_contrato,
                        Id_tipo_ausencia: SelFiltros[0].Id_tipo_ausencia,
                        fecha_desde: SelFiltros[0].fecha_desde,
                        fecha_hasta: SelFiltros[0].fecha_hasta,
                        Id_cliente:SelFiltros[0].Id_cliente,
                    }})
                    if(Licencia.loading===false){
                        SetdataLicencia(Licencia.data.GetEmpleLicencias_ByFilter)
                    }

                    const modal= Modal.success({
                            content: message,
                        });
                    setTimeout(() => {
                        modal.destroy();
                    }, 3000);

                }
                else{
                    const modal=  Modal.warning({
                        content: message,
                    });
                    setTimeout(() => {
                            modal.destroy();
                    }, 3000);
                }   
        }
    };

    const handleSaveEstObs = async (values) => {
      console.log(dataSel.Id_contractlic)
        const EstLicencia = await EstadoLicenciaEmpleado({variables:{
            Id_contractlic: dataSel.Id_contractlic,
            Estado:values.Estado,
            Observaciones:values.Observaciones,
            Creado_por: creadoPor,
    
        }})
    
        
        const success = EstLicencia.data.Estado_LicenciaEmpleado.success
        const message = EstLicencia.data.Estado_LicenciaEmpleado.message
        if(success){   

            const ObsData = await DataObsAusencia({variables:{
                Id_contractlic:dataSel.Id_contractlic
            }})
        
            setDataObs(ObsData.data.GetAusenciasObs)

            const Licencia = await  ListaLicencia({variables:{
                Id_contrato: SelFiltros[0].Id_contrato,
                Id_tipo_ausencia: SelFiltros[0].Id_tipo_ausencia,
                fecha_desde: SelFiltros[0].fecha_desde,
                fecha_hasta: SelFiltros[0].fecha_hasta,
                Id_cliente:SelFiltros[0].Id_cliente,
            }})
            if(Licencia.loading===false){
                SetdataLicencia(Licencia.data.GetEmpleLicencias_ByFilter)
            }

            const modal= Modal.success({
                    content: message,
                });
            setTimeout(() => {
                modal.destroy();
            }, 3000);

        }
        else{
            const modal=  Modal.warning({
                content: message,
            });
            setTimeout(() => {
                    modal.destroy();
            }, 3000);
        }   

    };

   const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;
    };

    const handleCancelAdj = () => {
        setViewAdj(false);
    };

    const onVerAdj = async (record) => {

      
        setViewAdj(true);
        const AdjData = await DataAdjAusencia({variables:{
            Id_contractlic:record.Id_contractlic
        }})
       
        setDataAdj(AdjData.data.GetAusenciasAdj)
        setDataSelAdj(record)
    }

     const handleSaveAdj = async (values,Arrfilename) => {

        setLoading(true);
        const valor = Arrfilename.map((item) => item.filename);
       
        const EstLicenciaAdj = await Crear_AusenciasAdj({variables:{
            Id_contractlic: dataSelAdj.Id_contractlic,
            Codigo:values.DoctoAusencia,
            adjunto:  valor,
            creado_por: creadoPor,
    
        }})
    
        
        const success = EstLicenciaAdj.data.Crear_AusenciasAdj.success
        const message = EstLicenciaAdj.data.Crear_AusenciasAdj.message
        if(success){   

            const AdjData = await DataAdjAusencia({variables:{
                Id_contractlic:dataSelAdj.Id_contractlic
            }})
        
            setDataAdj(AdjData.data.GetAusenciasAdj)

            const modal= Modal.success({
                    content: message,
                });
            setTimeout(() => {
                modal.destroy();
                setLoading(false);
            }, 3000);

        }
        else{
            const modal=  Modal.warning({
                content: message,
            });
            setTimeout(() => {
                setLoading(false);
                modal.destroy();
            }, 3000);
        }   

    };

    
    
    if(loadingpage){
        return (
        <div className="site-card-border-less-wrapper">
           
          <Buscar/>
         
        </div>
        )
    }

    
    return (
        <div className="site-card-border-less-wrapper">
        
            <Card size="small" style={{ textAlign: 'Left', }}  title='Ausencias Empleados'     extra={[ <IcoButtonList key="1" icon='BellOutlined' SesionID={creadoPor}    />,<IcoButton key="2" icon='SettingOutlined' onChildClick={(array) => onGroupClick(array)} submenu={submenu}  />]}  >
                <Card size="small" bordered={false} style={{  display: 'flex' }}>
                
                     <Row gutter={[24, 8]}>
                    <Col xs={{ span: 12, offset: 0 }} lg={{ span: 5 }}  >
                        <Button onClick={onAddPost} icon={<Icon type={'CalendarOutlined'}/>}>Crear</Button>
                    </Col>
                    <Col xs={{ span: 12, offset: 0 }} lg={{ span: 5 }} >
                        <Button onClick={onDelLicencia}  disabled={!selRowKeys.length} icon={<Icon type={'MinusSquareOutlined'}/>} danger>Borrar</Button>
                    </Col>
                    <Col xs={{ span: 12, offset: 0 }} lg={{ span: 6 }} >
                        <Button  onClick={onAprobLicencia} disabled={!selRowKeys.length} icon={<Icon type={'CheckSquareOutlined'}/>}>Aprobar</Button>
                    </Col>
                    <Col xs={{ span: 12, offset: 0 }} lg={{ span: 6 }} >
                        <Button onClick={onRechLicencia}  disabled={!selRowKeys.length} icon={<Icon type={'CloseSquareOutlined'}/>} danger>Rechazar</Button>
                    </Col>
                    </Row>
               
                </Card>
                
                   <SearchEmpleLicencia HandleDataLicencia = {HandleDataLicencia}  SetdataLicencia={SetdataLicencia} clientes={clientes} Ausencia={Ausencia} />
                   <Space>&nbsp;</Space>
                   <TableLicencia DataLicencia={DataLicencia} setSelRowKeys={setSelRowKeys} handleVerObs={onVerObs} handleVerAdj={onVerAdj} />
                
            </Card>
            <ModalClave Visible={Visible} Loading={Loading} handleOk={handleOk} handleCancel={handleCancel }/>
            <ModalLicencia viewNew ={viewNew}  Loading={Loading} handleSaveLicencia={handleSaveLicencia} handleCancelLicencia={handleCancelLicencia} clientes={clientes} Ausencia={Ausencia} Estdata={Estdata}/>  
            <ModalMessages ViewMessage={ViewMessage} handleCancelMe={handleCancelMe} messages={messages}  />
            <ModalObsRechazo MRechazoView={MRechazoView} Loading={Loading} handleSaveObs={handleSaveObs} handleCancelObs={handleCancelObs}  />
            <ModalObsLicencia viewLicencia={viewLicencia} Loading={Loading} handleSaveEstObs={handleSaveEstObs} handleCancelObsVer={handleCancelObsVer} dataSel={dataSel} dataObs={dataObs} Estdata={Estdata} />
            <ModalLicenciaAdj viewAdj={viewAdj} Loading={Loading} handleSaveAdj={handleSaveAdj} handleCancelAdj={handleCancelAdj} TipoDocto={TipoDocto} dataAdj={dataAdj} />
        </div>

    );
}

export default CreacionAusencias