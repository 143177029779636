import React,{useState}  from 'react'
import { Modal,Card } from 'antd';
import '../../Assets/Css/page.css';
import TableEmpleados from './TableEmpleados';


const ModalPostulantes = ({PostVisible, handleCancelPostEmp, SetdataEmp,DataEmp,handleSelPost, dataDetSelect,HandleEditEstDet,HandleEditPostAdjDet,handleDeletepost,HandlePostCardDet}) => {
    
//const[DataEmp,SetdataEmp] = useState([]);   
const [activeTabKey1, setActiveTabKey1] = useState('Ver');
 
    

    const tabList = [
        {
            key: 'Ver',
            tab: 'Postulantes',
        }
    ];
     
    

    const onTab1Change = key => {
        setActiveTabKey1(key);
    };


    const contentList = {
        Ver:<div>
            <TableEmpleados dataDetSelect={dataDetSelect} HandleEditEstDet={HandleEditEstDet} HandleEditPostAdjDet={HandleEditPostAdjDet} handleDeletepost={handleDeletepost} HandlePostCardDet={HandlePostCardDet}  />
        </div>,
        
    };


    return (
        <Modal
            width="80%"
            visible={PostVisible}
            style={{ top: 20 }}
            title="Postulantes"
            onCancel={handleCancelPostEmp}
            footer={[
                
               
            ]}
        >

        <Card
            style={{ width: '100%' }}
            bordered={false} 
            tabList={tabList}
            activeTabKey={activeTabKey1}
            onTabChange={key => {
            onTab1Change(key);
        }}
      >
        {contentList[activeTabKey1]}
      </Card>

        
           
        </Modal>
    )
}

export default ModalPostulantes
