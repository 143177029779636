import gql from "graphql-tag";

export const PostulacionClientAdj = gql`
query postulacionClientAdj($id_postulacion_cab:ID!){
  GetClientAdj(id_postulacion_cab:$id_postulacion_cab){
    title
    Descripcion
    adjunto
    
  }
}`;
