import gql from "graphql-tag";

export const Allclientes = gql`
query{
  GetClientAll{
    Id_cliente
    Rut
    Nombre
    Direccion_principal
    id_giro
    giro
    id_estadou
    estadou
    Fecha_creacion
    Fecha_modificacion
    Creado_por_name
    Modificado_por_name
  }
}`;

export const AllclientesActivo = gql`
query{
 GetClientAllActivo
  {
    Id_cliente
    Rut
    Nombre
    Direccion_principal
    id_giro
    giro
    id_estadou
    estadou
    Fecha_creacion
    Fecha_modificacion
    Creado_por_name
    Modificado_por_name
  } 

}`;


export const  ClientesByFilter = gql`

query  GetClientesByFilter($Rut:String!,$Nombre:String!,$id_giro:[ID]!)
{
    GetClientbyFiltro(Nombre: $Nombre
                    Rut: $Rut
                    id_giro: $id_giro){
    Id_cliente
    Rut
    Nombre
    Direccion_principal
    id_giro
    giro
    id_estadou
    estadou
    Fecha_creacion
    Fecha_modificacion
    Creado_por_name
    Modificado_por_name
  }
    
}`;

export const  GetClieContactbyId = gql`

query  GetClieContactbyId($Id_cliente:ID)
{
    GetClientContactbyId(Id_cliente:$Id_cliente){
    Id_contacto
    Id_cliente
    Nombre
    DescripcionCargo
    Activo
    SActivo
    Mail
    Id_tipo
    id_Sesion
    Users
    Fecha_creacion
    Creado_por
  }
    
}`;

export const  GetClientContactbyIdContact = gql`

query  GetClientContactbyIdContact($Id_contacto:ID!)
{
  GetClientContactbyIdContact(Id_contacto:$Id_contacto)
  {
    Id_contacto
    Id_cliente
    Nombre
    DescripcionCargo
    Activo
    SActivo
    Mail
    Id_tipo
    id_Sesion
    Users
    Fecha_creacion
    Creado_por
   
  }
  
}`;

export const  GetClieContactDetbyId = gql`
query  GetClieContactDetbyId($Id_cliente:ID!)
{
    GetClientContactDetbyId(Id_cliente: $Id_cliente)
    {
      Id_tipo
      Id_contacto
      Id_cliente
      Nombre
      DescripcionCargo
      Activo
      SActivo
      tipo_contacto
      id_tipocontacto
      contactologin
      descripcion
      Icon
      creacion_tipo
      id_Sesion
      Users
      Creado_por
    }
}`;

export const  GetClieContactAll = gql`

  query{
    GetClientContactAll
    {
      Id_contacto
      Id_cliente
      Nombre
      DescripcionCargo
      Activo
      Fecha_creacion
      Creado_por
    }
}`;

export const  GetDataContact_mail_ById = gql`
query  GetClieContactDetbyId($Id_contacto:ID!)
{
  GetDataContact_mail_ById(Id_contacto:$Id_contacto)
  {
    Id_tipo
  	Id_contacto
    Id_cliente
    Nombre
    DescripcionCargo
    Activo
    SActivo
    tipo_contacto
    id_tipocontacto
    descripcion
    Icon
    creacion_tipo
    Creado_por
  }
}`; 

export const  GetDataContactClient_ById = gql`
query  GetDataContactClient_ById($id_Sesion:ID!)
{
  GetDataContactClient_ById(id_Sesion: $id_Sesion)
  {
    Id_contacto
    Id_cliente
    Nombre_Cliente
    Nombre
    DescripcionCargo
    Activo
    SActivo
    Mail
    Id_tipo
    id_Sesion
    Users
    Fecha_creacion
    Creado_por
  }
  
}`;










