import {useQuery,useMutation,useLazyQuery} from '@apollo/client'
import {Allusers,GetUsers_AlertNameByIdSesion} from "../model/query/usuarios/AllUsers"
import {EditUsers,CreateUsers,BloqueoUsers,Updatepass,Resetpass,updateUserSend} from "../model/mutation/usuarios/Setusers"


export const useAllusers = () =>{
    const result = useQuery(Allusers);
    return result;
}

export const useAlertNameById = () =>{
    const result = useLazyQuery(GetUsers_AlertNameByIdSesion);
    return result;
}
 

export const useEditusers = () =>{
   const result = useMutation(EditUsers,{refetchQueries:[{query:Allusers}]});
    return result;
}

export const useCreateusers = () =>{
   const result = useMutation(CreateUsers,{refetchQueries:[{query:Allusers}]});
    return result;
}


export const useBloqueousers = () =>{
   const result = useMutation(BloqueoUsers,{refetchQueries:[{query:Allusers}]});
    return result;
}

export const useUpdatepass = () =>{
   const result = useMutation(Updatepass,{});
   return result;
}

export const useResetpass = () =>{
   const result = useMutation(Resetpass,{});
   return result;
}

export const useUpdateUserSend = () =>{
   const result = useMutation(updateUserSend);
   return result;
}




export default useAllusers 


