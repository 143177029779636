import { Layout } from 'antd';
import { Link } from "react-router-dom";
import '../Assets/Css/NavBar.css';
const Header = () => {
    const { Header } = Layout;
    return (
       <Link to='/' ><Header className="logo" style={{ padding: 30 }}/></Link>
    )
}

export default Header
