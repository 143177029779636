
import React from 'react';
import { BrowserRouter, Route, Routes} from "react-router-dom";
import { Layout} from 'antd';


import Login from './Login';
import Inipage from './Inipage';
import Usuarios from './Usuarios';
import Relojcontrol from './Relojcontrol';
import ReportHextra from './ReportHextra';
import ReportHextraResumen from './ReportHextraResumen';
import CreacionContratado from './CreacionContratado';
import CreacionTurnos from './CreacionTurnos';
import CreacionAusencias from './CreacionAusencias';
import CreacionVacaciones from './CreacionVacaciones';
import Modulos from './Modulos';
import Clientes from './Clientes';
import Requerimientos from './Requerimientos';
import RequerimientosCliente from './RequerimientosCliente';
import Postulantes from './Postulantes';
import MenuLeft from '../Components/MenuLeftNivel3dynamic';
import Footer from '../Components/Footer';

import NotFound from './Notfoundpage';
import useToken from '../hooks/useToken';



const Views = () => {

const {token, setToken} = useToken();   

 
    if (!token) {
        return <Login setToken={setToken}  />
    }
 
   
    return (   
        <BrowserRouter> 
       
        <div className="App">
            <Layout>
                <MenuLeft />
                <Layout className="site-layout" style={{ height: "100vh" }}>
                    
                    <Routes>
                        <Route path='/' element={<Inipage />} />
                        <Route path='/Usuarios' element={<Usuarios />} />
                        <Route path='/Modulos' element={<Modulos />} />
                        
                        <Route path='/Clientes' element={<Clientes />} />
                        <Route path='/Postulantes' element={<Postulantes />}/>
                        <Route path='/Lista_Requerimientos' element={<Requerimientos/>}/>
                        <Route path='/Lista_Requerimientoscliente' element={<RequerimientosCliente/>}/>
                        <Route path='/Relojcontrol' element={<Relojcontrol />} />
                        <Route path='/CreacionTurnos' element={<CreacionTurnos/>}/>
                        <Route path='/CreacionAusencias' element={<CreacionAusencias/>}/>
                        <Route path='/CreacionVacaciones' element={<CreacionVacaciones/>}/>
                        <Route path='/CreacionContratado' element={<CreacionContratado/>}/>
                        <Route path='/ReportHextra' element={<ReportHextra/>}/>
                        <Route path='/ReportHextraResumen' element={<ReportHextraResumen/>}/>
                        
                        <Route path='*' element={<NotFound/>} />
                        
                    </Routes>
                    <Footer />    
                </Layout>
            </Layout>
        </div>   
       
        </BrowserRouter>
    );

}

export default Views
