import gql from "graphql-tag";



export const createPostulante = gql`
mutation createPostulante($Rut:String!,$Nombre:String!,$Id_Sexo:ID!, $Id_Nacionalidad:ID!, $Id_estado_civil:ID! 
                         $Fecha_nacimiento:String! , $Direccion:String , $id_comuna:String!, $Creado_por:String!){
  createPostulante(Rut: $Rut,
                    Nombre: $Nombre,
                    Id_Sexo: $Id_Sexo
                    Id_Nacionalidad: $Id_Nacionalidad
                    Id_estado_civil: $Id_estado_civil
                    Fecha_nacimiento:  $Fecha_nacimiento
                    Direccion: $Direccion
                    id_comuna: $id_comuna
                    Creado_por: $Creado_por)
      {
        success
        message
        Id_empleado
      }
    
}`;

export const createPostulanteEdu = gql`
mutation createPostulanteEdu($Id_empleado:ID!,$ultimo_nivel_cursado:ID!,$Inst_Educativa:String!, $Titulo:String,
                             $fecha_egreso:String, $Nota_egreso:String , $descripcion:String , $Creado_por:String!){
  createPostulanteEdu(Id_empleado: $Id_empleado
                      ultimo_nivel_cursado: $ultimo_nivel_cursado
                      Inst_Educativa: $Inst_Educativa
                      Titulo: $Titulo
                      fecha_egreso:  $fecha_egreso
                      Nota_egreso: $Nota_egreso
                      descripcion: $descripcion
                      Creado_por: $Creado_por)
      {
        success
        message
        
      }
    
}`;

export const createPostulanteLab = gql`
mutation createPostulanteLab($Id_empleado:ID!,$id_cargo:ID!,$tipo_empleo:ID!,$Nombre_empresa:String!, $Ubicacion:String,
                             $fecha_inicio:String!, $fecha_finalizacion:String,$cargo_actual:String , $descripcion_cargo:String , $Creado_por:String!){
  createPostulanteLab(id_empleado: $Id_empleado
                      id_cargo: $id_cargo
                      tipo_empleo: $tipo_empleo
                      Nombre_empresa: $Nombre_empresa
                      Ubicacion: $Ubicacion
                      fecha_inicio: $fecha_inicio
                      fecha_finalizacion:  $fecha_finalizacion
                      cargo_actual:$cargo_actual
                      descripcion_cargo: $descripcion_cargo
                      Creado_por: $Creado_por)
      {
        success
        message
        
      }
    
}`;

export const createPostulanteCert = gql`
mutation createPostulanteCert($Id_empleado:ID!,$Empresa_emisora:String!,$nombre_cert_licencia:String,$fecha_de_caducidad:String!, 
  $id_credencial_licencia:String!,$URL_credencial:String , $Creado_por:String!){
  createPostulanteCert(Id_empleado: $Id_empleado
                        Empresa_emisora: $Empresa_emisora
                        nombre_cert_licencia: $nombre_cert_licencia
                        fecha_de_caducidad: $fecha_de_caducidad
                        id_credencial_licencia: $id_credencial_licencia
                        URL_credencial: $URL_credencial
                        Creado_por: $Creado_por)
      {
        success
        message
        
      }
    
}`;

export const createPostulanteCont = gql`
mutation createPostulanteCont($Id_empleado:ID!,$Id_tipocontacto:ID!,$descripcion:String!,$Creado_por:String!){
  createPostulanteCont( Id_empleado: $Id_empleado
                        Id_tipocontacto: $Id_tipocontacto
                        descripcion: $descripcion
                        Creado_por: $Creado_por)

      {
        success
        message
      }
    
}`;

export const createPostulanteIdioma = gql`
mutation createPostulanteIdioma($Id_empleado:ID!,$id_idiomas:[ID!],$Creado_por:String!){
  createPostulanteIdioma(
                          id_empleado: $Id_empleado
                          id_idiomas: $id_idiomas
                          Creado_por: $Creado_por
                          )

      {
        success
        message
        
      }

     
    
}`;

export const updatePostulante = gql`
mutation updatePostulante($Id_empleado:ID!,$Rut:String!,$Nombre:String!,$Id_Sexo:ID!, $Id_Nacionalidad:ID!, $Id_estado_civil:ID! 
                         ,$Fecha_nacimiento:String! , $Direccion:String! , $id_comuna:String!, $Modificado_por:String!){
  updatePostulante(  Id_empleado: $Id_empleado,
                    Rut: $Rut,
                    Nombre: $Nombre,
                    Id_Sexo: $Id_Sexo
                    Id_Nacionalidad: $Id_Nacionalidad
                    Id_estado_civil: $Id_estado_civil
                    Fecha_nacimiento:  $Fecha_nacimiento
                    Direccion: $Direccion
                    id_comuna: $id_comuna
                    Modificado_por: $Modificado_por)
      {
        success
        message
      }
    
}`;

export const updatePostulanteEdu = gql`
mutation updatePostulanteEdu($Id_empleado:ID!,$Id_educacion:ID!,$ultimo_nivel_cursado:ID!,$Inst_Educativa:String!, $Titulo:String!,
                             $fecha_egreso:String!, $Nota_egreso:String! , $descripcion:String , $Creado_por:String!){
  updatePostulanteEdu(Id_empleado: $Id_empleado
                      Id_educacion:$Id_educacion
                      ultimo_nivel_cursado: $ultimo_nivel_cursado
                      Inst_Educativa: $Inst_Educativa
                      Titulo: $Titulo
                      fecha_egreso:  $fecha_egreso
                      Nota_egreso: $Nota_egreso
                      descripcion: $descripcion
                      Creado_por: $Creado_por)
      {
        success
        message
        
      }
    
}`;

export const updatePostulanteLab = gql`
mutation updatePostulanteLab($id_empleado:ID!,$id_exlaboral:ID!,$id_cargo:ID!,$tipo_empleo:ID!,$Nombre_empresa:String!, $Ubicacion:String!,
                             $fecha_inicio:String!, $fecha_finalizacion:String! , $descripcion_cargo:String , $Creado_por:String!){
  updatePostulanteLab(id_empleado: $id_empleado
                      id_exlaboral: $id_exlaboral
                      id_cargo: $id_cargo
                      tipo_empleo: $tipo_empleo
                      Nombre_empresa: $Nombre_empresa
                      Ubicacion: $Ubicacion
                      fecha_inicio: $fecha_inicio
                      fecha_finalizacion:  $fecha_finalizacion
                      descripcion_cargo: $descripcion_cargo
                      Creado_por: $Creado_por)
      {
        success
        message
        
      }
    
}`;

export const updatePostulanteCert = gql`
mutation updatePostulanteCert($Id_empleado:ID!,$id_cert_licencia:ID!,$Empresa_emisora:String!,$nombre_cert_licencia:String!,$fecha_de_caducidad:String!, 
  $id_credencial_licencia:String!,$URL_credencial:String! , $Creado_por:String!){
  updatePostulanteCert(Id_empleado: $Id_empleado
                        id_cert_licencia:$id_cert_licencia
                        Empresa_emisora: $Empresa_emisora
                        nombre_cert_licencia: $nombre_cert_licencia
                        fecha_de_caducidad: $fecha_de_caducidad
                        id_credencial_licencia: $id_credencial_licencia
                        URL_credencial: $URL_credencial
                        Creado_por: $Creado_por)
      {
        success
        message
        
      }
    
}`;

export const deletePostulante = gql`
mutation deletePostulante($Id_empleado:ID!, $Modificado_por:String!){
  deletePostulante(  Id_empleado: $Id_empleado,
                     Modificado_por: $Modificado_por)
      {
        success
        message
      }
    
}`;

export const deletePostulanteEdu = gql`
mutation deletePostulanteEdu($Id_empleado:ID!,$Id_educacion:ID! , $Creado_por:String!){
  deletePostulanteEdu(id_empleado: $Id_empleado
                      Id_educacion:$Id_educacion
                      Creado_por: $Creado_por)
      {
        success
        message
        
      }
    
}`;

export const deletePostulanteLab = gql`
mutation deletePostulanteLab($Id_empleado:ID!,$Id_exlaboral:ID! , $Creado_por:String!){
  deletePostulanteLab(id_empleado: $Id_empleado
                      id_exlaboral: $Id_exlaboral
                      Creado_por: $Creado_por)
      {
        success
        message
        
      }
    
}`;

export const deletePostulanteCert = gql`
mutation deletePostulanteCert($Id_empleado:ID!,$Id_cert_licencia:ID! , $Creado_por:String!){
  deletePostulanteCert(Id_empleado: $Id_empleado
                        id_cert_licencia:$Id_cert_licencia
                        Creado_por: $Creado_por)
      {
        success
        message
        
      }
    
}`;

export const deletePostulanteCont = gql`
mutation deletePostulanteCont($Id_empleado:ID!,$Id_contacto:ID! , $Creado_por:String!){
  deletePostulanteCont(Id_empleado: $Id_empleado
                        Id_contacto:$Id_contacto
                        Creado_por: $Creado_por)
      {
        success
        message
        
      }
    
}`;

export const deletePostulanteIdioma = gql`
mutation deletePostulanteIdioma($Id_empleado:ID!,$Id_idiomas:ID! , $Creado_por:String!){
  deletePostulanteIdioma(id_empleado: $Id_empleado
                        id_idiomas:$Id_idiomas
                        Creado_por: $Creado_por)
      {
        success
        message
        
      }
    
}`;

export const CreatePostulanteAdj = gql`
mutation CreatePostulanteAdj($Id_empleado:ID!,$Nombre:String!,
                        $Descripcion:String!,$adjunto:[String]!,$creado_por:String!,){
  CreatePostulanteAdj(
    Id_empleado:$Id_empleado
    Nombre: $Nombre
    Descripcion: $Descripcion
    adjunto: $adjunto
    creado_por: $creado_por
  )
  {
   success
   message
  }
    
}`;















