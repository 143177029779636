import React,{useState}  from 'react'
import { Modal,Card } from 'antd';
import moment from 'moment'
import {  format } from 'rut.js'
import '../../Assets/Css/page.css';

import SearchPostEmpleado from './SearchPostEmpleado'; 
import TableSelempleado from './TableSelempleado';
import TableEmpleados from './TableEmpleados';
import ModalPostulante from '../../Components/Postulaciones/ModalPostulante';
import{ useEmpleadosByFilter,useCreatePostulante,useCreatePostulanteEdu,useCreatePostulanteLab,
        useCreatePostulanteCert,useCreatePostulanteCont,useCreatePostulanteIdioma,useCreatePostulanteAdj } from '../../hooks/custom-Empleados';

import {useInsertPostDet} from '../../hooks/custom-Postulaciones';

const ModalPostulantes = ({PostVisible,dataSelClie,handleRefreshsel,handleCancelPostEmp, SetdataEmp,DataEmp,handleSelPost, dataDetSelect,HandleEditEstDet,HandleEditPostAdjDet,handleDeletepost,HandlePostCardDet,creadoPor}) => {
const[Setempleados] = useEmpleadosByFilter();  
const [SetCreatePostulante] = useCreatePostulante();
const [SetCreatePostulanteEdu] = useCreatePostulanteEdu();
const [SetCreatePostulanteLab] = useCreatePostulanteLab();
const [SetCreatePostulanteCert] = useCreatePostulanteCert();
const [SetCreatePostulanteCont] = useCreatePostulanteCont();
const [SetCreatePostulanteIdioma] = useCreatePostulanteIdioma();  
const [SetCreatePostulanteAdj] = useCreatePostulanteAdj();
const [DataPostDet] = useInsertPostDet();
const [activeTabKey1, setActiveTabKey1] = useState('Ver');
const [Loading, setLoading] = useState(false);
const [Editview,SetEditview] = useState(false);
const [newpostulantname, setNewpostulantname] = useState(false);
   const [newpostulant, setNewpostulant] = useState(0);
    
  
    const tabList = [
        {
            key: 'Ver',
            tab: 'Postulantes',
        },
        {
            key: 'Buscar',
            tab: 'Buscar Postulantes',
        },
    ];
     
    const HandleDataEmp = async (values) => {
        let Rut='';
        if(values.Rut!==''){
             Rut=format(values.Rut);
        }
        const empleado = await  Setempleados({variables:{
            Rut: Rut,
            Nombre:values.Nombre,
            Id_sexo: values.Sexo,
            Id_nacionalidad: values.Nacionalidad,
            Id_estado_civil: values.Estado_civil,
            Id_cargo: values.Cargo,
            edad: "",
            experiencia: "",
            id_idioma: []
          
        }})
 
     
      
        if(empleado.loading===false){
          
            SetdataEmp(empleado.data.GetEmpleadosByFilter)
           
        }
       

    };

     const handleNewPostulant = async (values) => {
           
        const CreateResult = await  SetCreatePostulante({variables:{
            Rut: format(values.Rut),
            Nombre:values.NombrePostulante,
            Id_Sexo: values.Sexo,
            Id_Nacionalidad: values.Nacionalidad,
            Id_estado_civil: values.EstCivilModal,
            Fecha_nacimiento: moment(values.FechaNacimiento).format('YYYY/MM/DD'),
            Direccion: values.Direccion ,
            id_comuna: values.Comuna,
            Creado_por: creadoPor,

        
        }})
       
        setLoading(true);
        const success = CreateResult.data.createPostulante.success
        const message = CreateResult.data.createPostulante.message
        setNewpostulant(CreateResult.data.createPostulante.Id_empleado)
        let Id_empleado = CreateResult.data.createPostulante.Id_empleado
        if(success){   
            setNewpostulantname(values.NombrePostulante);
           //console.log(Id_empleado,dataSelClie.id_postulacion_cab,creadoPor)
            const Result = await  DataPostDet({variables:{
                Id_empleado: Id_empleado,
                id_postulacion_cab: dataSelClie.id_postulacion_cab,
                creado_por: creadoPor, 
            }})
        
        
            const successsel = Result.data.createPostDet.success
            const messagesel = Result.data.createPostDet.message

            if(successsel){ 
                Modal.success({
                    content: messagesel,
                });
            
                setTimeout(() => {
                    setLoading(false);
                }, 3000);

                return handleRefreshsel(dataSelClie.id_postulacion_cab)
            }else{
                Modal.warning({
                    content: message,
                }); 
                setLoading(false);

            }
        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }

    };

    const handleNewPostEduc = async (values) => {
            // console.log(values)
        const CreateResult = await  SetCreatePostulanteEdu({variables:{
            Id_empleado: newpostulant,
            ultimo_nivel_cursado:values.NivelCursado, 
            Inst_Educativa: values.InstEducativa,
            Titulo: values.Titulo,
            fecha_egreso: moment(values.FechaEgreso).format('YYYY/MM/DD'),
            Nota_egreso: values.NotaEgreso,
            descripcion: values.descripcion,
            Creado_por: creadoPor,

        
        }})
       
        setLoading(true);
        const success = CreateResult.data.createPostulanteEdu.success
        const message = CreateResult.data.createPostulanteEdu.message
        if(success){   
            Modal.success({
                 content: message,
            });

            setTimeout(() => {
               setLoading(false);
               
           }, 1000);

        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }


    };

    const handleNewPostLab = async (values) => {
        let CargoActual = 'false'    
        if(values.CargoActual!==undefined){
            CargoActual =values.CargoActual.toString()
        }
        const CreateResult = await  SetCreatePostulanteLab({variables:{
            Id_empleado: newpostulant,
            id_cargo: values.Cargo,
            tipo_empleo: values.TipoEmpleo,
            Nombre_empresa: values.NombreEmpresa,
            Ubicacion: values.DireccionEmp,
            fecha_inicio: moment(values.FechaInicio).format('YYYY/MM/DD'),
            fecha_finalizacion: moment(values.FechaTermino).format('YYYY/MM/DD'),
            cargo_actual:CargoActual,
            descripcion_cargo: values.DescripcionCargo,
            Creado_por: creadoPor,

        
        }})
       
        setLoading(true);
        const success = CreateResult.data.createPostulanteLab.success
        const message = CreateResult.data.createPostulanteLab.message
        if(success){   
            Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
               
           }, 1000);

        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }

    };

    const handleNewPostCert = async (values) => {
            // console.log(values)
        const CreateResult = await  SetCreatePostulanteCert({variables:{
            Id_empleado: newpostulant,
            Empresa_emisora: values.EmpresaEmisora,
            nombre_cert_licencia: values.NombreCertificado,
            fecha_de_caducidad: moment(values.FechaCaducidad).format('YYYY/MM/DD'),
            id_credencial_licencia: values.IdCredLicencia,
            URL_credencial: values.URLCredencial,
            Creado_por: creadoPor,

        
        }})
       
        setLoading(true);
        const success = CreateResult.data.createPostulanteCert.success
        const message = CreateResult.data.createPostulanteCert.message
        if(success){   
            Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
               
           }, 1000);

        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }

    };

    const handleNewPostIdioma = async (values) => {
            // console.log(values)
        const CreateResult = await  SetCreatePostulanteIdioma({variables:{
            Id_empleado: newpostulant,
            id_idiomas:values.Idiomas,
            Creado_por: creadoPor,

        
        }})
       
        setLoading(true);
        const success = CreateResult.data.createPostulanteIdioma.success
        const message = CreateResult.data.createPostulanteIdioma.message
        if(success){   
            Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
               
           }, 1000);

        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }
    };
    
    const handleNewPostContacto = async (values) => {
       // console.log(values)
        const CreateResult = await  SetCreatePostulanteCont({variables:{
            Id_empleado: newpostulant,
            Id_tipocontacto: values.TipoContacto2,
            descripcion: values.Descripcion2,
            Creado_por: creadoPor,

        
        }})
       
        setLoading(true);
        const success = CreateResult.data.createPostulanteCont.success
        const message = CreateResult.data.createPostulanteCont.message
        if(success){   
            Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
               
           }, 1000);

        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }

    };

    const handleSaveAdjPost = async (values,Arrfilename) => {
     
        setLoading(true);
        const valor = Arrfilename.map((item) => item.filename);

            const InsertAdj = await SetCreatePostulanteAdj({variables:{
                Id_empleado: newpostulant,
                Nombre:values.Nombre,
                Descripcion: values.Descripcion,
                adjunto:  valor,
                creado_por: creadoPor,
        
            }})
       
         
            const success = InsertAdj.data.CreatePostulanteAdj.success
            const message = InsertAdj.data.CreatePostulanteAdj.message
            if(success){   
             
                Modal.success({
                   content: message,
                });

                setTimeout(() => {
                    setLoading(false);  
                }, 1000);

            }
            else{
                Modal.warning({
                    content: message,
                });
                setLoading(false);
            }   
    };

    const handleCancelPostulant = async (values) => {
        SetEditview(false);
    };

    const handleviewnewpostulant = async (values) => {
        SetEditview(true);
    };

    const onTab1Change = key => {
        setActiveTabKey1(key);
    };


    const contentList = {
        Ver:<div>
            <TableEmpleados dataDetSelect={dataDetSelect} HandleEditEstDet={HandleEditEstDet} HandleEditPostAdjDet={HandleEditPostAdjDet} handleDeletepost={handleDeletepost} HandlePostCardDet={HandlePostCardDet}  />
        </div>,
        Buscar:<div>
            <SearchPostEmpleado HandleDataEmp={HandleDataEmp}  SetdataEmp={SetdataEmp} handleviewnewpostulant={handleviewnewpostulant}/>
            <TableSelempleado DataEmp={DataEmp} handleSelPost={handleSelPost}  />
        </div>,
    };


    return (
        <Modal
            width="85%"
            visible={PostVisible}
            style={{ top: 20 }}
            title="Postulantes"
            onCancel={handleCancelPostEmp}
            footer={[
                
               
            ]}
        >

        <Card
            style={{ width: '100%' }}
            bordered={false} 
            tabList={tabList}
            activeTabKey={activeTabKey1}
            onTabChange={key => {
            onTab1Change(key);
        }}
      >
        {contentList[activeTabKey1]}
      </Card>

        
        <ModalPostulante Editview={Editview} newpostulantname={newpostulantname}  Loading={Loading}  handleCancelPostulant={handleCancelPostulant}  handleNewPostulant={handleNewPostulant}  handleNewPostEduc={handleNewPostEduc}  handleNewPostCert={handleNewPostCert}  handleNewPostLab={handleNewPostLab}  handleNewPostIdioma={handleNewPostIdioma} handleNewPostContacto={handleNewPostContacto} handleSaveAdjPost={handleSaveAdjPost}  />
             
        </Modal>
        
    )
}

export default ModalPostulantes
