import React,{useState,useEffect} from 'react'
import { Modal,Space,Button,Tabs, PageHeader, Descriptions,Divider,Avatar,List,Tooltip} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import '../../Assets/Css/page.css';
import ModalEditPost from './ModalEditPost';
import ModalEduAdd from './ModalEduAdd';
import ModalLabAdd from './ModalLabAdd';
import ModalCertAdd  from './ModalCertAdd';
import ModalidiomaAdd  from './ModalidiomaAdd';
import ModalContactoAdd  from './ModalContactoAdd';
import ModalEditEducacion from './ModalEditEducacion';
import ModalEditLaboral from './ModalEditLaboral';
import ModalEditCert from './ModalEditCert';
import  {   useEmpleadoEduById,useEmpleadoLabById,useEmpleadoCertById,useEmpleadoIdiomaById,
            useEmpleContactosById,useUpdatePostulanteEdu,useUpdatePostulanteLab,
            useUpdatePostulanteCert,useDeletePostulanteEdu,useDeletePostulanteLab,
            useDeletePostulanteCert, useDeletePostulanteCont,useDeletePostulanteIdioma,
            useCreatePostulanteEdu,useCreatePostulanteLab,useCreatePostulanteCert,
            useCreatePostulanteCont,useCreatePostulanteIdioma} from '../../hooks/custom-Empleados';
   
import moment from 'moment'   
const { TabPane } = Tabs;


const ModalPostulanteView = ({ viewdataPost, handleEditPost,handleCancelPostView,DataPostulant,creadoPor}) => {
    
    const [ViewPostulanteEdu] = useEmpleadoEduById();
    const [ViewPostulanteLab] = useEmpleadoLabById();
    const [ViewPostulanteCert] = useEmpleadoCertById();
    const [ViewPostulanteCont] = useEmpleContactosById();
    const [ViewPostulanteIdioma] = useEmpleadoIdiomaById();

    const [SetCreatePostulanteEdu] = useCreatePostulanteEdu();
    const [SetCreatePostulanteLab] = useCreatePostulanteLab();
    const [SetCreatePostulanteCert] = useCreatePostulanteCert();
    const [SetCreatePostulanteCont] = useCreatePostulanteCont();
    const [SetCreatePostulanteIdioma] = useCreatePostulanteIdioma();
    
    const [SetUpdatePostulanteEdu] = useUpdatePostulanteEdu();
    const [SetUpdatePostulanteLab] = useUpdatePostulanteLab();
    const [SetUpdatePostulanteCert] = useUpdatePostulanteCert();
    const [SetDeletePostulanteEdu] = useDeletePostulanteEdu();
    const [SetDeletePostulanteLab] = useDeletePostulanteLab();
    const [SetDeletePostulanteCert] = useDeletePostulanteCert();
    const [SetDeletePostulanteCont] = useDeletePostulanteCont();
    const [SetDeletePostulanteIdi] = useDeletePostulanteIdioma();
    const [viewEditPost, setViewEditPost] = useState(false);
    const [viewEditEdu, setViewEditEdu] = useState(false);
    const [viewEditLab, setViewEditLab] = useState(false);
    const [viewEditCert, setViewEditCert] = useState(false);
    const [viewEduAdd, setViewEduAdd] = useState(false);
    const [viewLabAdd, setViewLabAdd] = useState(false);
    const [viewCertAdd, setViewCertAdd] = useState(false);
    const [viewIdiomaAdd, setViewIdiomaAdd] = useState(false);
    const [viewContactoAdd, setViewContactoAdd] = useState(false);
    
    
    const [Loading, setLoading] = useState(false);
    const [EditDataPost, setEditDataPost] = useState([]);
    const [EditDataEdu, setEditDataEdu] = useState([]);
    const [EditDataLab, setEditDataLab] = useState([]);
    const [EditDataCert, setEditDataCert] = useState([]);
    
    
    
    const [dataEduView, setDataEduView] = useState([]);
    const [dataLabView, setDataLabView] = useState([]);
    const [dataIdiView, setDataIdiView] = useState([]);
    const [dataContView, setDataContView] = useState([]);
    const [dataCertView,setDataCertView] = useState([]);
    const [postulant,setPostulant] = useState([]);

   

     useEffect(()=>{

       

        async function getDataViewAll(){
        
            if(DataPostulant.Id_empleado!==undefined){
                setPostulant(DataPostulant.Id_empleado);

                const empleadoEduView = await  ViewPostulanteEdu({variables:{
                    id_empleado: DataPostulant.Id_empleado,
                }})
                
                
                if(empleadoEduView.loading===false){
                    setDataEduView(empleadoEduView.data.GetempleadoEduById)          
                } 

                const empleadoLabView = await  ViewPostulanteLab({variables:{
                    id_empleado: DataPostulant.Id_empleado,
                }})
                

               
                if(empleadoLabView.loading===false){
                    setDataLabView(empleadoLabView.data.GetempleadolabById)
                
                } 

               const empleadoCertView = await  ViewPostulanteCert({variables:{
                    id_empleado:DataPostulant.Id_empleado,
                }})
                
                if(empleadoCertView.loading===false){
                setDataCertView(empleadoCertView.data.GetempleadoCertById)
                
                } 
                
                const empleadoContView = await  ViewPostulanteCont({variables:{
                    id_empleado: DataPostulant.Id_empleado,
                }})
                
                if(empleadoContView.loading===false){
                   
                    setDataContView(empleadoContView.data.GetContactosById)
                
                } 
     
                const empleadoIdiView = await  ViewPostulanteIdioma({variables:{
                    id_empleado: DataPostulant.Id_empleado,
                }})
           
                if(empleadoIdiView.loading===false){
                      // console.log(empleadoIdiView.data.GetempleadoidiomaById)
                    setDataIdiView(empleadoIdiView.data.GetempleadoidiomaById)
                
                }
                 
            }
        }
        getDataViewAll()

    },[DataPostulant, ViewPostulanteCert, ViewPostulanteCont, ViewPostulanteEdu, ViewPostulanteIdioma, ViewPostulanteLab])
   
    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;
    };
   
   
    const onAgreePost = (record) => {
      // // console.log(record)
        setEditDataPost(record)
        setViewEditPost(true)
    }

    const onAgreeEdu = (record) => {
      // // console.log(record)
        setEditDataEdu(record)
        setViewEditEdu(true)
    }

    const onAgreeLab = (record) => {
      // console.log(record)
        setEditDataLab(record)
        setViewEditLab(true)
    }

    const onAgreeCert = (record) => {
       // console.log(record)
        setEditDataCert(record)
        setViewEditCert(true)
    }

    const onDeleteEdu = (record) => {
       // console.log(record)
        let mensaje=''
        mensaje = 'Estas Seguro de Eliminar Nivel cursado ' +record.nivel_cursado+ ' ..??'
       

        Modal.confirm({
           title: mensaje,
            
           onOk:async()=>{
            
            const BloqResult = await  SetDeletePostulanteEdu({variables:{
                Id_empleado:record.Id_empleado,
                Id_educacion:record.Id_educacion,
                Creado_por: creadoPor,
               
            }})
        
           
            const success = BloqResult.data.deletePostulanteEdu.success
            const message = BloqResult.data.deletePostulanteEdu.message
           //// console.log(success)
            if(success){   
                handleSelEmpleEdu(record)
            const  modal = Modal.success({
                    content: message,
                });

                setTimeout(() => {
                    modal.destroy();
                }, 3000);

            }else{
                Modal.warning({
                    content: message,
                });
               
                
            
            }  
        }   
       })
        //return onViewAdj(record);
    }

    const onDeleteLab = (record) => {
       // console.log(record)
        let mensaje=''
        mensaje = 'Estas Seguro de Eliminar el Cargo ' +record.cargo+ ' ..??'
       

        Modal.confirm({
           title: mensaje,
            
           onOk:async()=>{
            
            const deletelab = await  SetDeletePostulanteLab({variables:{
                Id_empleado:record.id_empleado,
                Id_exlaboral:record.id_exlaboral,
                Creado_por: creadoPor,
               
            }})
        
           
            const success = deletelab.data.deletePostulanteLab.success
            const message = deletelab.data.deletePostulanteLab.message
           //// console.log(success)
            if(success){   
                handleSelEmpleLab(record)
                const  modal = Modal.success({
                    content: message,
                });

                setTimeout(() => {
                    modal.destroy();
                }, 3000);

            }else{
                Modal.warning({
                    content: message,
                });
               
                
            
            }  
        }   
       })
        //return onViewAdj(record);
    }

    const onDeleteCert = (record) => {
       // console.log(record)
        let mensaje=''
        mensaje = 'Estas Seguro de Eliminar la certificación ' +record.nombre_cert_licencia+ ' ..??'
       

        Modal.confirm({
           title: mensaje,
            
           onOk:async()=>{
            
            const deletecert = await  SetDeletePostulanteCert({variables:{
                Id_empleado:record.Id_empleado,
                Id_cert_licencia:record.id_cert_licencia,
                Creado_por: creadoPor,
               
            }})
        
           
            const success = deletecert.data.deletePostulanteCert.success
            const message = deletecert.data.deletePostulanteCert.message
           //// console.log(success)
            if(success){   
                handleSelEmpleCert(record)
            const  modal = Modal.success({
                    content: message,
                });

                setTimeout(() => {
                    modal.destroy();
                }, 3000);

            }else{
                Modal.warning({
                    content: message,
                });
               
                
            
            }  
        }   
       })
        //return onViewAdj(record);
    }

    const onDeleteCont = (record) => {
       // console.log(record)
        let mensaje=''
        mensaje = 'Estas Seguro de Eliminar Contacto ' +record.title+ ' ..??'
       

        Modal.confirm({
           title: mensaje,
            
           onOk:async()=>{
            
            const deletecont = await  SetDeletePostulanteCont({variables:{
                Id_empleado:record.Id_empleado,
                Id_contacto:record.Id_tipocontacto,
                Creado_por: creadoPor,
               
            }})
        
           
            const success = deletecont.data.deletePostulanteCont.success
            const message = deletecont.data.deletePostulanteCont.message
           //// console.log(success)
            if(success){   
                handleSelEmpleCont(record)
            const  modal = Modal.success({
                    content: message,
                });

                setTimeout(() => {
                    modal.destroy();
                }, 3000);

            }else{
                Modal.warning({
                    content: message,
                });
               
                
            
            }  
        }   
       })
        //return onViewAdj(record);
    }

    const onDeleteIdi = (record) => {
       // console.log(record)
        let mensaje=''
        mensaje = 'Estas Seguro de Eliminar Idioma ' +record.idioma+ ' ..??'
       

        Modal.confirm({
           title: mensaje,
            
           onOk:async()=>{
            
            const deleteidioma = await  SetDeletePostulanteIdi({variables:{
                Id_empleado:record.id_empleado,
                Id_idiomas:record.id_idiomas,
                Creado_por: creadoPor,
               
            }})
        
           
            const success = deleteidioma.data.deletePostulanteIdioma.success
            const message = deleteidioma.data.deletePostulanteIdioma.message
           //// console.log(success)
            if(success){   
                handleSelEmpleIdi(record)
            const  modal = Modal.success({
                    content: message,
                });

                setTimeout(() => {
                    modal.destroy();
                }, 3000);

            }else{
                Modal.warning({
                    content: message,
                });
               
                
            
            }  
        }   
       })
        //return onViewAdj(record);
    }

    const onAddEdu = async () => {
      
        setViewEduAdd(true)
    };

    const onAddLab = async () => {
      
        setViewLabAdd(true)
    };

    const onAddCert = async () => {
      
        setViewCertAdd(true)
    };

    const onAddIdioma = async () => {
      
        setViewIdiomaAdd(true)
    };

    const onAddContacto = async () => {
      
        setViewContactoAdd(true)
    };


    const handleCancelPostulant = async (values) => {
       
         setViewEditPost(false)
    };

    const handleCancelEducacion = async (values) => {
      
         setViewEditEdu(false)
    };

    const handleCancelEduAdd = async (values) => {
      
         setViewEduAdd(false)
    };

    const handleCancelLabAdd = async (values) => {
      
         setViewLabAdd(false)
    };

    const handleCancelCertAdd = async (values) => {
      
         setViewCertAdd(false)
    };

    const handleCancelIdiomaAdd = async (values) => {
      
         setViewIdiomaAdd(false)
    };

    const handleCancelContactoAdd = async (values) => {
      
         setViewContactoAdd(false)
    };

    const handleCancelLaboral = async (values) => {
         setViewEditLab(false)
    };

    const handleCancelCert = async (values) => {
         setViewEditCert(false)
    };

    const handleSavePost = async (values) => {
          return handleEditPost(values,EditDataPost)
    };

    const handleSavePostEdu = async (values) => {
         //console.log(values)
          const UpdateResultEdu = await  SetUpdatePostulanteEdu({variables:{
            Id_empleado:EditDataEdu.Id_empleado,
            Id_educacion:EditDataEdu.Id_educacion,
            ultimo_nivel_cursado:values.NivelCursado, 
            Inst_Educativa: values.InstEducativa,
            Titulo: values.Titulo,
            fecha_egreso: moment(values.FechaEgreso).format('YYYY/MM/DD'),
            Nota_egreso: values.NotaEgreso,
            descripcion: values.Comentarios,
            Creado_por: creadoPor,

        
        }})
       
        setLoading(true);
        const success = UpdateResultEdu.data.updatePostulanteEdu.success
        const message = UpdateResultEdu.data.updatePostulanteEdu.message
        if(success){   
            handleSelEmpleEdu(EditDataEdu)
            Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
               
           }, 3000);

        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }
    };

    const handleSavePostLab = async (values) => {
        // console.log(EditDataLab)
         const EditResultLab = await  SetUpdatePostulanteLab({variables:{
            id_empleado: EditDataLab.id_empleado,
            id_exlaboral: EditDataLab.id_exlaboral,
            id_cargo: values.Cargo,
            tipo_empleo: values.TipoEmpleo,
            Nombre_empresa: values.NombreEmpresa,
            Ubicacion: values.DireccionEmp,
            fecha_inicio: moment(values.FechaInicio).format('YYYY/MM/DD'),
            fecha_finalizacion: moment(values.FechaTermino).format('YYYY/MM/DD'),
            descripcion_cargo: values.DescripcionCargo,
            Creado_por: creadoPor,

        
        }})
       
        setLoading(true);
        const success = EditResultLab.data.updatePostulanteLab.success
        const message = EditResultLab.data.updatePostulanteLab.message
        if(success){   
            handleSelEmpleLab(EditDataLab)
           Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
               
           }, 3000);

        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }
    };

    const handleSavePostCert = async (values) => {
        // console.log(values)
         const EditResultCert = await  SetUpdatePostulanteCert({variables:{
            Id_empleado: EditDataCert.Id_empleado,
            id_cert_licencia: EditDataCert.id_cert_licencia,
            Empresa_emisora: values.EmpresaEmisora,
            nombre_cert_licencia: values.NombreCertificado,
            fecha_de_caducidad: moment(values.FechaCaducidad).format('YYYY/MM/DD'),
            id_credencial_licencia: values.IdCredLicencial,
            URL_credencial: values.URLCredencial,
            Creado_por: creadoPor,

        
        }})
       
        setLoading(true);
        const success = EditResultCert.data.updatePostulanteCert.success
        const message = EditResultCert.data.updatePostulanteCert.message
        if(success){  
            handleSelEmpleCert(EditDataCert) 
            Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
               
           }, 3000);

        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }
    };

    const handleSelEmpleEdu = async (values) =>{
        const SelempleadoEduView = await  ViewPostulanteEdu({variables:{
                    id_empleado: values.Id_empleado,
        }})
        
        
        if(SelempleadoEduView.loading===false){
            setDataEduView(SelempleadoEduView.data.GetempleadoEduById)          
        }
    }

    const handleSelEmpleLab = async (values) =>{
        const empleadoLabView = await  ViewPostulanteLab({variables:{
                    id_empleado: DataPostulant.Id_empleado,
        }})
        

        
        if(empleadoLabView.loading===false){
            setDataLabView(empleadoLabView.data.GetempleadolabById)
        
        } 
    }

    const handleSelEmpleCert = async (values) =>{
        const empleadoCertView = await  ViewPostulanteCert({variables:{
                    id_empleado:DataPostulant.Id_empleado,
        }})
        
        if(empleadoCertView.loading===false){
        setDataCertView(empleadoCertView.data.GetempleadoCertById)
        
        } 
    }

    const handleSelEmpleCont = async (values) =>{
        const empleadoContView = await  ViewPostulanteCont({variables:{
            id_empleado: DataPostulant.Id_empleado,
        }})
        
        if(empleadoContView.loading===false){
            setDataContView(empleadoContView.data.GetContactosById)
        
        } 
    }

    const handleSelEmpleIdi = async (values) =>{
        const empleadoIdiView = await  ViewPostulanteIdioma({variables:{
            id_empleado: DataPostulant.Id_empleado,
        }})
    
        if(empleadoIdiView.loading===false){
            setDataIdiView(empleadoIdiView.data.GetempleadoidiomaById)
        
        }
    }

    const handleAddPostEduc = async (values) => {
             
        const CreateResult = await  SetCreatePostulanteEdu({variables:{
            Id_empleado: postulant,
            ultimo_nivel_cursado:values.NivelCursado, 
            Inst_Educativa: values.InstEducativa,
            Titulo: values.Titulo,
            fecha_egreso: moment(values.FechaEgreso).format('YYYY/MM/DD'),
            Nota_egreso: values.NotaEgreso,
            descripcion: values.descripcion,
            Creado_por: creadoPor,

        
        }})
       
        setLoading(true);
        const success = CreateResult.data.createPostulanteEdu.success
        const message = CreateResult.data.createPostulanteEdu.message
        if(success){   

            const empleadoEduView = await  ViewPostulanteEdu({variables:{
                    id_empleado: postulant,
            }})

            if(empleadoEduView.loading===false){
                setDataEduView(empleadoEduView.data.GetempleadoEduById)          
            } 
            

            setTimeout(() => {
                Modal.success({
                    content: message,
                });
                 setLoading(false);
           }, 3000);

        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }


    };
    
    const handleAddPostLab = async (values) => {
           
        let CargoActual = 'false'    
        if(values.CargoActual!==undefined){
            CargoActual =values.CargoActual.toString()
        }
        const CreateResult = await  SetCreatePostulanteLab({variables:{
            Id_empleado: postulant,
            id_cargo: values.Cargo,
            tipo_empleo: values.TipoEmpleo,
            Nombre_empresa: values.NombreEmpresa,
            Ubicacion: values.DireccionEmp,
            fecha_inicio: moment(values.FechaInicio).format('YYYY/MM/DD'),
            fecha_finalizacion: moment(values.FechaTermino).format('YYYY/MM/DD'),
            cargo_actual:CargoActual,
            descripcion_cargo: values.DescripcionCargo,
            Creado_por: creadoPor,

        
        }})
       
        setLoading(true);
        const success = CreateResult.data.createPostulanteLab.success
        const message = CreateResult.data.createPostulanteLab.message
        if(success){   
            const empleadoLabView = await  ViewPostulanteLab({variables:{
                    id_empleado: DataPostulant.Id_empleado,
            }})

            if(empleadoLabView.loading===false){
                setDataLabView(empleadoLabView.data.GetempleadolabById)
            
            } 

            setTimeout(() => {
                Modal.success({
                    content: message,
                });
                 setLoading(false);
           }, 3000);


        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }

    };
  
    const handleAddPostCert = async (values) => {
            // console.log(values)
        const CreateResult = await  SetCreatePostulanteCert({variables:{
            Id_empleado: postulant,
            Empresa_emisora: values.EmpresaEmisora,
            nombre_cert_licencia: values.NombreCertificado,
            fecha_de_caducidad: moment(values.FechaCaducidad).format('YYYY/MM/DD'),
            id_credencial_licencia: values.IdCredLicencia,
            URL_credencial: values.URLCredencial,
            Creado_por: creadoPor,

        
        }})
       
        setLoading(true);
        const success = CreateResult.data.createPostulanteCert.success
        const message = CreateResult.data.createPostulanteCert.message
        if(success){   
            const empleadoCertView = await  ViewPostulanteCert({variables:{
                    id_empleado:DataPostulant.Id_empleado,
            }})
            
            if(empleadoCertView.loading===false){
            setDataCertView(empleadoCertView.data.GetempleadoCertById)
            
            } 

            setTimeout(() => {
                Modal.success({
                    content: message,
                });
                 setLoading(false);
           }, 3000);


        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }

    };

    const handleAddPostIdioma = async (values) => {
    
            // console.log(values)
        const CreateResult = await  SetCreatePostulanteIdioma({variables:{
            Id_empleado: postulant,
            id_idiomas:values.Idiomas,
            Creado_por: creadoPor,

        
        }})
       
        setLoading(true);
        const success = CreateResult.data.createPostulanteIdioma.success
        const message = CreateResult.data.createPostulanteIdioma.message
        if(success){   
           
            const empleadoIdiView = await  ViewPostulanteIdioma({variables:{
                id_empleado: DataPostulant.Id_empleado,
            }})
        
            if(empleadoIdiView.loading===false){
                setDataIdiView(empleadoIdiView.data.GetempleadoidiomaById)
            }

            setTimeout(() => {
                Modal.success({
                    content: message,
                });
                setLoading(false);
               
           }, 1000);

        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }
    };

    const handleAddPostContacto = async (values) => {
       // console.log(values)
        const CreateResult = await  SetCreatePostulanteCont({variables:{
            Id_empleado: postulant,
            Id_tipocontacto: values.TipoContacto2,
            descripcion: values.Descripcion2,
            Creado_por: creadoPor,

        
        }})
       
        setLoading(true);
        const success = CreateResult.data.createPostulanteCont.success
        const message = CreateResult.data.createPostulanteCont.message
        if(success){   

            const empleadoContView = await  ViewPostulanteCont({variables:{
                id_empleado: DataPostulant.Id_empleado,
            }})
            
            if(empleadoContView.loading===false){
                setDataContView(empleadoContView.data.GetContactosById)
            } 
            
            setTimeout(() => {
                Modal.success({
                    content: message,
                });
                setLoading(false);
               
           }, 3000);

        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }

    };

    return (
        <>
        <Modal
            visible={viewdataPost}
            title={'Postulante'}
            style={{ top: 20 }}
            width={'100vh'}
            onCancel={handleCancelPostView}
            footer={[]}
        >
        <Tabs defaultActiveKey="1" destroyInactiveTabPane={true} >
            <TabPane tab="Postulantes" key="1">
                <PageHeader
                    title={DataPostulant.Nombre}
                    className="site-page-header"
                   // subTitle="Rut: {{DataPostulant.Rut}} " 
                   
                    avatar={{src:<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="UserOutlined" key="handlehead2" />} />}}
                  
                >
                <div
                        id="scrollableDiv"
                        style={{
                            height: 200,
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                    <InfiniteScroll
                        dataLength="1"
                        //endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                        <p></p>
                        <Descriptions  size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                            extra={[<Button  onClick={() => { onAgreePost(DataPostulant) }}  icon={<Icon type='EditOutlined' />}/> ]}>
                        <Descriptions.Item label="Rut">{DataPostulant.Rut}</Descriptions.Item>
                    
                        <Descriptions.Item label="Edad">{DataPostulant.edad}</Descriptions.Item>
                        <Descriptions.Item label="Estado Civil">{DataPostulant.Estado_civil}</Descriptions.Item>
                        <Descriptions.Item label="Genero">{DataPostulant.Sexo}</Descriptions.Item>
                        <Descriptions.Item label="Nacionalidad">{DataPostulant.Nacionalidad}</Descriptions.Item>
                        <Descriptions.Item label="Comuna">{DataPostulant.comuna_nombre}</Descriptions.Item>
                        <Descriptions.Item label="Dirección">{DataPostulant.Rut}</Descriptions.Item>
                    </Descriptions>
                    </InfiniteScroll>
                </div>    
                </PageHeader>
            </TabPane>
            <TabPane tab="Educación" key="2">
                <PageHeader
                    
                    title={DataPostulant.Nombre}
                    className="site-page-header"
                // subTitle="Rut: {{DataPostulant.Rut}} "
                    
                    avatar={{src:<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="UserOutlined" key="handlehead2" />} />}}
                      extra={[<Tooltip title="Nuevo" color={'blue'} key={'blue'}><Button  onClick={() => { onAddEdu() }}  icon={<Icon type='FormOutlined' />}/></Tooltip> ]}
                >
                    <div
                        id="scrollableDiv"
                        style={{
                            height: 200,
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                    <InfiniteScroll
                        dataLength={dataEduView.length}
                        //endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                    {
                        dataEduView.map((item) =>(
                        <>
                        <p></p>
                        <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                            extra={[<Space><Tooltip title="Editar" color={'blue'} key={'blue'}><Button onClick={() => { onAgreeEdu(item) }} icon={<Icon type='EditOutlined' />}/></Tooltip>
                                    <Tooltip title="Eliminar" color={'blue'} key={'blue'}><Button onClick={() => { onDeleteEdu(item) }}  icon={<Icon type='DeleteOutlined' />}/></Tooltip></Space>,
                                    ]}>
                    
                                    
                            <Descriptions.Item label="Nivel Cursado">{item.nivel_cursado}</Descriptions.Item>
                            <Descriptions.Item label="Inst_educativa">{item.Inst_Educativa}</Descriptions.Item>
                            <Descriptions.Item label="Titulo">{item.Titulo}</Descriptions.Item>
                            <Descriptions.Item label="Fecha_egreso">{item.fecha_egreso}</Descriptions.Item>
                            <Descriptions.Item label="Nota_egreso">{item.Nota_egreso}</Descriptions.Item>
                            <Descriptions.Item label="descripcion">{item.descripcion}</Descriptions.Item>
                        
                            
                            

                        </Descriptions>
                        <Divider />
                        </>
                        ))
                        
                    }
                    </InfiniteScroll>
                    </div>
                </PageHeader>
                
            </TabPane>
            <TabPane tab="Laboral" key="3">
                <PageHeader
                    title={DataPostulant.Nombre}
                    className="site-page-header"
                // subTitle="Rut: {{DataPostulant.Rut}} "
                
                    avatar={{src:<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="UserOutlined" key="handlehead2" />} />}}
                    extra={[<Tooltip title="Nuevo" color={'blue'} key={'blue'}><Button  onClick={() => { onAddLab() }}  icon={<Icon type='FormOutlined' />}/></Tooltip> ]}
               
                >
                <div
                        id="scrollableDiv"
                        style={{
                            height: 200,
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                    <InfiniteScroll
                        dataLength={dataLabView.length}
                        //endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                    {
                        dataLabView.map((item) =>(
                        <>
                        <p></p>
                            <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                            extra={[<Space><Tooltip title="Editar" color={'blue'} key={'blue'}><Button onClick={() => { onAgreeLab(item) }}  icon={<Icon type='EditOutlined' />}/></Tooltip>
                                        <Tooltip title="Eliminar" color={'blue'} key={'blue'}><Button onClick={() => { onDeleteLab(item) }} icon={<Icon type='DeleteOutlined' />}/></Tooltip></Space>,
                                    ]}>
                            <Descriptions.Item label="Cargo">{item.cargo}</Descriptions.Item>
                            <Descriptions.Item label="Tipo Empleo">{item.empleo_tipo}</Descriptions.Item>
                            <Descriptions.Item label="Nombre empresa">{item.Nombre_empresa}</Descriptions.Item>
                            <Descriptions.Item label="Ubicacion">{item.Ubicacion}</Descriptions.Item>
                            <Descriptions.Item label="Fecha inicio">{item.fecha_inicio}</Descriptions.Item>
                            <Descriptions.Item label="Fecha finalizacion">{item.fecha_finalizacion}</Descriptions.Item>
                            <Descriptions.Item label="Descripcion Cargo">{item.descripcion_cargo}</Descriptions.Item>
                        
                            </Descriptions>
                            <Divider />
                        </>
                        ))
                        
                    }
                    </InfiniteScroll>
                </div>
                </PageHeader>   
            </TabPane>
            <TabPane tab="Certificados" key="4">
                <PageHeader
                    title={DataPostulant.Nombre}
                    className="site-page-header"
                // subTitle="Rut: {{DataPostulant.Rut}} "
                    
                    avatar={{src:<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="UserOutlined" key="handlehead2" />} />}}
                    extra={[<Tooltip title="Nuevo" color={'blue'} key={'blue'}><Button  onClick={() => { onAddCert() }}  icon={<Icon type='FormOutlined' />}/></Tooltip> ]}
             
                >
                <div
                        id="scrollableDiv"
                        style={{
                            height: 200,
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                    <InfiniteScroll
                        dataLength={dataCertView.length}
                        //endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                    {
                        dataCertView.map((item) =>(
                        <>
                        <p></p>
                        <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                        extra={[<Space><Button onClick={() => { onAgreeCert(item) }}  icon={<Icon type='EditOutlined' />}/>
                                <Button onClick={() => { onDeleteCert(item) }} icon={<Icon type='DeleteOutlined' />}/></Space>,
                            ]}>
                            <Descriptions.Item label="Emitido por">{item.Empresa_emisora}</Descriptions.Item>
                            <Descriptions.Item label="Certificación">{item.nombre_cert_licencia}</Descriptions.Item>
                            <Descriptions.Item label="Caduca">{item.fecha_de_caducidad}</Descriptions.Item>
                            <Descriptions.Item label="Id credencial">{item.id_credencial_licencia}</Descriptions.Item>
                            <Descriptions.Item label="Url">{item.URL_credencial}</Descriptions.Item>
            
                            </Descriptions>
                            <Divider />
                            </>
                        ))
                        
                    }
                    </InfiniteScroll>
                </div>
                </PageHeader>
            </TabPane>
            <TabPane tab="Idiomas" key="5">
                <PageHeader
                    title={DataPostulant.Nombre}
                    className="site-page-header"
                   // subTitle="Rut: {{DataPostulant.Rut}} "
                    
                     avatar={{src:<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="UserOutlined" key="handlehead2" />} />}}
                      extra={[<Tooltip title="Nuevo" color={'blue'} key={'blue'}><Button  onClick={() => { onAddIdioma() }}  icon={<Icon type='FormOutlined' />}/></Tooltip> ]}
             
                >
                <div
                        id="scrollableDiv"
                        style={{
                            height: 200,
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                    <InfiniteScroll
                        dataLength={dataIdiView.length}
                        endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                    <p></p>
                    <List
                            itemLayout="horizontal"
                            dataSource={dataIdiView}
                            renderItem={item => (
                            <List.Item
                                actions={[<Space><Button onClick={() => { onDeleteIdi(item) }}  icon={<Icon type='DeleteOutlined' />}/></Space>]}
                            >
                                <List.Item.Meta
                                avatar={<Avatar style={{ backgroundColor: "#1890ff" }} icon={<Icon type="GlobalOutlined" key="handlehead" />} />}
                                title="Idioma"
                                description={item.idioma}
                                />
                            </List.Item>
                                )}
                            />

                    </InfiniteScroll>
                </div>
                </PageHeader>
            </TabPane>
            <TabPane tab="Contactos" key="6">
                <PageHeader
                    title={DataPostulant.Nombre}
                    className="site-page-header"
                   // subTitle="Rut: {{DataPostulant.Rut}} "
                    
                    avatar={{src:<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="UserOutlined" key="handlehead2" />} />}}
                    extra={[<Tooltip title="Nuevo" color={'blue'} key={'blue'}><Button  onClick={() => { onAddContacto() }}  icon={<Icon type='FormOutlined' />}/></Tooltip> ]}
               
                >
                <div
                    id="scrollableDiv"
                    style={{
                        height: 200,
                        overflow: 'auto',
                        padding: '0 16px',
                        border: '1px solid rgba(140, 140, 140, 0.35)',
                    }}
                    >
                    <InfiniteScroll
                        dataLength={dataContView.length}
                        endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                    <p></p>
                    <List
                            itemLayout="horizontal"
                            dataSource={dataContView}
                            renderItem={item => (
                            <List.Item
                                actions={[<Space><Button onClick={() => { onDeleteCont(item) }}  icon={<Icon type='DeleteOutlined' />}/></Space>]}
                            >
                                <List.Item.Meta
                                avatar={<Avatar style={{ backgroundColor: "#1890ff" }} icon={<Icon type={item.Icon} key="handlehead" />} />}
                                title={item.title}
                                description={item.Observaciones}
                                />
                            </List.Item>
                                )}
                            />
                   
                    </InfiniteScroll>
                </div>
                </PageHeader>
            </TabPane>
        </Tabs>
        <ModalEditPost  viewEditPost={viewEditPost} Loading={Loading} handleSavePost={handleSavePost} handleCancelPostulant={handleCancelPostulant} EditDataPost={EditDataPost}/>
        <ModalEduAdd  viewEduAdd={viewEduAdd} DataPostulant={DataPostulant} Loading={Loading} handleCancelEduAdd={handleCancelEduAdd} handleAddPostEduc={handleAddPostEduc} />
        <ModalLabAdd  viewLabAdd={viewLabAdd} DataPostulant={DataPostulant} Loading={Loading} handleCancelLabAdd={handleCancelLabAdd} handleAddPostLab={handleAddPostLab} />
        <ModalCertAdd  viewCertAdd={viewCertAdd} DataPostulant={DataPostulant} Loading={Loading} handleCancelCertAdd={handleCancelCertAdd} handleAddPostCert={handleAddPostCert} />
        <ModalidiomaAdd  viewIdiomaAdd={viewIdiomaAdd} DataPostulant={DataPostulant} Loading={Loading} handleCancelIdiomaAdd={handleCancelIdiomaAdd} handleAddPostIdioma={handleAddPostIdioma} />
        <ModalContactoAdd  viewContactoAdd={viewContactoAdd} DataPostulant={DataPostulant} Loading={Loading} handleCancelContactoAdd={handleCancelContactoAdd} handleAddPostContacto={handleAddPostContacto} />

        <ModalEditEducacion  viewEditEdu={viewEditEdu} Loading={Loading} handleSavePostEdu={handleSavePostEdu} handleCancelEducacion={handleCancelEducacion} EditDataEdu={EditDataEdu}/>
        <ModalEditLaboral  viewEditLab={viewEditLab} Loading={Loading} handleSavePostLab={handleSavePostLab} handleCancelLaboral={handleCancelLaboral} EditDataLab={EditDataLab}/>
        <ModalEditCert  viewEditCert={viewEditCert} Loading={Loading} handleSavePostCert={handleSavePostCert} handleCancelCert={handleCancelCert} EditDataCert={EditDataCert}/>
        </Modal>
      
       </> 
    )
   
}

export default ModalPostulanteView
