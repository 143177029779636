import {useState,useEffect}  from 'react'
import {useQuery,useLazyQuery} from '@apollo/client'
import { GralNiveles,GralRegiones,GralComunasById ,GralTipoEmpleo,GralEstado,GralSexo,GralCargo,GralEstCivil
         ,GralIdioma,GralNacionalidad,GralEstadoPost,GralGiro,GralTipoContacto,GetallBoolean,GetallRangoEdad
         ,GetallhorarioLab,GetallExperencia,GetallExamenes,GetallLicencia, GetallTContrato,GetallSendAlert
         ,GetallMeses,GetallTurnos,GetallIsapres,GetallAfp,GetallBancos,GetallTipoAusencia,GetallAprobacion
         ,GetallTDoctoAusencia,GetallTDoctoVacaciones} from "../model/query/general/General"


export const useAllNiveles = () =>{
 
    const [getNivelesAlldata] = useLazyQuery(GralNiveles);
    const [NivelesAlldata,SetnivelesAlldata] = useState([])
    const[error,SetError] = useState(null)
    const[isnivelesLoading,SetIsnivelesLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  NivelesData =  await getNivelesAlldata()
                if(NivelesData){
                   SetnivelesAlldata(NivelesData.data.GetallNiveles)
                } 
            }
            catch(error){
                SetError(error.message)
            }
            SetIsnivelesLoading(false)

        }
        getDataAll()

    },[getNivelesAlldata])

     return{
        NivelesAlldata,
        error,
        isnivelesLoading
     }
}

export const useAllTipoEmpleo = () =>{
 
    const [getTipoEmpAlldata] = useLazyQuery(GralTipoEmpleo);
    const [TipoEmpAlldata,SettipoEmpAlldata] = useState([])
    const[error,SetError] = useState(null)
    const[isTipoEmpLoading,SetIstipoEmpLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  TipoEmpData =  await getTipoEmpAlldata()
                if(TipoEmpData){
                   SettipoEmpAlldata(TipoEmpData.data.GralTipoEmpleo)
                } 
            }
            catch(error){
                SetError(error.message)
            }
            SetIstipoEmpLoading(false)

        }
        getDataAll()

    },[getTipoEmpAlldata])

     return{
        TipoEmpAlldata,
        error,
        isTipoEmpLoading
     }
}

export const useAllEstado = () =>{
    
    const result = useQuery(GralEstado);
    return result;
}


export const useAllEstadoPost = () =>{
    
    const result = useQuery(GralEstadoPost);
    return result;
}

export const useAllGiro = () =>{
    
    const result = useQuery(GralGiro);
    return result;
}

export const useAllTipoContacto= () =>{
    
    const result = useQuery(GralTipoContacto);
    return result;
}

export const useAllCargo = () =>{
   
    const [getCargoAlldata] = useLazyQuery(GralCargo);
    const [cargoAlldata,SetcargoAlldata] = useState([])
    const[error,SetError] = useState(null)
    const[iscargoLoading,SetIscargoLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  cargoData =  await getCargoAlldata()
                if(cargoData){
                    SetcargoAlldata(cargoData.data.GetallCargo)
                } 
            }
            catch(error){
                SetError(error.message)
            }
            SetIscargoLoading(false)

        }
        getDataAll()

    },[getCargoAlldata])

     return{
         cargoAlldata,
         error,
         iscargoLoading
     }
}

export const useAllEstCivil = () =>{
   
    const [getCivilAlldata] = useLazyQuery(GralEstCivil);
    const [civilAlldata,SetcivilAlldata] = useState([])
    const[error,SetError] = useState(null)
    const[iscivilLoading,SetIscivilLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  cargoData =  await getCivilAlldata()
                if(cargoData){
                   SetcivilAlldata(cargoData.data.GetallEstCivil)
                } 
            }
            catch(error){
                SetError(error.message)
            }
            SetIscivilLoading(false)

        }
        getDataAll()

    },[getCivilAlldata])

     return{
        civilAlldata,
        error,
        iscivilLoading
     }




}

export const useAllIdioma = () =>{
   

    const [getIdiomaAlldata] = useLazyQuery(GralIdioma);
    const [idiomaAlldata,SetidiomaAlldata] = useState([])
    const[error,SetError] = useState(null)
    const[isidiomaLoading,SetIsidiomaLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  cargoData =  await getIdiomaAlldata()
                if(cargoData){
                   SetidiomaAlldata(cargoData.data.GetallIdioma)
                } 
            }
            catch(error){
                SetError(error.message)
            }
            SetIsidiomaLoading(false)

        }
        getDataAll()

    },[getIdiomaAlldata])

     return{
        idiomaAlldata,
        error,
        isidiomaLoading
     }

}

export const useAllNacionalidad = () =>{
   

    const [getNacAlldata] = useLazyQuery(GralNacionalidad);
    const [NacAlldata,SetnacAlldata] = useState([])
    const[error,SetError] = useState(null)
    const[isnacLoading,SetIsnacLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  cargoData =  await getNacAlldata()
                if(cargoData){
                   SetnacAlldata(cargoData.data.GetallNacionalidad)
                } 
            }
            catch(error){
                SetError(error.message)
            }
            SetIsnacLoading(false)

        }
        getDataAll()

    },[getNacAlldata])

     return{
        NacAlldata,
        error,
        isnacLoading
     }
}

export const useAllSexo = () =>{
 
    const [getSexoAlldata] = useLazyQuery(GralSexo);
    const [SexoAlldata,SetsexoAlldata] = useState([])
    const[error,SetError] = useState(null)
    const[issexoLoading,SetIssexoLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  cargoData =  await getSexoAlldata()
                if(cargoData){
                   SetsexoAlldata(cargoData.data.GetallSexo)
                } 
            }
            catch(error){
                SetError(error.message)
            }
            SetIssexoLoading(false)

        }
        getDataAll()

    },[getSexoAlldata])

     return{
        SexoAlldata,
        error,
        issexoLoading
     }
}

export const useComunasById = () =>{
    const result = useLazyQuery(GralComunasById);
    return result;
}

export const useAllRegiones = () =>{
 
    const [getRegionAlldata] = useLazyQuery(GralRegiones);
    const [RegionAlldata,SetregionAlldata] = useState([])
    const[error,SetError] = useState(null)
    const[isRegionLoading,SetIsRegionLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  cargoData =  await getRegionAlldata()
                if(cargoData){
                   SetregionAlldata(cargoData.data.Gralregiones)
                } 
            }
            catch(error){
                SetError(error.message)
            }
            SetIsRegionLoading(false)

        }
        getDataAll()

    },[getRegionAlldata])

     return{
        RegionAlldata,
        error,
        isRegionLoading
     }
}

export const useAllBoolean = () =>{
 
    const [getBooleanAlldata] = useLazyQuery(GetallBoolean);
    const [booleanAlldata,SetBooleanAlldata] = useState([])
    const[error,SetError] = useState(null)
    const[isbooleanLoading,SetIsbooleanLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  cargoData =  await getBooleanAlldata()
                if(cargoData){
                   SetBooleanAlldata(cargoData.data.GetallBoolean)
                } 
            }
            catch(error){
                SetError(error.message)
            }
            SetIsbooleanLoading(false)

        }
        getDataAll()

    },[getBooleanAlldata])

     return{
        booleanAlldata,
        error,
        isbooleanLoading
     }
}

export const useAllRangoEdad = () =>{
 
    const [getRangoEdadAlldata] = useLazyQuery(GetallRangoEdad);
    const [rangoEdadAlldata,SetRangoEdadAlldata] = useState([])
    const[error,SetError] = useState(null)
    const[israngoEdadLoading,SetIsRangoEdadLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  cargoData =  await getRangoEdadAlldata()
                if(cargoData){
                   SetRangoEdadAlldata(cargoData.data.GetallRangoEdad)
                } 
            }
            catch(error){
                SetError(error.message)
            }
            SetIsRangoEdadLoading(false)

        }
        getDataAll()

    },[getRangoEdadAlldata])

     return{
        rangoEdadAlldata,
        error,
        israngoEdadLoading
     }
}

export const useAllHorarioLab = () =>{
 
    const [getHorarioLabAlldata] = useLazyQuery(GetallhorarioLab);
    const [horarioLabAlldata,SetHorarioLabAlldata] = useState([])
    const[error,SetError] = useState(null)
    const[ishorarioLabLoading,SetIsHorarioLabLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  cargoData =  await getHorarioLabAlldata()
                if(cargoData){
                   SetHorarioLabAlldata(cargoData.data.GetallhorarioLab)
                } 
            }
            catch(error){
                SetError(error.message)
            }
            SetIsHorarioLabLoading(false)

        }
        getDataAll()

    },[getHorarioLabAlldata])

     return{
        horarioLabAlldata,
        error,
        ishorarioLabLoading
     }
}

export const useAllExperencia = () =>{
 
    const[getExperenciaAlldata] = useLazyQuery(GetallExperencia);
    const[experenciaAlldata,SetExperenciaAlldata] = useState([])
    const[error,SetError] = useState(null)
    const[isexperenciaLoading,SetIsExperenciaLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  cargoData =  await getExperenciaAlldata()
                if(cargoData){
                   SetExperenciaAlldata(cargoData.data.GetallExperencia)
                } 
            }
            catch(error){
                SetError(error.message)
            }
            SetIsExperenciaLoading(false)

        }
        getDataAll()

    },[getExperenciaAlldata])

     return{
        experenciaAlldata,
        error,
        isexperenciaLoading
     }
}

export const useAllExamenes = () =>{
 
    const[getExamenesAlldata] = useLazyQuery(GetallExamenes);
    const[examenesAlldata,SetExamenesAlldata] = useState([])
    const[error,SetError] = useState(null)
    const[isexamenesLoading,SetIsExamenesLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  cargoData =  await getExamenesAlldata()
                if(cargoData){
                   SetExamenesAlldata(cargoData.data.GetallExamenes)
                } 
            }
            catch(error){
                SetError(error.message)
            }
            SetIsExamenesLoading(false)

        }
        getDataAll()

    },[getExamenesAlldata])

     return{
        examenesAlldata,
        error,
        isexamenesLoading
     }
}

export const useAllLicencia = () =>{
 
    const[getLicenciaAlldata] = useLazyQuery(GetallLicencia);
    const[licenciaAlldata,SetLicenciaAlldata] = useState([])
    const[error,SetError] = useState(null)
    const[islicenciaLoading,SetIsLicenciaLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  cargoData =  await getLicenciaAlldata()
                if(cargoData){
                   SetLicenciaAlldata(cargoData.data.GetallLicencia)
                } 
            }
            catch(error){
                SetError(error.message)
            }
            SetIsLicenciaLoading(false)

        }
        getDataAll()

    },[getLicenciaAlldata])

    return{
        licenciaAlldata,
        error,
        islicenciaLoading
    }
}

export const useAllTContrato = () =>{
 
    const[getTContratoAlldata] = useLazyQuery(GetallTContrato);
    const[tcontratoAlldata,SetTContratoAlldata] = useState([])
    const[error,SetError] = useState(null)
    const[istcontratoLoading,SetIsTContratoLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  cargoData =  await getTContratoAlldata()
                if(cargoData){
                   SetTContratoAlldata(cargoData.data.GetallTContrato)
                } 
            }
            catch(error){
                SetError(error.message)
            }
            SetIsTContratoLoading(false)

        }
        getDataAll()

    },[getTContratoAlldata])

    return{
        tcontratoAlldata,
        error,
        istcontratoLoading
    }
}

export const useAllSendAlert = () =>{
 
    const[getallSendAlertata] = useLazyQuery(GetallSendAlert);
    const[sendAlertalldata,setSendAlertalldata] = useState([])
    const[error,SetError] = useState(null)
    const[sendLoading,setSendLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  cargoData =  await getallSendAlertata()
                if(cargoData){
                   setSendAlertalldata(cargoData.data.GetallSendAlert)
                } 
            }
            catch(error){
                SetError(error.message)
            }
           setSendLoading(false)

        }
        getDataAll()

    },[getallSendAlertata])

    return{
        sendAlertalldata,
        error,
        sendLoading
    }
}

export const useAllMeses = () =>{
 
    const[getallMeses] = useLazyQuery(GetallMeses);
    const[mesesalldata,setMesesalldata] = useState([])
    const[error,SetError] = useState(null)
    const[mesesLoading,setMesesLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  cargoData =  await getallMeses()
                if(cargoData){
                  setMesesalldata(cargoData.data.GetallMeses)
                } 
            }
            catch(error){
                SetError(error.message)
            }
           setMesesLoading(false)

        }
        getDataAll()

    },[getallMeses])

    return{
        mesesalldata,
        error,
        mesesLoading
    }
}

export const useAllTurnos = () =>{
 
    const[getallTurnos] = useLazyQuery(GetallTurnos);
    const[allTurnosdata,setallTurnosdata] = useState([])
    const[error,SetError] = useState(null)
    const[TurnosLoading,setTurnosLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  cargoData =  await getallTurnos()
                if(cargoData){
                  setallTurnosdata(cargoData.data.GetallTurnos)
                } 
            }
            catch(error){
                SetError(error.message)
            }
           setTurnosLoading(false)

        }
        getDataAll()

    },[getallTurnos])

    return{
        allTurnosdata,
        error,
        TurnosLoading
    }
}

export const useAllIsapres = () =>{
 
    const[getallIsapres] = useLazyQuery(GetallIsapres);
    const[allIsapresdata,setallIsapresdata] = useState([])
    const[error,SetError] = useState(null)
    const[IsapresLoading,setIsapresLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  IsapresData =  await getallIsapres()
                if(IsapresData){
                  setallIsapresdata(IsapresData.data.GetallIsapres)
                } 
            }
            catch(error){
                SetError(error.message)
            }
           setIsapresLoading(false)

        }
        getDataAll()

    },[getallIsapres])

    return{
        allIsapresdata,
        error,
        IsapresLoading
    }
}

export const useAllAfp = () =>{
 
    const[getallAfp] = useLazyQuery(GetallAfp);
    const[allAfpdata,setallAfpdata] = useState([])
    const[error,SetError] = useState(null)
    const[AfpLoading,setAfpLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  AfpData =  await getallAfp()
                if(AfpData){
                  setallAfpdata(AfpData.data.GetallAfp)
                } 
            }
            catch(error){
                SetError(error.message)
            }
           setAfpLoading(false)

        }
        getDataAll()

    },[getallAfp])

    return{
        allAfpdata,
        error,
        AfpLoading
    }
}

export const useAllBancos = () =>{
 
    const[getallBancos] = useLazyQuery(GetallBancos);
    const[allBancosdata,setallBancosdata] = useState([])
    const[error,SetError] = useState(null)
    const[BancosLoading,setBancosLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  BancosData =  await getallBancos()
                if(BancosData){
                  setallBancosdata(BancosData.data.GetallBancos)
                } 
            }
            catch(error){
                SetError(error.message)
            }
           setBancosLoading(false)

        }
        getDataAll()

    },[getallBancos])

    return{
        allBancosdata,
        error,
        BancosLoading
    }
}

export const useAllTipoAusencia = () =>{
 
    const[getallTipoAusencia] = useLazyQuery(GetallTipoAusencia);
    const[allTipoAusenciadata,setallTipoAusenciadata] = useState([])
    const[error,SetError] = useState(null)
    const[TipoAusenciaLoading,setTipoAusenciaLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  TipoAusenciaData =  await getallTipoAusencia()
                if(TipoAusenciaData){
                  setallTipoAusenciadata(TipoAusenciaData.data.GetallTipoAusencia)
                } 
            }
            catch(error){
                SetError(error.message)
            }
           setTipoAusenciaLoading(false)

        }
        getDataAll()

    },[getallTipoAusencia])

    return{
        allTipoAusenciadata,
        error,
        TipoAusenciaLoading
    }
}


export const useAllAprobacion = () =>{
 
    const[getallAprobacion] = useLazyQuery(GetallAprobacion);
    const[allAprobaciondata,setallAprobaciondata] = useState([])
    const[error,SetError] = useState(null)
    const[AprobacionLoading,setAprobacionLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  AprobacionData =  await getallAprobacion()
                if(AprobacionData){
                  setallAprobaciondata(AprobacionData.data.GetallAprobacion)
                } 
            }
            catch(error){
                SetError(error.message)
            }
           setAprobacionLoading(false)

        }
        getDataAll()

    },[getallAprobacion])

    return{
        allAprobaciondata,
        error,
        AprobacionLoading
    }
}

export const useAllTDoctoAusencia = () =>{
 
    const[getallTDoctoAusencia] = useLazyQuery(GetallTDoctoAusencia);
    const[allTDoctoAusenciadata,setallTDoctoAusenciadata] = useState([])
    const[error,SetError] = useState(null)
    const[TDoctoAusenciaLoading,setTDoctoAusenciaLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  TDoctoAusenciaData =  await getallTDoctoAusencia()
                if(TDoctoAusenciaData){
                  setallTDoctoAusenciadata(TDoctoAusenciaData.data.GetallTDoctoAusencia)
                } 
            }
            catch(error){
                SetError(error.message)
            }
           setTDoctoAusenciaLoading(false)

        }
        getDataAll()

    },[getallTDoctoAusencia])

    return{
        allTDoctoAusenciadata,
        error,
        TDoctoAusenciaLoading
    }
}

export const useAllTDoctoVacaciones = () =>{
 
    const[getallTDoctoVacaciones] = useLazyQuery(GetallTDoctoVacaciones);
    const[allTDoctoVacacionesdata,setallTDoctoAusenciadata] = useState([])
    const[error,SetError] = useState(null)
    const[TDoctoVacacionesLoading,setTDoctoVacacionesLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                const  TDoctoVacacionesData =  await getallTDoctoVacaciones()
                if(TDoctoVacacionesData){
                  setallTDoctoAusenciadata(TDoctoVacacionesData.data.GetallTDoctoVacaciones)
                } 
            }
            catch(error){
                SetError(error.message)
            }
           setTDoctoVacacionesLoading(false)

        }
        getDataAll()

    },[getallTDoctoVacaciones])

    return{
        allTDoctoVacacionesdata,
        error,
        TDoctoVacacionesLoading
    }
}

export default useAllEstado


