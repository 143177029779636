import React  from 'react'
import { Button, PageHeader ,Row,Col} from 'antd';

import { FilterOutlined ,PrinterOutlined,FileExcelOutlined} from '@ant-design/icons';
import '../../../Assets/Css/page.css';



const HeadReportHextraResumen = ({Loading,Datapersona,handlePrint,handleFiltro,handleExcel}) => {
    console.log(Datapersona)
    return (
        
    <div className="site-page-header-ghost-wrapper2">
    <PageHeader
      ghost={false}
      
      title={Datapersona.Nombre}
      subTitle=" Informe de Horas Extras Resumen"
      extra={[
        <Button  key="2" icon={<FileExcelOutlined />} onClick={handleExcel}> A Excel</Button>,
        <Button  key="4" icon={<PrinterOutlined />} onClick={handlePrint}> Imprimir</Button>,
        <Button   key="3" icon={<FilterOutlined />} onClick={handleFiltro} loading={Loading }>Filtros</Button>,
      ]}
    >
    <Row gutter={8}>
  
      <Col  xs={{ span: 24, offset: 0 }} lg={{ span: 4 }}><b>Sucursal: </b>{Datapersona.Sucursal}</Col>
      <Col  xs={{ span: 24, offset: 0 }} lg={{ span: 4 }}><b>Empresa: </b>{Datapersona.Empresa}</Col>
      
    </Row>
    <Row gutter={16}>
      <Col  xs={{ span: 24, offset: 0 }} lg={{ span: 4 }}><b>Desde: </b>{Datapersona.Desde}</Col>
      <Col  xs={{ span: 24, offset: 0 }} lg={{ span: 4 }}><b>Hasta: </b>{Datapersona.hasta}</Col>
      <Col  xs={{ span: 24, offset: 0 }} lg={{ span: 6 }}><b>Monto Base Bono: </b>$ {Datapersona.MontoBaseBono}</Col>
    </Row>
    </PageHeader>
  </div>
        
    )
}

export default HeadReportHextraResumen
