import React,{useState,useEffect} from 'react'
import moment from 'moment'
import '../Assets/Css/page.css';
import { Modal,Card,Space,Button,Row,Col} from 'antd';
import IcoButton from '../Components/IconButtonGroup';
import Buscar from '../Components/Loading';
import ModalClave from '../Components/ModalClave';
import {useUpdatepass} from '../hooks/custom-users';

import IcoButtonList from '../Components/contratos/creacionVacaciones/IconButtonList';
import TableVacaciones from '../Components/contratos/creacionVacaciones/TableVacaciones';
import SearchEmpleVacaciones from '../Components/contratos/creacionVacaciones/SearchEmpleVacaciones';
import ModalVacaciones from '../Components/contratos/creacionVacaciones/ModalVacaciones';
import ModalMessages  from '../Components/contratos/creacionVacaciones/ModalMessages';
import ModalObsRechazo  from '../Components/contratos/ModalObsRechazo';
import ModalObsVacaciones from '../Components/contratos/creacionVacaciones/ModalObsVacaciones';
import ModalVacacionesAdj  from '../Components/contratos/creacionVacaciones/ModalVacacionesAdj';


import { useListaVacaciones,useNuevaVacacionesEmpleado,useEliminaVacacionesEmpleado,
         useAprobarVacacionesEmpleado,useRechazarVacacionesEmpleado,useEstadoVacacionesEmpleado,
         useObsVacaciones,useAdjVacaciones,useCrearVacacionesAdj } from '../hooks/custom-contratos';

import {useAllAprobacion,useAllTDoctoVacaciones } from '../hooks/custom-general'

import { useAllClientesActivo } from '../hooks/custom-Clientes'
import{useVerifyToken,useVerify} from "../hooks/useToken"   
const CreacionVacaciones = () => {
    
    const { confirm } = Modal;
    const {data:Tokensverify, loading:TokensverifyLoading}= useVerify();
    const{Tokens,TokensLoading} = useVerifyToken();
    const{data:clieData,loading:clieloading}= useAllClientesActivo();
    const {allAprobaciondata,AprobacionLoading} = useAllAprobacion();
    const {allTDoctoVacacionesdata,TDoctoVacacionesLoading} = useAllTDoctoVacaciones();
    const [NuevaVacacionesEmpleado] = useNuevaVacacionesEmpleado();
    const [EliminaVacacionesEmpleado] = useEliminaVacacionesEmpleado();
    const [AprobarVacacionesEmpleado] = useAprobarVacacionesEmpleado();
    const [RechazarVacacionesEmpleado] = useRechazarVacacionesEmpleado();
    const [DataObsVacaciones] = useObsVacaciones();
    const [DataAdjVacaciones] = useAdjVacaciones();
    const [Crear_VacacionesAdj] = useCrearVacacionesAdj();
    const [EstadoVacacionesEmpleado] = useEstadoVacacionesEmpleado();

    const [Estdata,setEstdata] = useState([]);
    const [clientes, setClientes] = useState();
    const [loadingpage, setloadingpage] = useState(true);
    const [Visible, setVisible] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [SetUpd] = useUpdatepass();

    const[ListaVacaciones] =  useListaVacaciones(); 
    
    
    const [SelFiltros, setSelFiltros] = useState();
    const [messages, setMessages] = useState(false);
    const [DataVacaciones, SetdataVacaciones] = useState(false); 
    const [viewNew,setViewNew] = useState(false);
    const [ViewMessage,setViewMessage] = useState(false);
    const [selRowKeys,  setSelRowKeys] = useState([]);
    const [MRechazoView, setMRechazoView] = useState(false);
    const [creadoPor, setCreadoPor] = useState('');
    
    const [viewVacaciones,SetviewVacaciones] = useState(false);
    const [dataSel,setDataSel] = useState([]);
    const [dataObs,setDataObs] = useState([]);
    const [viewAdj,setViewAdj] = useState(false);
    const [dataAdj,setDataAdj] = useState([]);
    const [dataSelAdj,setDataSelAdj] = useState([]);
    const [TipoDocto,SetTipoDocto] = useState([]);
    

   
   
    
   
   useEffect(() => {
  
         if(TokensverifyLoading===false){
            
            if(Tokensverify.GetLogin_validate.success===false){
                Modal.error({
                    title: Tokensverify.GetLogin_validate.message,
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");
                    }
                })
            }
           
        }

        if(TokensLoading===false){
            if(Tokens){
                 const userF=Tokens.token.split('||')
                 setCreadoPor(userF[2])
            }
        } 

        if(AprobacionLoading===false){    
           const dataest = allAprobaciondata
             setEstdata(dataest)
        } 

        if(TDoctoVacacionesLoading===false){    
           const dataDoctoVacaciones = allTDoctoVacacionesdata
             SetTipoDocto(dataDoctoVacaciones)
           
        }
         
        
        if(clieloading===false){
            const dataclient = clieData.GetClientAllActivo
             
            setClientes(dataclient)
             
        }
        if(clieloading===false ){
            setloadingpage(false)
        }

        

       
    }, [AprobacionLoading, TDoctoVacacionesLoading, Tokens, TokensLoading, Tokensverify, TokensverifyLoading, allAprobaciondata, allTDoctoVacacionesdata, clieData, clieloading])
   
   const [submenu] = useState([
        {
            key: '1',
            icon: 'KeyOutlined',
            title: 'Nueva Clave',

        }, {
            key: '2',
            icon: 'PoweroffOutlined',
            title: 'Salir',

        }
    ])

    const onGroupClick = (array) => {
       
        switch (array.key) {
            case '1':
                //console.log(array.key);
                setVisible(true);
               break
            default:
                Modal.confirm({
                    title: 'Estas Seguro de Cerrar la aplicación ..??',
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");

                        
                }
                })
        }
    }

    const handleOk = async (values) => {

      
       
        const EditPass = await  SetUpd({variables:{
            id_sesion:creadoPor,
            oldPass: values.claveactual,
            NewPass: values.NuevaClave,
          
        }})
      //console.log(EditPass)
       // setLoading(true);
        const success = EditPass.data.updatePassword.success
        const message = EditPass.data.updatePassword.message
        if(success===true){   
           const  modal = Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
                setVisible(false);
                modal.destroy();
            }, 3000);

        }else{
            Modal.warning({
                 content: message,
            });
            setLoading(false);
              
           
        }  

    };

    const handleCancel = () => {
        setVisible(false);
    };

   const HandleDataVacaciones = async (values) => {
      
        let fecha_desde="";
        let fecha_hasta ="";
        if(values.FechaVacaciones){
           fecha_desde= moment(values.FechaVacaciones[0]).format('YYYY/MM/DD')
           fecha_hasta =moment(values.FechaVacaciones[1]).format('YYYY/MM/DD')
        } 
        
        const Vacaciones = await  ListaVacaciones({variables:{
            Id_contrato: values.Id_contrato,
            fecha_desde: fecha_desde,
            fecha_hasta:  fecha_hasta,
            Id_cliente:values.Cliente,
        }})

        const filterArray=[{Id_contrato: values.Id_contrato,fecha_desde: fecha_desde,fecha_hasta:  fecha_hasta,Id_cliente:values.Cliente}]
        setSelFiltros(filterArray)
 
        if(Vacaciones.loading===false){
           SetdataVacaciones(Vacaciones.data.GetEmpleVacaciones_ByFilter)
        }
       
    };
    
    const onAddPost=()=>{
       setViewNew(true);
    }

    const handleCancelVacaciones = () => {
         setViewNew(false);
    };

    const handleCancelMe = () => {
         setViewMessage(false);
    };
    
    const handleSaveVacaciones = async (values) => {
        setLoading(true);
      
        const EditVacaciones = await NuevaVacacionesEmpleado({variables:{
            Id_contrato: values.Id_contrato,
            EsAprobada: values.EsAprobada,
            fecha_desde: moment(values.FechaVacaciones[0]).format('YYYY/MM/DD'),
            fecha_hasta: moment(values.FechaVacaciones[1]).format('YYYY/MM/DD'),
            creado_por: creadoPor,
      
        }})
       
         
            const success = EditVacaciones.data.Nueva_VacacionesEmpleado.success
            const message = EditVacaciones.data.Nueva_VacacionesEmpleado.message
            const estado = EditVacaciones.data.Nueva_VacacionesEmpleado.isdata
            setMessages(EditVacaciones.data.Nueva_VacacionesEmpleado.DataError)
        
            if(success){
                if (estado) {
                    setViewMessage(true);
                    setLoading(false);  
                }
                else{   
                    setTimeout(() => {
                        Modal.success({
                            content: message,
                        });
                        setLoading(false);
                    }, 2000);
                }

            }
            else{
                Modal.warning({
                    content: message,
                });
                setLoading(false);
            }  
    };

    const onDelVacaciones = async () => {
       
        if(selRowKeys){

            confirm({
                title: 'Eliminar de Vacaciones',
                icon:<Icon type={'ExclamationCircleOutlined'}/> ,
                content: 'Seguro de eliminar estos elementos.....?',
                async onOk() {

                    const DelVacaciones = await EliminaVacacionesEmpleado({variables:{
                        Id_contractvac: selRowKeys,
                        Creado_por: creadoPor,
                
                    }})
                
                    
                    const success = DelVacaciones.data.Elimina_VacacionesEmpleado.success
                    const message = DelVacaciones.data.Elimina_VacacionesEmpleado.message
                    if(success){   

                        const Vacaciones = await  ListaVacaciones({variables:{
                            Id_contrato: SelFiltros[0].Id_contrato,
                            Id_tipo_ausencia: SelFiltros[0].Id_tipo_ausencia,
                            fecha_desde: SelFiltros[0].fecha_desde,
                            fecha_hasta: SelFiltros[0].fecha_hasta,
                            Id_cliente:SelFiltros[0].Id_cliente,
                        }})
                        if(Vacaciones.loading===false){
                            SetdataVacaciones(Vacaciones.data.GetEmpleVacaciones_ByFilter)
                        }

                        const modal= Modal.success({
                                content: message,
                            });
                        setTimeout(() => {
                            modal.destroy();
                        }, 3000);

                    }
                    else{
                        const modal=  Modal.warning({
                            content: message,
                        });
                       setTimeout(() => {
                             modal.destroy();
                        }, 3000);
                    }  
                
                },
                /*onCancel() {
                console.log(selRowKeys);
                },*/
            });
        }
       
    };

    const onAprobVacaciones = async () => {
       
        if(selRowKeys){

            confirm({
                title: 'Aprobar Vacaciones',
                icon:<Icon type={'ExclamationCircleOutlined'}/> ,
                content: 'Seguro quiere Aprobar estos Registros.....?',
                async onOk() {

                    const AprobVacaciones = await AprobarVacacionesEmpleado({variables:{
                        Id_contractvac: selRowKeys,
                        Creado_por: creadoPor,
                
                    }})
                
                    
                    const success = AprobVacaciones.data.Aprobar_VacacionesEmpleado.success
                    const message = AprobVacaciones.data.Aprobar_VacacionesEmpleado.message
                    if(success){   

                        const Vacaciones = await  ListaVacaciones({variables:{
                            Id_contrato: SelFiltros[0].Id_contrato,
                            Id_tipo_ausencia: SelFiltros[0].Id_tipo_ausencia,
                            fecha_desde: SelFiltros[0].fecha_desde,
                            fecha_hasta: SelFiltros[0].fecha_hasta,
                            Id_cliente:SelFiltros[0].Id_cliente,
                        }})
                        if(Vacaciones.loading===false){
                            SetdataVacaciones(Vacaciones.data.GetEmpleVacaciones_ByFilter)
                        }

                        const modal= Modal.success({
                                content: message,
                            });
                        setTimeout(() => {
                            modal.destroy();
                        }, 3000);

                    }
                    else{
                        const modal=  Modal.warning({
                            content: message,
                        });
                       setTimeout(() => {
                             modal.destroy();
                        }, 3000);
                    }  
                
                },
                /*onCancel() {
                console.log(selRowKeys);
                },*/
            });
        }
       
    };

    const onRechVacaciones = () => {
        if(selRowKeys){
           setMRechazoView(true);
        }
       
    };

    const handleCancelObs = () => {
        setMRechazoView(false);
    };

     const handleSaveObs = async (values) => {
        if(selRowKeys){

            confirm({
                title: 'Rechazar Vacaciones',
                icon:<Icon type={'ExclamationCircleOutlined'}/> ,
                content: 'Seguro quiere Rechazar estos Registros.....?',
                async onOk() {

                    const RechVacaciones = await RechazarVacacionesEmpleado({variables:{
                        Id_contractvac: selRowKeys,
                        Observaciones:values.Observaciones,
                        Creado_por: creadoPor,
                
                    }})
                
                    
                    const success = RechVacaciones.data.Rechazar_VacacionesEmpleado.success
                    const message = RechVacaciones.data.Rechazar_VacacionesEmpleado.message
                    if(success){   

                        const Vacaciones = await  ListaVacaciones({variables:{
                            Id_contrato: SelFiltros[0].Id_contrato,
                            Id_tipo_ausencia: SelFiltros[0].Id_tipo_ausencia,
                            fecha_desde: SelFiltros[0].fecha_desde,
                            fecha_hasta: SelFiltros[0].fecha_hasta,
                            Id_cliente:SelFiltros[0].Id_cliente,
                        }})
                        if(Vacaciones.loading===false){
                            SetdataVacaciones(Vacaciones.data.GetEmpleVacaciones_ByFilter)
                        }

                        const modal= Modal.success({
                                content: message,
                            });
                        setTimeout(() => {
                            modal.destroy();
                        }, 3000);

                    }
                    else{
                        const modal=  Modal.warning({
                            content: message,
                        });
                       setTimeout(() => {
                             modal.destroy();
                        }, 3000);
                    }  
                
                },
                /*onCancel() {
                console.log(selRowKeys);
                },*/
            });
        }
    };

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;
    };

    const onVerObs = async (record) => {

        //console.log(record);
        SetviewVacaciones(true);
        const ObsData = await DataObsVacaciones({variables:{
            Id_contractvac:record.Id_contractvac
        }})
       
        setDataObs(ObsData.data.GetVacacionesObs)
        setDataSel(record);
    }

     const handleCancelObsVer = () => {
        SetviewVacaciones(false);
    };

    
    const handleSaveEstObs = async (values) => {
      console.log(dataSel.Id_contractlic)
        const EstVacaciones = await EstadoVacacionesEmpleado({variables:{
            Id_contractvac: dataSel.Id_contractvac,
            Estado:values.Estado,
            Observaciones:values.Observaciones,
            Creado_por: creadoPor,
    
        }})
    
        
        const success = EstVacaciones.data.Estado_VacacionesEmpleado.success
        const message = EstVacaciones.data.Estado_VacacionesEmpleado.message
        if(success){   

            const ObsData = await DataObsVacaciones({variables:{
                Id_contractvac:dataSel.Id_contractvac
            }})
        
            setDataObs(ObsData.data.GetVacacionesObs)

            const Vacaciones = await  ListaVacaciones({variables:{
                Id_contrato: SelFiltros[0].Id_contrato,
                Id_tipo_ausencia: SelFiltros[0].Id_tipo_ausencia,
                fecha_desde: SelFiltros[0].fecha_desde,
                fecha_hasta: SelFiltros[0].fecha_hasta,
                Id_cliente:SelFiltros[0].Id_cliente,
            }})
            if(Vacaciones.loading===false){
                SetdataVacaciones(Vacaciones.data.GetEmpleVacaciones_ByFilter)
            }

            const modal= Modal.success({
                    content: message,
                });
            setTimeout(() => {
                modal.destroy();
            }, 3000);

        }
        else{
            const modal=  Modal.warning({
                content: message,
            });
            setTimeout(() => {
                    modal.destroy();
            }, 3000);
        }   

    };

 
    const handleCancelAdj = () => {
        setViewAdj(false);
    };

    const onVerAdj = async (record) => {

        setViewAdj(true);
        const AdjData = await DataAdjVacaciones({variables:{
            Id_contractvac:record.Id_contractvac
        }})
       
        setDataAdj(AdjData.data.GetVacacionesAdj)
        setDataSelAdj(record)
    }

    const handleSaveAdj = async (values,Arrfilename) => {

        setLoading(true);
        const valor = Arrfilename.map((item) => item.filename);
       
        const EstVacacionesAdj = await Crear_VacacionesAdj({variables:{
            Id_contractvac: dataSelAdj.Id_contractvac,
            Codigo:values.DoctoVacaciones,
            adjunto:  valor,
            creado_por: creadoPor,
    
        }})
    
        
        const success = EstVacacionesAdj.data.Crear_VacacionesAdj.success
        const message = EstVacacionesAdj.data.Crear_VacacionesAdj.message
        if(success){   

            const AdjData = await DataAdjVacaciones({variables:{
                Id_contractvac:dataSelAdj.Id_contractvac,
            }})
        
            setDataAdj(AdjData.data.GetVacacionesAdj)

            const modal= Modal.success({
                    content: message,
                });
            setTimeout(() => {
                modal.destroy();
                setLoading(false);
            }, 3000);

        }
        else{
            const modal=  Modal.warning({
                content: message,
            });
            setTimeout(() => {
                setLoading(false);
                modal.destroy();
            }, 3000);
        }   

    };

    
    if(loadingpage){
        return (
        <div className="site-card-border-less-wrapper">
           
          <Buscar/>
         
        </div>
        )
    }
    return (
        <div className="site-card-border-less-wrapper">
        
            <Card size="small" style={{ textAlign: 'Left', }}  title='Vacaciones Empleados'    extra={[ <IcoButtonList key="1" icon='BellOutlined' SesionID={creadoPor}    />,<IcoButton key="2" icon='SettingOutlined' onChildClick={(array) => onGroupClick(array)} submenu={submenu}  />]}   >
                <Card size="small" bordered={false} style={{  display: 'flex' }}>
                
                    <Row gutter={[24, 8]}>
                    <Col xs={{ span: 12, offset: 0 }} lg={{ span: 5 }}  >
                        <Button onClick={onAddPost} icon={<Icon type={'CalendarOutlined'}/>}>Nuevo</Button>
                    </Col>
                    <Col xs={{ span: 12, offset: 0 }} lg={{ span: 5 }}>
                        <Button onClick={onDelVacaciones}  disabled={!selRowKeys.length} icon={<Icon type={'MinusSquareOutlined'}/>} danger>Borrar</Button>
                    </Col>
                    <Col xs={{ span: 12, offset: 0 }} lg={{ span: 6 }}>
                        <Button  onClick={onAprobVacaciones} disabled={!selRowKeys.length} icon={<Icon type={'CheckSquareOutlined'}/>}>Aprobar</Button>
                    </Col>
                    <Col xs={{ span: 12, offset: 0 }} lg={{ span: 6 }}>
                        <Button onClick={onRechVacaciones}  disabled={!selRowKeys.length} icon={<Icon type={'CloseSquareOutlined'}/>} danger>Rechazar</Button>
                    </Col>
                    </Row>

                    
                    
                  
                </Card>
                
                   <SearchEmpleVacaciones HandleDataVacaciones = {HandleDataVacaciones}  SetdataVacaciones={SetdataVacaciones} clientes={clientes}  />
                   <Space>&nbsp;</Space>
                   <TableVacaciones DataVacaciones={DataVacaciones} setSelRowKeys={setSelRowKeys} handleVerObs={onVerObs} handleVerAdj={onVerAdj}  />
                
            </Card>
            <ModalClave Visible={Visible} Loading={Loading} handleOk={handleOk} handleCancel={handleCancel }/>
            <ModalVacaciones viewNew ={viewNew}  Loading={Loading} handleSaveVacaciones={handleSaveVacaciones} handleCancelVacaciones={handleCancelVacaciones} clientes={clientes}  />  
            <ModalMessages ViewMessage={ViewMessage} handleCancelMe={handleCancelMe} messages={messages}  />
            <ModalObsRechazo MRechazoView={MRechazoView} Loading={Loading} handleSaveObs={handleSaveObs} handleCancelObs={handleCancelObs}  />
            <ModalObsVacaciones viewVacaciones={viewVacaciones} Loading={Loading} handleSaveEstObs={handleSaveEstObs} handleCancelObsVer={handleCancelObsVer} dataSel={dataSel} dataObs={dataObs} Estdata={Estdata} />
            <ModalVacacionesAdj viewAdj={viewAdj} Loading={Loading} handleSaveAdj={handleSaveAdj} handleCancelAdj={handleCancelAdj} TipoDocto={TipoDocto} dataAdj={dataAdj} />
    
           
        </div>

    );
}

export default CreacionVacaciones