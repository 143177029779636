import React, { useState, useRef} from 'react'
import { Table, Input, Button, Space ,Tooltip} from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined, FileTextOutlined,PaperClipOutlined } from '@ant-design/icons';

const TableLicencia = ({DataLicencia,setSelRowKeys,handleVerObs,handleVerAdj}) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
   
   
    const searchInput = useRef(null);
    
    
  
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button onClick={() => {handleReset(clearFilters); confirm({closeDropdown: false });}} size="small" style={{ width: 90 }}>
                        Borrar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput, 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
       
    };
    
    
    const paramstable = {
       bordered: true,
        pagination:false,
        size: 'small',
        tableLayout: 'auto',
        scroll: { x: 'max-content', y: '40vh'},
    }

    const [Columsdata] = useState([
        {
            title: 'Id_Licencia',
            dataIndex: 'Id_contractlic',
            key: 'Id_contractlic',
            hidden: true,  
            

        },
        {
            title: 'Id_empleado',
            dataIndex: 'Id_empleado',
            key: 'Id_empleado',
            hidden: true,  

        },
        {
            title: 'Id_cliente',
            dataIndex: 'Id_cliente',
            key: 'Id_cliente',
            hidden: true,  

        },
        {
            title: 'Rut',
            dataIndex: 'Rut',
            key: 'Rut',
            fixed:false,
            ...getColumnSearchProps('Rut'),

        },
        {
            title: 'Nombre',
            dataIndex: 'Nombre',
            key:'Nombre',
            fixed:false,
            ...getColumnSearchProps('Nombre'),

        },
         {
            title: 'Id_tipoausencia',
            dataIndex: 'Id_tipoausencia',
            key: 'Id_tipoausencia',
            hidden: true,  

        },
        {
            title: 'Tipo',
            dataIndex: 'tipoausencia',
            key: 'tipoausencia',
            fixed:false, 
            ...getColumnSearchProps('tipoausencia'),

        },
        {
            title: 'Aprobada',
            dataIndex: 'EsAprobada',
            key: 'EsAprobada',
            fixed:false, 
            ...getColumnSearchProps('EsAprobada'),

        },
        {
            title: 'Desde',
            dataIndex: 'fecha_desde',
            key: 'fecha_desde',
            fixed:false, 
            ...getColumnSearchProps('fecha_desde'),

        },
        {
            title: 'Hasta',
            dataIndex: 'fecha_hasta',
            key: 'fecha_hasta',
            fixed:false, 
            ...getColumnSearchProps('fecha_hasta'),

        },
        {
            title: 'Aprobación',
            dataIndex: 'fecha_aprobacion',
            key: 'fecha_aprobacion',
            fixed:false, 
            ...getColumnSearchProps('fecha_aprobacion'),

        },
        {
            title: 'Retorno',
            dataIndex: 'fecha_retorno',
            key: 'fecha_retorno',
            fixed:false, 
            ...getColumnSearchProps('fecha_retorno'),

        },
        {
            title: 'Dias',
            dataIndex: 'nro_dias',
            key: 'nro_dias',
            fixed:false, 
            ...getColumnSearchProps('nro_dias'),

        },
        {
            title: 'Creación',
            dataIndex: 'Fecha_creacion',
            key: 'Fecha_creacion',
            fixed:false, 
            ...getColumnSearchProps('Fecha_creacion'),

        },
         {
            title: 'Acción',
            dataIndex: 'accion',
            key: 'accion',
            fixed:false, 
            render: (text, record) => {
               
                return (
                    <>
                        <Space size="middle">
                          
                           <Tooltip title="Observaciones" color={'blue'} key={'blue'}><FileTextOutlined onClick={() => {onObs(record)}} /></Tooltip>
                            <Tooltip title="Agregar Adjuntos" color={'blue'} key={'blue'}><PaperClipOutlined onClick={() => {onAdj(record)}} /></Tooltip>
                             

                        </Space>
                    </>

                )
            }
        }
        
       
    ].filter(item => !item.hidden));
   
    const onObs = (record) => {
        return handleVerObs (record);
    }

    const onAdj = (record) => {
       
        return handleVerAdj (record);
    }
    

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelRowKeys(selectedRowKeys)
        },
    };
    
    
    

    return (
        <div>
            <Table  rowSelection={{ ...rowSelection }} columns={Columsdata} dataSource={DataLicencia} {...paramstable} rowKey="Id_contractlic"/> 
        </div>
    )
}

export default TableLicencia
