import React from 'react';
import './App.css';
import 'antd/dist/antd.min.css'; 
import Views from "./Views/Views";


function App() {
  return (
 
    <div className="App">
     <Views/>
    </div>

    
  );
}

export default App;
