import React, {useEffect}  from 'react'
import { Modal, Button, Form, Input, Space, Row, Col ,DatePicker,Select,Typography} from 'antd';
import {useAllTipoEmpleo,useAllCargo} from '../../hooks/custom-general';

import '../../Assets/Css/page.css';
import moment from 'moment'

const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;

const ModalEditLaboral = ({ viewEditLab, Loading, handleSavePostLab, handleCancelLaboral, EditDataLab}) => {
    const [formEditLaboral] = Form.useForm();
    const{cargoAlldata} = useAllCargo();
    const{TipoEmpAlldata} = useAllTipoEmpleo();
    const dateFormat = 'DD/MM/YYYY'
   
    useEffect(() => {
        
        if (ModalEditLaboral) {
            formEditLaboral.setFieldsValue({
                Cargo: EditDataLab?.id_cargo,
                TipoEmpleo: EditDataLab?.tipo_empleo,
                NombreEmpresa: EditDataLab?.Nombre_empresa,
                DireccionEmp: EditDataLab?.Ubicacion,
                FechaInicio:  moment(EditDataLab?.fecha_inicio,'YYYY/MM/DD'),
                FechaTermino: moment(EditDataLab?.fecha_finalizacion,'YYYY/MM/DD'),
                DescripcionCargo: EditDataLab?.descripcion_cargo,
              
            });
        }
       
    }, [EditDataLab, formEditLaboral]);
    
    const onAdd = async values => {
        return handleSavePostLab(values);   
    }

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;

    };

    return (
        <Modal
            visible={viewEditLab}
            forceRender
            title="Editar Exp.Laboral"
            onCancel={handleCancelLaboral}
            footer={[
                
               
            ]}
        >
             <Form
                form={formEditLaboral}
                name="NuevoPostulante"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                onFinish={onAdd}
            >
                
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Title level={5}>{"Seleccione"}</Title>
                </Col>
                </Row>
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Cargo"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Cargo',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Cargo" showArrow maxTagCount= 'responsive' 
                        showSearch
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }>
                  {cargoAlldata.map((data) => {
                        return (
                            <Option key={data.cargo_id} value={data.cargo_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}  
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="TipoEmpleo"
                    tooltip="Ingrese Descripción Cargo"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Tipo Empleo'  
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                   <Select placeholder="Tipo Empleo" >
                  {TipoEmpAlldata.map((data) => {
                        return (
                            <Option key={data.templeo_id} value={data.templeo_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}  
                </Select>
                </Form.Item>
                </Col>

                </Row>
                
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="NombreEmpresa"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Nombre Empresa',
                        },
                    ]}
                    hasFeedback
                >
                   <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Nombre Empresa"
                    />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="DireccionEmp"
                    tooltip="Direccion Empresa"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese Direccion Empresa'   
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Direccion Empresa"
                    />
                </Form.Item>
                </Col>

                </Row>

                 <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="FechaInicio"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Fecha Comienzo',
                        },
                    ]}
                    hasFeedback
                >
                    <DatePicker  format={dateFormat} style={{ width: '100%' }}  placeholder="Fecha Comienzo"/>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="FechaTermino"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Fecha Termino',
                        },
                    ]}
                    hasFeedback
                >
                    <DatePicker  format={dateFormat} style={{ width: '100%' }}  placeholder="Fecha Termino"/>
                </Form.Item>
                </Col>

                </Row>
                
                
                
                
                <Form.Item
                    name="DescripcionCargo" 
                >
                 <TextArea rows={4} placeholder="Descripcion Cargo"  style={{ width: '100%' }} />
                </Form.Item>
               
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Editar
                    </Button>
                    <Button key="back" onClick={handleCancelLaboral}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ModalEditLaboral
