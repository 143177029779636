import {useQuery,useLazyQuery,useMutation} from '@apollo/client'
import {AllPostulaciones} from "../model/query/postulaciones/AllPostulaciones"
import {ClientesByLikes} from "../model/query/postulaciones/ClientesByLikes"
import {PostulacionObs} from "../model/query/postulaciones/PostulacionObs"
import {PostulacionAdj} from "../model/query/postulaciones/PostulacionAdj"
import {PostulacionObsDet} from "../model/query/postulaciones/PostulacionObsDet"
import {PostulacionAdjDet} from "../model/query/postulaciones/PostulacionAdjDet"
import {PostulacionSelect} from "../model/query/postulaciones/PostulacionSelect"
import {createPostCliente,UpdatePostCliente,UpdatePostCargo,UpdatePostPersona,
    UpdatePostBeneficios,UpdatePostOtros,InsertObsPost,InsertAdjPost,InsertPostDet,
    InsertObsPostDet, InsertAdjPostDet,DeletePostDet} from "../model/mutation/postulaciones/Setpostulacion"




export const useAllPostulaciones = () =>{
    const result = useQuery(AllPostulaciones,{
        //fetchPolicy: 'network-only',
        pollInterval: 15000,
        onCompleted: () => console.log('called')});
    return result;
}

export const useClientesByLike = () =>{
    const result = useLazyQuery(ClientesByLikes);
    return result;
}

export const useCreatePostCliente = () =>{
   const result = useMutation(createPostCliente,{refetchQueries:[{query:AllPostulaciones}]});
    return result;
}

export const useUpdatePostCliente = () =>{
   const result = useMutation(UpdatePostCliente,{refetchQueries:[{query:AllPostulaciones}]});
    return result;
}

export const useUpdatePostCargo = () =>{
   const result = useMutation(UpdatePostCargo,{refetchQueries:[{query:AllPostulaciones}]});
    return result;
}

export const useUpdatePostPersona = () =>{
   const result = useMutation(UpdatePostPersona,{refetchQueries:[{query:AllPostulaciones}]});
    return result;
}

export const useUpdatePostBeneficios = () =>{
   const result = useMutation(UpdatePostBeneficios,{refetchQueries:[{query:AllPostulaciones}]});
    return result;
}

export const useUpdatePostOtros = () =>{
   const result = useMutation(UpdatePostOtros,{refetchQueries:[{query:AllPostulaciones}]});
    return result;
}

export const useInsertObsPost = () =>{
   const result = useMutation(InsertObsPost,{refetchQueries:[{query:AllPostulaciones}]});
    return result;
}

export const useInsertObsPostDet = () =>{
   const result = useMutation(InsertObsPostDet);
    return result;
}

export const useInsertAdjPost = () =>{
   const result = useMutation(InsertAdjPost,{});
    return result;
}

export const useInsertAdjPostDet = () =>{
   const result = useMutation(InsertAdjPostDet,{});
    return result;
}

export const useInsertPostDet = () =>{
   const result = useMutation(InsertPostDet,{});
    return result;
}


export const usePostulacionObs = () =>{
    const result = useLazyQuery(PostulacionObs,{});
    return result;
}

export const usePostulacionAdj = () =>{
    const result = useLazyQuery(PostulacionAdj,{});
    return result;
}

export const usePostulacionObsDet = () =>{
    const result = useLazyQuery(PostulacionObsDet,{});
    return result;
}

export const usePostulacionAdjDet = () =>{
    const result = useLazyQuery(PostulacionAdjDet,{});
    return result;
}

export const usePostulacionSelect = () =>{
    const result = useLazyQuery(PostulacionSelect,{});
    return result;
}

export const usePostulanteDelete = () =>{
    const result = useMutation(DeletePostDet,{});
    return result;
}

export default useAllPostulaciones 


