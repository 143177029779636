import React,{useState,useEffect}  from 'react'
import { Modal, Divider,Card,Form,Row,Col,Input ,Space,Button,Select} from 'antd';

import CardContactos from './CardContactos';
import ModalTypeContacto from './ModalTypeContacto';
import ModalLoginCreate from './ModalLoginCreate';
import '../../Assets/Css/page.css';
import {useAllTipoContacto} from '../../hooks/custom-general';
import {useContactmailById,useResetpassClient} from '../../hooks/custom-Clientes';

import InfiniteScroll from 'react-infinite-scroll-component';
const { Option } = Select;


const ModalCliecontactos = ({ EditCont,DataEditClient, Loading, handleCancelCont,dataContacto,dataContactoDet,handleNewContact,handleNewTcontact,handleNewLcontact,handleDelType,dataContactosing,handleBloqclave,handleDBloqclave,handleDelContact}) => {
  // console.log(DataEditClient.Nombre)
    const [activeTabKey1, setActiveTabKey1] = useState('Ver');
    const {data,loading} = useAllTipoContacto();
    const [ContactmailById]  = useContactmailById();
    const [SetPass] = useResetpassClient();
   
 

    const [form15] = Form.useForm();
    //const [form16] = Form.useForm();
    const [Tipodata,setTipodata] = useState([]);
    const [ContactDetdata,setContactDetdata] = useState([]);
    const [EditTCont, setEditTCont] = useState(false);
    const [EditLCont, setEditLCont] = useState(false);
    const [Maildata,setMaildata] = useState([]);
    
   

    useEffect(() => {
         setContactDetdata(dataContactosing)
        if(loading===false){    
            const datatipo = data.GetallTipoContacto
            setTipodata(datatipo)
        } 
        //setContactdata(dataContacto)
    }, [data, dataContacto, dataContactosing, loading]);
       
   
    const onAdd = async values => {
       // console.log(values)
        form15.resetFields();
        return handleNewContact(values)    
    }

    const handleCancelTCont = () => {
         setEditTCont(false);
    };

    const handleViewTContact= (values) => {
        setContactDetdata(values)
         setEditTCont(true);
         
    };

    const handleCancelLCont = () => {
         setEditLCont(false);
    };

    const handleViewLContact= async(values) => {
         
        
        const mailDet = await ContactmailById({variables:{
            Id_contacto:values.Id_contacto
        }})
         
        const datatipo = mailDet.data.GetDataContact_mail_ById
        setMaildata(datatipo)
       
           
        setContactDetdata(values)
         setEditLCont(true);
         
    };

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;
    };

    const handleResetclave = (record) => {
        if(record.id_Sesion!==null)
        {
            Modal.confirm({
                title: 'Deseas Resetear la clave del Usuario ' + record.Nombre + ' ..??',
                onOk:async () => {
                    const ResetPass = await  SetPass({variables:{
                        id_sesion:record.id_Sesion,
                    }})
                
                    const success = ResetPass.data.ResetPasswordClient.success
                    const message = ResetPass.data.ResetPasswordClient.message
                    if(success===true){   
                    const  modal = Modal.success({
                            content: message,
                        });

                        setTimeout(() => {
                            modal.destroy();
                        }, 3000);

                    }else{
                        Modal.warning({
                            content: message,
                        });  
                    
                    }  
                
                }
            })
        }
        else{
            Modal.warning({
                content: "Debe activar la sesion primero...",
            }); 
        }    

    }
    
   


    
    const tabList = [
    
    {
        key: 'Ver',
        tab: 'Empleados',
    },
    {
        key: 'contacto',
        tab: 'Nuevo Empleado',
    },
    /*{
        key: 'tipocontacto',
        tab: 'Nuevo Tipo contacto',
    },*/
    ];
    
    const contentList = {
        
    Ver: <div
      id="scrollableDiv"
      style={{
        height: '50vh',
        width: '100%',
        overflow: 'auto',
        padding: '5px 5px',
        border: '1px solid rgba(140, 140, 140, 0.35)',
      }}
    >
      <InfiniteScroll
       // pageStart={0}
        dataLength={dataContactoDet.length+dataContacto.length}
        
        //hasMore={data1.length < 50}
       // loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
        scrollableTarget="scrollableDiv"
      >
        
        <CardContactos dataContacto={dataContacto} handleViewTContact={handleViewTContact} handleViewLContact={handleViewLContact} dataContactoDet={dataContactoDet} handleDelType={handleDelType} handleDelContact={handleDelContact}/>
        </InfiniteScroll>
    </div>
    
    ,
    contacto: <Form
                form={form15}
                name="IngContacto"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                onFinish={onAdd}
            >
                
               
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Nombre"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Contacto',
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Nombre"
                    />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="DescripcionCargo"
                    tooltip="Ingrese Descripción Cargo"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Descripción Cargo'  
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Descripción Cargo"
                    />
                </Form.Item>
                </Col>

                </Row>
                
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="TipoContacto"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Tipo Contacto',
                        },
                    ]}
                    hasFeedback
                >
                    <Select placeholder="Tipo Contacto" >
                    {Tipodata.map((data,index) => {
                        return (
                            <Option key={index} value={data.tipo_contacto_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="Descripcion"
                    tooltip="Ingrese Descripción"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Descripción tipo'   
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Descripción Tipo"
                    />
                </Form.Item>
                </Col>

                </Row>

              
                
                


                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Guardar
                    </Button>
                    <Button key="back" onClick={handleCancelCont}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>,
        

    };
    
    const onTab1Change = key => {
        setActiveTabKey1(key);
    };

    return (
        <>
        <Modal
            visible={EditCont}
            title={DataEditClient.Nombre}
            style={{ top: 20 }}
            width={'100vh'}
            onCancel={handleCancelCont}
            footer={[]}
        >

        <Card
            style={{ width: '100%' }}
            bordered={false} 
            tabList={tabList}
            activeTabKey={activeTabKey1}
            onTabChange={key => {
            onTab1Change(key);
        }}
      >
        {contentList[activeTabKey1]}
      </Card>
            
        </Modal>
        <ModalTypeContacto EditTCont={EditTCont} DataEditClient={DataEditClient} Loading={Loading} handleCancelTCont={handleCancelTCont} ContactDetdata={ContactDetdata} handleNewTcontact={handleNewTcontact}/>
        <ModalLoginCreate EditLCont={EditLCont}  DataEditClient={DataEditClient} Loading={Loading} handleCancelLCont={handleCancelLCont} ContactDetdata={ContactDetdata} handleNewLcontact={handleNewLcontact} Maildata={Maildata} handleResetclave={handleResetclave} handleBloqclave={handleBloqclave} handleDBloqclave={handleDBloqclave}/>
        
       </> 
    )
   
}

export default ModalCliecontactos
