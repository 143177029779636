import gql from "graphql-tag";

export const Menuall = gql`
query{
    GetMenuTransfer {
      key
      title
      description
      
    }

}`;