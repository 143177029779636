
import {useLazyQuery} from '@apollo/client'
import {Loginuser} from "../model/query/login/Loginuser"

export const useLoginMenu = () =>{
    const result = useLazyQuery(Loginuser);
    return result;
}

export default useLoginMenu


