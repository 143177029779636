
import React,{useState,useEffect} from 'react'
import { List, Dropdown ,Avatar,Badge ,Divider,Tooltip} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useMensajeVacAlert,useUpdateMensajeVacAlert} from '../../../hooks/custom-Alertas';



const IconButtonList = ({icon,SesionID}) => {

const {data,loading} = useMensajeVacAlert(SesionID);
const [UpdateMensajeAlert] = useUpdateMensajeVacAlert(SesionID);
const [listView,setListView] = useState([]);
const [listCount,setListCount] = useState(0);
const [visible, setVisible] = useState(false);
const [creadoPor] = useState(SesionID);
    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;

    };
    

    useEffect(() => {
       
        if(loading===false){
            const dataAlert = data.GetMsgAlertByIdSesion
            setListView(dataAlert)
            
        }

        if(listView.length>=0){
            setListCount(listView.length)
        }
    }, [data, listView, loading])
    
    const onView = async (values) => {

       await UpdateMensajeAlert({variables:{
            id_Sesion:creadoPor,
            id_menalert:values.id_menalert,
        }})
       
    }

    const handleVisibleChange = (values) => {
        setVisible(values);
    };

    const list = (
        <div
            id="scrollableDiv"
            style={{
                height: 200,
                width:340,
                overflow: 'auto',
                padding: '0 16px',
                border: '1px solid rgba(140, 140, 140, 0.35)',
                backgroundColor:"#fff"

            }}
            >
            <InfiniteScroll
                dataLength={listCount}
                endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
                scrollableTarget="scrollableDiv"
            >

            <List
            itemLayout="horizontal"
            dataSource={listView}
            renderItem={item => (
            <List.Item
              actions={[ <Tooltip title={"Marcar como Leido"} color={'blue'} key={'blue'}><Icon type={'CheckCircleOutlined'} style={{color: "#1890ff" }}  onClick={() => {onView(item) }}/></Tooltip>]}>
                <List.Item.Meta
                avatar={<Avatar  style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}icon={<Icon type={item.icono} key="handlehead" />} />}
                title={item.title}
                description={item.mensaje}
                />
            </List.Item>

            
                )}
            />
            </InfiniteScroll>
        </div>
    );

    

    return (
           <Badge count={listCount}   color={'blue'} offset={[7, 0]} >
            <Dropdown.Button type="default" overlay={list}  trigger={['click']} icon={<Icon type={icon} />} onVisibleChange={handleVisibleChange} visible={visible}/>
           </Badge> 
      
    )
}




export default IconButtonList
