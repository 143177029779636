import React,{useState,useEffect}  from 'react'
import { Modal, Form,Row,Col,Input ,Space,Button,Select} from 'antd';


import '../../Assets/Css/page.css';
import {useAllTipoContacto} from '../../hooks/custom-general';

const { Option } = Select;


const ModalTypeContacto = ({ EditTCont, DataEditClient, Loading, handleCancelTCont,ContactDetdata,handleNewTcontact}) => {

   
    const {data,loading} = useAllTipoContacto();
  
    const [form16] = Form.useForm();
    const [Tipodata,setTipodata] = useState([]);
   
   

    useEffect(() => {
        if(loading===false){    
            const datatipo = data.GetallTipoContacto
            setTipodata(datatipo)
        } 

        if (EditTCont) {
           //console.log(DataEditClient)
            form16.setFieldsValue({
                id_contacto:ContactDetdata?.Id_contacto,
                NombreContacto:ContactDetdata?.Nombre,
               
            });
        }
    }, [ContactDetdata, EditTCont, data, form16, loading]);
       
   
   
    const onAdds = async values => {
        console.log(values)
        form16.resetFields();
        form16.setFieldsValue({
                id_contacto:ContactDetdata?.Id_contacto,
                NombreContacto:ContactDetdata?.Nombre,
               
            });
        return handleNewTcontact(values) 
           
    }

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;
    };

    
    return (
        <>
        <Modal
            visible={EditTCont}
            title={DataEditClient.Nombre}
           
            width={'100vh'}
            onCancel={handleCancelTCont}
            footer={[]}
        >
       

        <Form
                form={form16}
                name="TipoContacto"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                onFinish={onAdds}
            >
                

                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="NombreContacto"
                >
                <Input
                    prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                    type="text"
                    placeholder="Nombre"
                    readOnly
                />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="id_contacto"
                    hidden
                >
                <Input
                    prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                    type="text"
                    placeholder="id_contacto"
                    
                />
                </Form.Item>
                </Col>
                </Row>
               
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="TipoContacto2"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor Seleccione Tipo Contacto',
                        },
                    ]}
                    hasFeedback
                >
                    <Select placeholder="Tipo Contacto" >
                    {Tipodata.map((data) => {
                        return (
                            <Option key={data.tipo_contacto_id} value={data.tipo_contacto_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="Descripcion2"
                    tooltip="Ingrese Descripción"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Descripción'  
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Descripción"
                    />
                </Form.Item>
                </Col>

                </Row>
                
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Guardar
                    </Button>
                    <Button key="back" onClick={handleCancelTCont}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>
          
        </Modal>
       </> 
    )
   
}

export default ModalTypeContacto
