import React,{useState,useEffect} from 'react'
import moment from 'moment'
import '../Assets/Css/page.css';
import { Modal,Card,Space,Button,message} from 'antd';
import { FieldTimeOutlined } from '@ant-design/icons';
import { Excel } from "antd-table-saveas-excel";
import IcoButton from '../Components/IconButtonGroup';
import Buscar from '../Components/Loading';
import ModalClave from '../Components/ModalClave';
import {useUpdatepass} from '../hooks/custom-users';


import TableRelojControl from '../Components/contratos/relojcontrol/TableRelojControl';
import SearchEmpleRelojControl from '../Components/contratos/relojcontrol/SearchEmpleRelojControl';
import ModalRelojControlAdj from '../Components/contratos/relojcontrol/ModalRelojControlAdj';
import ModalRelojDel from '../Components/contratos/relojcontrol/ModalRelojDel';
import { useAllClientesActivo } from '../hooks/custom-Clientes'
import { useRelojControlByFilter,useUploadRelojControl,useDeleteRelojControl } from '../hooks/custom-contratos';
import { isNonEmptyArray } from '@apollo/client/utilities';
import{useVerifyToken,useVerify} from "../hooks/useToken"  

const RelojControl = () => {
   const {data:Tokensverify, loading:TokensverifyLoading}= useVerify();
   const{Tokens,TokensLoading} = useVerifyToken(); 
   const [loadingpage,setLoadingpage]= useState(true); 
   const [Visible, setVisible] = useState(false);
   const [Loading, setLoading] = useState(false);
   const [SetUpd] = useUpdatepass();
   
    const{data:clieData,loading:clieloading}= useAllClientesActivo();
   const[RelojControl] = useRelojControlByFilter(); 
   const [UploadRelojControl] = useUploadRelojControl();
   const [DeleteRelojControl] = useDeleteRelojControl();
  
   const [DataReloj, SetdataReloj] = useState(false); 
   const [viewAdj,setViewAdj] = useState(false);
   const [viewDel,setViewDel] = useState(false);
  
    const [SelFiltros, setSelFiltros] = useState();
    const [clientes, setClientes] = useState();
    const [creadoPor, setCreadoPor] = useState('');
   
   
  
    useEffect(() => {
         if(TokensverifyLoading===false){
            
            if(Tokensverify.GetLogin_validate.success===false){
                Modal.error({
                    title: Tokensverify.GetLogin_validate.message,
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");
                    }
                })
            }
           
        }

        if(TokensLoading===false){
            if(Tokens){
                 const userF=Tokens.token.split('||')
                 setCreadoPor(userF[2])
            }
        } 

        if(clieloading===false){
            console.log(clieData)
            const dataclient = clieData.GetClientAllActivo
            setClientes(dataclient) 
            setLoadingpage(false) 
        }

        
       
    }, [Tokens, TokensLoading, Tokensverify, TokensverifyLoading, clieData, clieloading])
   
   const [submenu] = useState([
        {
            key: '1',
            icon: 'KeyOutlined',
            title: 'Nueva Clave',

        }, {
            key: '2',
            icon: 'PoweroffOutlined',
            title: 'Salir',

        }
    ])

    const onGroupClick = (array) => {
       
        switch (array.key) {
            case '1':
                //console.log(array.key);
                setVisible(true);
               break
            default:
                Modal.confirm({
                    title: 'Estas Seguro de Cerrar la aplicación ..??',
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");

                        
                }
                })
        }
    }

    const handleOk = async (values) => {

       
        const EditPass = await  SetUpd({variables:{
            id_sesion:creadoPor,
            oldPass: values.claveactual,
            NewPass: values.NuevaClave,
          
        }})
      //console.log(EditPass)
       // setLoading(true);
        const success = EditPass.data.updatePassword.success
        const message = EditPass.data.updatePassword.message
        if(success===true){   
           const  modal = Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
                setVisible(false);
                modal.destroy();
            }, 3000);

        }else{
            Modal.warning({
                 content: message,
            });
            setLoading(false);
              
           
        }  

    };

    const handleCancel = () => {
        setVisible(false);
    };

   const HandleDataReloj = async (values) => {
        const reloj = await  RelojControl({variables:{
            Id_cliente: values.Id_cliente,
            Id_contrato: values.Id_contrato,
            mes:values.Mes,
            anio: moment(values.Anio).format('YYYY'),
        }}) 
        
        const filterArray=[{ Id_cliente: values.Id_cliente,Id_contrato: values.Id_contrato,mes: values.Mes,anio: moment(values.Anio).format('YYYY')}]
        setSelFiltros(filterArray)
        if(reloj.loading===false){
           SetdataReloj(reloj.data.GetRegistroRelojControl)
        }
       
    };
    
    const onAddPost=()=>{
       setViewAdj(true);
    }

    const onDelPost=()=>{
       setViewDel(true);
    }

    const handleCancelAdj = () => {
         setViewAdj(false);
    };

    const handleCancelRelojDel = () => {
         setViewDel(false);
    };
    
    const handleSaveAdj = async (values,Arrfilename) => {
       
        setLoading(true);
       
            const valor= Arrfilename.map((item) => item.filename);
            const EditAdj = await UploadRelojControl({variables:{
                Adjunto:  valor,
                Creado_por: creadoPor,
        
            }})
      
        
            const success = EditAdj.data.SetUploadRelojControl.success
            const message = EditAdj.data.SetUploadRelojControl.message
            if(success){   
                
                
                setTimeout(() => {
                    Modal.success({
                        content: message,
                    });
                    setLoading(false);
                   
                  
                }, 3000);

            }
            else{
                Modal.warning({
                    content: message,
                });
                setLoading(false);
            }  
       
         
    };

    const handleDelReloj= async (values) => {
       
        setLoading(true);
      
        const Delreloj = await DeleteRelojControl({variables:{
            Id_contrato: values.Id_contrato,
            fecha_desde: moment(values.FechaReloj[0]).format('YYYY/MM/DD'),
            fecha_hasta: moment(values.FechaReloj[1]).format('YYYY/MM/DD'),
            Creado_por: creadoPor
      
        }})
       
        const success = Delreloj.data.Elimina_RelojControl.success
        const message = Delreloj.data.Elimina_RelojControl.message
        if(success){   
            const reloj = await  RelojControl({variables:{
                Id_cliente:  SelFiltros[0].Id_cliente,
                Id_contrato:  SelFiltros[0].Id_contrato,
                mes: SelFiltros[0].Mes,
                anio: moment( SelFiltros[0].Anio).format('YYYY'),
            }}) 
    
            if(reloj.loading===false){
            SetdataReloj(reloj.data.GetRegistroRelojControl)
            }
            
            setTimeout(() => {
                Modal.success({
                    content: message,
                });
                setLoading(false);
                
                
            }, 3000);

        }
        else{
            Modal.warning({
                content: message,
            });
            setLoading(false);
        }  
       
    };

    const handleExcel = () => {
        if(isNonEmptyArray(DataReloj)){
            const Columsdata = [
                {
                    title: 'Id_registro',
                    dataIndex: 'Id_registro',
                    key: 'Id_registro',
                },
                {
                    title: 'Nro_codigo',
                    dataIndex: 'Nro',
                    key: 'Nro',
                },
                {
                    title: 'Rut',
                    dataIndex: 'rut',
                    key: 'rut',
                },
                {
                    title: 'Nombre',
                    dataIndex: 'nombre',
                    key: 'nombre',
                },
                {
                    title: 'Sucursal',
                    dataIndex: 'sucursal',
                    key: 'sucursal',
                },
                {
                    title: 'Empresa',
                    dataIndex: 'empresa',
                    key: 'empresa',
                },
                {
                    title: 'Cod_reloj',
                    dataIndex: 'id_reloj',
                    key: 'id_reloj',
                },
                {
                    title: 'Fecha',
                    dataIndex: 'format_fecha_registro',
                    key: 'format_fecha_registro',
                },
                {
                    title: 'Tipo',
                    dataIndex: 'tipo_registro',
                    key: 'tipo_registro', 
                },  
            ];
        
            const excel = new Excel();
            excel
                .addSheet("test")
                .addColumns(Columsdata)
                .addDataSource(DataReloj, {
                    str2Percent: true
                })
                .saveAs("Excel.xlsx");
        }else{
              message.warning('Seleccione información primero...');
        }
    }
    
    if(loadingpage){
        return (
        <div className="site-card-border-less-wrapper">
           
          <Buscar/>
         
        </div>
        )
    }
    return (
        <div className="site-card-border-less-wrapper">
        
            <Card size="small" style={{ textAlign: 'Left', }}  title='Maestro Reloj Control'    extra={<IcoButton icon='SettingOutlined' onChildClick={(array) => onGroupClick(array)} submenu={submenu}  />}   >
                <Card size="small" bordered={false} style={{  display: 'flex' }}>
                     <Space>
                        <Button onClick={onAddPost} icon={<FieldTimeOutlined />}>Nueva Carga</Button>
                        <Button onClick={onDelPost} icon={<FieldTimeOutlined />}>Eliminar Carga</Button>
                    </Space>
                </Card>
                
                   <SearchEmpleRelojControl HandleDataReloj = {HandleDataReloj}  SetdataReloj={SetdataReloj} clientes={clientes} handleExcel={handleExcel}/>
                   <Space>&nbsp;</Space>
                   <TableRelojControl DataReloj={DataReloj}   />
                
            </Card>
            <ModalClave Visible={Visible} Loading={Loading} handleOk={handleOk} handleCancel={handleCancel }/>
            <ModalRelojControlAdj viewAdj ={viewAdj}  Loading={Loading} handleSaveAdj={handleSaveAdj} handleCancelAdj={handleCancelAdj}  />
             <ModalRelojDel viewDel={viewDel} Loading={Loading} handleDelReloj={handleDelReloj} handleCancelRelojDel={handleCancelRelojDel} clientes={clientes}  />
          
        </div>

    );
}

export default RelojControl