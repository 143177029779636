import React,{useEffect} from 'react'
import { Modal, Button, Form, Input, Space, Row, Col,Select} from 'antd';
import '../../Assets/Css/page.css';
import {useAllExamenes,useAllBoolean,useAllLicencia} from '../../hooks/custom-general';

const { TextArea } = Input;
const { Option, OptGroup } = Select;

const ModalPostOtros = ({ EditOtros,EditDataPost, Loading,handleOtrosSave, handleCancelOtros}) => {
       const{examenesAlldata} = useAllExamenes();
    const{booleanAlldata} = useAllBoolean();
    const{licenciaAlldata} = useAllLicencia();
    const [formOtros] = Form.useForm();
    
    useEffect(() => {
        if (EditOtros) {
           
            let Exameness='';
            let Licencia_conducir='';
            if(EditDataPost.Examenes!=null)
            {
                Exameness = EditDataPost.Examenes.split(',')
                
                if(EditDataPost.Licencia_conducir!=null){
                    Licencia_conducir = EditDataPost.Licencia_conducir.split(',')
                    formOtros.setFieldsValue({
                    
                        Examenes:Exameness,
                        Renovacion:EditDataPost.Renovacion,
                        ContratoPlanta:EditDataPost.Contrato_planta,
                        Ropa:EditDataPost.Ropa,
                        EPP:EditDataPost.EPP,
                        ClaseLicConducir:Licencia_conducir,
                        DescripcionOtros:EditDataPost.Obs_otros,
                        
                        
                    })
                 }
                else{
                    formOtros.setFieldsValue({
                    
                        Examenes:Exameness,
                        Renovacion:EditDataPost.Renovacion,
                        ContratoPlanta:EditDataPost.Contrato_planta,
                        Experiencia:EditDataPost.Experiencia,
                        Ropa:EditDataPost.Ropa,
                        EPP:EditDataPost.EPP,
                        DescripcionOtros:EditDataPost.Obs_otros,
                        
                        
                    })
                }
            }   
            else{
               if(EditDataPost.Licencia_conducir!=null){
                    Licencia_conducir = EditDataPost.Licencia_conducir.split(',')
                    formOtros.setFieldsValue({
                    
                        Renovacion:EditDataPost.Renovacion,
                        ContratoPlanta:EditDataPost.Contrato_planta,
                        Ropa:EditDataPost.Ropa,
                        EPP:EditDataPost.EPP,
                        ClaseLicConducir:Licencia_conducir,
                        DescripcionOtros:EditDataPost.Obs_otros,
                        
                        
                    })
                 }
                else{
                    formOtros.setFieldsValue({
                    
                        Renovacion:EditDataPost.Renovacion,
                        ContratoPlanta:EditDataPost.Contrato_planta,
                        Ropa:EditDataPost.Ropa,
                        EPP:EditDataPost.EPP,
                        DescripcionOtros:EditDataPost.Obs_otros,
                        
                        
                    })
                }
            } 
        }
    });

    const onOtros = values => {
        return handleOtrosSave(values);     
    }

    return (
        <>
        <Modal
            visible={EditOtros}
            title="Editar Otros"
             //style={{ top: 20 }}
            onCancel={handleCancelOtros}
            footer={[]}
        >

             <Form
                layout={"vertical"}
                form={formOtros}
                name="Solicitud"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                
                onFinish={onOtros}
            >
             
              
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    label="Ropa"
                    name="Ropa"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Opción',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Ropa" >
                 <OptGroup label="Ropa">
                  {booleanAlldata.map((data) => {
                        return (
                            <Option key={data.estid} value={data.estid}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                    </OptGroup>
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                 <Form.Item
                    label="EPP"
                    name="EPP"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Opción',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="EPP" >
                <OptGroup label="EPP">
                  {booleanAlldata.map((data) => {
                        return (
                            <Option key={data.estid} value={data.estid}>
                                {data.descripcion}
                            </Option>
                        );
                    })} 
                </OptGroup>    
                </Select>
                </Form.Item>
                </Col>
                </Row>
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    label="Examenes"
                    name="Examenes"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Opción',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Examenes" mode="multiple" maxTagCount= 'responsive' showArrow>
                <OptGroup label="Examenes">
                  {examenesAlldata.map((data) => {
                        return (
                            <Option key={data.codigo} value={data.descripcion}>
                                {data.descripcion}
                            </Option>
                        );
                    })} 
                </OptGroup>     
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                 <Form.Item
                    label="Renovación"
                    name="Renovacion"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Opción',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Renovacion" >
                <OptGroup label="Renovacion">
                 {booleanAlldata.map((data) => {
                        return (
                            <Option key={data.estid} value={data.estid}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </OptGroup>    
                </Select>
                </Form.Item>
                </Col>
                </Row>
                
               <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="ContratoPlanta"
                     label="Contrato Planta"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Opción',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Contrato Planta" >
                <OptGroup label="Contrato Planta">
                 {booleanAlldata.map((data) => {
                        return (
                            <Option key={data.estid} value={data.estid}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </OptGroup>    
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                     label="Licencia Conducir"
                    name="ClaseLicConducir"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Clase Licencia Conducir',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Clase Licencia" mode="multiple" maxTagCount= 'responsive' showArrow >
                 <OptGroup label="Clase Licencia">
                  {licenciaAlldata.map((data) => {
                        return (
                            <Option key={data.codigo} value={data.descripcion}>
                                {data.descripcion}
                            </Option>
                        );
                    })} 
                    </OptGroup> 
                </Select>
                </Form.Item>
                </Col>
                </Row>

                <Form.Item
                    name="DescripcionOtros" 
                >
                 <TextArea rows={4} placeholder="Observaciones"  style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Guardar
                    </Button>
                    <Button key="back" onClick={handleCancelOtros}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>   
            
        </Modal>
        </> 
    )
}

export default ModalPostOtros
