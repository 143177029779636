import React,{useState}  from 'react'
import { Modal, Button,Space ,Tabs ,Descriptions,PageHeader,Avatar} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import ModalPostEditBenef from '../Postulaciones/ModalPostEditBenef';
import ModalPostEditCargo from '../Postulaciones/ModalPostEditCargo';
import ModalPostEditClient from '../Postulaciones/ModalPostEditClient';
import ModalPostEditOtros from '../Postulaciones/ModalPostEditOtros';
import ModalPostEditPers from '../Postulaciones/ModalPostEditPers';
import '../../Assets/Css/page.css';

const { TabPane } = Tabs;


const ModalPostView = ({ ViewCliente,EditDataPost, Loading, handleUpdateClient,handleCargoSave,handlePersonaSave,handleBeneficiosSave,handleOtrosSave,handleCancelView}) => {
const [EditBeneficio, setEditBeneficio] = useState(false);
const [EditOtros, setEditOtros] = useState(false);
const [EditPersona, setEditPersona] = useState(false);
const [EditCargo, setEditCargo] = useState(false);
const [EditCliente, setEditCliente] = useState(false);   
   
const Icon = ({ type, ...rest }) => {
    const icons = require(`@ant-design/icons`);
    const Component = icons[type];
    return <Component {...rest} />;

};

const onAgreeBef= () => {
    setEditBeneficio(true)
}

const handleCancelbef = () => {
    setEditBeneficio(false);
};

const onAgreeCargo= () => {
   setEditCargo(true);
}

const handleCancelCargo = () => {
    setEditCargo(false);
};

const onAgreeClient= () => {
    setEditCliente(true)
}

const handleCancelClient = () => {
    setEditCliente(false);
};

const onAgreeOtros= () => {
    setEditOtros(true)
}

const handleCancelOtros = () => {
    setEditOtros(false);
};

const onAgreePers= () => {
    setEditPersona(true)
}

const handleCancelPers = () => {
    setEditPersona(false);
};


    return (
        <>
        <Modal
            visible={ViewCliente}
            width={'100vh'}
            title="Nueva postulación"
             style={{ top: 20 }}
            onCancel={handleCancelView}
            footer={[]}
        >

        <Tabs defaultActiveKey="1" destroyInactiveTabPane={true}  >
            <TabPane tab="Cliente" key="1">
                <PageHeader
                    title= {"Requerimiento Nro." + EditDataPost.id_postulacion_cab}
                    className="site-page-header"
                   // subTitle={EditDataPost.Fecha_creacion}
                   extra={[<Button  key="B1" onClick={() => { onAgreeClient() }}  icon={<Icon type='EditOutlined' />}/> ]}
                   
                    avatar={{src:<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="SolutionOutlined" key="handlehead2" />} />}}
                   
                >
                <div
                        id="scrollableDiv"
                        style={{
                            height: 200,
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                    <InfiniteScroll
                        dataLength="1"
                        scrollableTarget="scrollableDiv"
                    >
                        <p></p>
                        <Descriptions  size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                        
                        <Descriptions.Item label="Codigo">{EditDataPost.Id_cliente}</Descriptions.Item>
                        <Descriptions.Item label="Solicitante">{EditDataPost.NombreSolicitante}</Descriptions.Item>
                        <Descriptions.Item label="Cliente">{EditDataPost.Cliente}</Descriptions.Item>
                    </Descriptions>
                    </InfiniteScroll>
                </div> 
                </PageHeader>   
              
            </TabPane>
            <TabPane tab="Cargo" key="2">
                    
                    <PageHeader
                    title= {"Requerimiento Nro." + EditDataPost.id_postulacion_cab}
                    className="site-page-header"
                   // subTitle={EditDataPost.Fecha_creacion}
                   extra={[<Button key="B2" onClick={() => { onAgreeCargo() }}  icon={<Icon type='EditOutlined' />}/> ]}
                   
                    avatar={{src:<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="SolutionOutlined" key="handlehead2" />} />}}
                   
                    >
                    <div
                        id="scrollableDiv"
                        style={{
                            height: 200,
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                    <Space>
                    <InfiniteScroll
                        dataLength="1"
                        scrollableTarget="scrollableDiv"
                    >
                    
                    {
                      
                        <>
                        <p></p>
                        <Descriptions  size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                    
                            <Descriptions.Item label="Cargo">{EditDataPost.DCargo}</Descriptions.Item>
                            <Descriptions.Item label="Nro Vacantes">{EditDataPost.Nro_vacantes}</Descriptions.Item>
                            <Descriptions.Item label="Horario Empleo">{EditDataPost.DHorario_empleo}</Descriptions.Item>
                            <Descriptions.Item label="Tipo Contrato">{EditDataPost.DTipo_contrato}</Descriptions.Item>
                            <Descriptions.Item label="Meses Plazo Fijo">{EditDataPost.Meses_plazo_fijo}</Descriptions.Item>
                            <Descriptions.Item label="Experiencia">{EditDataPost.DExperiencia}</Descriptions.Item>
                            <Descriptions.Item label="Obs.">{EditDataPost.DescripcionCargo}</Descriptions.Item>
                            
                            
                        </Descriptions>
                       
                        </>
                        
                        
                    }
                   
                    </InfiniteScroll>
                     </Space>
                    </div>
                    </PageHeader>
                    
            </TabPane>
            <TabPane tab="Persona" key="3">
                <PageHeader
                    title= {"Requerimiento Nro." + EditDataPost.id_postulacion_cab}
                    className="site-page-header"
                   // subTitle={EditDataPost.Fecha_creacion}
                   extra={[<Button key="B3" onClick={() => { onAgreePers() }}  icon={<Icon type='EditOutlined' />}/> ]}
                   
                    avatar={{src:<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="SolutionOutlined" key="handlehead2" />} />}}
                   
                >
                <div
                        id="scrollableDiv"
                        style={{
                            height: 200,
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                    <InfiniteScroll
                        dataLength="1"
                        scrollableTarget="scrollableDiv"
                    >
                    {
                       
                        <>
                        <p></p>
                            <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                            <Descriptions.Item label="Nivel cursado">{EditDataPost.DNivel_cursado}</Descriptions.Item>
                            <Descriptions.Item label="Genero">{EditDataPost.Genero}</Descriptions.Item>
                            <Descriptions.Item label="Rest.Nacionalidad">{EditDataPost.Drest_nacionalidad}</Descriptions.Item>
                            <Descriptions.Item label="Rest.Comuna">{EditDataPost.Drest_comuna}</Descriptions.Item>
                             <Descriptions.Item label="Rango Edad">{EditDataPost.DRango_edad}</Descriptions.Item>
                            <Descriptions.Item label="Persona Discapacitada">{EditDataPost.Dpersona_discapacitada}</Descriptions.Item>
                            <Descriptions.Item label="Obs.">{EditDataPost.Obs_personas}</Descriptions.Item>
                        
                            </Descriptions>
                            
                        </>
                       
                        
                    }
                    </InfiniteScroll>
                </div>
                </PageHeader>
            </TabPane>
            <TabPane tab="Remuneración" key="4">
                <PageHeader
                    title= {"Requerimiento Nro." + EditDataPost.id_postulacion_cab}
                    className="site-page-header"
                   // subTitle={EditDataPost.Fecha_creacion}
                   extra={[<Button key="B4" onClick={() => { onAgreeBef() }}  icon={<Icon type='EditOutlined' />}/> ]}
                   
                    avatar={{src:<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="SolutionOutlined" key="handlehead2" />} />}}
                   
                >
                <div
                        id="scrollableDiv"
                        style={{
                            height: 200,
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                    <InfiniteScroll
                        dataLength="1"//endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                    {
                       
                        <>
                        <p></p>
                        <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                            <Descriptions.Item label="Sueldo Liquido">{new Intl.NumberFormat('es-CL', {currency: 'CLP', style: 'currency'}).format(EditDataPost.Sueldo_liquido)}</Descriptions.Item>
                            <Descriptions.Item label="Sueldo Base">{new Intl.NumberFormat('es-CL', {currency: 'CLP', style: 'currency'}).format(EditDataPost.Sueldo_base)}</Descriptions.Item>
                            <Descriptions.Item label="Bono">{new Intl.NumberFormat('es-CL', {currency: 'CLP', style: 'currency'}).format(EditDataPost.Bono)}</Descriptions.Item>
                            <Descriptions.Item label="Hora Extra">{EditDataPost.Dhextra}</Descriptions.Item>
                            <Descriptions.Item label="Monto Colación">{new Intl.NumberFormat('es-CL', {currency: 'CLP', style: 'currency'}).format(EditDataPost.Monto_colacion)}</Descriptions.Item>
                            <Descriptions.Item label="Monto Movilización">{new Intl.NumberFormat('es-CL', {currency: 'CLP', style: 'currency'}).format(EditDataPost.Monto_movilizacion)}</Descriptions.Item>
                           
                            <Descriptions.Item label="Obs.">{EditDataPost.Obs_beneficios}</Descriptions.Item>
                            </Descriptions>
                            
                            </>
                       
                        
                    }
                    </InfiniteScroll>
                </div>
                </PageHeader>
               
            </TabPane>
            <TabPane tab="Otros" key="5">
                <PageHeader
                    title= {"Requerimiento Nro." + EditDataPost.id_postulacion_cab}
                    className="site-page-header"
                   // subTitle={EditDataPost.Fecha_creacion}
                   extra={[<Button  key="B5" onClick={() => { onAgreeOtros() }}  icon={<Icon type='EditOutlined' />}/> ]}
                   
                    avatar={{src:<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="SolutionOutlined" key="handlehead2" />} />}}
                   
                >
                <div
                        id="scrollableDiv"
                        style={{
                            height: 200,
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                    <InfiniteScroll
                        dataLength="1"//endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                    {
                       
                        <>
                        <p></p>
                        <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                            <Descriptions.Item label="Ropa">{EditDataPost.dropa}</Descriptions.Item>
                            <Descriptions.Item label="EPP">{EditDataPost.dEPP}</Descriptions.Item>
                            <Descriptions.Item label="Examenes">{EditDataPost.Examenes}</Descriptions.Item>
                            <Descriptions.Item label="Renovación">{EditDataPost.Drenovacion}</Descriptions.Item>
                            <Descriptions.Item label="Contrato Planta">{EditDataPost.Dcontrato_planta}</Descriptions.Item>
                            <Descriptions.Item label="Licencia">{EditDataPost.Licencia_conducir}</Descriptions.Item>
                          
                            
                            <Descriptions.Item label="Obs.">{EditDataPost.Obs_otros}</Descriptions.Item>
            
                            </Descriptions>
                            
                            </>
                       
                        
                    }
                    </InfiniteScroll>
                </div>
                </PageHeader>
            </TabPane>
        </Tabs>

        <ModalPostEditBenef  EditBeneficio={EditBeneficio} EditDataPost={EditDataPost}  Loading={Loading} handleBeneficiosSave={handleBeneficiosSave} handleCancelbef={handleCancelbef}/>
        <ModalPostEditCargo  EditCargo={EditCargo} EditDataPost={EditDataPost} Loading={Loading} handleCargoSave={handleCargoSave} handleCancelCargo={handleCancelCargo}/>
        <ModalPostEditClient EditCliente={EditCliente} EditDataPost={EditDataPost} Loading={Loading} handleUpdateClient={handleUpdateClient} handleCancelClient={handleCancelClient}/>
        <ModalPostEditOtros  EditOtros={EditOtros} EditDataPost={EditDataPost} Loading={Loading} handleOtrosSave={handleOtrosSave} handleCancelOtros={handleCancelOtros}/>
        <ModalPostEditPers EditPersona={EditPersona} EditDataPost={EditDataPost} Loading={Loading} handlePersonaSave={handlePersonaSave} handleCancelPers={handleCancelPers}/>
       
        </Modal>
        
        </> 
    )
}

export default ModalPostView
