import React,{useState,useEffect} from 'react'
import { Modal,Tabs, PageHeader, Descriptions,Divider,Avatar,List,Tooltip} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import '../../Assets/Css/page.css';

import  {   useEmpleadoEduById,useEmpleadoLabById,useEmpleadoCertById,useEmpleadoIdiomaById,
            useEmpleContactosById,useEmpleAdjuntos,
        } from '../../hooks/custom-Empleados';
   
   
const { TabPane } = Tabs;


const ModalPostulanteView = ({ viewdataPost, handleEditPost,handleCancelPostView,DataPostulant}) => {
    
    const [ViewPostulanteEdu] = useEmpleadoEduById();
    const [ViewPostulanteLab] = useEmpleadoLabById();
    const [ViewPostulanteCert] = useEmpleadoCertById();
    const [ViewPostulanteCont] = useEmpleContactosById();
    const [ViewPostulanteIdioma] = useEmpleadoIdiomaById();
    const [SelDataAdj] = useEmpleAdjuntos();
    
 
   

    
    const [dataEduView, setDataEduView] = useState([]);
    const [dataLabView, setDataLabView] = useState([]);
    const [dataIdiView, setDataIdiView] = useState([]);
    const [dataContView, setDataContView] = useState([]);
    const [dataCertView,setDataCertView] = useState([]);
    const [dataAdjPost, setDataAdjPost] = useState([]);

     

     useEffect(()=>{

        

        async function getDataViewAll(){
        
            if(DataPostulant.Id_empleado!==undefined){
                const empleadoEduView = await  ViewPostulanteEdu({variables:{
                    id_empleado: DataPostulant.Id_empleado,
                }})
                
                
                if(empleadoEduView.loading===false){
                    setDataEduView(empleadoEduView.data.GetempleadoEduById)          
                } 

                  const empleadoLabView = await  ViewPostulanteLab({variables:{
                    id_empleado: DataPostulant.Id_empleado,
                }})
                

               
                if(empleadoLabView.loading===false){
                    setDataLabView(empleadoLabView.data.GetempleadolabById)
                    
                } 

               const empleadoCertView = await  ViewPostulanteCert({variables:{
                    id_empleado:DataPostulant.Id_empleado,
                }})
                
                if(empleadoCertView.loading===false){
                setDataCertView(empleadoCertView.data.GetempleadoCertById)
                
                } 
                
                const empleadoContView = await  ViewPostulanteCont({variables:{
                    id_empleado: DataPostulant.Id_empleado,
                }})
                
                if(empleadoContView.loading===false){
                   
                    setDataContView(empleadoContView.data.GetContactosById)
                
                } 
     
                const empleadoIdiView = await  ViewPostulanteIdioma({variables:{
                    id_empleado: DataPostulant.Id_empleado,
                }})
           
                if(empleadoIdiView.loading===false){
                      // console.log(empleadoIdiView.data.GetempleadoidiomaById)
                    setDataIdiView(empleadoIdiView.data.GetempleadoidiomaById)
                
                }

                const SelAdjData = await SelDataAdj({variables:{
                    Id_empleado:DataPostulant.Id_empleado,
                }})
                setDataAdjPost(SelAdjData.data.GetempleadoAdjById)
                 
            }
        }
        getDataViewAll()

    },[DataPostulant, SelDataAdj, ViewPostulanteCert, ViewPostulanteCont, ViewPostulanteEdu, ViewPostulanteIdioma, ViewPostulanteLab])
   
   
    const onView = (values) => {
      
        let url=process.env.REACT_APP_DOWNLOAD+'/'+values
        window.open(url,'_parent');
    }
   
    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;
    };
   

   
    return (
        <>
        <Modal
            visible={viewdataPost}
            title={'Postulante'}
            style={{ top: 20 }}
            width={'130vh'}
            onCancel={handleCancelPostView}
            footer={[]}
        >
        <Tabs defaultActiveKey="1" destroyInactiveTabPane={true} >
            <TabPane tab="Postulantes" key="1">
                <PageHeader
                    title={DataPostulant.Nombre}
                    className="site-page-header"
                   // subTitle="Rut: {{DataPostulant.Rut}} " 
                   
                    avatar={{src:<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="UserOutlined" key="handlehead2" />} />}}
                   
                >
                <div
                        id="scrollableDiv"
                        style={{
                            height: 200,
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                    <InfiniteScroll
                        dataLength="1"
                        //endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                        <p></p>
                        <Descriptions  size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                        >
                        <Descriptions.Item label="Rut">{DataPostulant.Rut}</Descriptions.Item>
                    
                        <Descriptions.Item label="Edad">{DataPostulant.edad}</Descriptions.Item>
                        <Descriptions.Item label="Estado Civil">{DataPostulant.Estado_civil}</Descriptions.Item>
                        <Descriptions.Item label="Genero">{DataPostulant.Sexo}</Descriptions.Item>
                        <Descriptions.Item label="Nacionalidad">{DataPostulant.Nacionalidad}</Descriptions.Item>
                        <Descriptions.Item label="Comuna">{DataPostulant.comuna_nombre}</Descriptions.Item>
                        <Descriptions.Item label="Dirección">{DataPostulant.Rut}</Descriptions.Item>
                    </Descriptions>
                    </InfiniteScroll>
                </div>    
                </PageHeader>
            </TabPane>
            <TabPane tab="Educación" key="2">
                <PageHeader
                    
                    title={DataPostulant.Nombre}
                    className="site-page-header"
                // subTitle="Rut: {{DataPostulant.Rut}} "
                    
                    avatar={{src:<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="UserOutlined" key="handlehead2" />} />}}
                
                >
                    <div
                        id="scrollableDiv"
                        style={{
                            height: 200,
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                    <InfiniteScroll
                        dataLength={dataEduView.length}
                        //endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                    {
                        dataEduView.map((item) =>(
                        <>
                        <p></p>
                        <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                            >
                    
                                    
                            <Descriptions.Item label="Nivel Cursado">{item.nivel_cursado}</Descriptions.Item>
                            <Descriptions.Item label="Inst_educativa">{item.Inst_Educativa}</Descriptions.Item>
                            <Descriptions.Item label="Titulo">{item.Titulo}</Descriptions.Item>
                            <Descriptions.Item label="Fecha_egreso">{item.fecha_egreso}</Descriptions.Item>
                            <Descriptions.Item label="Nota_egreso">{item.Nota_egreso}</Descriptions.Item>
                            <Descriptions.Item label="descripcion">{item.descripcion}</Descriptions.Item>
                        
                            
                            

                        </Descriptions>
                        <Divider />
                        </>
                        ))
                        
                    }
                    </InfiniteScroll>
                    </div>
                </PageHeader>
                
            </TabPane>
            <TabPane tab="Laboral" key="3">
                <PageHeader
                    title={DataPostulant.Nombre}
                    className="site-page-header"
                // subTitle="Rut: {{DataPostulant.Rut}} "
                
                    avatar={{src:<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="UserOutlined" key="handlehead2" />} />}}
                
                >
                <div
                        id="scrollableDiv"
                        style={{
                            height: 200,
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                    <InfiniteScroll
                        dataLength={dataLabView.length}
                        //endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                    {
                        dataLabView.map((item) =>(
                        <>
                        <p></p>
                            <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                            >
                            <Descriptions.Item label="Cargo">{item.cargo}</Descriptions.Item>
                            <Descriptions.Item label="Tipo Empleo">{item.empleo_tipo}</Descriptions.Item>
                            <Descriptions.Item label="Nombre empresa">{item.Nombre_empresa}</Descriptions.Item>
                            <Descriptions.Item label="Ubicacion">{item.Ubicacion}</Descriptions.Item>
                            <Descriptions.Item label="Fecha inicio">{item.fecha_inicio}</Descriptions.Item>
                            <Descriptions.Item label="Fecha finalizacion">{item.fecha_finalizacion}</Descriptions.Item>
                            <Descriptions.Item label="Descripcion Cargo">{item.descripcion_cargo}</Descriptions.Item>
                        
                            </Descriptions>
                            <Divider />
                        </>
                        ))
                        
                    }
                    </InfiniteScroll>
                </div>
                </PageHeader>   
            </TabPane>
            <TabPane tab="Certificados" key="4">
                <PageHeader
                    title={DataPostulant.Nombre}
                    className="site-page-header"
                // subTitle="Rut: {{DataPostulant.Rut}} "
                    
                    avatar={{src:<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="UserOutlined" key="handlehead2" />} />}}
                
                >
                <div
                        id="scrollableDiv"
                        style={{
                            height: 200,
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                    <InfiniteScroll
                        dataLength={dataCertView.length}
                        //endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                    {
                        dataCertView.map((item) =>(
                        <>
                        <p></p>
                        <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                        >
                            <Descriptions.Item label="Emitido por">{item.Empresa_emisora}</Descriptions.Item>
                            <Descriptions.Item label="Certificación">{item.nombre_cert_licencia}</Descriptions.Item>
                            <Descriptions.Item label="Caduca">{item.fecha_de_caducidad}</Descriptions.Item>
                            <Descriptions.Item label="Id credencial">{item.id_credencial_licencia}</Descriptions.Item>
                            <Descriptions.Item label="Url">{item.URL_credencial}</Descriptions.Item>
            
                            </Descriptions>
                            <Divider />
                            </>
                        ))
                        
                    }
                    </InfiniteScroll>
                </div>
                </PageHeader>
            </TabPane>
            <TabPane tab="Idiomas" key="5">
                <PageHeader
                    title={DataPostulant.Nombre}
                    className="site-page-header"
                   // subTitle="Rut: {{DataPostulant.Rut}} "
                    
                     avatar={{src:<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="UserOutlined" key="handlehead2" />} />}}
                   
                >
                <div
                        id="scrollableDiv"
                        style={{
                            height: 200,
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                    <InfiniteScroll
                        dataLength={dataIdiView.length}
                        endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                    <p></p>
                    <List
                            itemLayout="horizontal"
                            dataSource={dataIdiView}
                            renderItem={item => (
                            <List.Item
                                
                            >
                                <List.Item.Meta
                                avatar={<Avatar style={{ backgroundColor: "#1890ff" }} icon={<Icon type="GlobalOutlined" key="handlehead" />} />}
                                title="Idioma"
                                description={item.idioma}
                                />
                            </List.Item>
                                )}
                            />

                    </InfiniteScroll>
                </div>
                </PageHeader>
            </TabPane>
            <TabPane tab="Contactos" key="6">
                <PageHeader
                    title={DataPostulant.Nombre}
                    className="site-page-header"
                   // subTitle="Rut: {{DataPostulant.Rut}} "
                    
                   avatar={{src:<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="UserOutlined" key="handlehead2" />} />}}
                     
                >
                <div
                    id="scrollableDiv"
                    style={{
                        height: 200,
                        overflow: 'auto',
                        padding: '0 16px',
                        border: '1px solid rgba(140, 140, 140, 0.35)',
                    }}
                    >
                    <InfiniteScroll
                        dataLength={dataContView.length}
                        endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                    <p></p>
                    <List
                            itemLayout="horizontal"
                            dataSource={dataContView}
                            renderItem={item => (
                            <List.Item
                               
                            >
                                <List.Item.Meta
                                avatar={<Avatar style={{ backgroundColor: "#1890ff" }} icon={<Icon type={item.Icon} key="handlehead" />} />}
                                title={item.title}
                                description={item.Observaciones}
                                />
                            </List.Item>
                                )}
                            />
                   
                    </InfiniteScroll>
                </div>
                </PageHeader>
            </TabPane>
            <TabPane tab="Adjuntos" key="7">
                <PageHeader
                    title={DataPostulant.Nombre}
                    className="site-page-header"
                   // subTitle="Rut: {{DataPostulant.Rut}} "
                    
                   avatar={{src:<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="UserOutlined" key="handlehead2" />} />}}
                     
                >
                <div
                    id="scrollableDiv"
                    style={{
                        height: 200,
                        overflow: 'auto',
                        padding: '0 16px',
                        border: '1px solid rgba(140, 140, 140, 0.35)',
                    }}
                    >
                <InfiniteScroll
                // pageStart={0}
                    dataLength={dataAdjPost.length}
                    
                    //hasMore={data1.length < 50}
                // loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                    endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
                    scrollableTarget="scrollableDiv"
                >
                    
                    <List
                    itemLayout="horizontal"
                    dataSource={dataAdjPost}
                    renderItem={item => (
                        <List.Item
                        actions={[<Tooltip title={item.adjunto} color={'blue'} key={'blue'}><Icon type={'FileTextOutlined'}  onClick={() => {onView(item.adjunto) }}/></Tooltip>]}
                    >
                
                        <List.Item.Meta
                        
                        title={item.title}
                        description={item.Descripcion}
                        />
                    </List.Item>
                        )}
                    />
                    </InfiniteScroll>
                </div>
                </PageHeader>
            </TabPane>
        </Tabs>
      </Modal>
      
       </> 
    )
   
}

export default ModalPostulanteView
