import React,{useState,useEffect} from 'react'
import moment from 'moment'
import {  format } from 'rut.js'
import '../Assets/Css/page.css';
import { Modal,Card,Space,Button} from 'antd';
import { UsergroupAddOutlined  } from '@ant-design/icons';
import IcoButton from '../Components/IconButtonGroup';
import Buscar from '../Components/Loading';
import ModalClave from '../Components/ModalClave';
import {useUpdatepass} from '../hooks/custom-users';


import TableContratado from '../Components/contratos/creacioncontratado/TableContratado';
import SearchEmpleContrato from '../Components/contratos/creacioncontratado/SearchEmpleContrato';
import ModalEmpleNew from '../Components/contratos/creacioncontratado/ModalEmpleNew';
import ModalContratadoView from '../Components/contratos/creacioncontratado/ModalContratadoView';


import { useContratados_ByFilter,useNuevo_EmpleadoLaboral,useNuevo_EmpleadoContrato,useNuevo_EmpleadoBanco,
         useNuevo_EmpleadoVacaciones } from '../hooks/custom-contratos';
import { useAllAfp,useAllIsapres,useAllTContrato,useAllBancos } from '../hooks/custom-general'
import { useAllClientesActivo } from '../hooks/custom-Clientes'
import{ useCreatePostulanteCont } from '../hooks/custom-Empleados';
import{useVerifyToken,useVerify} from "../hooks/useToken"  

const CreacionContratado = () => {
    const {data:Tokensverify, loading:TokensverifyLoading}= useVerify();
    const{Tokens,TokensLoading} = useVerifyToken();
    const{allAfpdata,AfpLoading}= useAllAfp();
    const{allIsapresdata,IsapresLoading}= useAllIsapres();
    const {allBancosdata,BancosLoading} = useAllBancos();
    const{tcontratoAlldata,istcontratoLoading}= useAllTContrato();
    const{data:clieData,loading:clieloading}= useAllClientesActivo();
    
    const [clientes, setClientes] = useState();
    const [Nuevo_EmpleadoLaboral] = useNuevo_EmpleadoLaboral();
    const [Nuevo_EmpleadoContrato] = useNuevo_EmpleadoContrato();
    const [Nuevo_EmpleadoBanco] = useNuevo_EmpleadoBanco();
    const [Nuevo_EmpleadoVacaciones] = useNuevo_EmpleadoVacaciones();
    const [SetCreatePostulanteCont] = useCreatePostulanteCont();
     


    const [afp, setAfp] = useState();
     const [Banco, setBanco] = useState();
    const [isapre, setIsapre] = useState();
    const [contratos, setContratos] = useState();
    const [loadingpage, setloadingpage] = useState(true);
    const [Visible, setVisible] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [SetUpd] = useUpdatepass();

    const[ListaEmpleados] =  useContratados_ByFilter(); 
   
  
   const [DataEmpleado, SetdataEmpleado] = useState(false); 
   const [viewNew,setViewNew] = useState(false);
   const [contratoView,setContratoView] = useState(false);
   const [newEmplename, setNewEmplename] = useState(false);
   const [newEmpleId, setNewEmpleId] = useState(false);
   const [newContractId, setNewContractId] = useState(false);
   
   const [DataContrato, setDataContrato] = useState([]);
   const [creadoPor, setCreadoPor] = useState('');
 
   
   
  
    useEffect(() => {
        if(TokensverifyLoading===false){
            
            if(Tokensverify.GetLogin_validate.success===false){
                Modal.error({
                    title: Tokensverify.GetLogin_validate.message,
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");
                    }
                })
            }
           
        }

       if(TokensLoading===false){
            if(Tokens){
                 const userF=Tokens.token.split('||')
                 setCreadoPor(userF[2])
            }
        } 
         
        if(IsapresLoading===false){
            setIsapre(allIsapresdata)
        }

         if(AfpLoading===false){
            setAfp(allAfpdata)
        }

        if(BancosLoading===false){
            setBanco(allBancosdata)
        }

        if(istcontratoLoading===false){
            setContratos(tcontratoAlldata)
        }

        if(clieloading===false){
            const dataclient = clieData.GetClientAllActivo
             
            setClientes(dataclient)
             
        }
        if(clieloading===false && AfpLoading===false && IsapresLoading===false && istcontratoLoading===false && BancosLoading===false){
            setloadingpage(false)
        }
     
    }, [AfpLoading, BancosLoading, IsapresLoading, Tokens, TokensLoading, Tokensverify, TokensverifyLoading, allAfpdata, allBancosdata, allIsapresdata, clieData, clieloading, istcontratoLoading, tcontratoAlldata])
   
   const [submenu] = useState([
        {
            key: '1',
            icon: 'KeyOutlined',
            title: 'Nueva Clave',

        }, {
            key: '2',
            icon: 'PoweroffOutlined',
            title: 'Salir',

        }
    ])

    const onGroupClick = (array) => {
       
        switch (array.key) {
            case '1':
                //console.log(array.key);
                setVisible(true);
               break
            default:
                Modal.confirm({
                    title: 'Estas Seguro de Cerrar la aplicación ..??',
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");

                        
                }
                })
        }
    }

    const handleOk = async (values) => {

      
       
        const EditPass = await  SetUpd({variables:{
            id_sesion:creadoPor,
            oldPass: values.claveactual,
            NewPass: values.NuevaClave,
          
        }})
      //console.log(EditPass)
       // setLoading(true);
        const success = EditPass.data.updatePassword.success
        const message = EditPass.data.updatePassword.message
        if(success===true){   
           const  modal = Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
                setVisible(false);
                modal.destroy();
            }, 3000);

        }else{
            Modal.warning({
                 content: message,
            });
            setLoading(false);
              
           
        }  

    };

    const handleCancel = () => {
        setVisible(false);
    };

   const HandleDataEmple = async (values) => {
    
        const Empleados = await  ListaEmpleados({variables:{
           Id_cliente: values.Id_cliente,
            Id_contrato: values.Id_contrato,
            Tipo_contrato: values.Tipo_contrato,
            id_isapre: values.id_isapre,
            id_afp: values.id_afp,
        }})
 
        if(Empleados.loading===false){
           SetdataEmpleado(Empleados.data.GetContratados_ByFilter)
        }
       
    };
    
    const onAddEmple=()=>{
       setViewNew(true);
    }
    
    const handleCancelEmple = () => {
        setNewEmpleId(false)
        setNewEmplename('Agregar un Empleado Primero...')
        setViewNew(false);
    };

    const handleCancelContView = () => {
        setContratoView(false)
    };

    const handleEmpleSave = async (values) => {
        //console.log(values)
        setLoading(true);
        const NewEmple = await Nuevo_EmpleadoContrato({variables:{
            Rut: format(values.Rut),
            Fecha_nacimiento:  moment(values.FechaNacimiento).format('YYYY/MM/DD'),
            Nombre: values.Nombre,
            Id_cliente: values.Empresa,
            Id_sexo: values.Sexo,
            Id_Nacionalidad: values.Nacionalidad,
            Id_estado_civil: values.EstadoCivil,
            Id_comuna: values.Comuna,
            Direccion: values.Direccion,
            creado_por: creadoPor,

      
        }})
        
            const success = NewEmple.data.Nuevo_EmpleadoContrato.success
            const message = NewEmple.data.Nuevo_EmpleadoContrato.message
            setNewEmpleId(NewEmple.data.Nuevo_EmpleadoContrato.Id_empleado);
            setNewContractId(NewEmple.data.Nuevo_EmpleadoContrato.Id_contrato);
            if(success){   
               
                setNewEmplename(values.Nombre)
                setTimeout(() => {
                    Modal.success({
                        content: message,
                    });
                    setLoading(false);
                   
                  
                }, 1000);

            }
            else{
                Modal.warning({
                    content: message,
                });
                setLoading(false);
            } 
           

    };

    const handleLaboralSave = async (values) => {

        setLoading(true);
        console.log(newContractId)
        const NewEmpleLab = await Nuevo_EmpleadoLaboral({variables:{
            Id_contrato: newContractId,
            Tipo_contrato: values.TipoContrato,
            id_cargo: values.Cargo,
            fecha_ini_contrato: moment(values.FechaDesde).format('YYYY/MM/DD'),
            fecha_fin_contrato: moment(values.FechaHasta).format('YYYY/MM/DD'),
            id_isapre: values.id_isapre,
            id_afp:values.id_afp,
            creado_por: creadoPor,

      
        }})
       
         
            const success = NewEmpleLab.data.Nuevo_EmpleadoLaboral.success
            const message = NewEmpleLab.data.Nuevo_EmpleadoLaboral.message
           
            if(success){   
                
                
                setTimeout(() => {
                    Modal.success({
                        content: message,
                    });
                    setLoading(false);
                   
                  
                }, 1000);

            }
            else{
                Modal.warning({
                    content: message,
                });
                setLoading(false);
            } 
    }

    const handleFinancieroSave = async (values) => {

        setLoading(true);
        //console.log(newEmpleId,values)
        const NewEmpleBanco = await Nuevo_EmpleadoBanco({variables:{
            Id_contrato: newContractId,
            Id_entfin: values.Banco,
            nrocuenta: values.CtaCte,
            creado_por: creadoPor,
        }})
       
       
            const success = NewEmpleBanco.data.Nuevo_EmpleadoBanco.success
            const message = NewEmpleBanco.data.Nuevo_EmpleadoBanco.message
           
            if(success){   
                setTimeout(() => {
                    Modal.success({
                        content: message,
                    });
                    setLoading(false);
                   
                  
                }, 1000);

            }
            else{
                Modal.warning({
                    content: message,
                });
                setLoading(false);
            } 
    }

    const handleContactoSave = async (values) => {
        //setLoading(true);
         console.log(newEmpleId,values)
        const CreateResult = await  SetCreatePostulanteCont({variables:{
            Id_empleado: newEmpleId,
            Id_tipocontacto: values.TipoContacto2,
            descripcion: values.Descripcion2,
            Creado_por: creadoPor,

        
        }})
       
        setLoading(true);
        const success = CreateResult.data.createPostulanteCont.success
        const message = CreateResult.data.createPostulanteCont.message
        if(success){   
            Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
               
           }, 1000);

        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }

    };

    const handleVacacionesSave= async (values) => {
       // setLoading(true);
        
        const CreateResult = await  Nuevo_EmpleadoVacaciones({variables:{
            Id_contrato: newContractId,
            DiasVacaciones: values.Dias,
            creado_por: creadoPor,

        
        }})
       
        setLoading(true);
        const success = CreateResult.data.Nuevo_EmpleadoVacaciones.success
        const message = CreateResult.data.Nuevo_EmpleadoVacaciones.message
        if(success){   
            Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
               
           }, 1000);

        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }

    };

    const handleEditCont = async (values) =>{

    }

    const onContratoDataView = async  (record) => {
        setDataContrato(record);
        setContratoView(true);    
    }
    
    if(loadingpage){
        return (
        <div className="site-card-border-less-wrapper">
           
          <Buscar/>
         
        </div>
        )
    }
    return (
        <div className="site-card-border-less-wrapper">
        
            <Card size="small" style={{ textAlign: 'Left', }}  title='Lista de Empleados Contratados'    extra={<IcoButton icon='SettingOutlined' onChildClick={(array) => onGroupClick(array)} submenu={submenu}  />}   >
                <Card size="small" bordered={false} style={{  display: 'flex' }}>
                <Space>
                    <Button onClick={onAddEmple} icon={<UsergroupAddOutlined />}>Nuevo Empleado</Button>
                    
                </Space>    
                </Card>
                
                   <SearchEmpleContrato HandleDataEmple = {HandleDataEmple}  SetdataEmpleado={SetdataEmpleado} clientes={clientes} contratos={contratos} isapre={isapre} afp={afp}/>
                   <Space>&nbsp;</Space>
                   <TableContratado DataEmpleado={DataEmpleado} handleContratoDataView={onContratoDataView}   />
                
            </Card>
            <ModalClave Visible={Visible} Loading={Loading} handleOk={handleOk} handleCancel={handleCancel }/>
            <ModalContratadoView contratoView={contratoView} handleEditCont={handleEditCont} handleCancelContView={handleCancelContView} DataContrato={DataContrato} creadoPor={creadoPor}/>
            <ModalEmpleNew viewNew ={viewNew}  Loading={Loading} newEmplename={newEmplename} handleEmpleSave={handleEmpleSave} handleLaboralSave={handleLaboralSave} handleFinancieroSave={handleFinancieroSave} handleContactoSave={handleContactoSave} handleVacacionesSave={handleVacacionesSave} handleCancelEmple={handleCancelEmple} contratos={contratos} isapre={isapre} afp={afp} clientes={clientes} Banco={Banco}/>
        </div>

    );
}

export default CreacionContratado