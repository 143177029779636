import gql from "graphql-tag";


export const  GetAllEmpleados = gql`
query  GetAllEmpleados
{
  GetAllEmpleados{
      keyunique
      Id_empleado
      Rut
      Nombre
      Sexo
      Nacionalidad
      Estado_civil
      edad
      comuna_nombre
      cargo
      experiencia
  }
    
}`;

export const  EmpleadosByFilter = gql`
query  GetEmpleadosByFilter($Rut:String!,$Nombre:String!,$Id_sexo:[ID]!,
                            $Id_nacionalidad:[ID]!,$Id_estado_civil:[ID]!,$Id_cargo:[ID]!,
                            $edad:String!,$experiencia:String!)
{
    GetEmpleadosByFilter(
      Rut: $Rut,
      Nombre:$Nombre,
      Id_sexo: $Id_sexo,
      Id_nacionalidad: $Id_nacionalidad,
      Id_estado_civil: $Id_estado_civil,
      Id_cargo: $Id_cargo,
      edad: $edad,
      experiencia: $experiencia,
     
    )
    {
      Id_empleado
      Rut
      Nombre
      Id_sexo
      Sexo
      Id_Nacionalidad
      Nacionalidad
      Id_estado_civil
      Estado_civil
      Fecha_nacimiento
      edad
      region_id
      id_comuna
      comuna_nombre
      Direccion
     
    }
    
}`;

export const  Getempleados = gql`
query  Getempleados($id_empleado:ID!)
{
  Getempleados(id_empleado:$id_empleado){

      keyunique
      Id_empleado
      Rut
      Nombre
      Id_sexo
      Sexo
      Id_Nacionalidad
      Nacionalidad
      Id_estado_civil
      Estado_civil
      Fecha_nacimiento
      edad
      region_id
      id_comuna
      comuna_nombre
      id_cargo
      cargo
      experiencia
      Direccion
  }
    
}`;

export const  GetempleadosById = gql`
query GetempleadosById($id_empleado:ID!)
{
 GetempleadosById(id_empleado:$id_empleado){

    Id_empleado
    Rut
    Nombre
    Id_sexo
    Sexo
    Id_Nacionalidad
    Nacionalidad
    Id_estado_civil
    Estado_civil
    Fecha_nacimiento
    edad
    region_id
    id_comuna
    comuna_nombre
    Direccion
  }
    
}`;

export const  GetempleadoEduById = gql`
query  GetempleadoEduById($id_empleado:ID!)
{
  GetempleadoEduById(id_empleado:$id_empleado){
      Id_educacion
      Id_empleado
      ultimo_nivel_cursado
      nivel_cursado
      Inst_Educativa
      Titulo
      fecha_egreso
      Nota_egreso
      descripcion
  }
    
}`;

export const  GetempleadoLabById = gql`
query  GetempleadoLabById($id_empleado:ID!)
{
  GetempleadolabById(id_empleado:$id_empleado){
    id_exlaboral
    id_empleado
    id_cargo
    cargo
    tipo_empleo
    empleo_tipo
    Nombre_empresa
    Ubicacion
    fecha_inicio
    fecha_finalizacion
    descripcion_cargo
  }

    
}`;

export const  GetempleadoCertById = gql`
query  GetempleadoCertById($id_empleado:ID!)
{
  GetempleadoCertById(id_empleado:$id_empleado){
      id_cert_licencia
      Id_empleado
      Empresa_emisora
      nombre_cert_licencia
      fecha_de_caducidad
      id_credencial_licencia
      URL_credencial
  }
       
}`;

export const  GetempleadoIdiomaById = gql`
query  GetempleadoIdiomaById($id_empleado:ID!)
{
   GetempleadoidiomaById(id_empleado:$id_empleado){
      id_idiomas
      id_empleado
      id_idioma
      idioma
      Fecha_creacion
  }
       
}`;

export const GetEmpleContactos = gql`
query postulacionObs($id_empleado:ID!){
  GetContactosById(Id_empleado:$id_empleado)
    {
      Id_empleado
      Id_tipocontacto
      title
      Observaciones
      Icon
    }
}`;

export const GetempleadoAdjById = gql`
query GetempleadoAdjById($Id_empleado:ID!){
  GetempleadoAdjById(Id_empleado: $Id_empleado)
  {
    title
		Descripcion
		adjunto
  }
}`




