import React from 'react'

function Notfoundpage() {
    return (
        <div>
            Error 404 Not found
        </div>
    )
}

export default Notfoundpage
