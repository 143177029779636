import gql from "graphql-tag";

export const CreateClient = gql`
mutation CreateClient($Giro:String!,$Nombre:String!,$Direccion:String!, $Rut:String!, $Creado_por:String! ){
    
    createClient(Giro: $Giro
        Nombre: $Nombre
        Direccion: $Direccion
        Rut: $Rut
        Creado_por: $Creado_por)
   {
        success
        message
   }

    
}`;

export const UpdateClient = gql`
mutation UpdateClient($Id_cliente:ID!,$Rut:String!, $Giro:String!,$Nombre:String!,$Direccion:String!,$Modificado_por:String!){
     updateClient(Id_cliente:$Id_cliente
        Rut:$Rut
        Giro: $Giro
        Nombre: $Nombre
        Direccion: $Direccion
        Modificado_por: $Modificado_por)
  {
    success
    message
   
  }

    
}`;




export const BloqueoClient = gql`
mutation DeleteUsers($Id_cliente:ID!,$Estado:String!, $Modificado_por:String!){
    deleteClient(Id_cliente:$Id_cliente
                  Estado:$Estado
                  Modificado_por: $Modificado_por)
    {
        success
        message 
    } 
    
}`;

export const contactBloqContacto = gql`
mutation contactBloqContacto($Id_contacto:ID!,$Estado:String!){
    contactBloqContacto(Id_contacto:$Id_contacto
                  Estado:$Estado
                  )
    {
        success
        message 
    } 
    
}`;



export const createContactTypeClient = gql`
mutation createContactTypeClient($Id_contacto:ID!,$tipo_contacto:ID!,$descripcion:String!,$Creado_por:String!){
     createContactTypeClient(
            Id_contacto: $Id_contacto
            tipo_contacto: $tipo_contacto
            descripcion: $descripcion
            Creado_por: $Creado_por
            )
      {
        success
        message

      }
    
}`;

export const createContactClient = gql`
mutation createContactClient($Id_cliente:ID!,$Nombre:String!,$DescripcionCargo:String!,$tipo_contacto:ID!,$descripcion:String!,$Creado_por:String!){
      createContactClient(
        Id_cliente:$Id_cliente
        Nombre: $Nombre
        DescripcionCargo: $DescripcionCargo
        tipo_contacto: $tipo_contacto
        descripcion: $descripcion
        Creado_por: $Creado_por
      )
      {
        success
        message
      }
    
}`;

export const deleteContactClient = gql`
mutation deleteContactClient($Id_contacto:ID!){
      deleteContactClient(
        Id_contacto:$Id_contacto
        
      )
      {
        success
        message
      }
    
}`;

export const DeleteContactTypeClient = gql`
mutation DeleteContactTypeClient($Id_contacto:ID!,$Id_tipocontacto:ID!){
      DeleteContactTypeClient(
        Id_contacto: $Id_contacto,
        Id_tipocontacto:$Id_tipocontacto
      )
      {
        success
        message
      }
    
}`;

export const createUpdateClientContactSesion = gql`
mutation createUpdateClientContactSesion($Id_contacto:ID!,$Users:String!,$Pass:String!,$Id_tipo:ID!){
 createUpdateClientContactSesion(
      Id_contacto: $Id_contacto
      Users: $Users
      Pass: $Pass
      Id_tipo: $Id_tipo
      )
  {
  	success
		message
  }
  
}`;

export const ResetPasswordClient = gql`
mutation ResetPasswordClient($id_sesion:ID!){
     ResetPasswordClient(id_sesion:$id_sesion)
    {
        success
        message
        
    } 
    
}`;







