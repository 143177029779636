import React, { useState, useRef} from 'react'
import { Table, Input, Button, Space,Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined, FileTextOutlined, UsergroupAddOutlined ,PaperClipOutlined} from '@ant-design/icons';

const TablePostulacion = ({ data, EditPost, EditEst,EditPostAdj,EditPostEmp}) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
   

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button onClick={() => {handleReset(clearFilters); confirm({closeDropdown: false });}} size="small" style={{ width: 90 }}>
                        Borrar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput, 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
       
    };
    
    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;
    };

    const paramstable = {
       bordered: true,
        pagination:false,
        size: 'small',
        tableLayout: 'auto',
        scroll: { x: 'max-content', y: '50vh'},
    }

    const [Columsdata] = useState([
        
        {
            title: 'Id',
            dataIndex: 'id_postulacion_cab',
            key: 'id_postulacion_cab',
            fixed:false,
            

        },
        {
            title: 'Cliente',
            dataIndex: 'Cliente',
            key: 'Cliente',
            fixed:false,
            ...getColumnSearchProps('Cliente'),
           

        },
        {
            title: 'Solicitante' ,
            dataIndex: 'NombreSolicitante',
            key: 'NombreSolicitante',
            fixed:false,
            ...getColumnSearchProps('NombreSolicitante'),

        },
        {
            title: 'Cargo',
            dataIndex: 'DCargo',
            key: 'DCargo',
            fixed:false,
            ...getColumnSearchProps('DCargo'),

        },
        {
            title: 'Vacantes',
            dataIndex: 'Nro_vacantes',
            key: 'Nro_vacantes',
            fixed:false,
            ...getColumnSearchProps('Nro_vacantes'),

        },
        
        {
            title: 'Estado',
            dataIndex: 'Estado',
            key: 'Estado',
            fixed:false,
            ...getColumnSearchProps('Estado'),

        },
        {
            title: 'Estado_id',
            dataIndex: 'Estado_id',
            key: 'Estado_id',
            hidden: true,
            
            

        },
        {
            title: 'Creado_por',
            dataIndex: 'Creado_por',
            key: 'Creado_por',
            hidden: true,

        },
        {
            title: 'Acción',
            dataIndex: 'accion',
            key: 'accion',
            fixed:false,
            render: (text, record) => {
               
                return (
                    <>
                        <Space size="middle">
                              <Tooltip title="Cambiar Estado" color={'blue'} key={'blue'}><FileTextOutlined onClick={() => {onEditEst(record) }} /></Tooltip>
                               <Tooltip title="Ver Información" color={'blue'} key={'blue'}> <Icon type='IdcardOutlined'  className="site-form-item-icon"  onClick={() => {onPost(record) }} /></Tooltip>
                              <Tooltip title="Agregar Postulantes" color={'blue'} key={'blue'}><UsergroupAddOutlined  onClick={() => {onAgreeEmpPost(record)}} /></Tooltip>
                             <Tooltip title="Agregar Adjunto" color={'blue'} key={'blue'}><PaperClipOutlined onClick={() => { onAgreePost(record) }} /></Tooltip>
                        </Space>
                    </>

                )
            }
        }
    ].filter(item => !item.hidden));
   
    
  
    const onPost = (record) => {
        return EditPost(record);
    }

    const onEditEst = (record) => {
        return EditEst(record);
    }

    const onAgreePost = (record) => {
        
        return EditPostAdj(record);
    }

    const onAgreeEmpPost = (record) => {
        return EditPostEmp(record);
    }
   

    return (
          
        <Table columns={Columsdata} dataSource={data} {...paramstable} rowKey="id_postulacion_cab"/> 
    )
}

export default TablePostulacion
