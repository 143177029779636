import React,{useState,useEffect}  from 'react'
import { Modal, Button, Form, Input, Space, Row, Col, Select } from 'antd';
import {useAllGiro} from '../../hooks/custom-general';
import '../../Assets/Css/page.css';

import {  validate } from 'rut.js'
const { Option } = Select;

const ModalEditClient = ({ EditClient, Loading, handleSaveEdit, handleCancelEdit, DataEditClient}) => {
    const [Form13] = Form.useForm();
    const{data,loading} = useAllGiro();
    const [girodata,setGirodata] = useState([]);
   
    useEffect(() => {
        if(loading===false){  
            const datagiro = data.GetallGiro
            setGirodata(datagiro)
        }
      
     
        if (EditClient) {
           //console.log(DataEditClient)
            Form13.setFieldsValue({
                Giro:DataEditClient?.giro,
                Direccion:DataEditClient?.Direccion_principal,
                NombreCliente:DataEditClient?.Nombre,
                Rut: DataEditClient?.Rut,
            });
        }
       
    }, [EditClient, Form13, loading, data, DataEditClient]);
    
    const onAdd = async values => {
       // console.log(values)
        if(validate(values.Rut)){
             return handleSaveEdit(values); 
        }   
    }

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;

    };

    return (
        <Modal
            visible={EditClient}
            title="Editar Cliente"
            onCancel={handleCancelEdit}
            footer={[
                
               
            ]}
        >
            <Form
                form={Form13}
                name="EditarCliente"
                initialValues={{
                    remember: false,
                    resetFields:true,
                   
                }}
                onFinish={onAdd}
            >
               <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="Rut"
                    tooltip="Ingrese Rut sin puntos solo Guion"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Rut'  
                        }  
                        ,{
                            pattern: /[0-9kK-]+$/,
                            message: 'Rut solo numeros y Guion y K ',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                            if (validate(value)) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Rut mal ingresado..!'));
                            },
                        }),
                    ]}
                    hasFeedback  
                    
                >
                    <Input 
                        maxLength={15}
                        prefix={<Icon type='UserOutlined'  className="site-form-item-icon" />}
                        type="text"
                      
                       
                        
                    />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Giro"
                  
                    rules={[
                        {
                            required: true,
                            message: 'Confirma seleccionando el giro',
                        },
                    ]}
                  
                >
                   <Select placeholder="Giro"  
                 
                   >
                    {girodata.map((data) => {
                        return (
                            <Option key={data.giro_id} value={data.giro_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
              
                </Col>
                </Row>
                <Form.Item
                    name="NombreCliente"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Nombre Cliente',
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Nombre Cliente"
                    />
                </Form.Item>

                <Form.Item
                    name="Direccion"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Direccion',
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Direccion"
                    />
                </Form.Item>
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading} >
                        Guardar
                    </Button>
                    <Button key="back" onClick={handleCancelEdit}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
               
               
            </Form>
        </Modal>
    )
}

export default ModalEditClient
