import React, { useState, useEffect} from 'react'

import '../Assets/Css/page.css';
import { Card,  Modal, Button} from 'antd';
import { UsergroupAddOutlined } from '@ant-design/icons';
import IcoButton from '../Components/IconButtonGroup';
import ModalClave from '../Components/ModalClave';
import ModalUser from '../Components/Users/ModalUser';
import ModalEditUser from '../Components/Users/ModalEditUser';
import TableUsers from '../Components/Users/TableUsers';
import Buscar from '../Components/Loading';
import {useAllusers,useEditusers,useCreateusers,useBloqueousers,useUpdatepass,useResetpass,useUpdateUserSend,useAlertNameById} from '../hooks/custom-users';
import{useVerifyToken,useVerify} from "../hooks/useToken"  

const Usuarios = () => { 
    const {data:Tokensverify, loading:TokensverifyLoading}= useVerify();
    const{Tokens,TokensLoading} = useVerifyToken();
    const {data,loading} = useAllusers();
    const [SetUpdateSend] = useUpdateUserSend();
    const [SetEdit] = useEditusers();
    const [DataNameAlert] = useAlertNameById();
    const [SetBloq] = useBloqueousers();
    const [SetUpd] = useUpdatepass();
    const [SetPass] = useResetpass();
    const [SetCreate] = useCreateusers();
    const [Loading, setLoading] = useState(false);
    const [Visible, setVisible] = useState(false);
    const [Userdata,setUserdata] = useState([]);
    const [NewUser, setNewUser] = useState(false);
    const [EditUser, setEditUser] = useState(false);
    const [dataEditUser, setEditDataUser] = useState([]);
    const [nameAlert, setNameAlert] =  useState(false);
    const [creadoPor, setCreadoPor] = useState('');

    
    useEffect(() => {
        
         if(TokensverifyLoading===false){
            
            if(Tokensverify.GetLogin_validate.success===false){
                Modal.error({
                    title: Tokensverify.GetLogin_validate.message,
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");
                    }
                })
            }
           
        }

        if(TokensLoading===false){
            if(Tokens){
                 const userF=Tokens.token.split('||')
                 setCreadoPor(userF[2])
            }
        } 


        if(loading===false){
            const datauser = data.GetAllUsers
            setUserdata(datauser)
        }
    }, [Tokens, TokensLoading, Tokensverify, TokensverifyLoading, data, loading])

    
    const [submenu] = useState([
        {
            key: '1',
            icon: 'KeyOutlined',
            title: 'Nueva Clave',

        }, {
            key: '2',
            icon: 'PoweroffOutlined',
            title: 'Salir',

        }
    ])

   
    

    const onDeleteuser=  (record)=> {
        let mensaje=''
        if(record.Estado==="Bloqueado"){
            mensaje = 'Estas Seguro de Desloquear el Usuario ' +record.NombreCompleto+ ' ..??'
        }
        else{
            mensaje = 'Estas Seguro de Bloquear el Usuario ' +record.NombreCompleto+ ' ..??'
        }

        Modal.confirm({
           title: mensaje,
            
           onOk:async()=>{
            
            const BloqResult = await  SetBloq({variables:{
                id_usuario:record.id_usuario,
                Estado:record.Estado,
               
            }})
        
           
            const success = BloqResult.data.deleteUser.success
            const message = BloqResult.data.deleteUser.message
           // console.log(success)
            if(success){   
            const  modal = Modal.success({
                    content: message,
                });

                setTimeout(() => {
                    modal.destroy();
                }, 3000);

            }else{
                Modal.warning({
                    content: message,
                });
               
                
            
            }  
        }   
       })
       
    }

    const onResetclave = (record) => {
       
        Modal.confirm({
            title: 'Deseas Resetear la clave del Usuario ' + record.NombreCompleto + ' ..??',
            onOk:async () => {
                const ResetPass = await  SetPass({variables:{
                    id_sesion:record.id_Sesion,
                }})
              
                const success = ResetPass.data.resetPassword.success
                const message = ResetPass.data.resetPassword.message
                if(success===true){   
                const  modal = Modal.success({
                        content: message,
                    });

                    setTimeout(() => {
                        modal.destroy();
                    }, 3000);

                }else{
                    Modal.warning({
                        content: message,
                    });  
                
                }  
               
            }
        })

    }
    
    const onEdituser = async (record) => {

        const Result = await  DataNameAlert({variables:{
            id_Sesion: record.id_Sesion, 
        }})
       
        setNameAlert(false)
        const descripcion = Result.data.GetUsers_AlertNameByIdSesion.descripcion
        if(descripcion){setNameAlert(descripcion)}
        setEditUser(true);
        setEditDataUser(record);
      
    }

    const onGroupClick = (array) => {
       
        switch (array.key) {
            case '1':
                //console.log(array.key);
                setVisible(true);
               break
            default:
                Modal.confirm({
                    title: 'Estas Seguro de Cerrar la aplicación ..??',
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");

                        
                }
                })
        }
    }

    const handleOk = async (values) => {

      
       
        const EditPass = await  SetUpd({variables:{
            id_sesion:creadoPor,
            oldPass: values.claveactual,
            NewPass: values.NuevaClave,
          
        }})
      //console.log(EditPass)
       // setLoading(true);
        const success = EditPass.data.updatePassword.success
        const message = EditPass.data.updatePassword.message
        if(success===true){   
           const  modal = Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
                setVisible(false);
                modal.destroy();
            }, 3000);

        }else{
            Modal.warning({
                 content: message,
            });
            setLoading(false);
              
           
        }  

    };

    const handleSave = async (values) => {
         
        const CreateResult = await  SetCreate({variables:{
            Users:values.Usuario,
            Pass:values.Usuario+values.Emailuser,
            Nombre: values.NombreUsuario,
            Apellidop: values.ApellidoPaterno,
            Apellidom : values.ApellidoMaterno,  
            Email : values.Emailuser, 
       
        }})
       
        setLoading(true);
        const success = CreateResult.data.createUser.success
        const message = CreateResult.data.createUser.message
        const id_Sesion = CreateResult.data.createUser.id_Sesion
       
        if(success){ 
            if (Array.isArray(values.SendAlert) && values.SendAlert.length) { 
                await SetUpdateSend({variables:{
                    send_name:values.SendAlert,
                    sesionId:id_Sesion,
                
                }})
            }    
           

            const  modal = Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
                setNewUser(false);
                modal.destroy();
           }, 3000);

        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }
   
    };
      
    const handleSaveEdit = async (values) => {
       
      //   console.log(values)
       // console.log(values)
      const EditResult = await  SetEdit({variables:{
            id_sesion:dataEditUser.id_Sesion,
            Nombre: values.NombreUsuario,
            Apellidop: values.ApellidoPaterno,
            Apellidom : values.ApellidoMaterno,  
            Email : values.Emailuser, 
            
        }})
       
        setLoading(true);
        const success = EditResult.data.updateUser.success
        const message = EditResult.data.updateUser.message
        if(success){  
            
            await SetUpdateSend({variables:{
                send_name:"",
                sesionId:dataEditUser.id_Sesion,
            
            }})

           if (Array.isArray(values.SendAlert) && values.SendAlert.length) { 
                await SetUpdateSend({variables:{
                    send_name:values.SendAlert,
                    sesionId:dataEditUser.id_Sesion,
                
                }})
            }   

            const  modal = Modal.success({
                content: message,
            });

            setTimeout(() => {
                setLoading(false);
                setEditUser(false);
                modal.destroy();
            }, 3000);

        }else{
            Modal.warning({
                 content: message,
            });
            setLoading(false);
              
           
        }  
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleCancelUse = () => {
        setNewUser(false);
    };

    const handleCancelEdit = () => {
        setEditUser(false);
    };

    const onAdduser=()=>{
        setNewUser(true);
    }
    
    if(loading){
        return (
        <div className="site-card-border-less-wrapper">
           
          <Buscar/>
         
        </div>
        )
    }

    return (
        <div className="site-card-border-less-wrapper">
        
            <Card size="small" style={{ textAlign: 'Left', }}  title='Lista de usuarios'  className='card_height' extra={<IcoButton icon='SettingOutlined' onChildClick={(array) => onGroupClick(array)} submenu={submenu}  />} >
                <Card size="small" bordered={false} style={{  display: 'flex' }}>
                    <Button onClick={onAdduser} icon={<UsergroupAddOutlined />}>Nuevo Usuario</Button>
                </Card>
                <TableUsers data={Userdata}  Deleteuser={onDeleteuser} Resetclave={onResetclave} Edituser={onEdituser} />
            </Card>
            <ModalClave Visible={Visible} Loading={Loading} handleOk={handleOk} handleCancel={handleCancel }/>
            <ModalUser NewUser={NewUser} Loading={Loading} handleSave={handleSave} handleCancelUse={handleCancelUse} />
            <ModalEditUser EditUser={EditUser} Loading={Loading} handleSaveEdit={handleSaveEdit} handleCancelEdit={handleCancelEdit} dataEditUser={dataEditUser}  nameAlert ={nameAlert}/>
        </div>

    );
}

export default Usuarios
