import React, { useState, useRef} from 'react'
import { Table, Input, Button, Space,Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined, FileTextOutlined,PaperClipOutlined,IdcardOutlined,UserDeleteOutlined } from '@ant-design/icons';

const TableEmpleados = ({dataDetSelect,HandleEditEstDet,HandleEditPostAdjDet,handleDeletepost,HandlePostCardDet}) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
   
    const searchInput = useRef(null);
    
  
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button onClick={() => {handleReset(clearFilters); confirm({closeDropdown: false });}} size="small" style={{ width: 90 }}>
                        Borrar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput, 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
       
    };
    

    const paramstable = {
       bordered: true,
        pagination:false,
        size: 'small',
        tableLayout: 'auto',
        scroll: { x: 'max-content', y: '50vh'},
    }
    const [Columsdata] = useState([
        {
            title: 'Id',
            dataIndex: 'id_postulacion_det',
            key: 'id_postulacion_det',
            fixed:false,
            //hidden:true,
              
            

        },
        {
            title: 'Id',
            dataIndex: 'Id_empleado',
            key: 'Id_empleado',
            hidden:true,
            
             

        },
        {
            title: 'Nombre',
            dataIndex: 'Nombre',
            key: 'Nombre',
            fixed:false,
            ...getColumnSearchProps('Nombre'),

        },
        {
            title: 'Rut',
            dataIndex: 'Rut',
            key: 'Rut',
            fixed:false,
            ...getColumnSearchProps('Rut'),

        },
        {
            title: 'Sexo',
            dataIndex: 'Sexo',
            key: 'Sexo',
            fixed:false,
            ...getColumnSearchProps('Sexo'),

        },
        {
            title: 'Nacionalidad',
            dataIndex: 'Nacionalidad',
            key: 'Nacionalidad',
            fixed:false,
            ...getColumnSearchProps('Nacionalidad'),

        },
          {
            title: 'Est.civil',
            dataIndex: 'Estado_civil',
            key: 'Estado_civil',
            fixed:false, 
            ...getColumnSearchProps('Estado_civil'),

        },
        {
            title: 'Comuna',
            dataIndex: 'comuna_nombre',
            key: 'comuna_nombre',
            fixed:false,
            ...getColumnSearchProps('comuna_nombre'),
            
        },
        {
            title: 'Edad',
            dataIndex: 'edad',
            key: 'edad',
            fixed:false,
            ...getColumnSearchProps('edad'),
            

        },
        {
            title: 'Estado',
            dataIndex: 'Id_estado_postulacion',
            key: 'Id_estado_postulacion',
            hidden:true,
            

        },
        {
            title: 'Estado',
            dataIndex: 'Estado_postulacion',
            key: 'Estado_postulacion',
            fixed:false,
            ...getColumnSearchProps('Estado_postulacion'),
            

        },
         {
            title: 'Acción',
            dataIndex: 'accion',
            key: 'accion',
            fixed:false,
            render: (text, record) => {
               
                return (
                    <>
                        <Space size="middle">
                          
                            <Tooltip title="Información Adicional" color={'blue'} key={'blue'}><IdcardOutlined  onClick={() => {onVerCard(record) }} /> </Tooltip>
                            <Tooltip title="Cambiar Estado" color={'blue'} key={'blue'}><FileTextOutlined onClick={() => {onEditEst(record) }} /></Tooltip>
                            <Tooltip title="Agregar Adjuntos" color={'blue'} key={'blue'}><PaperClipOutlined onClick={() => { onAgreePost(record) }} /></Tooltip>
                            
                            <Tooltip title="Eliminar Postulante" color={'blue'} key={'blue'}><UserDeleteOutlined  onClick={() => { onDeletePost(record) }} /></Tooltip>
                        </Space>
                    </>

                )
            }
        }
        
       
    ].filter(item => !item.hidden));
   
    const onEditEst = (record) => {
        return HandleEditEstDet (record);
    }

    const onAgreePost = (record) => {
        return HandleEditPostAdjDet(record);
    }
    const onDeletePost = (record) => {
        return handleDeletepost(record);
    }

    const onVerCard = (record) => {
       
        return HandlePostCardDet(record);
    }
    

    return (
        <div>
            <Table  columns={Columsdata} dataSource={dataDetSelect} {...paramstable} rowKey="id_postulacion_det"/> 
        </div>
    )
}

export default TableEmpleados
