
import React from 'react'
import { Menu, Dropdown } from 'antd';



const IconButtonGroup = ({ icon, onChildClick, submenu}) => {

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;

    };

    const handleMenuClick = (e) => {
        return onChildClick(e)
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            {
                submenu.map((item) => (
                    <Menu.Item icon={<Icon type={item.icon} />} key={item.key}>{item.title}</Menu.Item>
                ))
            }
        </Menu> 
    );

    

    return (
            <Dropdown.Button type="default" overlay={menu}   icon={<Icon type={icon} />} />
      
    )
}




export default IconButtonGroup
