import gql from "graphql-tag";

export const PostulacionSelect = gql`
query postulacionSelect($id_postulacion_cab:ID!){
  GetPostSel(id_postulacion_cab:$id_postulacion_cab){
    id_postulacion_det
    id_postulacion_cab
    Id_empleado
    Rut
    Nombre
    Sexo
    Nacionalidad
    Estado_civil
    edad
    Direccion
    comuna_nombre
    Activo
    cargo
    experiencia
    Id_estado_postulacion
    Estado_postulacion 
    
  }
}`;
