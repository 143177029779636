import gql from "graphql-tag";

export const PostulacionAdj = gql`
query postulacionAdj($id_postulacion_cab:ID!){
  GetAdj(id_postulacion_cab:$id_postulacion_cab){
    title
    Descripcion
    adjunto
    
  }
}`;
