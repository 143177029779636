import React, { useState, useRef} from 'react'
import { Table, Input, Button, Space,Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';

const TableContratado = ({DataEmpleado,handleContratoDataView}) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const searchInput = useRef(null);
    
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<Icon type='SearchOutlined'/>}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button onClick={() => {handleReset(clearFilters); confirm({closeDropdown: false });}} size="small" style={{ width: 90 }}>
                        Borrar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <Icon type='SearchOutlined' style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput, 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
       
    };
    
    
    const paramstable = {
        bordered: true,
        pagination:false,
        size: 'small',
        tableLayout: 'auto',
        scroll: { x: 'max-content', y: '50vh'},
    }

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;
    };

    const [Columsdata] = useState([
        {
            title: 'Id_Contrato',
            dataIndex: 'Id_Contrato',
            key: 'Id_Contrato',
            hidden: true,  
            

        },
        {
            title: 'Id_empleado',
            dataIndex: 'Id_empleado',
            key: 'Id_empleado',
            hidden: true,  

        },
        {
            title: 'Id_cliente',
            dataIndex: 'Id_cliente',
            key: 'Id_cliente',
            hidden: true,  

        },
        {
            title: 'Cliente',
            dataIndex: 'cliente',
            key: 'cliente',
            hidden: true, 

        },
        {
            title: 'Rut',
            dataIndex: 'Rut',
            key: 'Rut',
            fixed:false,
            ...getColumnSearchProps('Rut'),

        },
        {
            title: 'Nombre',
            dataIndex: 'Nombre',
            key:'Nombre',
            fixed:false,
            ...getColumnSearchProps('Nombre'),

        },
         {
            title: 'id_cargo',
            dataIndex: 'id_cargo',
            key: 'id_cargo',
            hidden: true,  

        },
        {
            title: 'cargo',
            dataIndex: 'cargo',
            key: 'cargo',
            fixed:false, 
            ...getColumnSearchProps('cargo'),

        },
        {
            title: 'Tipo_contrato',
            dataIndex: 'Tipo_contrato',
            key: 'Tipo_contrato',
            hidden: true,  

        },
        {
            title: 'Contrato',
            dataIndex: 'tcontrato_name',
            key: 'tcontrato_name',
            fixed:false, 
            ...getColumnSearchProps('tcontrato_name'),

        },
        {
            title: 'Inicio_Contrato',
            dataIndex: 'fecha_ini_contrato',
            key: 'fecha_ini_contrato',
            fixed:false, 
            ...getColumnSearchProps('fecha_ini_contrato'),

        },
        {
            title: 'Fin_Contrato',
            dataIndex: 'fecha_fin_contrato',
            key: 'fecha_fin_contrato',
            fixed:false, 
            ...getColumnSearchProps('fecha_fin_contrato'),

        },
         {
            title: 'id_isapre',
            dataIndex: 'id_isapre',
            key: 'id_isapre',
            hidden: true,  

        },
        {
            title: 'Isapre',
            dataIndex: 'isapre',
            key: 'isapre',
            fixed:false, 
            ...getColumnSearchProps('isapre'),

        },
         {
            title: 'id_afp',
            dataIndex: 'id_afp',
            key: 'id_afp',
            hidden: true,  

        },
        {
            title: 'AFP',
            dataIndex: 'afp',
            key: 'afp',
            fixed:false, 
            ...getColumnSearchProps('afp'),

        },
      
        {
            title: 'Creado_por',
            dataIndex: 'Creado_por_name',
            key: 'Creado_por_name',
             hidden: true,  
            
        },
         {
            title: 'Acción',
            dataIndex: 'accion',
            key: 'accion',
            fixed:false, 
            render: (text, record) => {
               
                return (
                    <>
                        <Space size="middle">
                             <Tooltip title="Ver Información" color={'blue'} key={'blue'}> <Icon type='IdcardOutlined'  className="site-form-item-icon"  onClick={() => {onVerCard(record) }} /></Tooltip>
                             <Tooltip title="Asistencia" color={'blue'} key={'red'}><Icon type='CarryOutOutlined'  onClick={() => { }} /> </Tooltip>
                            <Tooltip title="Remuneraciones" color={'blue'} key={'orange'}><Icon type='DollarCircleOutlined' onClick={() => { }} /></Tooltip>
                            <Tooltip title="Baja de empleado" color={'blue'} key={'tripie'}><Icon type='UserDeleteOutlined' onClick={() => {  }} /></Tooltip>
                      

                        </Space>
                    </>

                )
            }
        }
        
       
    ].filter(item => !item.hidden));
   
   
    const onVerCard = (record) => {
        return handleContratoDataView(record);
    }

    return (
        <div>
            <Table  columns={Columsdata} dataSource={DataEmpleado} {...paramstable} rowKey="Id_Contrato"/> 
        </div>
    )
}

export default TableContratado
