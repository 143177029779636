import React  from 'react'
import { Modal, Form,Row,Col,Input ,Space,Button,DatePicker,Typography,Avatar} from 'antd';
import '../../Assets/Css/page.css';
const { Title } = Typography;

const ModalCertAdd = ({ viewCertAdd, DataPostulant, Loading, handleCancelCertAdd,handleAddPostCert}) => {
  
    const [formCertAdd] = Form.useForm();
    const dateFormat = 'DD/MM/YYYY'

    const onAddCert = async values => {
        formCertAdd.resetFields();
        return handleAddPostCert(values)    
    }

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;
    };

    
    return (
        <>
        <Modal
            visible={viewCertAdd}
            title="Nueva Certificación"
            style={{ top: 20 }}
            width={'100vh'}
            onCancel={handleCancelCertAdd}
            footer={[]}
        >

        <Form
            form={formCertAdd}
            name="AgregarNuevoPostulante"
            initialValues={{
                remember: false,
                resetFields:true,
            }}
            onFinish={onAddCert}
        >
            
            <Row gutter={16}>
            
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 21 }}>
            <Title level={5}> <Space><Avatar size={32} icon={<Icon type='UserOutlined' className="site-form-item-icon" />} />{DataPostulant.Nombre}</Space></Title>
            </Col>
            
            </Row>
            <Row gutter={16}>
            
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
            <Form.Item
                name="EmpresaEmisora"
                rules={[
                    {
                        required: true,
                        message: 'Por favor ingrese Empresa Emisora',
                    },
                ]}
                hasFeedback
            >
                <Input
                    prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                    type="text"
                    placeholder="Empresa Emisora"
                />
            </Form.Item>
            </Col>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
            <Form.Item 
                name="NombreCertificado"
                tooltip="Direccion Empresa"
                rules={[
                    {
                        required: false,
                        message: 'Por favor ingrese Nombre Certificado licencia'   
                    }  
                    
                    
                ]}
                hasFeedback  
                
            >
                <Input
                    prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                    type="text"
                    placeholder="Nombre Certificado"
                />
            </Form.Item>
            </Col>

            </Row>
            
            
                <Row gutter={16}>
            
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
            <Form.Item
                name="FechaCaducidad"
                rules={[
                    {
                        required: true,
                        message: 'Por favor ingrese Fecha Caducidad',
                    },
                ]}
                hasFeedback
            >
                <DatePicker format={dateFormat} style={{ width: '100%' }}  placeholder="Fecha Caducidad"/>
            </Form.Item>
            </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
            <Form.Item
                name="IdCredLicencia"
                rules={[
                    {
                        required: true,
                        message: 'Por favor ingrese id Licencia/certificado',
                    },
                ]}
                hasFeedback
            >
                <Input
                    prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                    type="text"
                    placeholder="id Licencia/certificado"
                />
            </Form.Item>
            </Col>

            </Row>
            
            <Row gutter={16}>
            
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
            <Form.Item
                name="URLCredencial"
                rules={[
                    {
                        required: false,
                        message: 'Por favor ingrese URL Credencial',
                    },
                ]}
                hasFeedback
            >
                <Input
                    prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                    type="text"
                    placeholder="URL Credencial"
                />
            </Form.Item>
            </Col>
            

            </Row>
            
            <Form.Item>
                <Space>
                
                <Button htmlType="submit" type="primary" loading={Loading } >
                    Agregar
                </Button>
                <Button key="back" onClick={handleCancelCertAdd}>
                    Cancelar
                </Button>
                </Space>

            </Form.Item>
        </Form>
            
        </Modal>
       </> 
    )
   
}

export default ModalCertAdd
