import React,{useState,useEffect}  from 'react'
import { Modal, Form,Row,Col,Input ,Space,Button,Select,Typography,Avatar} from 'antd';
import '../../Assets/Css/page.css';
import {useAllTipoContacto} from '../../hooks/custom-general';
const { Option } = Select;
const { Title } = Typography;

const ModalContactoAdd = ({ viewContactoAdd, DataPostulant, Loading, handleCancelContactoAdd,handleAddPostContacto}) => {
  
 

    const {data,loading} = useAllTipoContacto();
    const [formContactoAdd] = Form.useForm();
    const [Tipodata,setTipodata] = useState([]);



    useEffect(() => {
    
        if(loading===false){    
            const datatipo = data.GetallTipoContacto
            setTipodata(datatipo)
        } 
       
        
    }, [DataPostulant, data, loading]);

  

    const onAddContacto = async values => {
        formContactoAdd.resetFields();
        return handleAddPostContacto(values)    
    }

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;
    };

    return (
        <>
        <Modal
            visible={viewContactoAdd}
            title={"Nuevo Contacto Usuario"}
            style={{ top: 20 }}
            width={'100vh'}
            onCancel={handleCancelContactoAdd}
            footer={[]}
        >

            <Form
                form={formContactoAdd}
                name="AgregarTipoContacto"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                onFinish={onAddContacto}
            >
                
               
               <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 21 }}>
                <Title level={5}> <Space><Avatar size={32} icon={<Icon type='UserOutlined' className="site-form-item-icon" />} />{DataPostulant.Nombre}</Space></Title>
                </Col>
               
                </Row>

                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="TipoContacto2"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor Seleccione Tipo Contacto',
                        },
                    ]}
                    hasFeedback
                >
                    <Select placeholder="Tipo Contacto" >
                    {Tipodata.map((data) => {
                        return (
                            <Option key={data.tipo_contacto_id} value={data.tipo_contacto_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="Descripcion2"
                    tooltip="Ingrese Descripción"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Descripción'  
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Descripción"
                    />
                </Form.Item>
                </Col>

                </Row>
                
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Agregar
                    </Button>
                    <Button key="back" onClick={handleCancelContactoAdd}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>
            
        </Modal>
       </> 
    )
   
}

export default ModalContactoAdd 