import { Layout } from 'antd';
const Footer = () => {
    const { Footer } = Layout;
    return (
        
        <Footer className='transparent'>
            <strong>©Copyright GbaSelección RRHH  2022 . </strong>Todos los derechos reservados. Powered by <a href="mailto:gbenavides@gbaconsultores.cl">GBA Consultores SPA.</a>
        </Footer>
       
    )
}

export default Footer
