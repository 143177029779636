import React from 'react'
import PropTypes from 'prop-types';
import  '../Assets/Css/page.css';
import { Form, Input, Button, Row, Card, Layout,Image,message } from 'antd';
import { UserOutlined, LockOutlined ,EyeTwoTone,EyeInvisibleOutlined} from '@ant-design/icons';
import Footer from '../Components/Footer';
import Logo from '../Assets/Img/Login.svg';
import useLoginMenu from '../hooks/custom-login';


export const Login = ({setToken}) => {
    
    const [getLogin] = useLoginMenu(); 
    const  onFinish = async values => {
       
       const  userData = await getLogin({ variables:{
                User:values.username,
                pass:values.password
            }}
        )
       
        
        if(userData){
           const Success = userData.data.GetLogin.success
           const Messages = userData.data.GetLogin.message
           const UserToken = userData.data.GetLogin.token
          // const ids = userData.data.GetLogin.ids

            if(Success){
                //setToken(UserToken+'||'+values.username+'||'+ids)
                  setToken(UserToken+'||'+values.username)
            }
            else{
                message.info(Messages, 3)
            }
        }  
    };

    

    const { Content } = Layout;
    return (
       <Layout className='background'>
       <Content>
        <Row name="row_login" type="flex" justify="center" align="middle" style={{ minHeight: '88vh', padding: '5vh'  }}>
        <Card title="" name="card_login" extra={<Image className='logo' width={200} src={Logo} preview={false} /> }  style={{ width: '70vh'}}>
            <Form
            
                name="normal_login"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="username"
                    rules={[{
                            required: true,
                            message: 'Introduzca su nombre de usuario.',
                        }]}
                    
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Usuario" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese su contraseña!',
                        },
                    ]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon"  />}
                        type="password"
                        placeholder="Password"
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}

                    />
                </Form.Item>
                 <Form.Item>
                    <Button type="primary" htmlType="submit"  className="login-form-button" block >
                                   
                        Ingresar
                    </Button>
                               
                
                </Form.Item>
            </Form>
        </Card>
        </Row>
        
        </Content>
            <Footer />
               
        </Layout>
       
      
            
    );
}
export default Login

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}
