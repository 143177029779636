import gql from "graphql-tag";

/*============Creacion de contratos Empleados===============*/
export const  Nuevo_EmpleadoContrato = gql`
mutation Nuevo_EmpleadoContrato($Rut:String!,$Fecha_nacimiento:String!,$Nombre:String!,$Id_cliente:ID!, 
    $Id_sexo:ID!, $Id_Nacionalidad:ID!, $Id_estado_civil:ID!, $Id_comuna:ID!, $Direccion:String!, $creado_por:String!){
  Nuevo_EmpleadoContrato(
    Rut: $Rut,
    Fecha_nacimiento:$Fecha_nacimiento,
    Nombre:$Nombre,
    Id_cliente:$Id_cliente,
    Id_sexo: $Id_sexo,
    Id_Nacionalidad: $Id_Nacionalidad,
    Id_estado_civil:$Id_estado_civil,
    Id_comuna: $Id_comuna,
    Direccion: $Direccion,
    creado_por:$creado_por,
  )
  {
    success
    message
    Id_empleado
    Id_contrato
  }
}`;

export const  Nuevo_EmpleadoLaboral = gql`
mutation Nuevo_EmpleadoLaboral($Id_contrato:ID!,$Tipo_contrato:ID!,$id_cargo:ID!,$fecha_ini_contrato:String!, 
    $fecha_fin_contrato:String!, $id_isapre:ID!, $id_afp:ID!, $creado_por:String!){
 
  Nuevo_EmpleadoLaboral(
    Id_contrato: $Id_contrato,
    Tipo_contrato: $Tipo_contrato,
    id_cargo: $id_cargo,
    fecha_ini_contrato: $fecha_ini_contrato,
    fecha_fin_contrato: $fecha_fin_contrato,
    id_isapre: $id_isapre,
    id_afp:$id_afp,
    creado_por: $creado_por,
  )
  {
    success
    message
   
  }
}`;

export const  Nuevo_EmpleadoBanco = gql`
mutation Nuevo_EmpleadoBanco($Id_contrato:ID!,$Id_entfin:ID!,$nrocuenta:String!, $creado_por:String!){
 
  Nuevo_EmpleadoBanco(
    Id_contrato: $Id_contrato
    Id_entfin: $Id_entfin
    nrocuenta: $nrocuenta
    creado_por: $creado_por
  )
  {
    success
    message
   
  }
}`;

export const  Nuevo_EmpleadoVacaciones = gql`
mutation Nuevo_EmpleadoVacaciones($Id_contrato:ID!,$DiasVacaciones:ID!, $creado_por:String!){
 
  Nuevo_EmpleadoVacaciones(
    Id_contrato: $Id_contrato
    DiasVacaciones: $DiasVacaciones
    creado_por: $creado_por
  )
  {
    success
    message
   
  }
}`;

/*============Subida de datos de relojcontrol de usuarios contratados===============*/

export const  UploadRelojControl = gql`
mutation UploadRelojControl($Adjunto:[String]!,$Creado_por:String!){
SetUploadRelojControl(
                      adjunto: $Adjunto,
                      creado_por:$Creado_por,
                    )
  {
    success
    message
    messageError {error}
  }
    
}`;

export const  Elimina_RelojControl = gql`
mutation Elimina_RelojControl($Id_contrato:ID!,$fecha_desde:String!,$fecha_hasta:String!,$Creado_por:String!){
  Elimina_RelojControl(
    Id_contrato: $Id_contrato
    fecha_desde: $fecha_desde
    fecha_hasta: $fecha_hasta
    Creado_por: $Creado_por
    
  )
  {
    success
    message
  }

}`;
  /*============creacion de turnos de trabajo de usuarios contratados===============*/

export const  Nuevo_TurnoEmpleado = gql`
mutation Nuevo_TurnoEmpleado($Id_contrato:[ID]!,$Id_turno:String!,$fecha_desde:String!,$fecha_hasta:String!, $creado_por:String!){
  Nuevo_TurnoEmpleado
    ( 
      Id_contrato: $Id_contrato
      Id_turno: $Id_turno
      fecha_desde: $fecha_desde
      fecha_hasta: $fecha_hasta
      creado_por: $creado_por
    )
    {
      success
      message
      isdata
      DataError {
        Mensaje
      }
    } 
}`;

export const  Elimina_TurnoEmpleado = gql`
mutation Elimina_TurnoEmpleado($Id_contTurno:[ID]!,$Creado_por:ID!){
  Elimina_TurnoEmpleado(
        Id_contTurno:$Id_contTurno
        Creado_por:$Creado_por
    )
  {
    success
    message
  }
}`;

  /*============creacion de Licencias de trabajo de usuarios contratados===============*/
export const  Nueva_LicenciaEmpleado = gql`
mutation Nueva_LicenciaEmpleado($Id_contrato:[ID]!,$Id_tipoausencia:String!,$EsAprobada:String!,$fecha_desde:String!,$fecha_hasta:String!, $creado_por:String!){
  Nueva_LicenciaEmpleado(
      Id_contrato: $Id_contrato
      Id_tipoausencia: $Id_tipoausencia
      EsAprobada: $EsAprobada
      fecha_desde: $fecha_desde
      fecha_hasta: $fecha_hasta
      creado_por: $creado_por
		)
   {
    success
    message
    isdata
    DataError {
      Mensaje
    }
   }  
}`;   

export const  Elimina_LicenciaEmpleado = gql`
mutation Elimina_LicenciaEmpleado($Id_contractlic:[ID]!,$Creado_por:ID!){
  Elimina_LicenciaEmpleado(
      Id_contractlic:$Id_contractlic
      Creado_por: $Creado_por
    )
   {
    success
    message
   
    
   }
}`;

export const  Aprobar_LicenciaEmpleado = gql`
mutation Aprobar_LicenciaEmpleado($Id_contractlic:[ID]!,$Creado_por:ID!){
  Aprobar_LicenciaEmpleado(
    Id_contractlic: $Id_contractlic
    Creado_por: $Creado_por
  )
  {
    success
    message
  }
}`;

export const  Rechazar_LicenciaEmpleado = gql`
mutation Rechazar_LicenciaEmpleado($Id_contractlic:[ID]!,$Observaciones:String!,$Creado_por:ID!){
  Rechazar_LicenciaEmpleado(
    Id_contractlic: $Id_contractlic
    Observaciones:$Observaciones
    Creado_por: $Creado_por
  )
  {
    success
    message
  }
}`;

export const  Estado_LicenciaEmpleado = gql`
mutation Estado_LicenciaEmpleado($Id_contractlic:ID!,$Estado:ID!,$Observaciones:String!,$Creado_por:ID!){
  Estado_LicenciaEmpleado(
    Id_contractlic: $Id_contractlic
    Estado:$Estado
    Observaciones:$Observaciones
    Creado_por: $Creado_por
  )
  {
    success
    message
  }
}`;

export const Crear_AusenciasAdj = gql`
mutation Crear_AusenciasAdj($Id_contractlic:ID!,$Codigo:ID!,$adjunto:[String]!,$creado_por:String!){
Crear_AusenciasAdj(
    Id_contractlic: $Id_contractlic
    Codigo: $Codigo
    adjunto: $adjunto
    creado_por: $creado_por
  )
  {
    success
    message
   
  }
}`;

 /*============creacion de Vacaciones de trabajo de usuarios contratados===============*/
export const  Nueva_VacacionesEmpleado = gql`
mutation Nueva_VacacionesEmpleado($Id_contrato:[ID]!,$EsAprobada:String!,$fecha_desde:String!,$fecha_hasta:String!, $creado_por:String!){
  Nueva_VacacionesEmpleado(
      Id_contrato: $Id_contrato
      EsAprobada: $EsAprobada
      fecha_desde: $fecha_desde
      fecha_hasta: $fecha_hasta
      creado_por: $creado_por
		)
   {
    success
    message
    isdata
    DataError {
      Mensaje
    }
   }  
}`;   

export const  Elimina_VacacionesEmpleado = gql`
mutation Elimina_VacacionesEmpleado($Id_contractvac:[ID]!,$Creado_por:ID!){
  Elimina_VacacionesEmpleado(
      Id_contractvac:$Id_contractvac
      Creado_por: $Creado_por
    )
   {
    success
    message
   
    
   }
}`;

export const  Aprobar_VacacionesEmpleado = gql`
mutation Aprobar_VacacionesEmpleado($Id_contractvac:[ID]!,$Creado_por:ID!){
  Aprobar_VacacionesEmpleado(
    Id_contractvac: $Id_contractvac
    Creado_por: $Creado_por
  )
  {
    success
    message
  }
}`;

export const  Rechazar_VacacionesEmpleado = gql`
mutation Rechazar_VacacionesEmpleado($Id_contractvac:[ID]!,$Observaciones:String!,$Creado_por:ID!){
  Rechazar_VacacionesEmpleado(
    Id_contractvac: $Id_contractvac
    Observaciones:$Observaciones
    Creado_por: $Creado_por
  )
  {
    success
    message
  }
}`;

export const  Estado_VacacionesEmpleado = gql`
mutation Estado_VacacionesEmpleado($Id_contractvac:ID!,$Estado:ID!,$Observaciones:String!,$Creado_por:ID!){
  Estado_VacacionesEmpleado(
    Id_contractvac: $Id_contractvac
    Estado:$Estado
    Observaciones:$Observaciones
    Creado_por: $Creado_por
  )
  {
    success
    message
  }
}`;

export const Crear_VacacionesAdj = gql`
mutation Crear_VacacionesAdj($Id_contractvac:ID!,$Codigo:ID!,$adjunto:[String]!,$creado_por:String!){
Crear_VacacionesAdj(
    Id_contractvac: $Id_contractvac
    Codigo: $Codigo
    adjunto: $adjunto
    creado_por: $creado_por
  )
  {
    success
    message
   
  }
}`;