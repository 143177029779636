import React,{useState,useEffect}  from 'react'
import { Modal, Button, Form, Input,InputNumber , Space, Row, Col,Tabs ,Select,Typography} from 'antd';
import '../../Assets/Css/page.css';
//import {useClientesByLike} from '../../hooks/custom-PostulacionCliente';
import {useAllLicencia,useAllExamenes,useAllExperencia,useAllHorarioLab,useAllNiveles,
useAllRangoEdad,useAllBoolean,useAllSexo,useAllCargo,useAllTContrato} from '../../hooks/custom-general';



const { TextArea } = Input;
const { TabPane } = Tabs;
const { Option, OptGroup } = Select;
const { Title } = Typography;



const ModalPostNew = ({ NewCliente,datapostulacionName, handleCreateCargoSave,Loading,handlePersonaSave,handleBeneficiosSave,handleOtrosSave, handleCancelUse}) => { 
    const{cargoAlldata} = useAllCargo();
    const{NivelesAlldata} = useAllNiveles();
    const{SexoAlldata} = useAllSexo();
  
    
    const{licenciaAlldata} = useAllLicencia();
    const{examenesAlldata} = useAllExamenes();
    const{experenciaAlldata} = useAllExperencia();
    const{horarioLabAlldata} = useAllHorarioLab();
    const{rangoEdadAlldata} = useAllRangoEdad();
    const{booleanAlldata} = useAllBoolean();
    const{tcontratoAlldata} = useAllTContrato();

   const [form2] = Form.useForm();
    const [disabled,setDisabled] = useState(true);
     const [disabledL,setDisabledL] = useState(false);
    const [disabledB,setDisabledB] = useState(false);
    const [namenewpost,setNamenewpost] = useState('Agregar el cargo Primero...');
    
    useEffect(() => {
        if(NewCliente){
            if(datapostulacionName!==false){
                setNamenewpost(datapostulacionName)
            }  
        }
    }, [NewCliente, datapostulacionName, namenewpost]);
   

    
    const onCargo = values => {
            form2.resetFields();
            return handleCreateCargoSave(values); 
          
    }

    const onPersona = values => {
            form2.resetFields();
            return handlePersonaSave(values); 
          
    }

    const onBeneficios = values => {
            form2.resetFields();
            return handleBeneficiosSave(values); 
          
    }

    const onOtros = values => {
            form2.resetFields();
            return handleOtrosSave(values); 
          
    }


    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;

    };

    const onChanget = (values) => {
       
        if(values==="1"){
           setDisabled(true)
        }
        if(values==="2"){
           setDisabled(false)
        }
    };

     const onChangeL = (values) => {
       
        if(values>0){
           setDisabledB(true)
            form2.setFieldsValue({
                SueldoBase: 0 
            });
        }
        if(values===0){
           setDisabledB(false)
        }

        if(values===null){
           setDisabledB(false)
        }
    };

    const onChangeB = (values) => {
       
        if(values>0){
            setDisabledL(true)
            form2.setFieldsValue({
                SueldoLiquido: 0,
            });
        }
        if(values===0){
           setDisabledL(false)
        }

        if(values===null){
           setDisabledL(false)
        }
    };


    return (
        <>
        <Modal
            visible={NewCliente}
            title="Nueva postulación"
             style={{ top: 20 }}
            onCancel={handleCancelUse}
            footer={[]}
        >

        <Tabs defaultActiveKey="1" destroyInactiveTabPane={true}  >
           
            <TabPane tab="Cargo" key="1">
            <Form
                form={form2}
                layout={"vertical"}
                name="Solicitud"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                
                onFinish={onCargo}
            >
                
               <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24 }}>
                <Title level={5}>{namenewpost}</Title>
                </Col>
                </Row>
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    label="Cargo"
                    name="Cargo"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Cargo',
                        },
                    ]}
                    hasFeedback
                >
                
                <Select placeholder="Cargo" showSearch
                        filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                
                  {cargoAlldata.map((data) => {
                        return (
                            <Option key={data.cargo_id} value={data.cargo_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })} 
                
                </Select>
                
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    label="Vacantes"
                    name="vacantes"
                   
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Nro Vacantes'   
                        }   
                    ]}
                    hasFeedback  
                    
                >
                    <Input
                        prefix={<Icon type='UserAddOutlined' className="site-form-item-icon" />}
                        type="number"
                        placeholder="Nro Vacantes"
                    />
                </Form.Item>
                </Col>
                </Row>
                
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    label="Horario Laboral"
                    name="HorarioLaboral"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Horario Laboral',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Horario Empleo" >
                <OptGroup label="Horario Laboral">
                   {horarioLabAlldata.map((data) => {
                        return (
                            <Option key={data.hlabid} value={data.hlabid}>
                                {data.descripcion}
                            </Option>
                        );
                    })} 
                </OptGroup> 
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                 <Form.Item 
                    label="Tipo Contrato"
                    name="TipoContrato"
                    
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Tipo Contrato'  
                        }   
                    ]}
                    hasFeedback  
                    
                >
                <Select placeholder="Tipo Contrato" onChange={onChanget} >
                <OptGroup label="Tipo Contrato">
                  {tcontratoAlldata.map((data) => {
                        return (
                            <Option key={data.tcontid} value={data.tcontid}>
                                {data.descripcion}
                            </Option>
                        );
                    })} 
                </OptGroup>     
                </Select>
                </Form.Item>
                </Col>
                </Row>

                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="MesesPfijo"
                    label="Meses Plazo Fijo"
                   
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese Meses Plazo Fijo'   
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                    <Input
                        prefix={<Icon type='CalendarOutlined' className="site-form-item-icon" />}
                        type="number"
                        disabled={disabled}
                        placeholder="Meses Plazo Fijo"
                    />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                     label="Experiencia Laboral"
                    name="Experiencia"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor Seleccione Experiencia',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Experiencia" >
                <OptGroup label="Experiencia">
                  {experenciaAlldata.map((data) => {
                        return (
                            <Option key={data.expid} value={data.expid}>
                                {data.descripcion}
                            </Option>
                        );
                    })} 
                </OptGroup>     
                </Select>
                </Form.Item>
                </Col>
                </Row>
                
                
                
                
                <Form.Item
                    
                    name="DescripcionCargo" 
                >
                 <TextArea rows={4} placeholder="Otros asociados al cargo"  style={{ width: '100%' }} />
                </Form.Item>
               
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Guardar
                    </Button>
                    <Button key="back" onClick={handleCancelUse}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>
                
            </TabPane>
            <TabPane tab="Persona" key="2">
                <Form
                layout={"vertical"}
                form={form2}
                name="NuevoPostulante"
                onFinish={onPersona}
            >
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24 }}>
                <Title level={5}>{namenewpost}</Title>
                </Col>
                </Row>
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    label="Educación"
                    name="NivelCursado"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Ultimo Nivel Cursado',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Ultimo Nivel Cursado" >
                <OptGroup label="Nivel Cursado">
                  {NivelesAlldata.map((data) => {
                        return (
                            <Option key={data.nivel_id} value={data.nivel_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}  
                </OptGroup>    
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    label="Genero"
                    name="Genero"
                    rules={[
                        { required: true,message: 'Seleccione Genero'},
                                
                    ]}
                    hasFeedback
                >
                <Select placeholder="Genero" mode="multiple" maxTagCount= 'responsive' showArrow >
                <OptGroup label="Genero">
                    {SexoAlldata.map((data) => {
                        return (
                            <Option key={data.sexo_id} value={data.descripcion}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </OptGroup>    
                </Select>
                </Form.Item>
                </Col>
                </Row>
               
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    label="Restricción Nacionalidad"
                    name="RestNacionalidad"
                    rules={[
                        { required: true,message: 'Seleccione Nacionalidad'},
                                
                    ]}
                    hasFeedback
                >
                <Select placeholder="Restricción Nacionalidad" >
                <OptGroup label="Restricción Nacionalidad">
                    {booleanAlldata.map((data) => {
                    return (
                        <Option key={data.estid} value={data.estid}>
                            {data.descripcion}
                        </Option>
                    );
                })}
                </OptGroup>
                </Select>
                </Form.Item>
                </Col>
                 <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    label="Restricción Comuna"
                    name="RestComuna"
                    rules={[
                        { required: true,message: 'Seleccione Comuna'},
                                
                    ]}
                    hasFeedback
                >
                <Select placeholder="Restricción Comunas" >
                <OptGroup label="Restricción Comunas">
                    {booleanAlldata.map((data) => {
                        return (
                            <Option key={data.estid} value={data.estid}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </OptGroup>    
                </Select>
                </Form.Item>
              
                </Col>
                </Row>
          
                <Row gutter={16}>
               <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                     label="Rango Edad"
                    name="RangoEdad"
                    rules={[
                        { required: true,message: 'Seleccione Rango de Edad'},
                                
                    ]}
                    hasFeedback
                >
                   <Select placeholder="Rango de Edad" >
                   <OptGroup label="Rango de Edad">
                    {rangoEdadAlldata.map((data) => {
                        return (
                            <Option key={data.redadid} value={data.redadid}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                    </OptGroup>
                    </Select>
                </Form.Item>
                </Col>
               <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    label="Discapacidad"
                    name="Discapacidad"
                    rules={[
                        { required: true,message: 'Seleccione Estado'},
                                
                    ]}
                    hasFeedback
                >
                <Select placeholder="Persona con Discapacidad" >
                    <OptGroup label="Persona con Discapacidad">
                    {booleanAlldata.map((data) => {
                        return (
                            <Option key={data.estid} value={data.estid}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                    </OptGroup>
                </Select>
                </Form.Item>
                </Col>
                </Row>
               
                <Form.Item
                    name="DescripcionPersona" 
                >
                 <TextArea rows={4} placeholder="Observaciones"  style={{ width: '100%' }} />
                </Form.Item>
               
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading} >
                        Guardar
                    </Button>
                    <Button key="back" onClick={handleCancelUse}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
               
               
            </Form>
            </TabPane>
            <TabPane tab="Remuneración" key="3">
            <Form
                form={form2}
                layout={"vertical"}
                name="Solicitud"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                
                onFinish={onBeneficios}
            >
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24 }}>
                <Title level={5}>{namenewpost}</Title>
                </Col>
                </Row>
                <Row gutter={16}>

                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                 initialValue={0}
                    label="Sueldo Liquido"
                    name="SueldoLiquido"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese El Sueldo Liquido',
                        },
                 
                    ]}
                    hasFeedback
                >
               
                <InputNumber 
                   
                    style={{ width: '100%' }}
                    min={0}
                    max={10000000}
                    prefix={<Icon type='DollarOutlined' className="site-form-item-icon" />}
                    placeholder="Sueldo Liquido"
                    formatter={value => ` ${value}`.replace(/\./,',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    parser={x => parseFloat(`${x}`.replace(/,/, '#').replace(/\./g, '').replace(/#/,'.')) } 
                    onChange={onChangeL} 
                    disabled={disabledL}
                   
                />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                 initialValue={0}
                    label="Sueldo Base"
                    name="SueldoBase"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese El Sueldo Base',
                        },
                    ]}
                    hasFeedback
                >
                <InputNumber 
                   
                    style={{ width: '100%' }}
                    prefix={<Icon type='DollarOutlined' className="site-form-item-icon" />}
                    placeholder="Sueldo Base"
                    formatter={value => ` ${value}`.replace(/\./,',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    parser={x => parseFloat(`${x}`.replace(/,/, '#').replace(/\./g, '').replace(/#/,'.')) } 
                    onChange={onChangeB}  
                    disabled={disabledB}
                />
                </Form.Item>
                </Col>
                </Row>
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                 initialValue={0}
                    label="Bono"
                    name="Bono"
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese El Bono',
                        },
                    ]}
                    hasFeedback
                >
                <InputNumber 
                 
                    style={{ width: '100%' }}
                    prefix={<Icon type='DollarOutlined' className="site-form-item-icon" />}
                    placeholder="Bono"
                    formatter={value => ` ${value}`.replace(/\./,',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    parser={x => parseFloat(`${x}`.replace(/,/, '#').replace(/\./g, '').replace(/#/,'.')) }  
                   
                />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                 <Form.Item 
                    label="Hora Extra"
                    name="HoraExtra"
                   
                    rules={[
                        {
                            required: true,
                            message: 'Por favor Ingrese Respuesta'  
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                <Select placeholder="Hora extra" >
                <OptGroup label="Hora extra">
                   {booleanAlldata.map((data) => {
                        return (
                            <Option key={data.estid} value={data.estid}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </OptGroup>    
                </Select>
                </Form.Item>
                </Col>
                </Row>
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                     initialValue={0}
                    label="Colacion"
                    name="Colacion"
                   
                >
                 <InputNumber 
                   
                    style={{ width: '100%' }}
                    prefix={<Icon type='DollarOutlined' className="site-form-item-icon" />}
                    placeholder="Monto Colación"
                    formatter={value => ` ${value}`.replace(/\./,',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    parser={x => parseFloat(`${x}`.replace(/,/, '#').replace(/\./g, '').replace(/#/,'.')) }  
                   
                />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                     initialValue={0}
                    label="Movilización"
                    name="Movilizacion"
                    
                >
               <InputNumber 
                    
                    style={{ width: '100%' }}
                    prefix={<Icon type='DollarOutlined' className="site-form-item-icon" />}
                    placeholder="Monto Movilización"
                    formatter={value => ` ${value}`.replace(/\./,',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                    parser={x => parseFloat(`${x}`.replace(/,/, '#').replace(/\./g, '').replace(/#/,'.')) }  
                   
                />
                </Form.Item>
                </Col>
               
                </Row>
                
                <Form.Item
                    name="DescripcionBeneficios" 
                >
                 <TextArea rows={4} placeholder="Observaciones"  style={{ width: '100%' }} />
                </Form.Item>
               

                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Guardar
                    </Button>
                    <Button key="back" onClick={handleCancelUse}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>   
            </TabPane>
            <TabPane tab="Otros" key="4">
             <Form
                form={form2}
                layout={"vertical"}
                name="Solicitud"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                
                onFinish={onOtros}
            >
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24 }}>
                <Title level={5}>{namenewpost}</Title>
                </Col>
                </Row>
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    label="Ropa"
                    name="Ropa"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Opción',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Ropa" >
                 <OptGroup label="Ropa">
                  {booleanAlldata.map((data) => {
                        return (
                            <Option key={data.estid} value={data.estid}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                    </OptGroup>
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                 <Form.Item
                    label="EPP"
                    name="EPP"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Opción',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="EPP" >
                <OptGroup label="EPP">
                  {booleanAlldata.map((data) => {
                        return (
                            <Option key={data.estid} value={data.estid}>
                                {data.descripcion}
                            </Option>
                        );
                    })} 
                </OptGroup>    
                </Select>
                </Form.Item>
                </Col>
                </Row>
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    label="Examenes"
                    name="Examenes"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Opción',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Examenes" mode="multiple" maxTagCount= 'responsive' showArrow>
                <OptGroup label="Examenes">
                  {examenesAlldata.map((data) => {
                        return (
                            <Option key={data.codigo} value={data.descripcion}>
                                {data.descripcion}
                            </Option>
                        );
                    })} 
                </OptGroup>     
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                 <Form.Item
                    label="Renovación"
                    name="Renovacion"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Opción',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Renovacion" >
                <OptGroup label="Renovacion">
                 {booleanAlldata.map((data) => {
                        return (
                            <Option key={data.estid} value={data.estid}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </OptGroup>    
                </Select>
                </Form.Item>
                </Col>
                </Row>
                
               <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="ContratoPlanta"
                     label="Contrato Planta"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Opción',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Contrato Planta" >
                <OptGroup label="Contrato Planta">
                 {booleanAlldata.map((data) => {
                        return (
                            <Option key={data.estid} value={data.estid}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </OptGroup>    
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                     label="Licencia Conducir"
                    name="ClaseLicConducir"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Clase Licencia Conducir',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Clase Licencia" mode="multiple" maxTagCount= 'responsive' showArrow >
                 <OptGroup label="Clase Licencia">
                  {licenciaAlldata.map((data) => {
                        return (
                            <Option key={data.codigo} value={data.descripcion}>
                                {data.descripcion}
                            </Option>
                        );
                    })} 
                    </OptGroup> 
                </Select>
                </Form.Item>
                </Col>
                </Row>

                <Form.Item
                    name="DescripcionOtros" 
                >
                 <TextArea rows={4} placeholder="Observaciones"  style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Guardar
                    </Button>
                    <Button key="back" onClick={handleCancelUse}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>   
            </TabPane>
           
        </Tabs>
           
        </Modal>
        </> 
    )
}

export default ModalPostNew 
