import React, {useEffect,useState}  from 'react'
import { Modal, Button, Form, Input, Space, Row, Col ,Select ,Card,List, Avatar, Divider } from 'antd';
import {useAllEstado} from '../../hooks/custom-general';
import '../../Assets/Css/page.css';
import InfiniteScroll from 'react-infinite-scroll-component';
const { TextArea } = Input;
const { Option } = Select;


const ModalObsEstadoPost = ({ EditEst, Loading, handleSaveEst, handleCancelEst,dataEditEst,dataObs}) => {
    const [form4] = Form.useForm();
    const {data,loading} = useAllEstado();
    const [Estdata,setEstdata] = useState([]);
    const [activeTabKey1, setActiveTabKey1] = useState('Editar');
  
   
  
    useEffect(() => {

        if (EditEst) {
            if(loading===false){    
                const dataest = data.GetallEst
                setEstdata(dataest)
            } 

           form4.setFieldsValue({
                Estado:dataEditEst.Estado_id,
            });
        }

       
       
        
       
    }, [dataEditEst, data, loading, EditEst, form4]);

    
    const onAdd = async values => {
        form4.resetFields(["Observaciones"]);
        return handleSaveEst(values);     
    }

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;

    };
    

    const tabList = [
    {
        key: 'Editar',
        tab: 'Editar',
    },
    {
        key: 'Observaciones',
        tab: 'Observaciones',
    },
    ];

    
    const contentList = {
        Editar: <Form
                form={form4}
                name="EstadoPostulacion"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                onFinish={onAdd}
            >
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item name="Estado" label="Estado" 
                    rules={[
                        { required: true,message: 'Seleccione Estado'},
                                
                    ]}
                >
                <Select placeholder="Estado" >
                    {Estdata.map((data) => {
                        return (
                            <Option key={data.estado_id} value={data.estado_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
                </Col>
                </Row>
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24 }}>
                <Form.Item
                    name="Observaciones"    
                >
                <TextArea rows={4} placeholder="Comentarios"  style={{ width: '100%' }} />
                </Form.Item>
                </Col>
                </Row>
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading} >
                        Guardar
                    </Button>
                    <Button key="back" onClick={handleCancelEst}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>,
        Observaciones: <div
                            id="scrollableDiv"
                            style={{
                                height: 200,
                                overflow: 'auto',
                                padding: '0 16px',
                                border: '1px solid rgba(140, 140, 140, 0.35)',
                            }}
                            >
                            <InfiniteScroll
                            // pageStart={0}
                                dataLength={dataObs.length}
                                
                                //hasMore={data1.length < 50}
                            // loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                                endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
                                scrollableTarget="scrollableDiv"
                            >
        
                            <List
                            itemLayout="horizontal"
                            dataSource={dataObs}
                            renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                avatar={<Avatar style={{ backgroundColor: "#1890ff" }} icon={<Icon type="UserOutlined" key="handlehead" />} />}
                                title={item.title}
                                description={item.Estado}
                                />
                               
                                {item.Observaciones} 
                            </List.Item>

                            
                                )}
                            />
                            </InfiniteScroll>
                        </div>
    };


  

    const onTab1Change = key => {
        setActiveTabKey1(key);
    };

    return (
        <Modal
            visible={EditEst}
            title="Cambiar Estado"
            onCancel={handleCancelEst}
            footer={[
                
               
            ]}
        >

        <Card
        style={{ width: '100%' }}
        bordered={false} 
        tabList={tabList}
        activeTabKey={activeTabKey1}
        onTabChange={key => {
          onTab1Change(key);
        }}
      >
        {contentList[activeTabKey1]}
      </Card>

            
        </Modal>
    )
}

export default ModalObsEstadoPost
