import React,{useState,useEffect}  from 'react'
import moment from 'moment'
import { Modal, Button, Form, Input, Space, Row, Col,Tabs,DatePicker ,Select,Typography,InputNumber} from 'antd';
import '../../../Assets/Css/page.css';
import {  validate,format } from 'rut.js'

import {useAllTipoContacto,useAllCargo,useAllEstCivil,useAllNacionalidad,
        useAllSexo,useAllRegiones,useComunasById} from '../../../hooks/custom-general';
import{ useEmpleadosByFilter } from '../../../hooks/custom-Empleados';        

const { TabPane } = Tabs;
const { Option } = Select;
const { Title } = Typography;



const ModalPostNew = ({ viewNew, Loading,newEmplename, handleEmpleSave,handleLaboralSave,handleFinancieroSave,handleContactoSave,handleVacacionesSave,handleCancelEmple,contratos,isapre,afp,clientes,Banco}) => {
   
     const{civilAlldata} = useAllEstCivil();
    const{NacAlldata} = useAllNacionalidad();
    const{SexoAlldata} = useAllSexo();
    const{RegionAlldata} = useAllRegiones();
    const[SelComuna] = useComunasById();
    const{cargoAlldata} = useAllCargo();
    const {data,loading} = useAllTipoContacto();
   


    const [form2] = Form.useForm();
    const [disabled,setDisabled] = useState();
    const [Tipodata,setTipodata] = useState([]);
    const [Comunas,setComunas] = useState([]);
    const [namenewpost,setNamenewpost] = useState('Agregar un Empleado Primero...');
    const dateFormat = 'DD/MM/YYYY'
    const[Setempleados] = useEmpleadosByFilter();
   
    
    useEffect(() => {
       
        if(loading===false){    
            const datatipo = data.GetallTipoContacto
            setTipodata(datatipo)
        }    

        if(newEmplename!==false){
            setNamenewpost(newEmplename)
        }
    }, [data, loading, newEmplename]);
    

    const onCityChange = async values => {
        const comunassel = await  SelComuna({variables:{
            region_id:values,
        }})
 
     
      
        if(comunassel.loading===false){
            setComunas(comunassel.data.GralComunasById)
           
        }
         
    }


    const onAddEmpleado = values => {
         
            return handleEmpleSave(values); 
          
    }

    const onAddLaboral = values => {
           
            return handleLaboralSave(values); 
          
    }

    const onAddFinanciero = values => {
          
            return handleFinancieroSave(values); 
          
    }

    const onAddContacto = values => {
           
            return handleContactoSave(values); 
          
    }

    const onAddVacaciones = values => {
           
            return handleVacacionesSave(values); 
          
    }


    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;

    };

    const onChangeInd = (values) => {
       
        if(values==="1"){
             form2.setFieldsValue({
                FechaHasta: moment('3000-01-01','YYYY/MM/DD'), 
            });
           setDisabled(true)
        }
        if(values==="2"){
             form2.setFieldsValue({
                FechaHasta: '', 
            });
           setDisabled(false)
        }
    };

   
    const onDataEmpleado = async value => {
     
        let Rut='';
        if(value.Rut!==''){
             Rut=format(value.target.value);
        }
           
        const empleado = await  Setempleados({variables:{
            Rut: Rut,
            Nombre:"",
            Id_sexo: [],
            Id_nacionalidad:[],
            Id_estado_civil: [],
            Id_cargo: [],
            edad: "",
            experiencia: "",
            id_idioma: []
        }})
 
     
      
        if(empleado.loading===false){
          
           onCityChange(empleado.data.GetEmpleadosByFilter[0].region_id)
            
            form2.setFieldsValue({
                FechaNacimiento:moment(empleado.data.GetEmpleadosByFilter[0].Fecha_nacimiento,'DD/MM/YYYY'),
                Nombre: empleado.data.GetEmpleadosByFilter[0].Nombre,
                EstadoCivil:  empleado.data.GetEmpleadosByFilter[0].Id_estado_civil,
                Sexo: empleado.data.GetEmpleadosByFilter[0].Id_sexo,
                Nacionalidad: empleado.data.GetEmpleadosByFilter[0].Id_Nacionalidad,
                Comuna:empleado.data.GetEmpleadosByFilter[0].id_comuna,
                Direccion:empleado.data.GetEmpleadosByFilter[0].Direccion,
                Region:empleado.data.GetEmpleadosByFilter[0].region_id,
            });


        }
       

    };

    return (
        <>
        <Modal
            visible={viewNew}
            title="Nuevo Empleado"
             style={{ top: 20 }}
            onCancel={handleCancelEmple}
            footer={[]}
        >

        <Tabs defaultActiveKey="1" destroyInactiveTabPane={true}  >
            <TabPane tab="Empleado" key="1">
            <Form
                form={form2}
                name="Empleado"
                onFinish={onAddEmpleado}
            >
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="Rut"
                    tooltip="Ingrese Rut sin puntos solo Guion"
                   
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Rut'  
                        }  
                        ,{
                            pattern: /[0-9kK-]+$/,
                            message: 'Rut solo numeros y Guion y K ',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                            if (validate(value)) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Rut mal ingresado..!'));
                            },
                        }),
                    ]}
                    hasFeedback  
                    
                >
                    <Input 
                        maxLength={15}
                        prefix={<Icon type='UserOutlined'  className="site-form-item-icon" />}
                        type="text"
                        placeholder="Ingrese Rut sin puntos solo Guión"
                        onChange={onDataEmpleado}
                        
                    />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="FechaNacimiento"
                    rules={[
                        { required: true,message: 'Seleccione Fecha Nacimiento'},
                                
                    ]}
                    hasFeedback
                >
                  <DatePicker format={dateFormat} style={{ width: '100%' }}  placeholder="Fecha Nacimiento"/>
                 
                </Form.Item>
              
                </Col>
                </Row>
                <Form.Item
                    name="Nombre"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Nombre',
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Nombre"
                    />
                </Form.Item>
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
               <Form.Item
                    name="Empresa"
                    rules={[
                        { required: true,message: 'Seleccione Empresa'},
                                
                    ]}
                    hasFeedback
                >
                   <Select placeholder="Empresa" showSearch
                        filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                    {clientes.map((data) => {
                        return (
                            <Option key={data.Id_cliente} value={data.Id_cliente}>
                                {data.Nombre}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
               <Form.Item
                    name="EstadoCivil"
                    rules={[
                        { required: true,message: 'Seleccione Estado Civil'},
                                
                    ]}
                    hasFeedback
                >
                   <Select placeholder="Estado Civil" >
                    {civilAlldata.map((data) => {
                        return (
                            <Option key={data.estado_civil_id} value={data.estado_civil_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
                </Col>
                
                </Row>
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Sexo"
                    rules={[
                        { required: true,message: 'Seleccione Genero'},
                                
                    ]}
                    hasFeedback
                >
                   <Select placeholder="Genero" >
                    {SexoAlldata.map((data) => {
                        return (
                            <Option key={data.sexo_id} value={data.sexo_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Nacionalidad"
                    rules={[
                        { required: true,message: 'Seleccione Nacionalidad'},
                                
                    ]}
                    hasFeedback
                >
                   <Select placeholder="Nacionalidad" >
                    {NacAlldata.map((data) => {
                        return (
                            <Option key={data.nacionalidad_id} value={data.nacionalidad_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
              
                </Col>
                </Row>
          
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
               <Form.Item
                    name="Region"
                    rules={[
                        { required: true,message: 'Seleccione Region'},
                                
                    ]}
                    hasFeedback
                >
                   <Select placeholder="Region" onChange={onCityChange} showSearch
                        filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                    {RegionAlldata.map((data) => {
                        return (
                            <Option key={data.region_id} value={data.region_id}>
                                {data.region_nombre}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Comuna"
                    rules={[
                        { required: true,message: 'Seleccione Comuna'},
                                
                    ]}
                    hasFeedback
                >
                <Select placeholder="Comunas" showSearch
                        filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                    {Comunas.map((data) => {
                        return (
                            <Option key={data.comuna_id} value={data.comuna_id}>
                                {data.comuna_nombre}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
              
                </Col>
                </Row>
                <Form.Item
                    name="Direccion"
                    rules={[
                        {
                            required:true,
                            message: 'Por favor ingrese Direccion',
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Direccion"
                    />
                </Form.Item>
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading} >
                        Guardar
                    </Button>
                    <Button key="back" onClick={handleCancelEmple}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
               
               
            </Form>
            </TabPane>
            <TabPane tab="Laboral" key="2">
            
             <Form
                form={form2}
                name="Laboral"
                onFinish={onAddLaboral}
            >
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24 }}>
                <Title level={5}>{namenewpost}</Title>
                </Col>
                </Row>
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Cargo"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Cargo',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Cargo" showSearch
                        filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                  {cargoAlldata.map((data) => {
                        return (
                            <Option key={data.cargo_id} value={data.cargo_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}  
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="TipoContrato"
                    rules={[
                        { required: true,message: 'Seleccione Tipo Contrato'},
                                
                    ]}
                    hasFeedback
                >
                   <Select placeholder="Tipo Contrato"  onChange={onChangeInd}>
                   {contratos.map((data) => {
                        return (
                            <Option key={data.tcontid} value={data.tcontid}>
                                {data.descripcion}
                            </Option>
                        );
                    })}  
                </Select>
                </Form.Item>
              
                </Col>
                </Row>
               
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
               <Form.Item
                    name="FechaDesde"
                    rules={[
                        { required: true,message: 'Seleccione Fecha Inicio Contrato'},
                                
                    ]}
                    hasFeedback
                >
                  <DatePicker format={dateFormat} style={{ width: '100%' }}  placeholder="Fecha Inicio Contrato"/>
                 
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="FechaHasta"
                    rules={[
                        { required: true,message: 'Seleccione Fecha fin Contrato'},
                                
                    ]}
                    hasFeedback
                >
                  <DatePicker disabled={disabled} format={dateFormat} style={{ width: '100%' }}  placeholder="Fecha fin Contrato"/>
                 
                </Form.Item>
                </Col>
                </Row>
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                   name="id_isapre"
                     rules={[
                                { required: true,message: 'Seleccione Isapre'},
                            ]}
                >
                <Select placeholder="Selecione Isapre"  showArrow maxTagCount= 'responsive' showSearch
                        filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                     {isapre.map((data) => {
                        return (
                            <Option key={data.isapre_id} value={data.isapre_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}  
                </Select>
                </Form.Item>
              
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
               <Form.Item
                    name="id_afp"
                     rules={[
                                { required: true,message: 'Seleccione AFP'},
                            ]}
                >
                <Select placeholder="Selecione AFP"  showArrow maxTagCount= 'responsive'  showSearch
                        filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                    {afp.map((data) => {
                        return (
                            <Option key={data.afp_id} value={data.afp_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}  
                </Select>
                </Form.Item>
                </Col>
                </Row>
          
                
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading} >
                        Guardar
                    </Button>
                    <Button key="back" onClick={handleCancelEmple}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
               
               
            </Form>
            </TabPane>
            <TabPane tab="Financieros" key="3">
           
            <Form
                form={form2}
                name="financiero"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                onFinish={onAddFinanciero}
            >
                
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24 }}>
                <Title level={5}>{namenewpost}</Title>
                </Col>
                </Row>
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Title level={5}>{}</Title>
                </Col>
                </Row>

                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Banco"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor Seleccione Banco',
                        },
                    ]}
                    hasFeedback
                >
                    <Select placeholder="Bancos" showSearch
                        filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                    {Banco.map((data) => {
                        return (
                            <Option key={data.banco_id} value={data.banco_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="CtaCte"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor Ingrese Cta.Cte o vista'  
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder=" Cta.Cte o vista"
                    />
                </Form.Item>
                </Col>

                </Row>
                
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Guardar
                    </Button>
                    <Button key="back" onClick={handleCancelEmple}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>
            </TabPane>
            <TabPane tab="Contactos" key="4">
             <Form
                form={form2}
                name="TipoContacto"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                onFinish={onAddContacto}
            >
                
                 <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24 }}>
                <Title level={5}>{namenewpost}</Title>
                </Col>
                </Row>
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Title level={5}>{}</Title>
                </Col>
                </Row>

                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="TipoContacto2"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor Seleccione Tipo Contacto',
                        },
                    ]}
                    hasFeedback
                >
                    <Select placeholder="Tipo Contacto" >
                    {Tipodata.map((data) => {
                        return (
                            <Option key={data.tipo_contacto_id} value={data.tipo_contacto_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="Descripcion2"
                    tooltip="Ingrese Descripción"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Descripción'  
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Descripción"
                    />
                </Form.Item>
                </Col>

                </Row>
                
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Guardar
                    </Button>
                    <Button key="back" onClick={handleCancelEmple}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>
            </TabPane>
            <TabPane tab="Vacaciones" key="5">
             <Form
                form={form2}
                name="TipoContacto"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                onFinish={onAddVacaciones}
            >
                
                 <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24 }}>
                <Title level={5}>{namenewpost}</Title>
                </Col>
                </Row>
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Title level={5}>{}</Title>
                </Col>
                </Row>

                <Row gutter={16}>
               
                <Col xs={{ span: 12, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="Dias"
                    tooltip="Ingrese Dias"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese dias de vacaciones Inicial'  
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                    <InputNumber min={0} max={100}
                        defaultValue={0}
                      
                       style={{ width: '30vh' }}
                        placeholder="Vacaciones Inicial"
                    />
                </Form.Item>
                </Col>

                </Row>
                
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Guardar
                    </Button>
                    <Button key="back" onClick={handleCancelEmple}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>
            </TabPane>
        </Tabs>
           
        </Modal>
         </> 
    )
}

export default ModalPostNew 
