import React,{useState} from 'react'
import { Modal, Button, Form,  Space, Row, Col,Select,DatePicker } from 'antd';
import '../../../Assets/Css/page.css';
import 'moment/locale/es-us';
import locale from 'antd/es/date-picker/locale/es_ES';
import { useContratadoByIdCliente } from '../../../hooks/custom-contratos'

const { Option } = Select;
const { RangePicker } = DatePicker;




const ModalLicencia = ({viewNew, Loading, handleSaveLicencia, handleCancelLicencia,clientes,Ausencia,Estdata}) => {
    const [selEmpleados] = useContratadoByIdCliente();

    const [form5] = Form.useForm();
     const dateFormat = 'DD/MM/YYYY'
    const [empleados,setEmpleados] = useState([]);
   
     
    
    const onAdd = async values => {
       
        handleSaveLicencia(values)
        form5.resetFields();
          
    }

    const onClieChange = async values => {
        const emplesel = await  selEmpleados({variables:{
            Id_cliente:values,
        }})
       
 
        if(emplesel.loading===false){
            setEmpleados(emplesel.data.GetContratadoByIdCliente)
        }
         
    }

    return (
        <>
        <Modal
            visible={viewNew}
            title={"Crear Ausencia"}
            onCancel={handleCancelLicencia}
            footer={[]} 
        >

        
        <Form
                form={form5}
                name="NuevoLicencia"
                initialValues={{
                    preserve:false,
                    resetFields:true,
                }}
                onFinish={onAdd}
            >
                
                 <Row gutter={24}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Id_tipoausencia"
                     rules={[
                                { required: true,message: 'Seleccione Licencia'},
                            ]}
                >
                     <Select placeholder="Selecione Licencia"  showArrow maxTagCount= 'responsive'  >
                    {Ausencia.map((data) => {
                        return (
                            <Option key={data.tipoa_id} value={data.tipoa_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}   
                </Select>
                </Form.Item>
                </Col>
                
                 <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="EsAprobada"
                     rules={[
                                { required: true,message: 'Aprobada'},
                            ]}
                >
                     <Select placeholder="Aprobada"  showArrow maxTagCount= 'responsive'  showSearch
                        filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {Estdata.map((data) => {
                        return (
                            <Option key={data.aprobada} value={data.aprobada}>
                                {data.descripcion}
                            </Option>
                        );
                    })}
                       
                </Select>
                </Form.Item>
                </Col>
                
                 </Row>
                <Row gutter={24}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Cliente"
                     rules={[
                                { required: true,message: 'Seleccione Cliente'},
                            ]}
                >
               <Select placeholder="Selección Cliente"  showArrow maxTagCount= 'responsive'  onChange={onClieChange} showSearch
                        filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                  {clientes.map(data => {
                        return (
                            <Option key={data.Id_cliente} value={data.Id_cliente}>
                                {data.Nombre}
                            </Option>
                        );
                    })}  
                </Select>
                </Form.Item>
                </Col>
               
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12}}>
                <Form.Item
                    name="Id_contrato"
                     rules={[
                                { required: true,message: 'Seleccione Empleado'},
                            ]}
                >
                <Select placeholder="Selección Empleado" mode="multiple" showArrow maxTagCount= 'responsive'  showSearch
                        filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                 {empleados.map((data) => {
                        return (
                            <Option key={data.Id_contrato} value={data.Id_contrato}>
                                {data.Nombre}
                            </Option>
                        );
                    })} 
                </Select>
                </Form.Item>
                </Col>
                 </Row>
                 <Row gutter={24}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 14 }}>
                <Form.Item
                    name="FechaLicencia"
                     rules={[
                                { required: true,message: 'Seleccione fecha de Licencia'},
                            ]}
                >
                    <RangePicker locale={locale} format={dateFormat} />
                </Form.Item>
                </Col>
                </Row>
                <Row gutter={24}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6 }}>
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Guardar
                    </Button>
                    <Button key="back" onClick={handleCancelLicencia}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
                </Col>
                </Row>
            </Form>
     
        </Modal>
       </> 
    )
}

export default ModalLicencia
