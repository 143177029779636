import React,{useState,useEffect}  from 'react'
import { Modal, Form,Row,Col,Input ,Space,Button,Select,PageHeader,Tooltip} from 'antd';
import '../../Assets/Css/page.css';
const { Option } = Select;


const ModalLoginCreate = ({ EditLCont, DataEditClient, Loading, handleCancelLCont,ContactDetdata,handleNewLcontact,Maildata ,handleResetclave,handleBloqclave,handleDBloqclave}) => {

    const [form16] = Form.useForm();
       const [DataEmail, setDataEmail] = useState([]);
       const [estadologin, setEstadologin] = useState("Sesion NO Iniciada...");
       const [estadomail, setEstadomail] = useState("Sesion NO Iniciada...");
      
   
    useEffect(() => {
     
        if (EditLCont) {
           if(Maildata){
                setDataEmail(Maildata)
           }
          
           if(ContactDetdata.Users!==null)
           {
            setEstadologin("Sesion Activada...")
           }
           else{
            setEstadologin("Sesion NO Iniciada...")
           }

           if(ContactDetdata.SActivo==='Bloqueado')
           {
            setEstadologin("Sesion Bloqueada...")
           }


           if(ContactDetdata.Id_tipo!==null)
           {
            setEstadomail("")
           }
           else{
            setEstadomail("Debe agregar Correo...")
           }
           
            form16.resetFields();
            if(ContactDetdata){
                form16.setFieldsValue({
                    Id_contacto:ContactDetdata.Id_contacto,
                    userSesion:ContactDetdata.Users,
                    Id_tipo:ContactDetdata.Id_tipo,
                
                });
            }

          

          
        }
    }, [ContactDetdata, DataEditClient, EditLCont, Maildata, form16]);
       
   
   
    const onAdds = async values => {
        return handleNewLcontact(values)    
    }
    const Onsesioncontact  = (values) => {
        
        return handleResetclave(values) 
    }
    
    const OnsesioncontactBloq  = (values) => {
       
        return handleBloqclave(values) 
    }

    const OnsesioncontactDBloq  = (values) => {
       
        return handleDBloqclave(values) 
    }


    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;
    };

    
    return (
        <>
        <Modal
            visible={EditLCont}
            title={ContactDetdata.Nombre}
           
            width={'100vh'}
            onCancel={handleCancelLCont}
            footer={[]}
        >
        <PageHeader
            ghost={false}
            title={estadologin}
            subTitle={estadomail}
            extra={[ <Tooltip title="Resetear Clave" color={'blue'} zIndex={1000}><Button  onClick={() => { Onsesioncontact(ContactDetdata) }}  icon={<Icon type='ReloadOutlined' />}/></Tooltip>,
                     <Tooltip title="Activar Sesion" color={'blue'} zIndex={1000}><Button  onClick={() => { OnsesioncontactDBloq(ContactDetdata) }}  icon={<Icon type='UnlockOutlined' />}/></Tooltip>,
                     <Tooltip title="Bloquear Sesion" color={'blue'} zIndex={1001}><Button  onClick={() => { OnsesioncontactBloq(ContactDetdata) }}  icon={<Icon type='LockOutlined' />}/></Tooltip>]}
           
        >

        <Form
                form={form16}
                name="Sesionuser"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                onFinish={onAdds}
            >
                

                <Row gutter={16}>
                
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Id_contacto"
                    hidden
                >
                <Input
                    prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                    type="text"
                    placeholder="id_contacto"
                    
                />
                </Form.Item>
                </Col>
                </Row>
               
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="userSesion"
                     rules={[
                        {
                            required: true,
                            message: 'Ingrese un Usuario Sesion',
                        },
                    ]}
                    hasFeedback
                >
                <Input
                    prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                    type="text"
                    placeholder="Usuario Sesion"
                    
                />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Id_tipo"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor Seleccione Email',
                        },
                    ]}
                    hasFeedback
                >
                    <Select placeholder="Email"  >
                   
                    {
                        DataEmail.map((data) => {
                        return (
                            <Option key={data.Id_tipo} value={data.Id_tipo}>
                                {data.descripcion}
                            </Option>
                        );
                        })
                    }
                </Select>
                </Form.Item>
                </Col>
               

                </Row>
                
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Guardar
                    </Button>
                    <Button key="back" onClick={handleCancelLCont}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
        </Form>
        </PageHeader>
        </Modal>
       </> 
    )
   
}

export default ModalLoginCreate
