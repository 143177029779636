import React,{useState,useEffect} from 'react'
import moment from 'moment'
import '../Assets/Css/page.css';
import { Modal,Card,Space,Button} from 'antd';
import IcoButton from '../Components/IconButtonGroup';
import Buscar from '../Components/Loading';
import ModalClave from '../Components/ModalClave';
import {useUpdatepass} from '../hooks/custom-users';


import TableTurnos from '../Components/contratos/creacionturnos/TableTurnos';
import SearchEmpleTurnos from '../Components/contratos/creacionturnos/SearchEmpleTurnos';
import ModalTurno from '../Components/contratos/creacionturnos/ModalTurno';
import ModalMessages  from '../Components/contratos/creacionturnos/ModalMessages';

import { useListaTurnos,useNuevoTurnoEmpleado,useEliminaTurnoEmpleado } from '../hooks/custom-contratos';
import { useAllTurnos } from '../hooks/custom-general'
import { useAllClientesActivo } from '../hooks/custom-Clientes'
import{useVerifyToken,useVerify} from "../hooks/useToken"   
const CreacionTurnos = () => {
    
    const { confirm } = Modal;
    const {data:Tokensverify, loading:TokensverifyLoading}= useVerify();
    const{Tokens,TokensLoading} = useVerifyToken();
    const{allTurnosdata,TurnosLoading}= useAllTurnos();
    const{data:clieData,loading:clieloading}= useAllClientesActivo();
    const [NuevoTurnoEmpleado] = useNuevoTurnoEmpleado();
    const [EliminaTurnoEmpleado] = useEliminaTurnoEmpleado();
    const [clientes, setClientes] = useState();
    const [turnos, setTurnos] = useState();
    const [loadingpage, setloadingpage] = useState(true);
    const [Visible, setVisible] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [SetUpd] = useUpdatepass();

    const[ListaTurnos] =  useListaTurnos(); 
    
    
    const [SelFiltros, setSelFiltros] = useState();
    const [messages, setMessages] = useState(false);
    const [DataTurno, SetdataTurno] = useState(false); 
    const [viewNew,setViewNew] = useState(false);
    const [ViewMessage,setViewMessage] = useState(false);
    const [selRowKeys,  setSelRowKeys] = useState([]);
   
   
   const [creadoPor, setCreadoPor] = useState('');
   
   useEffect(() => {
  
         if(TokensverifyLoading===false){
            
            if(Tokensverify.GetLogin_validate.success===false){
                Modal.error({
                    title: Tokensverify.GetLogin_validate.message,
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");
                    }
                })
            }
           
        }

        if(TokensLoading===false){
            if(Tokens){
                 const userF=Tokens.token.split('||')
                 setCreadoPor(userF[2])
            }
        } 
         
        if(TurnosLoading===false){
        
        setTurnos(allTurnosdata)

        }
        if(clieloading===false){
            const dataclient = clieData.GetClientAllActivo
             
            setClientes(dataclient)
             
        }
        if(clieloading===false && TurnosLoading===false){
            setloadingpage(false)
        }

        

       
    }, [ Tokens, TokensLoading, Tokensverify, TokensverifyLoading, TurnosLoading, allTurnosdata, clieData, clieloading])
   
   const [submenu] = useState([
        {
            key: '1',
            icon: 'KeyOutlined',
            title: 'Nueva Clave',

        }, {
            key: '2',
            icon: 'PoweroffOutlined',
            title: 'Salir',

        }
    ])

    const onGroupClick = (array) => {
       
        switch (array.key) {
            case '1':
                //console.log(array.key);
                setVisible(true);
               break
            default:
                Modal.confirm({
                    title: 'Estas Seguro de Cerrar la aplicación ..??',
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");

                        
                }
                })
        }
    }

    const handleOk = async (values) => {

      
       
        const EditPass = await  SetUpd({variables:{
            id_sesion:creadoPor,
            oldPass: values.claveactual,
            NewPass: values.NuevaClave,
          
        }})
      //console.log(EditPass)
       // setLoading(true);
        const success = EditPass.data.updatePassword.success
        const message = EditPass.data.updatePassword.message
        if(success===true){   
           const  modal = Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
                setVisible(false);
                modal.destroy();
            }, 3000);

        }else{
            Modal.warning({
                 content: message,
            });
            setLoading(false);
              
           
        }  

    };

    const handleCancel = () => {
        setVisible(false);
    };

   const HandleDataTurno = async (values) => {
      
        let fecha_desde="";
        let fecha_hasta ="";
        if(values.FechaTurno){
           fecha_desde= moment(values.FechaTurno[0]).format('YYYY/MM/DD')
           fecha_hasta =moment(values.FechaTurno[1]).format('YYYY/MM/DD')
        } 
        
        const Turno = await  ListaTurnos({variables:{
            Id_contrato: values.Id_contrato,
            Id_turno: values.Turno,
            fecha_desde: fecha_desde,
            fecha_hasta:  fecha_hasta,
            Id_cliente:values.Cliente,
        }})

        const filterArray=[{Id_contrato: values.Id_contrato,Id_turno: values.Turno,fecha_desde: fecha_desde,fecha_hasta:  fecha_hasta,Id_cliente:values.Cliente}]
        setSelFiltros(filterArray)
 
        if(Turno.loading===false){
           SetdataTurno(Turno.data.GetEmpleTurnos_ByFilter)
        }
       
    };
    
    const onAddPost=()=>{
       setViewNew(true);
    }

    const handleCancelTurno = () => {
         setViewNew(false);
    };

    const handleCancelMe = () => {
         setViewMessage(false);
    };
    
    const handleSaveTurno = async (values) => {
        setLoading(true);
      
        const EditTurno = await NuevoTurnoEmpleado({variables:{
            Id_contrato: values.Id_contrato,
            Id_turno: values.Id_turno,
            fecha_desde: moment(values.FechaTurno[0]).format('YYYY/MM/DD'),
            fecha_hasta: moment(values.FechaTurno[1]).format('YYYY/MM/DD'),
            creado_por: creadoPor,
      
        }})
       
         
            const success = EditTurno.data.Nuevo_TurnoEmpleado.success
            const message = EditTurno.data.Nuevo_TurnoEmpleado.message
            const estado = EditTurno.data.Nuevo_TurnoEmpleado.isdata
            setMessages(EditTurno.data.Nuevo_TurnoEmpleado.DataError)
        
            if(success){
                if (estado) {
                    setViewMessage(true);
                    setLoading(false);  
                }
                else{   
                    setTimeout(() => {
                        Modal.success({
                            content: message,
                        });
                        setLoading(false);
                    }, 2000);
                }

            }
            else{
                Modal.warning({
                    content: message,
                });
                setLoading(false);
            }  
    };

    const onDelTurno = async () => {
        
        if(selRowKeys){

            confirm({
                title: 'Eliminar de turnos',
                icon:<Icon type={'ExclamationCircleOutlined'}/> ,
                content: 'Seguro de eliminar estos elementos.....?',
                async onOk() {

                    const DelTurno = await EliminaTurnoEmpleado({variables:{
                        Id_contTurno: selRowKeys,
                        Creado_por: creadoPor,
                
                    }})
                
                    
                    const success = DelTurno.data.Elimina_TurnoEmpleado.success
                    const message = DelTurno.data.Elimina_TurnoEmpleado.message
                    if(success){   

                        const Turno = await  ListaTurnos({variables:{
                            Id_contrato: SelFiltros[0].Id_contrato,
                            Id_turno: SelFiltros[0].Id_turno,
                            fecha_desde: SelFiltros[0].fecha_desde,
                            fecha_hasta: SelFiltros[0].fecha_hasta,
                            Id_cliente:SelFiltros[0].Id_cliente,
                        }})
                        if(Turno.loading===false){
                            SetdataTurno(Turno.data.GetEmpleTurnos_ByFilter)
                        }

                        const modal= Modal.success({
                                content: message,
                            });
                        setTimeout(() => {
                            modal.destroy();
                        }, 3000);

                    }
                    else{
                        const modal=  Modal.warning({
                            content: message,
                        });
                       setTimeout(() => {
                             modal.destroy();
                        }, 3000);
                    }  
                
                },
                /*onCancel() {
                console.log(selRowKeys);
                },*/
            });
        }
       
    };

   const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;
    };
    
    if(loadingpage){
        return (
        <div className="site-card-border-less-wrapper">
           
          <Buscar/>
         
        </div>
        )
    }
    return (
        <div className="site-card-border-less-wrapper">
        
            <Card size="small" style={{ textAlign: 'Left', }}  title='Maestro Turnos Empleados'    extra={<IcoButton icon='SettingOutlined' onChildClick={(array) => onGroupClick(array)} submenu={submenu}  />}   >
                <Card size="small" bordered={false} style={{  display: 'flex' }}>
                <Space>
                    <Button onClick={onAddPost} icon={<Icon type={'CalendarOutlined'}/>}>Crear Turnos</Button>
                    <Button onClick={onDelTurno}  disabled={!selRowKeys.length} icon={<Icon type={'MinusSquareOutlined'}/>}>Borrar Turnos</Button>
                    
                </Space>    
                </Card>
                
                   <SearchEmpleTurnos HandleDataTurno = {HandleDataTurno}  SetdataTurno={SetdataTurno} clientes={clientes} turnos={turnos} />
                   <Space>&nbsp;</Space>
                   <TableTurnos DataTurno={DataTurno} setSelRowKeys={setSelRowKeys}   />
                
            </Card>
            <ModalClave Visible={Visible} Loading={Loading} handleOk={handleOk} handleCancel={handleCancel }/>
            <ModalTurno viewNew ={viewNew}  Loading={Loading} handleSaveTurno={handleSaveTurno} handleCancelTurno={handleCancelTurno} clientes={clientes} turnos={turnos} />  
            <ModalMessages ViewMessage={ViewMessage} handleCancelMe={handleCancelMe} messages={messages}  />
        </div>

    );
}

export default CreacionTurnos