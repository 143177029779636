import React from 'react'
import { Modal, Button, Form, Input, Space, Row, Col ,Card} from 'antd';
import '../../Assets/Css/page.css';
const { TextArea } = Input;


const ModalObsRechazo = ({ MRechazoView, Loading, handleSaveObs, handleCancelObs}) => {
    const [form4] = Form.useForm();
   
  
  
    const onAdd = async values => {
        form4.resetFields(["Observaciones"]);
        return handleSaveObs(values);     
    }

    
    return (
        <Modal
            visible={MRechazoView}
            title="Rechazo"
            onCancel={handleCancelObs}
            footer={[
                
               
            ]}
        >

        <Card
        style={{ width: '100%' }}
        bordered={false}  >
            <Form
                    form={form4}
                    name="Observaciones"
                    initialValues={{
                        remember: false,
                        resetFields:true,
                    }}
                    onFinish={onAdd}
                >
                    
                    <Row gutter={24}>
                    <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24 }}>
                    <Form.Item
                        name="Observaciones"  
                          rules={[
                                { required: true,message: 'Ingrese Motivo Rechazo'},
                            ]}  
                    >
                    <TextArea rows={4} placeholder="Motivo Rechazo"  style={{ width: '100%',height:'100%' }} />
                    </Form.Item>
                    </Col>
                    </Row>
                    
                    <Form.Item>
                        <Space>
                    
                        <Button htmlType="submit" type="primary" loading={Loading} >
                            Guardar
                        </Button>
                        <Button key="back" onClick={handleCancelObs}>
                            Cancelar
                        </Button>
                        </Space>

                    </Form.Item>
            </Form>
        </Card>     
        </Modal>
    )
}

export default ModalObsRechazo
