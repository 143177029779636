import React, { useState} from 'react'
import { Table } from 'antd';

const TableReportHextraResumen = ({Datahextra,Datapersona,Handleprint,HandleFiltro,paramstable}) => {

    const [Columsdata] = useState([
        {
            title: 'id_registro',
            dataIndex: 'id_registro',
            key: 'id_registro',
            hidden:true,
           
        },
     
       {
            title: 'Rut',
            dataIndex: 'Rut',
            key: 'Rut',
           
            
           
        },
        {
            title: 'Nombre',
            dataIndex: 'Nombre',
            key: 'Nombre',
             width:'25vh' ,
             
        },
       
        {
        title:  'Total Mes' ,
         fixed:false,
            children: [
                {
                    title: 'HH.50%',
                    dataIndex: 'pagohextra_normal_num',
                    key: 'pagohextra_normal_num',
                    width:'13vh' ,
                    
                },
                {
                    title: 'HH.100%',
                    dataIndex: 'pagohextra_feriado_num',
                    key: 'pagohextra_feriado_num',
                    width:'13vh' ,
                },
                {
                    title: 'HH.Noche',
                    dataIndex: 'horas_total_noche_num',
                    key: 'horas_total_noche_num',
                     width:'14vh' ,
                },
                {
                    title: 'Bono',
                    dataIndex: 'bono',
                    key: 'bono',
                    width:'15vh' ,
                },
            ]
        },
        {
        title:  'Asistencia' ,
         fixed:false,
            children: [   
            {
                title: 'Trabajados',
                dataIndex: 'DiasTrab',
                key: 'DiasTrab',
                 width:'15vh' ,
                
            },
            {
                title: 'Ausencias',
                dataIndex: 'DiasAus',
                key: 'DiasAus',
                width:'15vh' ,
                
                
            },
            {
                title: 'Fechas_Ausencias',
                dataIndex: 'fechaAus',
                key: 'fechaAus', 
               width:'23vh' ,
              
                  
                
                
            },
             {
                title: 'Observación',
                dataIndex: 'razonLic',
                key: 'razonLic',
                width:'45vh' ,
               
                 
            },
            ]
        },
        
    ].filter(item => !item.hidden));
   
   
    return (
       
            <Table  columns={Columsdata} dataSource={Datahextra} {...paramstable} rowKey="id_registro"/> 
       
    )
}

export default TableReportHextraResumen
