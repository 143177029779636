import React,{useState,useEffect} from 'react'

import '../Assets/Css/page.css';
import { Modal,Card} from 'antd';

import IcoButton from '../Components/IconButtonGroup';
import SelectSaveModule from '../Components/Modulos/SelectSaveModule';
import Buscar from '../Components/Loading';
import ModalClave from '../Components/ModalClave';
import {useUpdatepass} from '../hooks/custom-users';
import{useVerifyToken,useVerify} from "../hooks/useToken"  





const Modulos = () => {
    const {data:Tokensverify, loading:TokensverifyLoading}= useVerify();
   const{Tokens,TokensLoading} = useVerifyToken(); 
   const {loading} = useState(); 
   const [Visible, setVisible] = useState(false);
   const [Loading, setLoading] = useState(false);
   const [SetUpd] = useUpdatepass();
    const [creadoPor, setCreadoPor] = useState('');

   useEffect(() => {
         if(TokensverifyLoading===false){
            
            if(Tokensverify.GetLogin_validate.success===false){
                Modal.error({
                    title: Tokensverify.GetLogin_validate.message,
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");
                    }
                })
            }
           
        }
         
        if(TokensLoading===false){
            if(Tokens){
                 const userF=Tokens.token.split('||')
                 setCreadoPor(userF[2])
            }
        }
    }, [Tokens, TokensLoading, Tokensverify, TokensverifyLoading])

   const [submenu] = useState([
        {
            key: '1',
            icon: 'KeyOutlined',
            title: 'Nueva Clave',

        }, {
            key: '2',
            icon: 'PoweroffOutlined',
            title: 'Salir',

        }
    ])

    const onGroupClick = (array) => {
       
        switch (array.key) {
            case '1':
                //console.log(array.key);
                setVisible(true);
               break
            default:
                Modal.confirm({
                    title: 'Estas Seguro de Cerrar la aplicación ..??',
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");

                        
                }
                })
        }
    }

    const handleOk = async (values) => {

       
        const EditPass = await  SetUpd({variables:{
            id_sesion:creadoPor,
            oldPass: values.claveactual,
            NewPass: values.NuevaClave,
          
        }})
      //console.log(EditPass)
       // setLoading(true);
        const success = EditPass.data.updatePassword.success
        const message = EditPass.data.updatePassword.message
        if(success===true){   
           const  modal = Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
                setVisible(false);
                modal.destroy();
            }, 3000);

        }else{
            Modal.warning({
                 content: message,
            });
            setLoading(false);
              
           
        }  

    };

    const handleCancel = () => {
        setVisible(false);
    };

    if(loading){
        return (
        <div className="site-card-border-less-wrapper">
          <Buscar/>
        </div>
        )
    }
    return (
        <div className="site-card-border-less-wrapper">
        
            <Card size="small" style={{ textAlign: 'Left', }}  title='Modulos de Permisos'  className='card_height'  extra={<IcoButton icon='SettingOutlined' onChildClick={(array) => onGroupClick(array)} submenu={submenu}  />}   >
                <SelectSaveModule   />
                <ModalClave Visible={Visible} Loading={Loading} handleOk={handleOk} handleCancel={handleCancel }/>
            </Card>
           
        </div>

    );
}

export default Modulos