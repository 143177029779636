import gql from "graphql-tag";

export const PostulacionClientObsDet = gql`
query postulacionClientObsDet($id_postulacion_det:ID!){
  GetClientObsDet(id_postulacion_det:$id_postulacion_det){
   title
   Estado
   Observaciones 
    
  }
}`;
