import React  from 'react'
import { Modal, Form,Row,Col,Space,Button,Select,Typography,Avatar} from 'antd';
import '../../Assets/Css/page.css';
import {useAllIdioma} from '../../hooks/custom-general';
const { Option } = Select;

const { Title } = Typography;

const ModalidiomaAdd = ({ viewIdiomaAdd, DataPostulant, Loading, handleCancelIdiomaAdd,handleAddPostIdioma}) => {
  
    const{idiomaAlldata} = useAllIdioma();
    const [formIdiomaAdd] = Form.useForm();
 

    const onAddIdioma = async values => {
       //// console.log(values)
        formIdiomaAdd.resetFields();
        return handleAddPostIdioma(values)    
    }

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;
    };

    return (
        <>
        <Modal
            visible={viewIdiomaAdd}
            title="Nuevo Idioma"
            style={{ top: 20 }}
            width={'100vh'}
            onCancel={handleCancelIdiomaAdd}
            footer={[]}
        >

            <Form
                form={formIdiomaAdd}
                name="AgregarNuevoPostulante"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                onFinish={onAddIdioma}
            >
                
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 21 }}>
                <Title level={5}> <Space><Avatar size={32} icon={<Icon type='UserOutlined' className="site-form-item-icon" />} />{DataPostulant.Nombre}</Space></Title>
                </Col>
               
                </Row>
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Idiomas"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor Seleccione Idioma(s)',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Ingrese Idioma(s)" mode="multiple" maxTagCount= 'responsive' showArrow >
                    {idiomaAlldata.map((data) => {
                        return (
                            <Option key={data.idioma_id} value={data.idioma_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })} 
                    </Select>
                </Form.Item>
                </Col>
               

                </Row>

               
                
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Agregar
                    </Button>
                    <Button key="back" onClick={handleCancelIdiomaAdd}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>        
    
            
        </Modal>
       </> 
    )
   
}

export default ModalidiomaAdd
