import React,{useState} from 'react'
import { Button, Form,  Space, Row, Col ,Select  } from 'antd';
import '../../../Assets/Css/page.css';


import { useContratadoByIdCliente } from '../../../hooks/custom-contratos'

const { Option } = Select;



const SearchEmpleTurnos = ({HandleDataEmple,SetdataEmpleado,clientes,contratos,isapre,afp}) => {
    const [selEmpleados] = useContratadoByIdCliente();
    const [form5] = Form.useForm();
    const [empleados,setEmpleados] = useState([]);
     
    
    
   
    const onAdd = values => {
        console.log(values)
        return HandleDataEmple(values); 
    }

    const  handleClear = () => {
             form5.resetFields();
              return SetdataEmpleado([])
    }

   
    const onClieChange = async values => {
        const emplesel = await  selEmpleados({variables:{
            Id_cliente:values,
        }})
       
 
        if(emplesel.loading===false){
            setEmpleados(emplesel.data.GetContratadoByIdCliente)
        }
         
    }

    
   
    return (
        
             <Form
                form={form5}
                name="BusquedaContratados"
                className="ant-advanced-search-form"
                initialValues={{
                    preserve:false,
                    resetFields:true,
                }}
                onFinish={onAdd}
            >
                
               
                <Row gutter={24}>
                
                
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 4 }}>
                <Form.Item
                    name="Id_cliente"
                     rules={[
                                { required: true,message: 'Seleccione Cliente'},
                            ]}
                >
               <Select placeholder="Selección Cliente"  showArrow maxTagCount= 'responsive'  onChange={onClieChange} >
                  {clientes.map(data => {
                        return (
                            <Option key={data.Id_cliente} value={data.Id_cliente}>
                                {data.Nombre}
                            </Option>
                        );
                    })}  
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 4}}>
                <Form.Item
                    name="Id_contrato"
                     rules={[
                                { required: false,message: 'Seleccione Empleado'},
                            ]}
                >
                <Select placeholder="Selección Empleado" showArrow maxTagCount= 'responsive'  showSearch
                        filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                 {empleados.map((data) => {
                        return (
                            <Option key={data.Id_contrato} value={data.Id_contrato}>
                                {data.Nombre}
                            </Option>
                        );
                    })} 
                </Select>
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 3 }}>
                <Form.Item
                    name="Tipo_contrato"
                     rules={[
                                { required: false,message: 'Seleccione Contrato'},
                            ]}
                >
                     <Select placeholder="Selecione Contrato" mode="multiple" showArrow maxTagCount= 'responsive'  >
                    {contratos.map((data) => {
                        return (
                            <Option key={data.tcontid} value={data.tcontid}>
                                {data.descripcion}
                            </Option>
                        );
                    })}  
                </Select>
                </Form.Item>
                </Col>

                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 3 }}>
                <Form.Item
                    name="id_isapre"
                     rules={[
                                { required: false,message: 'Seleccione Isapre'},
                            ]}
                >
                     <Select placeholder="Selecione Isapre" mode="multiple" showArrow maxTagCount= 'responsive' showSearch
                        filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {isapre.map((data) => {
                        return (
                            <Option key={data.isapre_id} value={data.isapre_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}  
                </Select>
                </Form.Item>
                </Col>

                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 3 }}>
                <Form.Item
                    name="id_afp"
                     rules={[
                                { required: false,message: 'Seleccione AFP'},
                            ]}
                >
                     <Select placeholder="Selecione AFP" mode="multiple" showArrow maxTagCount= 'responsive'  showSearch
                        filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {afp.map((data) => {
                        return (
                            <Option key={data.afp_id} value={data.afp_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })}  
                </Select>
                </Form.Item>
                </Col>
               

                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 5 }}>
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={false} >
                        Buscar
                    </Button>
                    <Button key="back" onClick={handleClear}>
                        Limpiar
                    </Button>
                    {/*<Button onClick={ViewCal}>
                       Calendario
                    </Button>*/}
                    </Space>

                </Form.Item>
                </Col>
                </Row>
            </Form>
        
    )
}

export default SearchEmpleTurnos
