import React  from 'react'
import { Modal, Button, Form, Input, Space, Row, Col,Select } from 'antd';
import '../../Assets/Css/page.css';
import { useAllSendAlert } from '../../hooks/custom-general'
const { Option, OptGroup } = Select;




const ModalUser = ({ NewUser, Loading, handleSave, handleCancelUse}) => {
    const {sendAlertalldata} = useAllSendAlert();
    const [form2] = Form.useForm();
    
    const onAdd = values => {
      return handleSave(values); 
    }


    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;

    };

    return (
        <Modal
            visible={NewUser}
            title="Nuevo Usuario"
            onCancel={handleCancelUse}
            footer={[
                
               
            ]}
        >
            <Form
                form={form2}
                name="NuevoUsuario"
                layout="vertical"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                onFinish={onAdd}
            >
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Usuario"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Usuario',
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Usuario"
                    />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="NombreUsuario"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Nombre Usuario',
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Nombre Usuario"
                    />
                </Form.Item>
                </Col>
                </Row>
                
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="ApellidoPaterno"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Apellido Paterno',
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Apellido Paterno"
                    />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="ApellidoMaterno"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Apellido Materno',
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Apellido Materno"
                    />
                </Form.Item>
                </Col>
                </Row>
                <Form.Item
                    name="Emailuser"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Email !',
                        },
                        {
                            type: 'email',
                            message: 'No es Email Valido ',
                        },
                          
                    ]}
                    hasFeedback
                >
                    <Input
                        prefix={<Icon type='MailOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="E-mail"
                    />
                </Form.Item>
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 18 }}>
                <Form.Item
                    name="SendAlert"
                    label="Mensajes"
                    tooltip="Recepción de alertas por correo, Un ejemplo es cuando se crea una nueva Postulación..!  "
                    rules={[
                        {
                            required: false,
                            message: 'Por favor ingrese Opción',
                        },
                    ]}
                    hasFeedback
                >
                 
                <Select placeholder="Alertas" mode="multiple" maxTagCount= 'responsive' showArrow >
                <OptGroup label="Alertas">
                  {sendAlertalldata.map((data) => {
                        return (
                            <Option key={data.send_id} value={data.send_id}>
                                {data.descripcion}
                            </Option>
                        );
                    })} 
                </OptGroup>     
                </Select>
               
               
                </Form.Item>
                </Col>
               
                </Row>
                


                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading} >
                        Guardar
                    </Button>
                    <Button key="back" onClick={handleCancelUse}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ModalUser
