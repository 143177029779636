import gql from "graphql-tag";

export const  MsgAlertByIdSesion = gql`
query   GetMsgAlertByIdSesion($id_Sesion:String!,$modulo:String!){
  GetMsgAlertByIdSesion(id_Sesion:$id_Sesion,modulo:$modulo)
  {
    id_menalert
    fechavence
    title
    mensaje
    modulo
    Id_cliente
    id_postulacion_cab
    icono
  }
  
    
}`;

export const  MsgAlertClientByIdSesion = gql`
query   GetMsgAlertClientByIdSesion($id_Sesion:String!,$modulo:String!){
  GetMsgAlertClientByIdSesion(id_Sesion:$id_Sesion,modulo:$modulo)
  {
    id_menalert
    fechavence
    title
    mensaje
    modulo
    Id_cliente
    id_postulacion_cab
    icono
  }
  
    
}`;