import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {ApolloClient, ApolloProvider,InMemoryCache,HttpLink} from '@apollo/client'
import { ConfigProvider } from 'antd';
import esES from 'antd/es/locale/es_ES';


let key_graphql = process.env.REACT_APP_LINK_GRAPHQL
const client = new ApolloClient({
    link: new HttpLink({uri:key_graphql}),
    cache: new InMemoryCache(),
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <ConfigProvider locale={esES}>
      <App />
    </ConfigProvider>
  </ApolloProvider>,
  document.getElementById('root')
);

