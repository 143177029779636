import {useLazyQuery,useMutation} from '@apollo/client'
import {GetEmpleContactos,GetAllEmpleados,EmpleadosByFilter,
        GetempleadosById,GetempleadoEduById,GetempleadoLabById,GetempleadoCertById,
        GetempleadoIdiomaById,GetempleadoAdjById} from "../model/query/postulante/postulantes"

import {createPostulante,createPostulanteEdu,createPostulanteLab,createPostulanteCert,
createPostulanteCont,createPostulanteIdioma,updatePostulante,updatePostulanteEdu,
updatePostulanteLab,updatePostulanteCert,deletePostulante,deletePostulanteEdu,
deletePostulanteLab,deletePostulanteCert, deletePostulanteCont ,deletePostulanteIdioma,CreatePostulanteAdj} from "../model/mutation/postulantes/SetPostulantes"


/////////////////////////Querys//////////////////////////////////

export const useEmpleadosByFilter = () =>{
    const result = useLazyQuery(EmpleadosByFilter);
    return result;
}

export const useAllEmpleados = () =>{
    const result = useLazyQuery(GetAllEmpleados);
    return result;
}

export const useEmpleadoById = () =>{
    const result = useLazyQuery(GetempleadosById);
    return result;
}

export const useEmpleadoEduById = () =>{
    const result = useLazyQuery(GetempleadoEduById);
    return result;
}

export const useEmpleadoLabById = () =>{
    const result = useLazyQuery(GetempleadoLabById);
    return result;
}

export const useEmpleadoCertById = () =>{
    const result = useLazyQuery(GetempleadoCertById);
    return result;
}

export const useEmpleadoIdiomaById = () =>{
    const result = useLazyQuery(GetempleadoIdiomaById);
    return result;
}

export const useEmpleContactosById = () =>{
    const result = useLazyQuery(GetEmpleContactos,{});
    return result;
}

export const useEmpleContactos = () =>{
    const result = useLazyQuery(GetEmpleContactos,{});
    return result;
}

export const useEmpleAdjuntos = () =>{
    const result = useLazyQuery(GetempleadoAdjById,{});
    return result;
}


/////////////////////useMutation ////////////////////////

/////////////////////Create ////////////////////////

export const useCreatePostulante = () =>{
    const result = useMutation(createPostulante,{});
    return result;
}

export const useCreatePostulanteEdu = () =>{
    const result = useMutation(createPostulanteEdu);
    return result;
}

export const useCreatePostulanteLab = () =>{
    const result = useMutation(createPostulanteLab);
    return result;
}

export const useCreatePostulanteCert = () =>{
    const result = useMutation(createPostulanteCert);
    return result;
}

export const useCreatePostulanteCont = () =>{
    const result = useMutation(createPostulanteCont);
    return result;
}

export const useCreatePostulanteIdioma = () =>{
    const result = useMutation(createPostulanteIdioma);
    return result;
}

export const useCreatePostulanteAdj = () =>{
    const result = useMutation(CreatePostulanteAdj,{});
    return result;
}


//////////////////////////Update///////////////////////////////


export const useUpdatePostulante = () =>{
    const result = useMutation(updatePostulante,{});
    return result;
}

export const useUpdatePostulanteEdu = () =>{
    const result = useMutation(updatePostulanteEdu);
    return result;
}

export const useUpdatePostulanteLab = () =>{
    const result = useMutation(updatePostulanteLab);
    return result;
}

export const useUpdatePostulanteCert = () =>{
    const result = useMutation(updatePostulanteCert);
    return result;
}


//////////////////////////Delete///////////////////////////////

export const useDeletePostulante = () =>{
    const result = useMutation(deletePostulante,{});
    return result;
}

export const useDeletePostulanteEdu = () =>{
    const result = useMutation(deletePostulanteEdu);
    return result;
}

export const useDeletePostulanteLab = () =>{
    const result = useMutation(deletePostulanteLab);
    return result;
}

export const useDeletePostulanteCert = () =>{
    const result = useMutation(deletePostulanteCert);
    return result;
}

export const useDeletePostulanteCont = () =>{
    const result = useMutation(deletePostulanteCont);
    return result;
}

export const useDeletePostulanteIdioma = () =>{
    const result = useMutation(deletePostulanteIdioma);
    return result;
}


export default useEmpleadosByFilter


