import React,{useState,useEffect} from 'react'
import '../Assets/Css/page.css';
import { Modal,Card,Button} from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import IcoButton from '../Components/IconButtonGroup';
import Buscar from '../Components/Loading';
import ModalClave from '../Components/ModalClave';
import ModalCliente from '../Components/Clientes/ModalCliente';
import ModalEditClient from '../Components/Clientes/ModalEditClient';
import ModalClieContactos from '../Components/Clientes/ModalClieContactos';
import {useUpdatepass} from '../hooks/custom-users';
import TableClientes from '../Components/Clientes/TableClientes';
import {useAllClientes,useCreateclient,useEditclient,useBlocclient,useClieContactbyId,
        useContactDetbyId,useCreateContactTypeClient,useCreateContactClient,useDeleteContactTypeClient,
        useCreateUpdateContactSesion,useClientContactbyIdContact,useBlocContact,useDeleteContactClient} from '../hooks/custom-Clientes';
import {  format } from 'rut.js'

import{useVerifyToken,useVerify} from "../hooks/useToken"  

const Clientes = () => {
    const {data:Tokensverify, loading:TokensverifyLoading}= useVerify();
    const{Tokens,TokensLoading} = useVerifyToken();
    const {data,loading} = useAllClientes();
    const [SetEdit] = useEditclient();
    const [SetContactbyIdContact] = useClientContactbyIdContact();
    const [SetUpd] = useUpdatepass();
    const [SetCreate] = useCreateclient();
    const [SetCreateContact] = useCreateContactClient();
    const [SetCreateTcontact] =useCreateContactTypeClient();
    const [SetCreateUpdateContactSesion] =useCreateUpdateContactSesion();
    const [SetDel] = useBlocclient();
    const [SetDelContactClient] = useDeleteContactClient();
    const [SetBloqContact] = useBlocContact();
    
    const [SetDelType] = useDeleteContactTypeClient();
    const [DataContact] = useClieContactbyId();
    
    const [DataContactDet] = useContactDetbyId();
    const [clientedata, setClientedata] = useState([]);
    const [Visible, setVisible] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [EditClient, setEditClient] = useState(false);
    const [EditCont, setEditCont] = useState(false);
    const [DataEditClient, setDataEditClient] = useState([]);
    const [dataContacto, setDataContacto] = useState([]);
    const [dataContactoDet, setDataContactoDet] = useState([]);
    const [newClient, setNewClient] = useState(false);
    const [creadoPor, setCreadoPor] = useState('');
    const [dataContactosing, setDataContactosing] = useState([]);
  
   
   
   useEffect(() => {

        if(TokensverifyLoading===false){
            
            if(Tokensverify.GetLogin_validate.success===false){
                Modal.error({
                    title: Tokensverify.GetLogin_validate.message,
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/");
                    }
                })
            }
           
        }
       
        if(TokensLoading===false){
            if(Tokens){
                 const userF=Tokens.token.split('||')
                 setCreadoPor(userF[2])
            }
        }   


        if(loading===false){
            const datacliente = data.GetClientAll
            setClientedata(datacliente)
        }

         
    }, [Tokens, TokensLoading, Tokensverify, TokensverifyLoading, data, loading])
   
   const [submenu] = useState([
        {
            key: '1',
            icon: 'KeyOutlined',
            title: 'Nueva Clave',

        }, {
            key: '2',
            icon: 'PoweroffOutlined',
            title: 'Salir',

        }
    ])

    const onGroupClick = (array) => {
       
        switch (array.key) {
            case '1':
                //console.log(array.key);
                setVisible(true);
               break
            default:
                Modal.confirm({
                    title: 'Estas Seguro de Cerrar la aplicación ..??',
                    onOk: () => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('IniUri');
                        window.location.replace("/")
                       
                        
                }
                })
        }
    }

    const handleOk = async (values) => {

       
        const EditPass = await  SetUpd({variables:{
            id_sesion:creadoPor,
            oldPass: values.claveactual,
            NewPass: values.NuevaClave,
          
        }})
      //console.log(EditPass)
       // setLoading(true);
        const success = EditPass.data.updatePassword.success
        const message = EditPass.data.updatePassword.message
        if(success===true){   
           const  modal = Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
                setVisible(false);
                modal.destroy();
            }, 3000);

        }else{
            Modal.warning({
                 content: message,
            });
            setLoading(false);
              
           
        }  

    };

    const handleCancel = () => {
        setVisible(false);
    };

    const onDeletecliente = (record)=> {
        
        let mensaje=''
        if(record.estadou==="Bloqueado"){
            mensaje = 'Estas Seguro de Desloquear el Cliente ' +record.Nombre+ ' ..??'
        }
        else{
            mensaje = 'Estas Seguro de Bloquear el Cliente ' +record.Nombre+ ' ..??'
        }

        Modal.confirm({
           title: mensaje,
            
           onOk:async()=>{
            
            const BloqResult = await  SetDel({variables:{
                Id_cliente:record.Id_cliente,
                Estado:record.estadou,
                Modificado_por:creadoPor
               
            }})
        
           
            const success = BloqResult.data.deleteClient.success
            const message = BloqResult.data.deleteClient.message
           // console.log(success)
            if(success){   
                const  modal = Modal.success({
                    content: message,
                });

                setTimeout(() => {
                    modal.destroy();
                }, 3000);

            }else{
                Modal.warning({
                    content: message,
                });
               
                
            
            }  
        }   
       })
       
    }

    const onNuevoContacto = async(record) => {

        //console.log(record);
        
        const ContactData = await DataContact({variables:{
            Id_cliente:record.Id_cliente
        }})
        setDataContacto(ContactData.data.GetClientContactbyId)
        
        const ContactDetData = await DataContactDet({variables:{
            Id_cliente:record.Id_cliente
        }})
        setDataContactoDet(ContactDetData.data.GetClientContactDetbyId)

        setDataEditClient(record);
        setEditCont(true);
       
        //setNuevoContacto(record);
    
    }
    
    const onEditcliente = (record) => {
        setDataEditClient(record);
        setEditClient(true);
        
    }

    const onAdduser=()=>{
        setNewClient(true);
    }

    const handleSave = async (values) => {
       //console.log(values)
        const CreateResult = await  SetCreate({variables:{
            Giro:values.Giro,
            Direccion:values.Direccion,
            Nombre: values.NombreCliente,
            Rut: format(values.Rut),
            Creado_por:creadoPor,
        }})
       
        setLoading(true);
        const success = CreateResult.data.createClient.success
        const message = CreateResult.data.createClient.message
        if(success){   
            const  modal = Modal.success({
                 content: message,
            });

            setTimeout(() => {
                setLoading(false);
                setNewClient(false);
                modal.destroy();
           }, 3000);

        }else{
            Modal.warning({
                 content: message,
            }); 
            setLoading(false);

        }
   
    };

    const handleCancelClient = () => {
         setNewClient(false);
    };

    const handleSaveEdit = async (values) => {
          // console.log(EditPost.id_postulacion_cab)
        console.log(DataEditClient.Id_cliente,values)
        setLoading(true);
        const EditUser = await SetEdit({variables:{
            Id_cliente: DataEditClient.Id_cliente,
            Rut:values.Rut,
            Giro: values.Giro,
            Nombre: values.NombreCliente,
            Direccion: values.Direccion,
            Modificado_por: creadoPor,
        }})
       
       
        const success = EditUser.data.updateClient.success
        const message = EditUser.data.updateClient.message
        if(success){   
            const  modal = Modal.success({
                content: message,
            });

            setTimeout(() => {
                setLoading(false);
                //setEditPost(false);
                modal.destroy();
            }, 1500);

        }else{
            Modal.warning({
                content: message,
            });
            setLoading(false);


        }  
    }

    const handleNewContact = async (values) => {
       
       // console.log(values)
        setLoading(true);
        const Createcontact = await SetCreateContact({variables:{
            Id_cliente: DataEditClient.Id_cliente,
            Nombre: values.Nombre,
            DescripcionCargo: values.DescripcionCargo,
            tipo_contacto:  values.TipoContacto,
            descripcion:  values.Descripcion,
            Creado_por:  creadoPor,
        }})
       
       
        const success = Createcontact.data.createContactClient.success
        const message = Createcontact.data.createContactClient.message
        if(success){   

            const ContactData = await DataContact({variables:{
            Id_cliente:DataEditClient.Id_cliente
            }})
            setDataContacto(ContactData.data.GetClientContactbyId)
            
            const ContactDetData = await DataContactDet({variables:{
                Id_cliente:DataEditClient.Id_cliente
            }})
            setDataContactoDet(ContactDetData.data.GetClientContactDetbyId)


            const  modal = Modal.success({
                content: message,
            });

            setTimeout(() => {
                setLoading(false);
                //setEditPost(false);
                modal.destroy();
            }, 1500);

        }else{
            Modal.warning({
                content: message,
            });
            setLoading(false);


        }  
    }

    const handleNewTcontact = async (values) => {
       
       
        setLoading(true);
        const Createtcontact = await SetCreateTcontact({variables:{
          
            Id_contacto: values.id_contacto,
            tipo_contacto:  values.TipoContacto2,
            descripcion:  values.Descripcion2,
            Creado_por:  creadoPor,

        }})
       
       
        const success = Createtcontact.data.createContactTypeClient.success
        const message = Createtcontact.data.createContactTypeClient.message
        if(success){   
            
            const ContactData = await DataContact({variables:{
                Id_cliente:DataEditClient.Id_cliente
            }})
            setDataContacto(ContactData.data.GetClientContactbyId)


            const ContactDetData = await DataContactDet({variables:{
                Id_cliente:DataEditClient.Id_cliente
            },})
            setDataContactoDet(ContactDetData.data.GetClientContactDetbyId)


            const  modal = Modal.success({
                content: message,
            });

            setTimeout(() => {
                setLoading(false);
                //setEditPost(false);
                modal.destroy();
            }, 1500);

        }else{
            Modal.warning({
                content: message,
            });
            setLoading(false);


        }  
    }

    const handleNewLcontact = async (values) => {
       
        
        setLoading(true);
        const Createtsesion = await SetCreateUpdateContactSesion({variables:{
          
            Id_contacto: values.Id_contacto,
            Users: values.userSesion,
            Pass: values.userSesion+values.Id_contacto,
            Id_tipo: values.Id_tipo,

        }})
       
       
        const success = Createtsesion.data.createUpdateClientContactSesion.success
        const message = Createtsesion.data.createUpdateClientContactSesion.message

        
        if(success){   
            
            const ContactData = await DataContact({variables:{
                Id_cliente:DataEditClient.Id_cliente
            }})
            setDataContacto(ContactData.data.GetClientContactbyId)


            const ContactDetData = await DataContactDet({variables:{
                Id_cliente:DataEditClient.Id_cliente
            }})
            setDataContactoDet(ContactDetData.data.GetClientContactDetbyId)

            const ContactIdcontact = await SetContactbyIdContact({variables:{
                Id_contacto:values.Id_contacto
            }})
            
            setDataContactosing(ContactIdcontact.data.GetClientContactbyIdContact[0])

             
            

            const  modal = Modal.success({
                content: message,
            });

            setTimeout(() => {
                setLoading(false);
                //setEditPost(false);
                modal.destroy();
            }, 1500);

        }else{
            Modal.warning({
                content: message,
            });
            setLoading(false);


        }  
    }

    const handleDelType = async (values) => {
       
        console.log(values)
        setLoading(true);
        const Deletetcontact = await SetDelType({variables:{
          
            Id_contacto: values.Id_contacto,
            Id_tipocontacto:  values.id_tipocontacto,
           

        }})
       
       
        const success = Deletetcontact.data.DeleteContactTypeClient.success
        const message = Deletetcontact.data.DeleteContactTypeClient.message
        if(success){   


            const ContactData = await DataContact({variables:{
                Id_cliente:DataEditClient.Id_cliente
            }})
            setDataContacto(ContactData.data.GetClientContactbyId)

            const ContactDetData = await DataContactDet({variables:{
                Id_cliente:DataEditClient.Id_cliente
            }})
            setDataContactoDet(ContactDetData.data.GetClientContactDetbyId)


            const  modal = Modal.success({
                content: message,
            });

            setTimeout(() => {
                setLoading(false);
                //setEditPost(false);
                modal.destroy();
            }, 1500);

        }else{
            Modal.warning({
                content: message,
            });
            setLoading(false);


        }  
    }

    const handleDelContact = async (values) => {

       
        let mensaje=''
        mensaje = 'Daras de baja a  ' +values.Nombre+ ', Estas Seguro ..??'
        Modal.confirm({
        title: mensaje,
        
            onOk:async()=>{
                const Deletetcontact = await SetDelContactClient({variables:{
                    Id_contacto: values.Id_contacto,
                }})
            
                const success = Deletetcontact.data.deleteContactClient.success
                const message = Deletetcontact.data.deleteContactClient.message
                if(success){   

                    const ContactData = await DataContact({variables:{
                        Id_cliente:DataEditClient.Id_cliente
                    }})
                    setDataContacto(ContactData.data.GetClientContactbyId)

                    const ContactDetData = await DataContactDet({variables:{
                        Id_cliente:DataEditClient.Id_cliente
                    }})
                    setDataContactoDet(ContactDetData.data.GetClientContactDetbyId)


                    const  modal = Modal.success({
                        content: message,
                    });

                    setTimeout(() => {
                        //setLoading(false);
                        //setEditPost(false);
                        modal.destroy();
                    }, 1500);

                }else{
                    Modal.warning({
                        content: message,
                    });
                   // setLoading(false);
        
                } 
                
            } ,
            
            
        })
    }


    const handleBloqclave = (record)=> {
        if(record.id_Sesion!==null)
        {
            let mensaje=''
            mensaje = 'Estas Seguro de Bloquear la Sesion del Usuario ' +record.Nombre+ ' ..??'
                
           

            Modal.confirm({
            title: mensaje,
                
            onOk:async()=>{
                
                const BloqResult = await  SetBloqContact({variables:{
                    Id_contacto:record.Id_contacto,
                    Estado:"Bloqueado",
                }})
            
                const success = BloqResult.data.contactBloqContacto.success
                const message = BloqResult.data.contactBloqContacto.message
            // console.log(success)
                if(success){   
                    const ContactData = await DataContact({variables:{
                        Id_cliente:DataEditClient.Id_cliente
                    }})
                    setDataContacto(ContactData.data.GetClientContactbyId)
                    
                    const ContactDetData = await DataContactDet({variables:{
                        Id_cliente:DataEditClient.Id_cliente
                    }})
                    setDataContactoDet(ContactDetData.data.GetClientContactDetbyId)

                    const ContactIdcontact = await SetContactbyIdContact({variables:{
                        Id_contacto:record.Id_contacto
                    }})
                    setDataContactosing(ContactIdcontact.data.GetClientContactbyIdContact[0])
                const  modal = Modal.success({
                        content: message,
                    });

                    setTimeout(() => {
                        modal.destroy();
                    }, 3000);

                }else{
                    Modal.warning({
                        content: message,
                    });
                
                    
                
                }  
            }   
        })
       }
    }   
    
    const handleDBloqclave = (record)=> {
        if(record.id_Sesion!==null)
        {
            let mensaje=''
            mensaje = 'Estas Seguro de Activar la Sesion del Usuario ' +record.Nombre+ ' ..??'
                
           

            Modal.confirm({
            title: mensaje,
                
            onOk:async()=>{
                
                const BloqResult = await  SetBloqContact({variables:{
                    Id_contacto:record.Id_contacto,
                    Estado:"Activo",
                }})
            
                const success = BloqResult.data.contactBloqContacto.success
                const message = BloqResult.data.contactBloqContacto.message
            // console.log(success)
                if(success){   
                    const ContactData = await DataContact({variables:{
                        Id_cliente:DataEditClient.Id_cliente
                    }})
                    setDataContacto(ContactData.data.GetClientContactbyId)
                    
                    const ContactDetData = await DataContactDet({variables:{
                        Id_cliente:DataEditClient.Id_cliente
                    }})
                    setDataContactoDet(ContactDetData.data.GetClientContactDetbyId)

                    const ContactIdcontact = await SetContactbyIdContact({variables:{
                        Id_contacto:record.Id_contacto
                    }})
                    setDataContactosing(ContactIdcontact.data.GetClientContactbyIdContact[0])
                const  modal = Modal.success({
                        content: message,
                    });

                    setTimeout(() => {
                        modal.destroy();
                    }, 3000);

                }else{
                    Modal.warning({
                        content: message,
                    });
                
                    
                
                }  
            }   
        })
       }
    }   

    const handleCancelEdit = () => {
         setEditClient(false);
    };

    const handleCancelCont = () => {
         setEditCont(false);
    };

    
    if(loading){
        return (
        <div className="site-card-border-less-wrapper">
           
          <Buscar/>
         
        </div>
        )
    }
    return (
         <div className="site-card-border-less-wrapper">
        
            <Card size="small" style={{ textAlign: 'Left', }}  title='Maestro Clientes'  className='card_height'  extra={<IcoButton icon='SettingOutlined' onChildClick={(array) => onGroupClick(array)} submenu={submenu}  />}   >
                <Card size="small" bordered={false} style={{  display: 'flex' }}>
                    <Button onClick={onAdduser} icon={<GlobalOutlined />}>Nuevo Cliente</Button>
                </Card>
                <TableClientes data={clientedata}  Deletecliente={onDeletecliente}  Editcliente={onEditcliente} NuevoContacto={onNuevoContacto}  />
            </Card>
            <ModalClave Visible={Visible} Loading={Loading} handleOk={handleOk} handleCancel={handleCancel }/>
            <ModalCliente newClient={newClient} Loading={Loading} handleSave={handleSave} handleCancelClient={handleCancelClient}/>
            <ModalEditClient EditClient={EditClient} Loading={Loading} handleSaveEdit={handleSaveEdit} handleCancelEdit={handleCancelEdit}  DataEditClient={DataEditClient}   />
            <ModalClieContactos EditCont={EditCont}  DataEditClient={DataEditClient}  Loading={Loading} handleCancelCont={handleCancelCont} dataContacto={dataContacto} dataContactoDet={dataContactoDet} handleNewContact={handleNewContact} handleNewTcontact={handleNewTcontact} handleNewLcontact={handleNewLcontact} handleDelType={handleDelType} dataContactosing={dataContactosing} handleBloqclave={handleBloqclave} handleDBloqclave={handleDBloqclave} handleDelContact={handleDelContact}/>
          
           
        </div>

    );
}

export default Clientes