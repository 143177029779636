import React,{useState} from 'react'
import { Modal, Button, Form, Input, Space, Row, Col,Upload,Card,List, Avatar, Divider,Tooltip  } from 'antd';
import '../../Assets/Css/page.css';
import InfiniteScroll from 'react-infinite-scroll-component';




const ModalPostAdjDet = ({ EditAdjDet, Loading, handleSaveAdjDet, handleCancelAdjDet,dataEditAdjDet,dataAdjDet}) => {
    const [activeTabKey1, setActiveTabKey1] = useState('Ver');
    const [form8] = Form.useForm();
    const formData = new FormData();
    
    const onAdd = async values => {
        form8.resetFields();
        const resp =  await fetch(process.env.REACT_APP_LINK_UPLOAD, {
            method: 'POST',
            body:formData ,
        })
      
        const Arrfilename = await resp.json();
        return handleSaveAdjDet(values,Arrfilename); 
          
    }

    const onView = (values) => {
      
        let url=process.env.REACT_APP_DOWNLOAD+'/'+values
        window.open(url,'_parent');
    }

    const normFile = (e) => {
        formData.append('files',e.file);
       // if (Array.isArray(e)) {
       //     return e;
       // }
        return e && e.fileList;
    };

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;

    };

    const props = {
      beforeUpload: file => {
        return false;
      },
    };

    const tabList = [
    {
        key: 'Subir',
        tab: 'Subir Documento',
    },
    {
        key: 'Ver',
        tab: 'Ver Documento',
    },
    ];
    
     const contentList = {
        Subir: <Form
                form={form8}
                name="IngAdjuntoDet"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                onFinish={onAdd}
            >
                
               
                <Row gutter={16}>
                
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Nombre"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Solicitante',
                        },
                    ]}
                    hasFeedback
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Nombre"
                    />
                </Form.Item>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item 
                    name="Descripcion"
                    tooltip="Ingrese Descripción"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Encargado'  
                        }  
                        
                       
                    ]}
                    hasFeedback  
                    
                >
                    <Input
                        prefix={<Icon type='UserOutlined' className="site-form-item-icon" />}
                        type="text"
                        placeholder="Descripción"
                    />
                </Form.Item>
                </Col>

                </Row>
                
                <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24 }}>
               <Form.Item >
                    <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle
                    rules={[
                        {
                            required: true,
                            message: 'Por favor Seleccione Adjunto'  
                        }  
                    ]}>
                    <Upload.Dragger name="file" {...props}>
                        <p className="ant-upload-drag-icon">
                        <Icon type='InboxOutlined' className="site-form-item-icon" />
                        
                        </p>
                        <p className="ant-upload-text">Haga clic o arrastre el archivo a esta área para cargarlo</p>
                       
                    </Upload.Dragger>
                    </Form.Item>
                </Form.Item>
                </Col>
               
                </Row>
                
                


                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading } >
                        Guardar
                    </Button>
                    <Button key="back" onClick={handleCancelAdjDet}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>,
        Ver: <div
      id="scrollableDiv"
      style={{
        height: 200,
        overflow: 'auto',
        padding: '0 16px',
        border: '1px solid rgba(140, 140, 140, 0.35)',
      }}
    >
      <InfiniteScroll
       // pageStart={0}
        dataLength={dataAdjDet.length}
        
        //hasMore={data1.length < 50}
       // loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
        scrollableTarget="scrollableDiv"
      >
        
        <List
        itemLayout="horizontal"
        dataSource={dataAdjDet}
        renderItem={item => (
            <List.Item
            actions={[<Tooltip title={item.adjunto} color={'blue'} key={'blue'}><Icon type={'FileTextOutlined'}  onClick={() => {onView(item.adjunto) }}/></Tooltip>]}
          >
       
            <List.Item.Meta
           avatar={<Avatar style={{ backgroundColor: "#1890ff" }} icon={<Icon type="UserOutlined" key="handlehead" />} />}
            title={item.title}
            description={item.Descripcion}
            />
        </List.Item>
               )}
        />
        </InfiniteScroll>
    </div>
    
,
    };
    
    const onTab1Change = key => {
        setActiveTabKey1(key);
    };

    return (
        <>
        <Modal
            visible={EditAdjDet}
            title="Agregar Adjunto"
            onCancel={handleCancelAdjDet}
            footer={[]}
        >

        <Card
            style={{ width: '100%' }}
            bordered={false} 
            tabList={tabList}
            activeTabKey={activeTabKey1}
            onTabChange={key => {
            onTab1Change(key);
        }}
      >
        {contentList[activeTabKey1]}
      </Card>
            
        </Modal>
       </> 
    )
}

export default ModalPostAdjDet
