import React,{useEffect,useState} from 'react'
import { Modal,Space,Button,Tabs, PageHeader, Descriptions,Divider,Avatar,List,Tooltip} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useContratadoBancById,useVacacionesPendById } from '../../../hooks/custom-contratos';
import{ useEmpleadoById,useEmpleContactosById } from '../../../hooks/custom-Empleados';
const { TabPane } = Tabs;


const ModalContratadoView = ({ contratoView, handleEditCont,handleCancelContView,DataContrato,creadoPor}) => {
    
   const[Empleado_dataPersonal]= useEmpleadoById();
   const[Empleado_dataBanc]= useContratadoBancById();
   const[Empleado_dataVacPend]= useVacacionesPendById();
   const [ViewPostulanteCont] = useEmpleContactosById();

   const [DataEmpPersonal, SetDataEmpPersonal] = useState([]);
   const [DataEmpBanc, SetDataEmpBanc] = useState([]);
   const [dataContView, setDataContView] = useState([]);
   const [DataContVacaPend, setDataContVacaPend] = useState([]);


    
     useEffect(()=>{

       

        async function getDataViewAll(){
        
            if(contratoView===true){
                 const EmpDataPersonal = await  Empleado_dataPersonal({variables:{
                id_empleado:DataContrato.Id_empleado,
                
                }})
        
                if(EmpDataPersonal.loading===false){
                SetDataEmpPersonal(EmpDataPersonal.data.GetempleadosById)
                }
                
                const EmpDataBanc = await  Empleado_dataBanc({variables:{
                Id_contrato:DataContrato.Id_contrato,
                
                }})
        
                if(EmpDataBanc.loading===false){
                    SetDataEmpBanc(EmpDataBanc.data.GetContratadoBancById)
                }

                const empleadoContView = await  ViewPostulanteCont({variables:{
                    id_empleado: DataContrato.Id_empleado,
                }})
                
                if(empleadoContView.loading===false){
                    setDataContView(empleadoContView.data.GetContactosById)  
                } 

                const EmpDataVacPend = await  Empleado_dataVacPend({variables:{
                Id_contrato:DataContrato.Id_contrato,
                
                }})
        
                if(EmpDataVacPend.loading===false){
                    setDataContVacaPend(EmpDataVacPend.data.GetVacacionesPendById)
                }
                 
            }
        }
        getDataViewAll()

    },[DataContrato, Empleado_dataBanc, Empleado_dataPersonal, Empleado_dataVacPend, ViewPostulanteCont, contratoView])
   

    const Icon = ({ type, ...rest }) => {
        const icons = require(`@ant-design/icons`);
        const Component = icons[type];
        return <Component {...rest} />;
    };

    return (
        <>
        <Modal
            visible={contratoView}
            title={'Postulante'}
            style={{ top: 20 }}
            width={'100vh'}
            onCancel={handleCancelContView}
            footer={[]}
        >
        <Tabs defaultActiveKey="1" destroyInactiveTabPane={true} >
            <TabPane tab="Contratado" key="1">
                <PageHeader
                    title={DataContrato.Nombre}
                    className="site-page-header"
                   // subTitle="Rut: {{DataContrato.Rut}} " 
                   
                    avatar={{src:<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="UserOutlined" key="handlehead2" />} />}}
                  
                >
                <div
                        id="scrollableDiv"
                        style={{
                            height: 200,
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                    <InfiniteScroll
                        dataLength="1"
                        //endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                        <p></p>
                        <Descriptions  size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                            extra={[<Button  onClick={() => {  }}  icon={<Icon type='EditOutlined' />}/> ]}>
                        <Descriptions.Item label="Rut">{DataContrato.Rut}</Descriptions.Item>
                        <Descriptions.Item label="Empresa">{DataContrato.cliente}</Descriptions.Item>
                        <Descriptions.Item label="Fecha_nacimiento">{DataEmpPersonal.Fecha_nacimiento}</Descriptions.Item>
                        <Descriptions.Item label="Estado Civil">{DataEmpPersonal.Estado_civil}</Descriptions.Item>
                        <Descriptions.Item label="Genero">{DataEmpPersonal.Sexo}</Descriptions.Item>
                        <Descriptions.Item label="Nacionalidad">{DataEmpPersonal.Nacionalidad}</Descriptions.Item>
                        <Descriptions.Item label="Comuna">{DataEmpPersonal.comuna_nombre}</Descriptions.Item>
                        <Descriptions.Item label="Dirección">{DataEmpPersonal.Direccion}</Descriptions.Item>
                    </Descriptions>
                    </InfiniteScroll>
                </div>    
                </PageHeader>
            </TabPane>
            <TabPane tab="Laboral" key="2">
                <PageHeader
                    
                    title={DataContrato.Nombre}
                    className="site-page-header"
                // subTitle="Rut: {{DataContrato.Rut}} "
                    
                    avatar={{src:<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="UserOutlined" key="handlehead2" />} />}}
                      extra={[<Tooltip title="Nuevo" color={'blue'} key={'blue'}><Button  onClick={() => {  }}  icon={<Icon type='FormOutlined' />}/></Tooltip> ]}
                >
                    <div
                        id="scrollableDiv"
                        style={{
                            height: 200,
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                    <InfiniteScroll
                        dataLength={1}
                        //endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                    {
                       
                        <>
                        <p></p>
                        <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                            extra={[<Space><Tooltip title="Editar" color={'blue'} key={'blue'}><Button onClick={() => { }} icon={<Icon type='EditOutlined' />}/></Tooltip>
                                    <Tooltip title="Eliminar" color={'blue'} key={'blue'}><Button onClick={() => {  }}  icon={<Icon type='DeleteOutlined' />}/></Tooltip></Space>,
                                    ]}>
                    
                                    
                            <Descriptions.Item label="Cargo">{DataContrato.cargo}</Descriptions.Item>
                            <Descriptions.Item label="Tipo_Contrato">{DataContrato.tcontrato_name}</Descriptions.Item>
                            <Descriptions.Item label="Inicio Contrato">{DataContrato.fecha_ini_contrato}</Descriptions.Item>
                            <Descriptions.Item label="Fin Contrato">{DataContrato.fecha_fin_contrato}</Descriptions.Item>
                            <Descriptions.Item label="Salud">{DataContrato.isapre}</Descriptions.Item>
                            <Descriptions.Item label="Afp">{DataContrato.afp}</Descriptions.Item>
                        
                            
                            

                        </Descriptions>
                        <Divider />
                        </>
                       
                        
                    }
                    </InfiniteScroll>
                    </div>
                </PageHeader>
                
            </TabPane>
            <TabPane tab="Financiero" key="3">
                <PageHeader
                    title={DataContrato.Nombre}
                    className="site-page-header"
                // subTitle="Rut: {{DataContrato.Rut}} "
                
                    avatar={{src:<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="UserOutlined" key="handlehead2" />} />}}
                    extra={[<Tooltip title="Nuevo" color={'blue'} key={'blue'}><Button  onClick={() => {  }}  icon={<Icon type='FormOutlined' />}/></Tooltip> ]}
               
                >
                <div
                        id="scrollableDiv"
                        style={{
                            height: 200,
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                    <InfiniteScroll
                        dataLength={DataEmpBanc.length}
                        //endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                    {
                       DataEmpBanc.map((item) =>(
                        <>
                        <p></p>
                            <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                            extra={[<Space><Tooltip title="Editar" color={'blue'} key={'blue'}><Button onClick={() => {  }}  icon={<Icon type='EditOutlined' />}/></Tooltip>
                                        <Tooltip title="Eliminar" color={'blue'} key={'blue'}><Button onClick={() => {  }} icon={<Icon type='DeleteOutlined' />}/></Tooltip></Space>,
                                    ]}>
                            <Descriptions.Item label="Banco">{item.NombreEntidad}</Descriptions.Item>
                            <Descriptions.Item label="Nro Cuenta">{item.nrocuenta}</Descriptions.Item>
                            
                        
                            </Descriptions>
                            <Divider />
                        </>
                        ))
                        
                    }
                    </InfiniteScroll>
                </div>
                </PageHeader>   
            </TabPane>
             <TabPane tab="Contactos" key="4">
                <PageHeader
                    title={DataContrato.Nombre}
                    className="site-page-header"
                   // subTitle="Rut: {{DataContrato.Rut}} "
                    
                    avatar={{src:<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="UserOutlined" key="handlehead2" />} />}}
                    extra={[<Tooltip title="Nuevo" color={'blue'} key={'blue'}><Button  onClick={() => {  }}  icon={<Icon type='FormOutlined' />}/></Tooltip> ]}
               
                >
                <div
                    id="scrollableDiv"
                    style={{
                        height: 200,
                        overflow: 'auto',
                        padding: '0 16px',
                        border: '1px solid rgba(140, 140, 140, 0.35)',
                    }}
                    >
                    <InfiniteScroll
                        dataLength={dataContView.length}
                        endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                    <p></p>
                     <List
                            itemLayout="horizontal"
                            dataSource={dataContView}
                            renderItem={item => (
                            <List.Item
                                actions={[<Space><Button onClick={() => {  }}  icon={<Icon type='DeleteOutlined' />}/></Space>]}
                            >
                                <List.Item.Meta
                                avatar={<Avatar style={{ backgroundColor: "#1890ff" }} icon={<Icon type={item.Icon} key="handlehead" />} />}
                                title={item.title}
                                description={item.Observaciones}
                                />
                            </List.Item>
                                )}
                            />
                   
                    </InfiniteScroll>
                </div>
                </PageHeader>
            </TabPane>
            <TabPane tab="Vacaciones" key="5">
                <PageHeader
                    title={DataContrato.Nombre}
                    className="site-page-header"
                // subTitle="Rut: {{DataContrato.Rut}} "
                    
                    avatar={{src:<Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }} icon={<Icon type="UserOutlined" key="handlehead2" />} />}}
                    extra={[<Tooltip title="Nuevo" color={'blue'} key={'blue'}><Button  onClick={() => {  }}  icon={<Icon type='FormOutlined' />}/></Tooltip> ]}
             
                >
                <div
                        id="scrollableDiv"
                        style={{
                            height: 200,
                            overflow: 'auto',
                            padding: '0 16px',
                            border: '1px solid rgba(140, 140, 140, 0.35)',
                        }}
                    >
                    <InfiniteScroll
                        dataLength={1}
                        //endMessage={<Divider plain>Es todo, nada más 🤐 </Divider>}
                        scrollableTarget="scrollableDiv"
                    >
                    {
                        DataContVacaPend.map((item) =>(
                        <>
                        <p></p>
                        <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                        extra={[<Space><Button onClick={() => {  }}  icon={<Icon type='EditOutlined' />}/>
                                <Button onClick={() => {  }} icon={<Icon type='DeleteOutlined' />}/></Space>,
                            ]}>
                            <Descriptions.Item label="Dias por Año">{item.diasDeVacacionesPorAno}</Descriptions.Item>
                            <Descriptions.Item label="Dias Acumulados">{item.diasacumulados}</Descriptions.Item>
                            <Descriptions.Item label="Dias Progresivos">{item.diasprogresivos}</Descriptions.Item>
                            <Descriptions.Item label="Total dias">{item.diasTotales}</Descriptions.Item>
                            <Descriptions.Item label="Total Dias tomados">{item.diastotTomados}</Descriptions.Item>
                            <Descriptions.Item label="Dias Restantes">{item.diastotRestantes}</Descriptions.Item>
            
                            </Descriptions>
                            <Divider />
                            </>
                        ))
                        
                    }
                    </InfiniteScroll>
                </div>
                </PageHeader>
            </TabPane>
        </Tabs>
           </Modal>
      
       </> 
    )
   
}

export default ModalContratadoView
