import gql from "graphql-tag";

export const ClientPostulacionesById = gql`
query GetClientPostById($id_Sesion:ID!){
  GetClientPostById(id_Sesion:$id_Sesion) {
    
    success
    message
    id_postulacion_cab
    Id_cliente
    Cliente
    Solicitante
    NombreSolicitante
    Cargo
    DCargo
    Nro_vacante
    Nro_vacantes
    Horario_empleo
    DHorario_empleo
    Tipo_contrato
    DTipo_contrato
    Meses_plazo_fijo
    Sueldo_liquido
    Sueldo_base
    Bono
    DescripcionCargo
    Nivel_cursado
    DNivel_cursado
    Experiencia
    DExperiencia
    Genero
    Rest_nacionalidad
    Drest_nacionalidad
    Rango_edad
    DRango_edad
    Rest_comuna
    Drest_comuna
    Persona_discapacidad
    Dpersona_discapacitada
    Licencia_conducir
    Obs_personas
    Monto_colacion
    Monto_movilizacion
    Ropa
    dropa
    EPP
    dEPP
    Hora_extra
    Dhextra
    Obs_beneficios
    Examenes
    Renovacion
    Drenovacion
    Contrato_planta
    Dcontrato_planta
    Obs_otros
    Estado_id
    Estado
   
    Fecha_creacion
    Creado_por
    
  }
}`;

export const GetClientPostSel = gql`
query GetClientPostSel($id_postulacion_cab:ID!){
  GetClientPostSel(id_postulacion_cab:$id_postulacion_cab){
    success
    message
    id_postulacion_cab
    Id_cliente
    Cliente
    Solicitante
    NombreSolicitante
    Cargo
    DCargo
    Nro_vacante
    Nro_vacantes
    Horario_empleo
    DHorario_empleo
    Tipo_contrato
    DTipo_contrato
    Meses_plazo_fijo
    Sueldo_liquido
    Sueldo_base
    Bono
    DescripcionCargo
    Nivel_cursado
    DNivel_cursado
    Experiencia
    DExperiencia
    Genero
    Rest_nacionalidad
    Drest_nacionalidad
    Rango_edad
    DRango_edad
    Rest_comuna
    Drest_comuna
    Persona_discapacidad
    Dpersona_discapacitada
    Licencia_conducir
    Obs_personas
    Monto_colacion
    Monto_movilizacion
    Ropa
    dropa
    EPP
    dEPP
    Hora_extra
    Dhextra
    Obs_beneficios
    Examenes
    Renovacion
    Drenovacion
    Contrato_planta
    Dcontrato_planta
    Obs_otros
    Estado_id
    Estado
    Fecha_creacion
    Creado_por
    
  }
}`;

export const GetClientPostByClient = gql`
query  GetClientPostByClient($Id_cliente:ID!){
  GetClientPostByClient(Id_cliente:$Id_cliente){
    success
    message
    id_postulacion_cab
    Id_cliente
    Cliente
    Solicitante
    NombreSolicitante
    Cargo
    DCargo
    Nro_vacante
    Nro_vacantes
    Horario_empleo
    DHorario_empleo
    Tipo_contrato
    DTipo_contrato
    Meses_plazo_fijo
    Sueldo_liquido
    Sueldo_base
    Bono
    DescripcionCargo
    Nivel_cursado
    DNivel_cursado
    Experiencia
    DExperiencia
    Genero
    Rest_nacionalidad
    Drest_nacionalidad
    Rango_edad
    DRango_edad
    Rest_comuna
    Drest_comuna
    Persona_discapacidad
    Dpersona_discapacitada
    Licencia_conducir
    Obs_personas
    Monto_colacion
    Monto_movilizacion
    Ropa
    dropa
    EPP
    dEPP
    Hora_extra
    Dhextra
    Obs_beneficios
    Examenes
    Renovacion
    Drenovacion
    Contrato_planta
    Dcontrato_planta
    Obs_otros
    Estado_id
    Estado
    Fecha_creacion
    Creado_por
    
  }
}`;
