import {useState, useEffect} from 'react';
import {useLazyQuery,useQuery} from '@apollo/client'
import {Validatelogin,TokenValidate} from "../model/query/login/Loginuser"

export default function useToken(){
    
    const  getToken = () => {
     
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    }
    const [token, setToken] = useState(getToken());
  
    const saveToken = userToken => {
        localStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken);
    }

    return {
        setToken: saveToken,
        token
    }

}

export const useVerify = () =>{
    
 
    const tokenString = localStorage.getItem('token');
    let token=''
    let useToken = JSON.parse(tokenString);
    if(useToken){
        useToken = useToken.split('||');
        token = useToken[0]
    }  
     
    const { loading, error, data }  = useQuery(Validatelogin, {
                                    variables: { Token:token,  },
                                  
                                });
    
   
    return {loading, error, data};
}

/*export const useVerify = () =>{
    
    const[getTokensverify] = useLazyQuery(Validatelogin);
    const[Tokensverify,setTokensverify] = useState([])
    const[error,SetError] = useState(null)
    const[TokensverifyLoading,setTokensverifyLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  

                const tokenString = localStorage.getItem('token');
                let token=''
                let useToken = JSON.parse(tokenString);
                if(useToken){
                    useToken = useToken.split('||');
                    token = useToken[0]
                }  
               

                const  TokensVerifyData =  await getTokensverify({
                        variables: { Token:token,  },
                        
                    })
                if(TokensVerifyData){
                  setTokensverify(TokensVerifyData.data.GetLogin_validate)
                } 
            }
            catch(error){
                SetError(error.message)
            }
           setTokensverifyLoading(false)

        }
        getDataAll()

    },[getTokensverify])

    return{
        Tokensverify,
        error,
        TokensverifyLoading
    }
}*/




export const useVerifyToken = () =>{
    
    const[getTokens] = useLazyQuery(TokenValidate);
    const[Tokens,setTokens] = useState([])
    const[error,SetError] = useState(null)
    const[TokensLoading,setTokensLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  

                const tokenString = localStorage.getItem('token');
                let token=''
                let useToken = JSON.parse(tokenString);
                if(useToken){
                    useToken = useToken.split('||');
                    token = useToken[0]
                }  
               

                const  TokensData =  await getTokens({
                        variables: { Token:token,  },
                        
                    })
                if(TokensData){
                  setTokens(TokensData.data.GetTokenValidate)
                } 
            }
            catch(error){
                SetError(error.message)
            }
           setTokensLoading(false)

        }
        getDataAll()

    },[getTokens])

    return{
        Tokens,
        error,
        TokensLoading
    }
}

