import React,{useState,useEffect}  from 'react'
import { Modal, Button, Form,  Space, Row, Col,Select} from 'antd';
import '../../Assets/Css/page.css';

import {useClieContactbyId} from '../../hooks/custom-Clientes';


const { Option, OptGroup } = Select;

const ModalPostEditClient = ({EditCliente,EditDataPost, Loading, handleUpdateClient, handleCancelClient}) => {
    
    const [contacto] = useClieContactbyId();
    const [form2] = Form.useForm();
    const [dataContact,setDataContact] = useState([]);
    
  
    
    useEffect(() => {
       
   
        async function getDataViewAll(){
            if (EditCliente) {    
                const Contact = await contacto({variables:{
                    Id_cliente:EditDataPost.Id_cliente,
                }})

                if(Contact.loading===false){
                    setDataContact(Contact.data.GetClientContactbyId)          
                }
            }  
            
        }    
         getDataViewAll()

       
        
        if (EditCliente) {
            form2.setFieldsValue({
                Solicitante: EditDataPost?.Solicitante,  
            });
        }
   
       
   

    }, [EditCliente, EditDataPost, contacto, form2]);
   

    const onCliente = values => {
        
        return handleUpdateClient(values); 
    }

    return (
        <>
        <Modal
            visible={EditCliente}
            title="Editar Cliente"
             //style={{ top: 20 }}
            onCancel={handleCancelClient}
            footer={[]}
        >

      
            <Form
                form={form2}
                name="NuevaPostulacion"
                initialValues={{
                    remember: false,
                    resetFields:true,
                }}
                onFinish={onCliente}
            >
                
               <Row gutter={16}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12 }}>
                <Form.Item
                    name="Solicitante"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese Solicitante',
                        },
                    ]}
                    hasFeedback
                >
                <Select placeholder="Solicitante" >
                <OptGroup label="Solicitante">
                  {dataContact.map((data) => {
                        return (
                            <Option key={data.Id_contacto} value={data.Id_contacto}>
                                {data.Nombre}
                            </Option>
                        );
                    })}
                </OptGroup>      
                </Select>
                </Form.Item>
                </Col>
               
                </Row>
                
                <Form.Item>
                    <Space>
                   
                    <Button htmlType="submit" type="primary" loading={Loading} >
                        Guardar
                    </Button>
                    <Button key="back" onClick={handleCancelClient}>
                        Cancelar
                    </Button>
                    </Space>

                </Form.Item>
            </Form>
               
                   
           
           
        </Modal>
        </> 
    )
}

export default ModalPostEditClient
